import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { tagsQueryPredicates } from '../queries/tagsQueryPredicates';
import { useQueryCacheTagHelpers } from '../utils/useQueryCacheTagHelpers';

type MutationArg = { tagId: string; newName: string };

export const useMutationTagUpdate = () => {
  const { client } = useWoody();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  const { updateCachedTag } = useQueryCacheTagHelpers();

  return useMutation({
    mutationFn: async ({ tagId, newName }: MutationArg) => {
      return client.v2(
        {
          endpoint: '/v2/tags/{tagId}',
          params: {
            tagId,
          },
        },
        {
          method: 'patch',
          body: {
            name: newName,
          },
        },
      );
    },
    onMutate: ({ tagId, newName }) => {
      return updateCachedTag({
        id: tagId,
        name: newName,
      });
    },
    onError: (_e, _v, context) => {
      toast({
        content: 'Something went wrong while creating the tag',
      });

      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
    onSuccess: (tag, variables) => {
      track(AnalyticsEvents.RenamedTag, { tagId: variables.tagId });

      toast({
        content: 'Tag renamed successfully',
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate: tagsQueryPredicates.resourcesAll,
        type: 'active',
      });
    },
  });
};
