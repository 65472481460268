/**
 * Old slate types so we can remove the dependency on the Slate packages
 */

import { EditorJSBlockToolData } from '@/src/components/Tiptap/editorjs.types';

type LeafDecoration = {
  placeholder?: string;
};

type CodeDecoration = {
  token?: string;
  keyword?: boolean;
  comment?: boolean;
  punctuation?: boolean;
  function?: boolean;
};

export type CustomText = {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  strikeThrough?: boolean;
  code?: boolean;
  mark?: boolean;
} & LeafDecoration &
  CodeDecoration;

export enum BlockType {
  Paragraph = 'paragraph',
  Heading = 'heading',
  Link = 'link',
  List = 'list',
  ListItem = 'list-item',
  Check = 'check',
  Warning = 'warning',
  Image = 'image',
  Table = 'table',
  TableRow = 'table-row',
  TableCell = 'table-cell',
  Youtube = 'youtube',
  CodeBlock = 'code-block',
  CodeLine = 'code-line',

  QuoteFigure = 'quote-figure',
  Quote = 'quote',
  QuoteAuthor = 'quote-author',
}

type BlockElement = {
  id: string;
  createdAt: string;
};

type InlineElement = CustomText | LinkElement;

type ParagraphElement = BlockElement & {
  type: BlockType.Paragraph;
  children: InlineElement[];
};

type HeadingElement = BlockElement & {
  type: BlockType.Heading;
  children: InlineElement[];
  level: 1 | 2 | 3 | 4 | 5 | 6;
};

export type LinkElement = BlockElement & {
  type: BlockType.Link;
  url: string;
  children: Descendant[];
};

export type ListItemElement = BlockElement & {
  type: BlockType.ListItem;
  children: (InlineElement | ListElement)[];
};

export type ListElement = BlockElement & {
  type: BlockType.List;
  children: (ListItemElement | CheckElement)[]; // check element is here for helping in HTML conversion
  ordered: boolean;
  isTaskList?: boolean; // also for helping in HTML conversion
};

type CheckElement = BlockElement & {
  type: BlockType.Check;
  children: InlineElement[];
  checked: boolean;
};

export type QuoteFigureElement = BlockElement & {
  type: BlockType.QuoteFigure;
  // 2 children Quote and QuoteAuthor
  // children: [QuoteElement, QuoteAuthorElement] | [QuoteElement];
  children: (QuoteElement | QuoteAuthorElement)[];
};

type QuoteAuthorElement = BlockElement & {
  type: BlockType.QuoteAuthor;
  children: Descendant[];
};

type QuoteElement = BlockElement & {
  type: BlockType.Quote;
  children: Descendant[];
};
export type WarningElement = BlockElement & {
  type: BlockType.Warning;
  children: Descendant[];
};

type ImageElement = BlockElement & {
  type: BlockType.Image;
  children: [{ text: '' }];
  url?: string;
  alt?: string;
};

type CodeBlockElement = BlockElement & {
  type: BlockType.CodeBlock;
  children: Descendant[];
  language?: string;
};

type CodeLineElement = BlockElement & {
  type: BlockType.CodeLine;
  children: InlineElement[];
};

type TableElement = BlockElement & {
  type: BlockType.Table;
  children: TableRowElement[];
};

type TableCellElement = BlockElement & {
  type: BlockType.TableCell;
  children: InlineElement[];
};

type TableRowElement = BlockElement & {
  type: BlockType.TableRow;
  children: TableCellElement[];
};

export type CustomElement =
  | ParagraphElement
  | CodeBlockElement
  | CodeLineElement
  | HeadingElement
  | ListElement
  | ListItemElement
  | CheckElement
  | QuoteFigureElement
  | QuoteElement
  | QuoteAuthorElement
  | WarningElement
  | ImageElement
  | TableElement
  | TableCellElement
  | TableRowElement
  | LinkElement;

export type Descendant = CustomElement | CustomText;

export type FabricDataValue = {
  version: string;
  time: number;
  data: Descendant[];
  blocks: EditorJSBlockToolData[];
};
