import CrispSimilarIcon from '@/src/icons/CrispSimilarIcon';
import { Button } from '@/src/modules/ui/components/Button';
import React from 'react';
import styled from 'styled-components';

interface Props extends React.ComponentPropsWithoutRef<typeof Button> {
  type?: 'button' | 'submit' | 'reset';
}

const StyledButton = styled(Button).attrs((p) => {
  return {
    size: 'xs',
    ...p,
  };
})`
  background: linear-gradient(97.34deg, #0c6dff 16.09%, #8118d3 84.01%);
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 5.5px 8px;
  border-radius: 6px;
`;

const SimilarItemsInlineButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ type = 'button', ...props }, ref) => {
    return (
      <StyledButton {...props} type={type} ref={ref}>
        <CrispSimilarIcon /> Similar
      </StyledButton>
    );
  },
);

SimilarItemsInlineButton.displayName = 'SimilarItemsInlineButton';

export default SimilarItemsInlineButton;
