import { FC } from 'react';
import { IconProps } from './types';

const LinkExternalIcon: FC<IconProps> = ({ style }) => {
  return (
    <svg
      style={style}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-non-draggable
    >
      <path
        fillRule="evenodd"
        fill="currentColor"
        clipRule="evenodd"
        d="M10.5859 2H3.00008V0H14.0001V11H12.0001V3.41421L1.70718 13.7071L0.292969 12.2929L10.5859 2Z"
        data-non-draggable
      />
    </svg>
  );
};

export default LinkExternalIcon;
