import BuzzNoteTextIcon from '@/public/images/icons/BuzzNoteText.svg';
import { NotepadFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';

interface ResourcePreviewPageProps {
  resource: OptimisticDraft<NotepadFdoc>;
}

export const ResourcePreviewListItemNote: React.FC<ResourcePreviewPageProps> = () => {
  return <BuzzNoteTextIcon style={{ width: '1rem', height: '1rem' }} />;
};
