import { useDomContentRect } from '@/src/hooks/useDomRect';
import { TAGS_SECTION_HEIGHT } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/constants';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssTextEllipsis } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import Tooltip from '@/src/ui/Tooltip';
import { PrivateTag } from '@fabric/woody-client';
import Link from 'next/link';
import React from 'react';
import styled, { css } from 'styled-components';

interface ResourcePreviewNameProps {
  tags: PrivateTag[];
  compactAbsolute?: boolean;
}

export const ResourcePreviewTags: React.FC<ResourcePreviewNameProps> = ({
  tags,
  compactAbsolute,
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [rect] = useDomContentRect(containerRef, {
    observeParentResize: true,
  });
  const [itemsVisibility, setItemsPosition] = React.useState({ visible: 0, hidden: 0 });

  React.useEffect(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.clientWidth;

      // this tells us how much space for a first row will be used to render the tags

      const elements = containerRef.current.querySelectorAll('[data-preview-tag-item]');

      /**
       * initial size for the +x special tag
       * we assume that with e.g. 21 tags, there will be +xx - two digit tag. Each letter has a width of 8px
       */
      let occupiedWidth = 14 + elements.length.toString().length * 8;
      let itemsAtTop = 0;
      for (const element of elements) {
        try {
          const itemWidth = (element as HTMLElement).clientWidth;
          const widthWithSpacing = itemWidth + 3; // gap is 3px;
          if (widthWithSpacing + occupiedWidth < containerWidth) {
            occupiedWidth += widthWithSpacing;
            itemsAtTop += 1;
          } else {
            break;
          }
        } catch {
          //
        }
      }
      const itemsHidden = elements.length - itemsAtTop;
      setItemsPosition({
        visible: itemsAtTop,
        hidden: itemsHidden,
      });
    }
  }, [rect?.width, tags.length]);

  if (tags.length === 0) {
    return;
  }

  return (
    <Container compactAbsolute={compactAbsolute}>
      <ContainerInner ref={containerRef}>
        {tags.map((tag, index) => (
          <TagItem
            data-preview-tag-item
            key={tag.id}
            onClick={(e) => e.stopPropagation()}
            as={Link}
            href={`/tags/${tag.id}`}
            style={{
              order: index * 10,
            }}
          >
            {tag.name}
          </TagItem>
        ))}
        {itemsVisibility.hidden ? (
          <Tooltip
            allowTooltipHover
            delay={0}
            placement="top"
            label={
              <TooltipContainer>
                {tags.slice(itemsVisibility.visible).map((tag) => (
                  <TagItem
                    data-preview-tag-item
                    key={tag.id}
                    onClick={(e) => e.stopPropagation()}
                    as={Link}
                    href={`/tags/${tag.id}`}
                  >
                    {tag.name}
                  </TagItem>
                ))}
              </TooltipContainer>
            }
          >
            <TagItemPlus style={{ order: itemsVisibility.visible * 10 - 2 }}>
              +{itemsVisibility.hidden}
            </TagItemPlus>
          </Tooltip>
        ) : null}
      </ContainerInner>
    </Container>
  );
};

const ContainerInner = styled.div`
  display: flex;
  gap: 3px;
  justify-content: center;
  ${containerPreviewCompact} {
    justify-content: flex-start;
  }
  flex-shrink: 0;
  position: relative;
  width: 100%;
  flex-wrap: wrap;

  /** show only first row of tags */
  height: ${TAGS_SECTION_HEIGHT}px;
  overflow: hidden;
`;

const TooltipContainer = styled(ContainerInner)`
  max-width: min(80dvw, 200px);
  height: max-content;
  width: max-content;
`;

const Container = styled.div<{ compactAbsolute?: boolean }>`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  overflow: hidden;
  ${containerPreviewCompact} {
    order: 0;
    padding: 0.6875rem;
    padding-top: 0;
    height: calc(${TAGS_SECTION_HEIGHT}px);
    margin-bottom: 0.75rem;
    background: var(--preview-background-color, ${cssVar['color-bg-primary']});

    ${(p) =>
      p.compactAbsolute &&
      css`
        position: absolute;
        bottom: 100%;
        left: 0;
        background: transparent;
      `}
  }
`;

const TagItem = styled.span`
  padding: 0 3px;
  font-size: 0.75rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: ${TAGS_SECTION_HEIGHT}px;
  color: rgba(${cssVar['color-text-primary-rgb']}, 0.5);
  background: ${cssVar['color-bg-section']};
  border-radius: 5px;
  word-break: keep-all;
  white-space: nowrap;
  ${cssTextEllipsis}
  max-width: 100%;
  display: block;

  &:hover {
    background: #e7e2fc;
    color: ${cssVar['color-app-primary']};
  }
`;

const TagItemPlus = styled(TagItem)`
  text-overflow: initial;
  white-space: normal;
  flex-shrink: 0;
  font-variant-numeric: tabular-nums;
`;
