import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';
import {
  LocationSelectorNodeData,
  LocationSelectorNodePathItem,
} from '@/src/modules/resources/components/DestinationSelector/types';
import { Ancestor, FolderFdoc } from '@/src/types/api';

export const folderToLocationSelectorNodeData = (folder: FolderFdoc): LocationSelectorNodeData => {
  return {
    id: folder.id,
    title: folder.data.name || 'Untitled',
    displayType: 'folder',
  };
};
export const rootToLocationSelectorNodeData = (root: ResourceRoot): LocationSelectorNodeData => {
  return {
    id: root.id,
    title: root.folder.name || 'Untitled',
    displayType: root.subtype === 'inbox' ? ('inbox' as const) : ('space' as const),
  };
};

export const ancestorToLocationSelectorNodePathItem = (
  ancestor: Ancestor,
): LocationSelectorNodePathItem => {
  return {
    id: ancestor.id,
    title: ancestor.name,
  };
};
