import { FdocOfType, NotepadFdoc } from '@/src/types/api';

export const resourceDetailsQueryKeys = {
  pagePreview: (resourceId?: string) => ['resource-page-preview', resourceId],
  spotifyPreview: (url?: string) => ['resource-page-preview', url],
  pageMarkdown: (fdoc?: FdocOfType<'page'>) => ['resource-page-markdown', fdoc?.data.textUrl],
  favicon: (url?: string, size?: number) => ['resource-favicon', url, size],

  storedFileDetails: (fdoc?: FdocOfType<'stored_file'>) => [
    'resource-stored-file-details',
    fdoc?.id,
  ],
  imageDetails: (fdoc?: FdocOfType<'image'>) => ['resource-image-details', fdoc?.id],

  ancestors: (resourceId?: string) => ['resource-ancestors', resourceId],
  notepadState: (fdoc?: NotepadFdoc) => ['resource-notepad-state', fdoc?.id],
  notepadSync: (fdoc?: NotepadFdoc) => ['resource-notepad-sync', fdoc?.id],
};
