import { useMutationCreateSubFolder } from '@/src/modules/resources/mutations/useMutationCreateSubFolder';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Kbd } from '@/src/modules/ui/components/Kbd';
import Modal from '@/src/modules/ui/components/Modal';
import { TextInput } from '@/src/modules/ui/components/TextInput/TextInput';
import { FdocOfType } from '@/src/types/api';
import { noop } from '@/src/utils/noop';
import { DialogProps } from '@radix-ui/react-dialog';
import { useFormik } from 'formik';
import * as yup from 'yup';

export const ModalFolderCreate: React.FC<{
  modalProps: DialogProps;
  parentResourceId?: string;
  onCreate: (data: FdocOfType<'folder'>) => void;
}> = ({ modalProps, parentResourceId, onCreate }) => {
  const mutationCreateSubfolder = useMutationCreateSubFolder();

  const formik = useFormik({
    initialValues: {
      title: '',
    },
    validationSchema: yup.object({
      title: yup.string().min(1, 'Folder name is required').required('Folder name is required'),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: async ({ title }, formik) => {
      if (parentResourceId) {
        mutationCreateSubfolder.mutate(
          {
            parentResourceId,
            folderName: title,
            action: 'new-folder-modal',
          },
          {
            onSuccess: (data) => {
              modalProps.onOpenChange?.(false);
              formik.resetForm();
              onCreate(data as FdocOfType<'folder'>);
            },
          },
        );
      }

      formik.setSubmitting(false);
    },
  });

  return (
    <Modal {...modalProps} open={modalProps.open && !!parentResourceId}>
      <Modal.Portal>
        <Modal.Overlay style={{ zIndex: 140 }} visibleOnMobileViewport />
        <Modal.Content
          style={{ zIndex: 141 }}
          disableMobileFullscreen
          width="xs"
          // default is no focus, we want to focus the input
          onOpenAutoFocus={noop}
          onEscapeKeyDown={(e) => {
            // this prevents closing modals below
            e.stopPropagation();
          }}
        >
          <Modal.Body>
            <form onSubmit={formik.handleSubmit}>
              <Flex gap="sections" direction="column">
                <Flex gap="sectionsShort" direction="column">
                  <Modal.Title>Create folder</Modal.Title>
                  <TextInput
                    autoFocus
                    inputHeight="lg"
                    name="title"
                    type="text"
                    placeholder="Give it a name..."
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    disabled={formik.isSubmitting}
                    onBlur={formik.handleBlur}
                    tabIndex={1}
                  />
                </Flex>
                <Modal.DoubleButtonContainer>
                  <Modal.Button
                    variant="bg-secondary"
                    onClick={() => {
                      modalProps.onOpenChange?.(false);
                    }}
                  >
                    Cancel
                    <Kbd>
                      <Kbd.IconEsc />
                    </Kbd>
                  </Modal.Button>
                  <Modal.Button disabled={formik.isSubmitting || !formik.isValid} type="submit">
                    Create
                    <Kbd>
                      <Kbd.IconEnter />
                    </Kbd>
                  </Modal.Button>
                </Modal.DoubleButtonContainer>
              </Flex>
            </form>
          </Modal.Body>
        </Modal.Content>
      </Modal.Portal>
    </Modal>
  );
};
