import VideoIcon from '@/public/images/icons/Video.svg';
import FileIcon from '@/src/components/FileIcon';
import { useQueryResourceFileLikeDetails } from '@/src/modules/resource-detail/queries/useQueryResourceFileLikeDetails';
import { IconFileName } from '@/src/modules/resources/components/ResourcePreview/components/IconFileName';
import { ResourcePreviewLoadingState } from '@/src/modules/resources/components/ResourcePreview/components/LoadingState';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { cssAspectRatio } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/sharedCss';
import { useResourceStoredMetadata } from '@/src/modules/resources/hooks/useResourceStoredMetadata';
import { Flex } from '@/src/modules/ui/components/Flex';
import { Image } from '@/src/modules/ui/components/Image';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { StoredFileFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import styled from 'styled-components';

interface ResourcePreviewStoredFileProps {
  resource: OptimisticDraft<StoredFileFdoc>;
}

export const ResourcePreviewStoredFile: React.FC<ResourcePreviewStoredFileProps> = ({
  resource,
}) => {
  const {
    isVideo,
    titleWithoutExtension,
    extension,
    thumbnail: thumbnailRaw,
  } = useResourceStoredMetadata(resource);
  const hasThumbnail = !!thumbnailRaw;

  // we need to fetch thumbnail because the urls are alwas regenerated with every fetch
  const { data } = useQueryResourceFileLikeDetails(resource, {
    enabled: hasThumbnail,
  });

  const thumbnail = data?.thumbnail;
  return thumbnail ? (
    <Wrapper>
      <ThumbnailWrapper>
        <ResourcePreviewLoadingState resource={resource} />
        {isVideo && (
          <IconContainer>
            <VideoIcon />
          </IconContainer>
        )}
        <ThumbnailImage
          data-preview-content-wrapper="storef_file_thumbnail"
          src={thumbnail.md}
          alt={titleWithoutExtension}
        />
      </ThumbnailWrapper>
    </Wrapper>
  ) : (
    <Wrapper data-preview-content-wrapper="stored_file_icon">
      <ResourcePreviewLoadingState resource={resource} />
      <FileIcon
        height={90}
        width={120}
        extension={resource.data.extension?.replace('.', '') || '?'}
      />
      <IconFileNameContainer>
        <IconFileName title={titleWithoutExtension} extension={extension} />
      </IconFileNameContainer>
    </Wrapper>
  );
};

const IconFileNameContainer = styled.div`
  padding: 0 0.75rem;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;

  ${containerPreviewCompact} {
    padding: 0 0.6875rem;
  }
`;

const ThumbnailImage = styled(Image)`
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  display: block;
  border-radius: 1rem;
  border: 1px solid rgba(${cssVar['color-text-primary-rgb']}, 0.06);

  ${containerPreviewCompact} {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    border-radius: 0;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  ${cssAspectRatio};

  ${containerPreviewCompact} {
    svg[data-file-icon] {
      // the file type icon
      height: 60px !important;
      width: 90px !important;
    }
  }
`;

const IconContainer = styled.div`
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 1.875rem !important;
    width: 1.875rem !important;
    margin-left: 0.5rem;
  }
`;

const ThumbnailWrapper = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  position: relative;
  max-height: 100%;
  max-width: 100%;
  ${containerPreviewCompact} {
    width: 100%;
    height: 100%;
    @media (hover: hover) {
      ${ThumbnailImage} {
        transition: opacity 0.1s;
      }
      &:hover {
        ${ThumbnailImage} {
          opacity: 0.8;
        }
      }
    }
  }
`;
