/**
 * generates array of items
 * @param length
 * @param of default it's index 0, 1, ...
 * @returns
 */
export const newArrayLength = (length: number): number[] => {
  const array: number[] = [];

  for (let i = 0; i < length; i += 1) {
    array.push(i as number);
  }

  return array;
};
