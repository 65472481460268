import { FaviconImage } from '@/src/modules/ui/components/Favicon/FaviconImage';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled, { css } from 'styled-components';

type Position = 'absolute-top-left';

/**
 *
 * It's a wrapped FaviconImage with a container that has a border and a background color.
 * Mainly used on Resource preview - grid variant
 */
export const ResourcePreviewFavicon: React.FC<{
  pageUrl: string;
  className?: string;
  position?: Position;
}> = ({ className, position, pageUrl }) => {
  return (
    <FaviconContainer className={className} draggable={false} position={position}>
      <StyledFaviconImage pageUrl={pageUrl} sizeToLoad={32} />
    </FaviconContainer>
  );
};

const StyledFaviconImage = styled(FaviconImage)`
  height: 22px;
  width: 22px;
`;

const FaviconContainer = styled.div<{ position?: Position }>`
  width: 2.125rem;
  height: 2.125rem;
  border-radius: 0.375rem;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  border: 1px solid ${cssVar['color-border-secondary']};
  // bg always light because some favicons just don't work on dark mode
  background: #fcfcfd;
  color: #111;

  ${(p) =>
    p.position === 'absolute-top-left' &&
    css`
      position: absolute;
      top: 1.25rem;
      left: 1.25rem;
      box-shadow: 0 0 5px 0 rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.1);
    `}
`;
