import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import Color from 'color';

/**
 * An assortment of random colors.
 */
const userColors = [
  '#d92a2a',
  '#c2a800',
  '#2ad92a',
  '#2ad9d9',
  '#2a2ad9',
  '#d92ad9',
  '#2f4f4f',
  '#2e8b57',
  '#191970',
  '#8b0000',
  '#808000',
  '#ff0000',
  '#ff8c00',
  '#ffd700',
  '#7cfc00',
  '#ba55d3',
  '#e9967a',
  '#00ffff',
  '#ff00ff',
  '#1e90ff',
  '#eee8aa',
  '#dda0dd',
  '#ff1493',
  '#71d271',
  '#87cefa',
];

/**
 * Receives a color string (hex, rgb, hsl) that represents the background color,
 * and returns a color string that represents the foreground color, either white or black.
 * For use together with user colors so they have an appropriate contrast ratio.
 * @param color
 * @returns 'white' | 'black'
 */
export const getTextColor = (color: string) => {
  try {
    const c = Color(color);

    const r = c.red();
    const g = c.green();
    const b = c.blue();

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;

    return yiq >= 140 ? 'black' : 'white';
  } catch (e) {
    return 'white';
  }
};

/**
 * Lightens the given color by blending it with white by the specified amount.
 * This is an alternative to making the color transparent which conflicts with existing background colors.
 * @param {string} color - The color to lighten.
 * @param {number} amount - The amount to blend with white (0 to 1).
 * @returns {string} - The lightened color in the desired format (default is hex).
 * @example
 * getLightenedColor('#000000', 0.2) // returns '#333333'
 * getLightenedColor('#ff0000', 0.5) // returns '#ff8080'
 * getLightenedColor('#00ff00', 0.1) // returns '#19ff19'
 */
export const getLightenedColor = (color: string, amount: number) => {
  try {
    const c = Color(color);
    const white = Color('white');
    const lightenedColor = c.mix(white, amount);

    return lightenedColor.hex();
  } catch (e) {
    return color;
  }
};

/**
 * Gets a color and returns that coloer with lower opacity by given amount.
 * @param color
 * @param amount
 * @returns string
 * @example
 * getTransparentColor('#000000', 0.5) // returns 'rgba(0, 0, 0, 0.5)'
 * getTransparentColor('#000000', 0.1) // returns 'rgba(0, 0, 0, 0.1)'
 * getTransparentColor('#000000', 0.9) // returns 'rgba(0, 0, 0, 0.9)'
 */
export const getTransparentColor = (color: string, amount: number) => {
  try {
    const c = Color(color);

    return c.alpha(amount).toString();
  } catch (e) {
    return color;
  }
};

/**
 * Returns a color string that represents the background color for a user.
 * Based on the user's first and last character of their sub.
 * @param sub string
 * @returns string
 */
export const getUserColorFromId = (sub: string) => {
  const index = (sub.charCodeAt(0) + sub.charCodeAt(sub.length - 1)) % userColors.length;
  const color = userColors[index];

  return color;
};

// The sort order for colorLabels according to design on Figma.
const colorLabelSortOrder = ['#46CD24', '#FFE500', '#FF0000', '#0047FF', '#C72AEE'];

/**
 * Sorts an array of ColorLabel objects based on the color property.
 * The colors are sorted in a specific order: #46CD24, #FFE500, #FF0000, #0047FF, #C72AEE.
 * If a color is not found in the sortOrder, it will be placed at the end of the sorted array.
 *
 * @param {ColorLabel[]} colors - The array of ColorLabel objects to sort.
 * @returns {ColorLabel[]} - The sorted array of ColorLabel objects.
 */
export const sortColorLabels = (colors: ApiColorLabel[]): ApiColorLabel[] => {
  return colors.slice(0).sort((a, b) => {
    const aIndex = colorLabelSortOrder.indexOf(a.hexColor);
    const bIndex = colorLabelSortOrder.indexOf(b.hexColor);

    // If both colors are not found in the sortOrder, they remain in their current order.
    if (aIndex === -1 && bIndex === -1) return 0;

    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    // Sort colors based on their indices in the sortOrder.
    return aIndex - bIndex;
  });
};
