import { JsonObject, LsonObject, createClient } from '@liveblocks/client';
import { createRoomContext } from '@liveblocks/react';
import { WOODY_API_URL } from '../services/woody/woody';
import { DashboardEvent, DashboardUserMeta } from '../types/presence';

export const woodyAuthEndpoint = async (room: string) => {
  const response = await fetch(`${WOODY_API_URL}/liveblocks/auth`, {
    credentials: 'include',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ room }),
  });

  return await response.json();
};

const client = createClient({
  authEndpoint: woodyAuthEndpoint,
});

export const {
  RoomProvider: FdocRoomProvider,
  useOthers: useFdocOthers,
  useSelf: useFdocSelf,
  useStatus: useFdocStatus,
} = createRoomContext<JsonObject, LsonObject, DashboardUserMeta, DashboardEvent>(client);
