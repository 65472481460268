import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { isImageFdoc, isStoredFileFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { ImageFdoc, StoredFileFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { HalFdocStoredFile } from '@fabric/woody-client';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { FileLikeDetails } from '../resource-details.types';
import {
  halImageToFileLikeDetails,
  halStoredFileToFileLikeDetails,
} from '../utils/transformToFileLikeDetails';
import { resourceDetailsQueryKeys } from './resourceDetailsQueryKeys';

// File like details should be able to have a loooong stale time, because the presigned URL is valid for quite a while.
const FILE_LIKE_DETAILS_STALE_TIME_IN_MINUTES = 240;
const FILE_LIKE_DETAILS_REFETCH_INTERVAL_IN_MINUTES = 230; // we refetch a bit before stale, if for some reason the user is still on the page

/**
 * Fetches the details for a stored file (signed URL, etc).
 *
 * @param fdoc
 * @param queryOptions
 * @returns
 */
export const useQueryResourceFileLikeDetails = (
  fdoc?: OptimisticDraft<StoredFileFdoc | ImageFdoc>,
  queryOptions?: Partial<QueryObserverOptions<FileLikeDetails>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: resourceDetailsQueryKeys.pagePreview(fdoc?.id),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;
      console.log('asdasdasd');
      if (fdoc?.type === 'image')
        return halImageToFileLikeDetails(
          getWoodyResponseData(await client.fetchImage(safeResourceId)),
        );
      else
        return halStoredFileToFileLikeDetails(
          getWoodyResponseData(
            await client.fetchStoredFile(safeResourceId),
          ) as unknown as HalFdocStoredFile,
        );
    },
    placeholderData: keepPreviousData,
    ...queryOptions,
    refetchInterval: FILE_LIKE_DETAILS_REFETCH_INTERVAL_IN_MINUTES * 60 * 1000,
    staleTime: FILE_LIKE_DETAILS_STALE_TIME_IN_MINUTES * 60 * 1000,
    enabled: isQueryEnabled([
      !!fdoc?.id,
      queryOptions?.enabled,
      !fdoc?.isDraft,
      fdoc &&
        ((isStoredFileFdoc(fdoc) &&
          (!isResourceStateProcessing(fdoc?.stateProcessing) ||
            fdoc.data.contentType?.startsWith('image/'))) ||
          isImageFdoc(fdoc)),
    ]),
  });

  return query;
};
