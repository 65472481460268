import React, { ReactNode, createContext, useContext, useState, useMemo } from 'react';
import { DragState } from '../hooks/useDraggable';

type DragAndSelectContextProps = {
  selectedIds: string[];
  isDragging: boolean;
  setIsDragging: React.Dispatch<React.SetStateAction<boolean>>;
  dragOriginId: string | null;
  setDragOriginId: React.Dispatch<React.SetStateAction<string | null>>;
  dragState: DragState | null;
  setDragState: React.Dispatch<React.SetStateAction<DragState | null>>;
  clearSelection: () => void;
};

type DragAndSelectProviderProps = {
  children: ReactNode;
  selectedIds: string[];
  clearSelection: () => void;
};

// Create the context
const DragAndSelectContext = createContext<DragAndSelectContextProps | undefined>(undefined);

// Create the provider component
export const DragAndSelectProvider: React.FC<DragAndSelectProviderProps> = ({
  children,
  selectedIds,
  clearSelection,
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragOriginId, setDragOriginId] = useState<string | null>(null);
  const [dragState, setDragState] = useState<DragState | null>(null);

  const value = useMemo(
    () => ({
      selectedIds,
      isDragging,
      setIsDragging,
      dragOriginId,
      setDragOriginId,
      dragState,
      setDragState,
      clearSelection,
    }),
    [selectedIds, isDragging, dragOriginId, dragState, clearSelection]
  );

  return <DragAndSelectContext.Provider value={value}>{children}</DragAndSelectContext.Provider>;
};

// Custom hook to use the context
export const useDragAndSelect = () => useContext(DragAndSelectContext);
