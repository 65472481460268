import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const SearchIcon: FC<IconProps> = ({ color = 'currentColor', size = 18, className, ...props }) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <path
        d="M14.6477 15.7711L10.1932 11.3166C9.79545 11.6348 9.33807 11.8867 8.82102 12.0723C8.30398 12.2578 7.75376 12.3507 7.17045 12.3507C5.72538 12.3507 4.5025 11.8503 3.50182 10.8497C2.5006 9.84841 2 8.62532 2 7.18022C2 5.73514 2.5006 4.512 3.50182 3.51079C4.5025 2.51011 5.72538 2.00977 7.17045 2.00977C8.61556 2.00977 9.83865 2.51011 10.8399 3.51079C11.8406 4.512 12.3409 5.73514 12.3409 7.18022C12.3409 7.76353 12.2481 8.31374 12.0625 8.83079C11.8769 9.34783 11.625 9.80522 11.3068 10.2029L15.7812 14.6774C15.9271 14.8232 16 15.0022 16 15.2143C16 15.4265 15.9205 15.612 15.7614 15.7711C15.6156 15.9169 15.4299 15.9899 15.2045 15.9899C14.9792 15.9899 14.7935 15.9169 14.6477 15.7711ZM7.17045 10.7598C8.16477 10.7598 9.0101 10.4119 9.70636 9.71613C10.4021 9.01987 10.75 8.17454 10.75 7.18022C10.75 6.1859 10.4021 5.3406 9.70636 4.64431C9.0101 3.94855 8.16477 3.60067 7.17045 3.60067C6.17614 3.60067 5.33083 3.94855 4.63455 4.64431C3.93878 5.3406 3.59091 6.1859 3.59091 7.18022C3.59091 8.17454 3.93878 9.01987 4.63455 9.71613C5.33083 10.4119 6.17614 10.7598 7.17045 10.7598Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
