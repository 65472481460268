import EnterIcon from '@/public/images/icons/Enter.svg';
import { useResponsive } from '@/src/hooks/responsive';
import { useInputControls } from '@/src/hooks/useInputControls';
import { useRefocusOnBlur } from '@/src/hooks/useRefocusOnBlur';
import { useMutationTagCreate } from '@/src/modules/tags/mutations/useMutationTagCreate';
import { useMutationTagUpdate } from '@/src/modules/tags/mutations/useMutationTagUpdate';
import { Kbd } from '@/src/modules/ui/components/Kbd';
import { Label } from '@/src/modules/ui/components/Label';
import Modal from '@/src/modules/ui/components/Modal';
import { TextInput } from '@/src/modules/ui/components/TextInput/TextInput';

import { PrivateTag } from '@fabric/woody-client';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

type Props = React.PropsWithChildren<{
  tag?: PrivateTag;
  noTrigger?: boolean;
  triggerAsChild?: boolean;

  open?: boolean;
  setOpen?: (open: boolean) => void;
}>;

const StyledLabel = styled(Label)`
  font-weight: bold;
`;

const CreateOrRenameTagModalContent: React.FC<{
  tag?: PrivateTag;
  onClose: () => void;
}> = ({ tag, onClose }) => {
  const tagNameInput = useInputControls(tag?.name);

  const { mutate: mutateTagCreate } = useMutationTagCreate();
  const { mutate: mutateTagUpdate } = useMutationTagUpdate();

  const { isMobileView } = useResponsive();

  const onSubmit = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault();

      if (tag) {
        mutateTagUpdate({ tagId: tag.id, newName: tagNameInput.value });
      } else {
        mutateTagCreate({ tagName: tagNameInput.value });
      }

      onClose();
    },
    [mutateTagCreate, mutateTagUpdate, tagNameInput.value, tag, onClose],
  );

  const onBlurInput = useRefocusOnBlur(isMobileView);

  return (
    <form onSubmit={onSubmit}>
      <Modal.Header>{tag ? 'Rename tag' : 'Create new tag'}</Modal.Header>
      <Modal.Body>
        <Modal.Description>
          Tags let you add keywords to anything in Fabric, making them easier to find and browse
        </Modal.Description>

        <div className="grid gap-3">
          <StyledLabel htmlFor="tag-name">Name</StyledLabel>
          <TextInput
            id="tag-name"
            name="tag-name"
            data-1p-ignore
            autoComplete="off"
            placeholder="Tag name"
            value={tagNameInput.value}
            onChange={tagNameInput.onChange}
            autoFocus
            onBlur={onBlurInput}
            inputHeight="lg"
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={onClose} color="transparent">
          Cancel <Kbd>ESC</Kbd>
        </Modal.CancelButton>
        <Modal.Button type="submit" data-testid="modal-confirm-button">
          Save
          <Kbd>
            <EnterIcon />
          </Kbd>
        </Modal.Button>
      </Modal.Footer>
    </form>
  );
};

const CreateOrRenameTagModal: React.FC<Props> = ({
  tag,
  children,
  triggerAsChild = false,
  noTrigger = false,
  open: openProp,
  setOpen: setOpenProp,
}) => {
  const [_open, _setOpen] = useState(false);
  const open = useMemo(() => openProp ?? _open, [openProp, _open]);
  const setOpen = useMemo(() => setOpenProp ?? _setOpen, [setOpenProp, _setOpen]);

  return (
    <Modal open={open} onOpenChange={setOpen}>
      {!noTrigger && <Modal.Trigger asChild={triggerAsChild}>{children}</Modal.Trigger>}
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content>
          <CreateOrRenameTagModalContent tag={tag} onClose={() => setOpen(false)} />
        </Modal.Content>
      </Modal.Portal>
    </Modal>
  );
};

export default CreateOrRenameTagModal;
