import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { NotepadFdoc } from '@/src/types/api';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { resourceDetailsQueryKeys } from './resourceDetailsQueryKeys';

/**
 * Fetch a notepad yjs state.
 *
 * @param fdoc
 * @param queryOptions
 * @returns
 */
export const useQueryNotepadResourceState = (
  fdoc?: NotepadFdoc,
  queryOptions?: Partial<QueryObserverOptions<Uint8Array>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: resourceDetailsQueryKeys.notepadState(fdoc),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;
      const response = await client.v2({
        endpoint: '/v2/notepads/{resourceId}/state',
        params: {
          resourceId: safeResourceId,
        },
      });

      return new Uint8Array(await response.arrayBuffer());
    },
    staleTime: 0,
    refetchInterval: false,
    retry: false,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
    enabled: isQueryEnabled([!!fdoc?.id, queryOptions?.enabled]),
  });

  return {
    ...query,
  };
};
