import plur from 'plur';
import React, { useMemo } from 'react';

import useAuthStore from '@/src/hooks/auth';
import { useResponsive } from '@/src/hooks/responsive';
import { Space } from '@/src/modules/spaces/spaces.types';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import { Fdoc } from '@/src/types/api';
import { addHyphenationHints } from '@/src/utils/text';
import { SPOTIFY_URL_REGEX } from '@/src/utils/url-regex';
import clsx from 'clsx';
import { shallow } from 'zustand/shallow';
import { OfViewMode } from '../../ViewModeSwitcher/ViewModeSwitcher';
import { useFdocItemContext } from '../FdocItem';
import styles from './FdocItemComments.module.scss';

const FdocItemComments: React.FC<{
  fdoc: Fdoc;
  viewMode: OfViewMode;
  list?: Space;
}> = ({ fdoc, viewMode }) => {
  const { isMobileCard } = useFdocItemContext();
  const user = useAuthStore((state) => state.user, shallow);
  const { isMobile } = useResponsive();

  const showFirstComment = useMemo(() => {
    if (isMobile()) return fdoc.commentCount === 1 && fdoc.commentPinned?.user.id === fdoc.user?.id;

    // only show if the first comment is by the owner
    return fdoc.commentPinned?.user.id === fdoc.user?.id;
  }, [fdoc.commentCount, fdoc.commentPinned?.user.id, fdoc.user?.id, isMobile]);

  const showOwner = useMemo(() => {
    return user?.id !== fdoc.user?.id && !showFirstComment;
  }, [user?.id, fdoc.user?.id, showFirstComment]);

  const users = useMemo(() => {
    const users = fdoc.commenters?.filter((u) => showFirstComment && u.id !== fdoc.user?.id);

    if (showOwner) {
      // remove any occurence of the owner and then add it in the end
      users.splice(
        users.findIndex((u) => u.id === fdoc.user?.id),
        1,
      );

      if (!fdoc.user) return users;
      users.push(fdoc.user);
    }

    return users;
  }, [fdoc.commenters, fdoc.user, showFirstComment, showOwner]);

  const noCommentsAndNotOwner = fdoc.commentCount === 0 && !showOwner;
  const noBuffer = !['text', 'page', 'notepad'].includes(fdoc.type);
  const withLine = ['text', 'notepad'].includes(fdoc.type) && !isMobileCard;

  const isCustomSpotifyCard = useMemo(
    () =>
      fdoc?.type === 'page' && fdoc.data.pageUrl.match(SPOTIFY_URL_REGEX) && viewMode !== 'List',
    [fdoc, viewMode],
  );
  const absoluteComments =
    ['image', 'page', 'stored_file', 'folder'].includes(fdoc.type) &&
    viewMode === 'Grid' &&
    !isCustomSpotifyCard;

  const totalComments = (fdoc.commentCount ?? 0) - (showFirstComment ? 1 : 0);

  if (!fdoc.commenters && !absoluteComments) return null;

  if (noBuffer && noCommentsAndNotOwner && !absoluteComments) return null;

  if (noCommentsAndNotOwner && !absoluteComments)
    return (
      <div
        className={clsx(styles.item__buffer, absoluteComments && styles.item__comments__absolute)}
      />
    );

  return (viewMode === 'Grid' || viewMode === 'Sort') &&
    fdoc.commentCount &&
    fdoc.commentCount > 0 ? (
    <div
      className={clsx(
        styles.item__comments__wrapper,
        isMobileCard && styles.item__comments__wrapper__mobile,
        withLine && styles.with_line,
        absoluteComments && styles.item__comments__absolute,
        fdoc.isDirectShared && styles.item__comments__direct_share,
      )}
      style={{
        opacity: noCommentsAndNotOwner ? 0 : 1,
        pointerEvents: noCommentsAndNotOwner ? 'none' : 'all',
      }}
    >
      {(fdoc.commentPinned || fdoc.commentCount > 0) && (
        <div className={styles.first_comment} data-or-obscured>
          {fdoc.commentPinned && (
            <AvatarUser
              key={`first_comment__${fdoc.commentPinned.id}`}
              user={fdoc.commentPinned.user}
              size={16}
            />
          )}
          {fdoc.commentCount === 1 && fdoc.commentPinned ? (
            <span className={styles.text}>
              {addHyphenationHints(fdoc.commentPinned.content, 8)}
            </span>
          ) : (
            <span className={styles.text}>
              {fdoc.commentCount} {plur('comment', fdoc.commentCount)}
            </span>
          )}
        </div>
      )}
    </div>
  ) : (
    <>
      {(showFirstComment || totalComments > 0) && (
        <div
          className={clsx(
            styles.item__comments,
            'dashboard_scrollbar',
            viewMode === 'List' && styles.minimum__width__list__mode,
          )}
          data-testid="item-comment-section"
          data-or-obscured
        >
          {showFirstComment && fdoc.commentPinned && (
            <div className={styles.first_comment}>
              <AvatarUser
                key={`first_comment__${fdoc.commentPinned.id}`}
                user={fdoc.commentPinned.user}
                size={16}
              />
              <span className={styles.text}>
                {addHyphenationHints(fdoc.commentPinned.content, 8)}
              </span>
            </div>
          )}

          {totalComments > 0 && (
            <div className={styles.item__comments__total}>
              <div className={styles.item__comments__users}>
                {users?.map((user) => (
                  <AvatarUser key={`comments_users__${user.id}`} user={user} size={16} />
                ))}
              </div>

              {totalComments > 0 && (
                <span>
                  {totalComments} {plur('comment', totalComments)}
                </span>
              )}
            </div>
          )}
        </div>
      )}
      {totalComments === 0 && users.length > 0 && (
        <div
          className={styles.item__comments__users}
          style={{
            paddingRight: 10,
          }}
        >
          {users?.map((user) => (
            <AvatarUser key={`comments_users__${user.id}`} user={user} size={16} />
          ))}
        </div>
      )}
    </>
  );
};

export default FdocItemComments;
