import React, { useState } from 'react';

type ObjectStateValue = Record<string, unknown> | Record<string, unknown>[];

type OpenObjectState<T extends ObjectStateValue> = {
  value: T | null;
  set: (object: T | null) => void;
  clear: VoidFunction;
};

export type OpenObjectStateOnSet<T extends ObjectStateValue> = (
  boolState: Omit<OpenObjectState<T>, 'set'>,
) => Promise<unknown> | void;

/**
 * just a naming wrap for useBoolState
 * @param defaultState
 */
export const useObjectState = <T extends ObjectStateValue>(
  defaultState: T | null = null,
  options: {
    onSet?: OpenObjectStateOnSet<T>;
  } = {},
): OpenObjectState<T> => {
  const [object, setObject] = useState<T | null>(defaultState);

  const clear = React.useCallback(() => {
    setObject(null);
  }, [setObject]);

  const { onSet } = options;

  const set = React.useCallback(
    (object: T | null) => {
      setObject(object);
      onSet?.({ value: object, clear });
    },
    [onSet, clear],
  );

  const state: OpenObjectState<T> = React.useMemo(() => {
    return {
      value: object,
      clear,
      set,
    };
  }, [object, clear, set]);

  return state;
};
