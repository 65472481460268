import { useReferencedFn } from '@/src/hooks/useReferencedFn';
import { useEffect, useState } from 'react';

/**
 * A custom React hook that handles asynchronous operations and returns the result.
 *
 * @template T The type of the value returned by the asynchronous function.
 * @param {() => Promise<T>} asyncFunction An asynchronous function that returns a Promise.
 * @returns {T | undefined} The resolved value of the Promise, or undefined if the Promise hasn't resolved yet.
 *
 * This hook manages the state of an asynchronous operation, ensuring that:
 * 1. The component using this hook re-renders when the async operation completes.
 * 2. State updates are not performed if the component unmounts before the async operation finishes.
 * 3. The async function is called every time the component mounts or the asyncFunction reference changes.
 *
 * Usage example:
 * const data = useAsyncValue(() => fetchDataFromAPI());
 */
export function useAsyncValue<T>(asyncFunction: () => Promise<T>): T | undefined {
  const [value, setValue] = useState<T | undefined>(undefined);
  const functionRef = useReferencedFn(asyncFunction);

  useEffect(() => {
    let isMounted = true;

    functionRef.current().then((result) => {
      if (isMounted) {
        setValue(result);
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return value;
}
