import useModifierKeysStore from '@/src/store/modifierKeys';
import isHotkey from 'is-hotkey';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDragAndSelect } from '../lib/DragAndSelectContext';
import { ObjectDragEvent, ObjectDragOver } from '../types/draggable';
import { useDebouncedCallback } from './useDebouncedCallback';
import useDeferred from './useDeferred';

export type DragState = { width: number; height: number; x: number; y: number };
type DragOptions = {
  objectId?: string;
  ref: HTMLElement | null;
  disabled?: boolean;
  stopPropagation?: boolean;
  scale?: number;
  objectParentId?: string | null;
  onDragEnd?: () => void;
  onDragStart?: () => void;
};

const useDraggable = ({
  objectId: _objectId,
  ref,
  disabled,
  stopPropagation,
  scale: _scale = 1,
  onDragEnd,
  onDragStart,
  objectParentId,
}: DragOptions) => {
  const [isLocalDragging, setIsLocalDragging] = useState(false);
  const [localDragState, setLocalDragState] = useState<DragState | null>(null);
  const lastDragElements = useRef<Element[]>([]);
  const dragAndSelectContext = useDragAndSelect();
  const dragAndSelectContextRef = useRef(dragAndSelectContext);

  const objectId = useMemo(() => {
    return _objectId;
  }, [_objectId]);
  const scale = useMemo(() => {
    return _scale ?? 1;
  }, [_scale]);

  const { meta, shift } = useModifierKeysStore();
  const modifierOperation = meta || shift;

  useEffect(() => {
    dragAndSelectContextRef.current = dragAndSelectContext;
  }, [dragAndSelectContext]);

  const isDragging = useMemo(() => {
    return Boolean(
      (!disabled && isLocalDragging) ||
        (objectId &&
          dragAndSelectContext?.isDragging &&
          dragAndSelectContext.selectedIds.includes(objectId)),
    );
  }, [
    disabled,
    dragAndSelectContext?.isDragging,
    dragAndSelectContext?.selectedIds,
    isLocalDragging,
    objectId,
  ]);

  const sendDragOut = useCallback(
    (elements?: Element[]) => {
      if (!objectId || disabled) return;

      const dragOut: CustomEvent<ObjectDragEvent> = new CustomEvent('object-drag-out', {
        detail: { objectParentId, objectId, objectIds: dragAndSelectContext?.selectedIds },
      });
      lastDragElements.current.forEach((element) => {
        if (!elements || !elements.includes(element)) {
          element.dispatchEvent(dragOut);
        }
      });

      lastDragElements.current = elements ?? [];
    },
    [disabled, dragAndSelectContext?.selectedIds, objectId, objectParentId],
  );

  const sendDragOver = useCallback(
    (x: number, y: number) => {
      if (!objectId || disabled) return;

      const rect = ref?.getBoundingClientRect();

      const dragOver: CustomEvent<ObjectDragOver> = new CustomEvent('object-drag-over', {
        detail: {
          objectParentId,
          objectId,
          objectIds: dragAndSelectContext?.selectedIds,
          x: x,
          y: y,
          height: localDragState?.height ?? 0,
          width: localDragState?.width ?? 0,
          rect: {
            top: rect?.top ?? 0,
            left: rect?.left ?? 0,
            width: rect?.width ?? 0,
            height: rect?.height ?? 0,
          },
        },
      });

      // get the topmost data-droppable element
      const elements = document
        .elementsFromPoint(x, y)
        .filter((element) => element.getAttribute('data-droppable') === 'true' && element !== ref);
      const element = elements[0];

      if (element) {
        element.dispatchEvent(dragOver);
        sendDragOut([element]);
      } else {
        elements.forEach((element) => {
          element.dispatchEvent(dragOver);
        });
        sendDragOut(elements);
      }
    },
    [
      ref,
      disabled,
      dragAndSelectContext?.selectedIds,
      localDragState?.height,
      localDragState?.width,
      objectId,
      sendDragOut,
      objectParentId,
    ],
  );

  const sendDragOverDebounced = useDebouncedCallback(sendDragOver, 20);

  const sendDragDrop = useCallback(
    (x: number, y: number) => {
      if (!objectId || disabled) return;

      const dragDrop: CustomEvent<ObjectDragEvent> = new CustomEvent('object-drag-drop', {
        detail: {
          objectParentId,
          objectId,
          objectIds: dragAndSelectContext?.selectedIds,
        },
      });

      const elements = document
        .elementsFromPoint(x, y)
        .filter((element) => element.getAttribute('data-droppable') === 'true' && element !== ref);
      const element = elements[0];

      if (element) {
        element.dispatchEvent(dragDrop);
      } else {
        const uniqueElements = Array.from(new Set(elements));
        uniqueElements.forEach((element) => {
          // make sure the element contains the data-droppable and that it is true
          if (element.getAttribute('data-droppable') !== 'true') return;
          element.dispatchEvent(dragDrop);
        });
      }
    },
    [disabled, dragAndSelectContext?.selectedIds, objectId, ref, objectParentId],
  );

  const resetTimeoutRef = useRef<number | null>(null);

  const onMouseDown = (mouseDownEvent: React.MouseEvent) => {
    if (
      mouseDownEvent.button !== 0 ||
      !ref ||
      !objectId ||
      disabled ||
      modifierOperation ||
      (mouseDownEvent.target as HTMLElement).hasAttribute('data-non-draggable')
    )
      return;

    if (stopPropagation) {
      mouseDownEvent.stopPropagation();
    }

    const startX = mouseDownEvent.clientX;
    const startY = mouseDownEvent.clientY;
    let unlocked = false;
    let offsetX = 0;
    let offsetY = 0;

    const onMouseMove = (e: MouseEvent) => {
      if (!ref) return;

      // if it moves more than 5px, it's a drag
      if ((Math.abs(e.clientX - startX) > 10 || Math.abs(e.clientY - startY) > 10) && !unlocked) {
        const rect = ref.getBoundingClientRect();
        const state: DragState = { width: rect.width, height: rect.height, x: rect.x, y: rect.y };
        setIsLocalDragging(true);
        onDragStart?.();
        setLocalDragState(state);

        if (dragAndSelectContext) {
          if (!dragAndSelectContext.selectedIds.includes(objectId))
            dragAndSelectContext.clearSelection();
          dragAndSelectContext.setDragOriginId(objectId);
          dragAndSelectContext.setIsDragging(true);
          dragAndSelectContext.setDragState(state);
        }

        offsetX = rect.width / 2;
        offsetY = rect.height / 2;
        unlocked = true;

        // document.removeEventListener('mousemove', onMouseMove);
      }

      if (unlocked) {
        setLocalDragState((dragState) => {
          if (!dragState) return dragState;

          const newState = {
            ...dragState,
            x: e.clientX - offsetX,
            y: e.clientY - offsetY,
          };

          return newState;
        });

        sendDragOverDebounced(e.clientX, e.clientY);
      }
    };

    const onMouseUp = (e: MouseEvent) => {
      if (unlocked) {
        sendDragDrop(e.clientX, e.clientY);
      }

      onDragEnd?.();

      if (isLocalDragging && stopPropagation) {
        mouseDownEvent.stopPropagation();
      }

      if (resetTimeoutRef.current) cancelAnimationFrame(resetTimeoutRef.current);

      resetTimeoutRef.current = window.requestAnimationFrame(() => {
        setIsLocalDragging(false);
        setLocalDragState(null);

        if (dragAndSelectContext) {
          dragAndSelectContext.setDragOriginId(null);
          dragAndSelectContext.setIsDragging(false);
          dragAndSelectContext.setDragState(null);
        }
      });

      window.removeEventListener('mouseup', onMouseUp, true);
      window.removeEventListener('mousemove', onMouseMove, true);
      window.removeEventListener('keydown', onKeyDown, true);
    };

    const onKeyDown = (e: KeyboardEvent) => {
      if (!isHotkey('esc', e)) return;
      e.stopPropagation();

      if (resetTimeoutRef.current) cancelAnimationFrame(resetTimeoutRef.current);

      onDragEnd?.();

      setIsLocalDragging(false);
      setLocalDragState(null);

      if (dragAndSelectContext) {
        dragAndSelectContext.setDragOriginId(null);
        dragAndSelectContext.setIsDragging(false);
        dragAndSelectContext.setDragState(null);
      }

      window.removeEventListener('mouseup', onMouseUp, true);
      window.removeEventListener('mousemove', onMouseMove, true);
      window.removeEventListener('keydown', onKeyDown, true);
    };

    window.addEventListener('mousemove', onMouseMove, true);
    window.addEventListener('mouseup', onMouseUp, true);
    window.addEventListener('keydown', onKeyDown, true);
  };

  const selectObjectIndex = useMemo(() => {
    if (!isDragging || !objectId) return -1;

    return (
      dragAndSelectContext?.selectedIds
        .filter((id) => id !== dragAndSelectContext.dragOriginId)
        .indexOf(objectId) ?? -1
    );
  }, [dragAndSelectContext?.selectedIds, isDragging, objectId, dragAndSelectContext?.dragOriginId]);

  // Position is left to be handled by what calls this function
  // This transform is just for a visual effect on rotation and slight position adjustments in case of multiple selections
  const draggingStyles: CSSProperties | undefined = useMemo(() => {
    if (!isDragging || !objectId)
      return {
        transform: 'rotate(0deg) scale(1)',
      };

    // Default dragging styles are:
    // - 8deg rotation
    // - zIndex 100

    // If we are multi selecting, we will add a few adjustments:
    // - origin will have the default styles
    // - every other element will have 50 zIndex
    // - every other element will by index rotate negatively every 2 degrees
    // - every other element will also be offsetted with translateX and translateY slightly down and right
    // and rotating around that point, e.g. state position + 10px down and right and then rotate around that point

    if (isLocalDragging)
      return {
        transform: `rotate(4deg) scale(${scale})`,
        zIndex: 100,
      };

    const zIndex = -1 - selectObjectIndex;
    const indexChange = selectObjectIndex + 1;
    const rotation = indexChange * 2;
    const translateX = indexChange * 20;
    const translateY = indexChange * 15;

    return {
      transform: `rotate(-${rotation}deg) translateX(${translateX}px) translateY(${translateY}px)`,
      zIndex,
      // boxShadow: '0 0 20px 2px rgba(var(--fabric-color-text-primary-rgb), 0.2)',
      pointerEvents: 'none',
    };
  }, [isDragging, objectId, selectObjectIndex, scale, isLocalDragging]);

  // This is a recommendation returned to the items so they can decide if they should render or not
  // Essentially if we are in a dragAndSelectContext we won't show more than 4 elements (including the origin)
  // so we check the index of the element and if it's more than 3 we return false otherwise true
  // If the dragAndSelectContext is null, we return true
  const shouldRender = useMemo(() => {
    if (!dragAndSelectContext || !objectId || dragAndSelectContext.dragOriginId === objectId)
      return true;

    return selectObjectIndex < 3;
  }, [dragAndSelectContext, objectId, selectObjectIndex]);

  const deferredDraggingStyles = useDeferred(draggingStyles);

  if (disabled) {
    return {
      onMouseDown: undefined,
      isDragging: false,
      dragState: null,
      draggingStyles: undefined,
      shouldRender: true,
      isDragParent: false,
    };
  }

  return {
    onMouseDown,
    isDragging,
    dragState: localDragState,
    draggingStyles: deferredDraggingStyles,
    shouldRender,
    isDragParent: localDragState !== null && dragAndSelectContext?.dragOriginId === objectId,
  };
};

export default useDraggable;
