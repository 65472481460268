import { cssVar } from '@/src/modules/ui/theme/variables';
import { Content as ContentPrimitive } from '@radix-ui/react-dropdown-menu';
import styled, { keyframes } from 'styled-components';

const inAnimation = keyframes`
  from {
    transform: translateY(15px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const outAnimation = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(15px);
    opacity: 0;
  }
`;

export const Content = styled(ContentPrimitive)`
  background: ${cssVar['color-bg-secondary']};
  border: ${cssVar['color-border-primary']} 1px solid;
  box-shadow: 0px 1px 13.5px 0px #80808040;
  border-radius: 5px;
  width: max-content;
  padding: 0.25rem;
  font-size: 12px;
  font-weight: 600;

  &[data-state='open'] {
    animation: ${inAnimation} 0.1s ease-out forwards;
  }

  &[data-state='closed'] {
    animation: ${outAnimation} 0.1s ease-out forwards;
  }
  // style parent
  div:has(> &) {
    z-index: 126 !important;
  }
`;
