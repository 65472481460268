import clsx from 'clsx';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Page } from 'react-pdf';
import RenderIfVisible from 'react-render-if-visible';
import PagePlaceholder from './PagePlaceholder';
import { usePDFContent } from './PDF';
import styles from './RowPage.module.scss';
import { logTrackerIssue, TrackerIssueKind } from '@/src/lib/or';
import { OnError } from 'react-pdf/dist/cjs/shared/types';
import useDeferred from '@/src/hooks/useDeferred';

const RowPage: React.FC<{
  pageNumber: number;
  width: number;
  style?: React.CSSProperties;
  root: HTMLElement;
}> = ({ pageNumber, width, style, root }) => {
  const [ref, setRef] = useState<HTMLElement | null>(null);
  const { getEstimatedHeight, currentPage, scale, setCurrentPage } = usePDFContent();

  const roundedScale = useMemo(() => Math.floor(scale * 100) / 100, [scale]);
  const deferredScale = useDeferred(roundedScale);

  const height = useMemo(
    () => getEstimatedHeight(pageNumber, width, true),
    [getEstimatedHeight, pageNumber, width]
  );

  const visibleOffset = useMemo(
    () => (roundedScale > 1 ? height * 2 : height * 3),
    [roundedScale, height]
  );
  const initialVisible = useMemo(
    () => (roundedScale > 1 ? pageNumber < 2 : pageNumber < 4),
    [roundedScale, pageNumber]
  );

  const [isResizing, setIsResizing] = useState<boolean>(false);
  const debounce = useRef<number | null>(null);
  useEffect(() => {
    setIsResizing(true);

    if (debounce.current) clearTimeout(debounce.current);
    debounce.current = window.setTimeout(() => {
      setIsResizing(false);
    }, 300);

    return () => {
      if (debounce.current) clearTimeout(debounce.current);
    };
  }, [width]);

  useEffect(() => {
    // set a intersect observer when we hit the ref we set the current page
    if (!ref) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setCurrentPage(pageNumber);
        }
      },
      {
        threshold: 0.2 / roundedScale,
        root,
      }
    );

    observer.observe(ref);
  }, [ref, setCurrentPage, pageNumber, roundedScale, root]);

  const onLoadError: OnError = (error) => {
    logTrackerIssue({
      kind: TrackerIssueKind.PDF_FAILED_LOADING_PAGE,
      payload: {
        index: pageNumber,
        currentPage,
        width,
        height,
        error: {
          message: error.message,
          stack: error.stack ?? '',
          raw: error,
        },
      },
    });
  };

  const onRenderError: OnError = (error) => {
    logTrackerIssue({
      kind: TrackerIssueKind.PDF_FAILED_RENDERING_PAGE,
      payload: {
        index: pageNumber,
        currentPage,
        width,
        height,
        error: {
          message: error.message,
          stack: error.stack ?? '',
          raw: error,
        },
      },
    });
  };

  return (
    <>
      <div
        key={pageNumber}
        ref={setRef}
        data-page-index={pageNumber}
        style={{
          position: 'relative',
          width: width,
          height: height,
          willChange: 'width, height',
          backgroundColor: 'white',
          overflow: 'hidden',
        }}
      >
        <RenderIfVisible
          visibleOffset={visibleOffset}
          root={root}
          rootElement="div"
          defaultHeight={height}
          placeholderElement="div"
          initialVisible={initialVisible}
          stayRendered={pageNumber === currentPage}
          key={pageNumber}
        >
          <PagePlaceholder pageNumber={pageNumber} absolute />

          <div
            className={styles.wrapper}
            style={{
              ...style,
              width: width,
              height: height,
              willChange: 'width, height',
              transform: `scale(${1 / deferredScale})`,
              transformOrigin: '0 0',
            }}
          >
            {!isResizing && (
              <Page
                key={`${pageNumber}-page`}
                renderMode="canvas"
                pageNumber={pageNumber}
                width={width}
                height={height}
                className={clsx(styles.page)}
                scale={deferredScale}
                loading={<></>}
                onLoadError={onLoadError}
                onRenderError={onRenderError}
              />
            )}
          </div>
        </RenderIfVisible>
      </div>
    </>
  );
};

export default RowPage;
