import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../../ui/components/Spinner';
import IndexingIcon from '@/src/icons/IndexingIcon';
import { cssVar } from '../../ui/theme/variables';
import Tooltip from '@/src/ui/Tooltip';

const StateSpinnerContainer = styled.div<{
  size?: number;
  color?: string;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${(props) => props.color ?? cssVar['color-app-primary']};
  --spinner-size: ${(props) => props.size ?? 48}px;
`;

type StateSpinnerProps = React.ComponentPropsWithoutRef<typeof Spinner> & {
  isIndexing?: boolean;
  loadingTooltip?: string;
};

const IconWrapper = styled.div`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  width: var(--spinner-size);
  height: var(--spinner-size);
`;

const StateSpinner = React.forwardRef<HTMLDivElement, StateSpinnerProps>(
  ({ isIndexing, size, color, loadingTooltip, ...props }, ref) => {
    return (
      <Tooltip label={isIndexing ? 'Indexing and analyzing with AI' : loadingTooltip}>
        <StateSpinnerContainer ref={ref} size={size} color={color}>
          <Spinner size={size} {...props} />
          {isIndexing && (
            <IconWrapper
              style={{
                width: size,
                height: size,
              }}
            >
              <IndexingIcon size={Math.round((size ?? 48) / 2.2)} />
            </IconWrapper>
          )}
        </StateSpinnerContainer>
      </Tooltip>
    );
  },
);

StateSpinner.displayName = 'StateSpinner';

export default StateSpinner;
