import { cssVar } from '@/src/modules/ui/theme/variables';
import styled, { css } from 'styled-components';

export const EmbedWrapper = styled.div<{ orientation?: 'vertical' }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${cssVar['color-bg-secondary']};
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;

  ${(p) =>
    p.orientation === 'vertical'
      ? css`
          width: max-content;
          margin: 0 auto;
          max-width: 100%;
          iframe {
            aspect-ratio: 9 / 16;
          }
        `
      : css`
          iframe {
            width: 100%;
            aspect-ratio: 16 / 9;
          }
        `}
`;
