import type { ResourceTags } from '@/src/modules/tags/queries/useQueryResourceTags';
import { PrivateTag } from '@fabric/woody-client';

export const getSelectedAndSemiselectedTagIds = (data: ResourceTags[] = []) => {
  const mapUniqueTags = new Map<string, PrivateTag>();

  data
    .map((resource) => resource.tags)
    .flat()
    .forEach((tag) => {
      if (!mapUniqueTags.has(tag.id)) {
        mapUniqueTags.set(tag.id, tag);
      }
    });

  /**
   * tag in ALL resources
   */
  const selectedTags: PrivateTag[] = [];
  /**
   * tag which are in SOME resources
   */
  const indeterminateTags: PrivateTag[] = [];

  Array.from(mapUniqueTags).forEach(([tagId, tag]) => {
    if (data.every((resource) => resource.tags.find((t) => t.id === tagId))) {
      selectedTags.push(tag);
    } else {
      indeterminateTags.push(tag);
    }
  });

  return {
    selectedTags,
    indeterminateTags,
  };
};
