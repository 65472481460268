import styled from 'styled-components';

import Download from '@/public/images/icons/Download.svg';
import { ButtonIcon } from '@/src/modules/ui/components/Button';

export const ButtonIconDownload = styled(ButtonIcon).attrs((props) => {
  return {
    ...props,
    variant: 'bg-secondary',
    children: (
      <Download
        style={{
          width: 17,
          height: 'auto',
        }}
      />
    ),
  };
})``;
