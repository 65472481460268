'use client';

import { cn } from '@/src/lib/utils';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { CheckIcon } from '@radix-ui/react-icons';
import * as React from 'react';
import styled from 'styled-components';

const IndeterminateSymbol = styled.div`
  width: 9px;
  height: 1.5px;
  background: white;
  border-radius: 1.5px;
`;

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'peer h-[19px] w-[19px] shrink-0 rounded-[4px] border border-primary shadow focus-visible:outline-none focus-visible:ring-1',
      'focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
      // checked
      'data-[state=checked]:bg-primary data-[state=checked]:text-white',
      'data-[state=indeterminate]:bg-primary data-[state=indeterminate]:text-white',
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator className={cn('flex items-center justify-center text-current')}>
      {props.checked === 'indeterminate' ? (
        <IndeterminateSymbol />
      ) : (
        <CheckIcon className="h-4 w-4" />
      )}
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
