import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import debounce from 'lodash.debounce';
import React, { useState } from 'react';

export const useContainedImageSize = () => {
  const imageRef = React.useRef<HTMLImageElement | null>(null);
  const [imgWidth, setImgWidth] = React.useState<number | undefined>(400);
  const [loaded, setImgLoaded] = useState(false);

  useClientLayoutEffect(() => {
    const imgRef = imageRef.current;

    if (!imgRef) {
      setImgWidth(400);
      return;
    }

    // observe the image for changes in size
    // set the width of the content to the width of the image
    // but we must wait for the image to load before we can get the width
    // or it will "animate" the width change

    let previousWidth = 0;

    const setWidth = debounce((width: number) => {
      // minimum 300px, max 900px
      const nextWidth = Math.max(Math.min(width + 16, 900), 300);
      setImgWidth(nextWidth);
      previousWidth = nextWidth;
    }, 100);

    const observer = new ResizeObserver(() => {
      const width = imgRef.naturalWidth;
      if (previousWidth > width) return;
      setWidth(width);
    });

    const attachObserver = () => {
      if (!imgRef) return;
      observer.observe(imgRef);
      setWidth(imgRef.naturalWidth);
      setImgLoaded(true);
    };

    if (imgRef.complete) {
      attachObserver();
    } else {
      imgRef.addEventListener('load', attachObserver);
    }

    return () => {
      if (imgRef) {
        imgRef.removeEventListener('load', attachObserver);
      }
      observer.disconnect();

      setWidth.cancel();
    };
  }, [imageRef]);

  return React.useMemo(() => ({ imgWidth, imageRef, loaded }), [imgWidth, imageRef, loaded]);
};
