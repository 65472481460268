import type { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '../../../types/draftable';
import EditorPreview from '../../EditorPreview/EditorPreview';

type FdocItemContentNotepadProps = {
  fdoc: OptimisticDraft<Fdoc>;
  query: string;
  isMobileCard?: boolean;
  isListView?: boolean;
};

const FdocItemContentNotepad = ({
  fdoc,
  isMobileCard,
  isListView,
}: FdocItemContentNotepadProps) => {
  const data = fdoc.type === 'notepad' ? fdoc.data.editorjs ?? null : undefined;

  if (!data) return null;

  return (
    <EditorPreview
      data={data}
      key={fdoc.id}
      isListView={isListView}
      style={{
        fontSize: isMobileCard ? '12px' : '13px',
        fontWeight: isMobileCard ? 400 : undefined,
        lineHeight: isMobileCard ? 'normal' : 1.2,
        padding: isMobileCard ? `13px 13px 0px 13px` : isListView ? 0 : '0.8rem',
      }}
      isMobileCard={isMobileCard}
    />
  );
};

export default FdocItemContentNotepad;
