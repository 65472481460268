import { withConditionalMutation } from '@/src/lib/react-query/utilities';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useQueryFolderLabelsHelpers } from '@/src/modules/labels/queries/useQueryFolderLabels';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation } from '@tanstack/react-query';

type MutationVariables = {
  folderId: string;
  colorLabelId: ApiColorLabel['id'];
  newTitle: ApiColorLabel['name'];
};

export const useMutationRenameColorLabel = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { invalidate, updateCachedFolderLabel } = useQueryFolderLabelsHelpers();

  const mutation = useMutation({
    mutationFn: async ({ folderId, colorLabelId, newTitle }: MutationVariables) => {
      return await client.v2(
        {
          endpoint: '/v2/folders/{folderId}/color-labels/{colorLabelId}',
          params: {
            folderId,
            colorLabelId: `${colorLabelId}`,
          },
        },
        {
          method: 'put',
          body: {
            name: newTitle.trim(),
          },
        },
      );
    },
    onMutate: ({ folderId, colorLabelId, newTitle }) => {
      updateCachedFolderLabel(folderId, colorLabelId, newTitle);
    },
    onError: () => {
      toast({
        content: 'Failed to rename column',
      });
    },
    onSettled: (_, e, { folderId }) => {
      invalidate(folderId);
    },
    onSuccess: () => {
      track(AnalyticsEvents.RenameLabel);
    },
  });

  return withConditionalMutation(mutation, {
    ignorePredicate: ({ newTitle }) => {
      return !newTitle.trim();
    },
  });
};
