import React from 'react';

import ShortcutKey from '../components/ShortcutKey';
import { hasMacOSKbd } from '../hooks/interaction';
import clsx from 'clsx';

interface Modifiers {
  shift?: boolean;
  alt?: boolean;
  ctrl?: boolean;
  meta?: boolean;
}

interface Props extends React.HTMLProps<HTMLDivElement> {
  keybind?: string;
  icon?: React.ReactNode;
  modifiers: Modifiers;
  macModifiers?: Modifiers;
  shadow?: boolean;
  compact?: boolean;
}

// Shortcut visualizer
const Shortcut: React.FC<Props> = ({
  keybind,
  icon,
  modifiers,
  macModifiers,
  shadow = false,
  compact = false,
  ...props
}) => {
  const onMac = hasMacOSKbd();

  const parsedModifiers = [
    onMac && macModifiers?.shift ? '⇧' : !onMac && modifiers.shift ? '⇧' : null,
    onMac && macModifiers?.alt ? '⌥' : !onMac && modifiers.alt ? 'Alt' : null,
    onMac && macModifiers?.ctrl ? '⌘' : !onMac && modifiers.ctrl ? 'Ctrl' : null,
    onMac && macModifiers?.meta ? '⌘' : !onMac && modifiers.meta ? 'Win' : null,
  ].filter((value: string | null): value is string => value !== null);

  return (
    <div
      className={clsx(compact && 'text-gray-400 rounded flex gap-[2px] items-center')}
      {...props}
    >
      {parsedModifiers.map((modifier, index) => (
        <span key={index} className={clsx(!compact ? 'text-xs -mr-1 pr-[7px]' : 'text-xs')}>
          <ShortcutKey shadow={shadow} keybind={modifier} compact={compact} />
          {compact ? '+' : ' + '}
        </span>
      ))}
      {keybind && <ShortcutKey shadow={shadow} keybind={keybind} compact={compact} />}
      {icon}
    </div>
  );
};

export default Shortcut;
