import { P } from '@/src/modules/ui/components/Typography';
import styled from 'styled-components';

export const ResourcePreviewTitle = styled(P).attrs((props) => {
  return {
    weight: 600,
    size: 'sm',
    color: 'tertiary',
    ellipsis: true,
    ...props,
  };
})`
  flex-shrink: 0;
`;

export const ResourcePreviewDescription = styled(P).attrs((props) => {
  return {
    size: 'xxs',
    color: 'quaternary',
    ellipsis: true,
    ...props,
  };
})`
  flex-shrink: 0;
`;
