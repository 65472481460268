import { forwardRef } from 'react';
import styled from 'styled-components';
import { cssVar } from '../../ui/theme/variables';
import { MarkdownParserOptions } from '../markdown.types';
import { parseMarkdown } from '../markdown.utils';

type MarkdownRenderProps = {
  markdown: string;
  parserOptions?: MarkdownParserOptions;
};

const MarkdownDiv = styled.div`
  --margin-block-start: 1em;
  --margin-block-end: 1em;
  --padding-inline-start: 1em;
  --heading-scale: 2;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    font-size: max(calc(1em * var(--heading-scale)), 1em);
    margin-block-start: 0em;
    margin-block-end: cal(var(--margin-block-end) * 0.3);
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
  }

  h2 {
    font-size: max(1em, calc(0.75em * var(--heading-scale)));
  }

  h3 {
    font-size: max(1em, calc(0.685em * var(--heading-scale)));
  }

  h4 {
    font-size: max(1em, calc(0.625em * var(--heading-scale)));
  }

  h5 {
    font-size: max(1em, calc(0.56em * var(--heading-scale)));
  }

  h6 {
    font-size: max(1em, calc(0.5em * var(--heading-scale)));
  }

  p {
    margin-block-start: var(--margin-block-start);
    margin-block-end: var(--margin-block-end);
  }

  a {
    color: ${cssVar['color-app-primary']};
    text-decoration: underline;
  }

  code {
    display: inline;
    background-color: ${cssVar['color-bg-secondary']};
    color: ${cssVar['color-text-tertiary']};
    padding: 0.2em 0.4em;
    border-radius: 4px;

    font-family: 'Courier Prime', monospace;
    > * {
      font-family: 'Courier Prime', monospace;
    }
  }

  ul,
  ol {
    margin-block-start: var(--margin-block-start);
    margin-block-end: var(--margin-block-end);
    padding-inline-start: var(--padding-inline-start);
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  pre {
    background-color: ${cssVar['color-bg-secondary']};
    color: ${cssVar['color-text-tertiary']};
    padding: 0.5em;
    border-radius: 4px;
    overflow-x: auto;

    > code {
      display: block;
      padding: 0.5em;
      border-radius: 4px;
      font-size: 0.9em;
    }
  }

  blockquote {
    margin-block-start: var(--margin-block-start);
    margin-block-end: var(--margin-block-end);
    padding-inline-start: var(--padding-inline-start);
    border-left: 4px solid ${cssVar['color-app-primary']};
  }

  img {
    max-width: 100%;
    height: auto;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 1px solid ${cssVar['color-border-primary']};
    padding: 0.5em;
  }

  hr {
    border: none;
    border-top: 1px solid ${cssVar['color-border-primary']};
    margin-block-start: var(--margin-block-start);
    margin-block-end: var(--margin-block-end);
  }
`;

export const MarkdownRender = forwardRef<
  HTMLDivElement,
  MarkdownRenderProps & React.HTMLAttributes<HTMLDivElement>
>(({ markdown, parserOptions, ...props }, ref) => {
  return (
    <MarkdownDiv
      {...props}
      ref={ref}
      /**
       * @TODO - We should change how we parse markdown because this is a resource intensive operation.
       * Which causes the UI to freeze completely for 100ms to sometimes 800ms (depends on size).
       *
       * We should consider the following possibilites:
       * - Parse markdown to HTML serverse side which can be cached and sent to the client, this allows the client to avoid parsing markdown enitrely.
       * - Parse markdown in a web worker to avoid blocking the main thread, this should aleviate UI jank that ocurrs when parsing markdown.
       * - Similar to web worker, but with a service worker.
       * - Cache the parsed markdown into e.g. indexedDB, this allows the client to avoid parsing the same markdown several times.
       */
      dangerouslySetInnerHTML={{ __html: parseMarkdown(markdown, parserOptions) }}
    />
  );
});

MarkdownRender.displayName = 'MarkdownRender';
