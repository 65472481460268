import React, { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const CheckIcon: FC<IconProps> = ({
  color = 'currentColor',
  size = 16,
  strokeWidth = 2,
  className,
  ...props
}) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <path
        d="m13.3346 4-7.3333 7.3333L2.668 8"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckIcon;
