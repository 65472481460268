// Define regular expressions for matching different parts of a URL
const PROTOCOL_AND_DOUBLE_SLASH_REGEX = /(^\w+:|^)\/\//;
const WWW_REGEX = /^www\./;
const TRAILING_SLASH_REGEX = /\/$/;
const LAST_DOT_REGEX = /\.[^.]*$/;
const NON_ALPHANUMERIC_REGEX = /[^a-zA-Z0-9]/g;

const upperCaseFirstLetters = (str: string) => {
  return str.replace(/\b\w/g, (l) => l.toUpperCase());
};

export const titleFromUrl = (url: string) => {
  return upperCaseFirstLetters(
    url
      .replace(PROTOCOL_AND_DOUBLE_SLASH_REGEX, '')
      .replace(WWW_REGEX, '')
      .replace(TRAILING_SLASH_REGEX, '')
      .replace(LAST_DOT_REGEX, '')
      .replace(NON_ALPHANUMERIC_REGEX, ' ')
      .trim(),
  );
};

export const getHrefWithoutHashParams = (href: string) => {
  try {
    const url = new URL(href);
    const fragments = [url.origin, url.pathname, url.search];
    return fragments.join('');
  } catch {
    return href;
  }
};

export const formatPercent = (value: number, options?: Intl.NumberFormatOptions) =>
  Number(value).toLocaleString(undefined, {
    style: 'percent',
    maximumFractionDigits: 0,
    ...options,
  });
