import { usePDFContent } from '@/src/components/ExpandedFdoc/ExpandedPDF/PDF';
import styles from './StoredPDFContent.module.scss';

import RowPageList from '@/src/components/ExpandedFdoc/ExpandedPDF/RowPageList';
import Spinner from '@/src/components/Spinner/Spinner';
import clsx from 'clsx';
import { Document } from 'react-pdf';
import { OnDocumentLoadSuccess, OnError } from 'react-pdf/dist/cjs/shared/types';

const options = {
  cMapUrl: '/pdf/cmaps/',
  cMapPacked: true,
  standardFontDataUrl: '/pdf/standard_fonts/',
};

interface PDFDocumentProps {
  wrapperRef: HTMLDivElement | null;
  isFullScreen: boolean;
  tinyPagesIsVisible: boolean;
  width: number;
  hasSelection: boolean;
  isMouseDown: boolean;
  fileUrl?: string | null;
  onLoadSuccess: OnDocumentLoadSuccess;
  onLoadError: OnError;
}

export const PdfDocument: React.FC<PDFDocumentProps> = ({
  wrapperRef,
  isFullScreen,
  tinyPagesIsVisible,
  width,
  hasSelection,
  isMouseDown,
  fileUrl,
  onLoadSuccess,
  onLoadError,
}) => {
  const { scrollToPage } = usePDFContent();

  return (
    <Document
      onItemClick={(data) => {
        scrollToPage?.(data.pageNumber);
      }}
      options={options}
      file={fileUrl}
      onLoadSuccess={onLoadSuccess}
      onLoadError={onLoadError}
      renderMode="canvas"
      className={clsx(styles.pdf__document, {
        [styles.selecting]: hasSelection && isMouseDown,
      })}
      loading={
        <div
          style={{
            marginTop: 80,
            width,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Spinner size={30} thickness={3} />
        </div>
      }
    >
      {wrapperRef && (
        <RowPageList
          root={wrapperRef}
          width={isFullScreen && tinyPagesIsVisible ? width - 114 : width}
        />
      )}
    </Document>
  );
};
