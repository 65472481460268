import { useAuthUser } from '@/src/hooks/auth';
import {
  getResourcePlaceholderTitle,
  getResourceTitleWithDefault,
} from '@/src/modules/resources/utils/getResourceTitle';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { Fdoc, Thumbnail } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import React from 'react';

const appendExtension = (title: string, extension: string) => {
  /**
   * issue when be was saving the filename with the extension, we need to check if we should or shouldn't append
   */
  if (title.toLowerCase().endsWith(extension.toLowerCase())) {
    return title;
  }
  return title + (extension || '');
};

const getTitleWithoutExtension = (title: string, extension: string) => {
  return title.replace(extension, '');
};

interface UseResourceStoredMetadataReturnType {
  isNotRenameableStoredFile: boolean;
  isFileDownloadable: boolean;
  isTitleReadOnly: boolean;
  isImage: boolean;
  isVideo: boolean;
  isAudio: boolean;
  isPDF: boolean;
  isBookmark: boolean;
  isNotepad: boolean;
  isOwner: boolean;
  thumbnail: Thumbnail | null;
  title: string;
  titleWithExtension: string;
  titleWithoutExtension: string;
  isProcessing: boolean;
  isLoadingState: boolean;
  extension: string;
  titlePlaceholder: string;
}

export const useResourceStoredMetadata = <T extends OptimisticDraft<Fdoc> | undefined>(
  resource: T,
): UseResourceStoredMetadataReturnType => {
  const user = useAuthUser();

  return React.useMemo(() => {
    if (!resource) {
      return {
        icons: [],
        isNotRenameableStoredFile: false,
        isBookmark: false,
        isNotepad: false,
        isPDF: false,
        isImage: false,
        isVideo: false,
        isAudio: false,
        isFileDownloadable: false,
        isOwner: false,
        isTitleReadOnly: true,
        title: 'Unknown',
        titleWithExtension: 'Unknown',
        titleWithoutExtension: 'Unknown',
        titlePlaceholder: 'Untitled',
        thumbnail: null,
        isProcessing: false,
        isLoadingState: false,
        extension: '',
      };
    }

    const fdocContentType = resource.type === 'stored_file' ? resource.data.contentType : undefined;

    const isNotRenameableStoredFile =
      (resource.type === 'stored_file' &&
        !fdocContentType?.startsWith('image') &&
        !fdocContentType?.startsWith('video') &&
        !fdocContentType?.startsWith('audio') &&
        !fdocContentType?.startsWith('application/pdf')) ||
      false;

    const isBookmark = resource.type === 'page';
    const isNotepad = resource.type === 'notepad';

    const isPDF =
      (resource.type === 'stored_file' && resource.data.contentType?.includes('application/pdf')) ||
      false;

    const isImage =
      (resource.type === 'stored_file' && resource.data.contentType?.startsWith('image/')) ||
      resource.type === 'image' ||
      false;

    const isVideo =
      (resource.type === 'stored_file' && resource.data.contentType?.startsWith('video/')) || false;

    const isAudio =
      (resource.type === 'stored_file' && resource.data.contentType?.startsWith('audio/')) || false;

    // not sure why this exists
    // should probably has a different naming here...
    const isFileDownloadable =
      resource.type === 'stored_file' &&
      !resource.data.contentType?.includes('application/pdf') &&
      !isImage &&
      !isVideo &&
      !isAudio;

    const isTitleReadOnly =
      resource.type === 'stored_file' &&
      !resource.data.contentType?.includes('application/pdf') &&
      !isImage &&
      !isVideo &&
      !isAudio;

    const extension = ('extension' in resource.data && resource.data.extension) || '';

    const title = getResourceTitleWithDefault(resource);
    const titleWithExtension =
      'extension' in resource.data ? appendExtension(title, extension) : title;

    const titleWithoutExtension = getTitleWithoutExtension(titleWithExtension, extension);

    const canHaveThumbnail = isImage || isPDF || isVideo;
    const thumbnail =
      canHaveThumbnail && 'thumbnail' in resource.data ? resource.data.thumbnail : null;

    const isProcessing = isResourceStateProcessing(resource.stateProcessing) || false;
    const isLoadingState = isProcessing || resource.isDraft || false;

    return {
      isNotRenameableStoredFile,
      isFileDownloadable,
      isTitleReadOnly,
      isImage,
      isVideo,
      isAudio,
      isPDF,
      isBookmark,
      isNotepad,
      isOwner: resource.user?.id === user?.id,
      title,
      titlePlaceholder: getResourcePlaceholderTitle(resource),
      thumbnail,
      titleWithExtension,
      titleWithoutExtension,
      isProcessing,
      isLoadingState,
      extension,
    };
  }, [resource, user?.id]);
};
