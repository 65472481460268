import { Editor } from '@tiptap/react';
import { ReactNode } from 'react';

export enum ToolbarItemType {
  Toggle = 'toggle',
  Dropdown = 'dropdown',
  DropdownSeparator = 'dropdown-separator',
  Button = 'button',
  Custom = 'custom',
}

// Define the type for an individual item
export interface ToolbarToggle {
  type: ToolbarItemType.Toggle; // Type is required and must be 'toggle'
  key: string; // Key is required
  label: string; // Label is required
  icon?: ReactNode; // Icon is required and can be any renderable React node
  shortcut?: ReactNode; // Shortcut is optional and can be any renderable React node

  getIsActive: (editor: Editor) => boolean;
  onClick: (editor: Editor) => void;
}

export interface ToolbarSeparator {
  type: ToolbarItemType.DropdownSeparator; // Type is required and must be 'dropdown-separator'
  key: string; // Key is required
}

export interface ToolbarButton {
  type: ToolbarItemType.Button; // Type is required and must be 'button'
  key: string; // Key is required
  label: string; // Label is required
  icon?: ReactNode; // Icon is required and can be any renderable React node
  shortcut?: ReactNode; // Shortcut is optional and can be any renderable React node

  onClick: (editor: Editor) => void;
}

export interface ToolbarDropdown {
  type: ToolbarItemType.Dropdown; // Type is required and must be 'dropdown'
  key: string; // Key is required
  icon: ReactNode; // Icon is required and can be any renderable React node
  label: string; // Label is required
  items: ToolbarDropdownContent[]; // Items is an array of Item objects
}

interface ToolbarCustom {
  type: ToolbarItemType.Custom; // Type is required and must be 'custom'
  key: string; // Key is required
  render: (key: string, editor: Editor, insideDropdown: boolean) => ReactNode; // Render is required and must be a function that returns a renderable React node
}

export type ToolbarDropdownContent =
  | ToolbarToggle
  | ToolbarButton
  | ToolbarSeparator
  | ToolbarCustom;
export type ToolbarItem = ToolbarToggle | ToolbarDropdown | ToolbarButton | ToolbarCustom;

// Define the type for a group
export interface ToolbarGroup {
  name: string; // Name is required
  label: string; // Label is required
  items: ToolbarItem[]; // Items is an array of Item objects
}
