import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { multiplayerQueryKeys } from '@/src/modules/multiplayer/queries/multiplayerQueryKeys';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { MultiplayerSession, MultiplayerSessionRequest } from '@fabric/woody-client';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';

/**
 * Fetches and returns the markdown from the text url of a page resource.
 *
 * @param fdoc
 * @param queryOptions
 * @returns
 */
export const useQueryMultiplayerSession = (
  request?: MultiplayerSessionRequest,
  queryOptions?: Partial<QueryObserverOptions<MultiplayerSession>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: multiplayerQueryKeys.session(request),
    queryFn: async (params) => {
      const safeRequest = params.queryKey[1] as MultiplayerSessionRequest;

      return getWoodyResponseData(await client.getMultiplayerSession(safeRequest));
    },
    refetchOnMount: 'always',
    ...queryOptions,
    enabled: isQueryEnabled([!!request, queryOptions?.enabled]),
  });

  return query;
};
