import { cssDropdownItemMobile } from '@/src/modules/ui/components/DropdownMenu/Item';
import { mediaHover, mediaNotMobile } from '@/src/modules/ui/styled-utils';
import { Item } from '@radix-ui/react-context-menu';
import styled, { css } from 'styled-components';

export const ContextMenuItem = styled(Item)<{ variant?: 'danger' }>`
  ${mediaNotMobile} {
    padding: 8px;
    color: white;

    cursor: pointer;

    background: none;
    border: 1px solid transparent;
    outline: none;

    font-weight: 400;
    font-size: 13px;
    line-height: 16px;

    display: flex;
    align-items: center;

    border-radius: 9px;
    ${mediaHover} {
      &:hover {
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid rgba(255, 255, 255, 0.1);
      }
    }

    ${(p) =>
      p.variant === 'danger' &&
      css`
        color: #ff0000;
      `}

    &.item__context_menu__button_share {
      border-top: 1px solid #ffffff1a;
      border-bottom: 1px solid #ffffff1a;
    }

    > kbd {
      margin-left: auto;

      font-weight: 500;
      font-size: 12px;
      line-height: 13px;
      height: 19px;

      color: rgba(255, 255, 255, 0.7);
      background: rgba(255, 255, 255, 0.2);
      border-radius: 4px;

      padding: 2.5px 2px;
      display: flex;
      align-items: center;

      font-family: 'Inter', sans-serif;

      > svg {
        width: 12px;
        height: 12px;
        margin-right: 2px;
      }

      > span {
        margin-bottom: 1px;
      }
    }
  }
  ${cssDropdownItemMobile}
`;
