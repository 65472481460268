import { AttributeMap } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/types';
import {
  attributeDateFormatter,
  isValidAttribute,
} from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/utils';
import { useQueryResourceAncestors } from '@/src/modules/resource-detail/queries/useQueryResourceAncestors';
import { detailsToAttributeMaps } from '@/src/modules/resource-detail/resource-details.config';
import { LocationSelectorNodePathItem } from '@/src/modules/resources/components/DestinationSelector/types';
import { ancestorToLocationSelectorNodePathItem } from '@/src/modules/resources/components/DestinationSelector/utils';
import { useQueryResourceDetail } from '@/src/modules/resources/queries/useQueryResourceDetail';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { isStoredFileFdoc } from '@/src/modules/resources/utils/resourceTypes';
import Divider from '@/src/modules/ui/components/Divider';
import { Flex } from '@/src/modules/ui/components/Flex';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import { DL, DLItem, DT, DD as FDD } from '@/src/modules/ui/components/Typography';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import { Fdoc } from '@/src/types/api';
import { generateKind } from '@/src/utils/files';
import { formatDiskSpace, parseIntOrString } from '@/src/utils/number';
import { capitalizeFirstLetter } from '@/src/utils/text';
import React, { useMemo } from 'react';
import styled from 'styled-components';

interface ExtraPanelInfoProps {
  resource: Fdoc;
}

const DD = styled(FDD)`
  user-select: text;
`;

const Container = styled(Flex).attrs((p) => {
  return {
    direction: 'column',
    gap: 'elementsContainer',
    ...p,
  };
})`
  padding: 10px 12px;
  font-size: 0.9rem;

  ${mediaMobile} {
    padding: 14px;
  }
`;

const StyledScrollArea = styled(ScrollArea)`
  display: grid;

  [data-radix-scroll-area-viewport] > div {
    display: grid;
  }
`;

const isAttributesProcessing = (details: ResourceDetail) => {
  // just not good gen types, should be revalidated
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const processingStatus = (details as any)?.data?.attributes?.status;

  if (!processingStatus || ['processing', 'pending'].includes(processingStatus)) {
    return true;
  }
  return false;
};

const ExtraPanelInfo: React.FC<ExtraPanelInfoProps> = ({ resource }) => {
  const { data: details } = useQueryResourceDetail(resource.id);
  const { data: ancestors } = useQueryResourceAncestors(resource?.id);

  const resourcePath = useMemo<LocationSelectorNodePathItem[]>(
    () => ancestors?.map(ancestorToLocationSelectorNodePathItem) ?? [],
    [ancestors],
  );

  if (!details) return null;

  const attributes: AttributeMap[] = details
    ? (detailsToAttributeMaps(details).filter(isValidAttribute) as AttributeMap[])
    : [];

  const size = 'size' in details ? parseIntOrString(details.size ?? 0) : 0;
  const extension = isStoredFileFdoc(resource) ? resource.data.extension ?? '' : '';
  const mime = 'mime' in details ? details.mime : null;
  const createdAt = 'createdAt' in details ? details.createdAt : null;
  const modifiedAt = 'modifiedAt' in details ? details.modifiedAt : null;

  const isProcessing = isAttributesProcessing(details);

  return (
    <ForcedTheme colorScheme="dark" asChild>
      <StyledScrollArea>
        <Container>
          <DL>
            <DLItem>
              <DT>Path</DT>
              <DD>{resourcePath.map((p) => p.title).join(' / ')}</DD>
            </DLItem>

            <DLItem>
              <DT>Size</DT>
              <DD>
                {parseIntOrString(size).toLocaleString('en-US', {
                  style: 'decimal',
                  maximumFractionDigits: 0,
                  useGrouping: true,
                })}{' '}
                bytes ({formatDiskSpace(size)} on disk){' '}
              </DD>
            </DLItem>

            <DLItem>
              <DT>Kind</DT>
              <DD>{mime ? generateKind(mime, extension) : capitalizeFirstLetter(details.kind)} </DD>
            </DLItem>

            {createdAt && (
              <DLItem>
                <DT>Created</DT>
                <DD>{attributeDateFormatter(createdAt)}</DD>
              </DLItem>
            )}

            {modifiedAt && (
              <DLItem>
                <DT>Modified</DT>
                <DD>{attributeDateFormatter(modifiedAt)}</DD>
              </DLItem>
            )}
          </DL>
          {isProcessing && (
            <>
              <Divider />
              <Flex justifyContent="center" alignItems="center" style={{ padding: '1rem' }}>
                <Spinner size={24} />
              </Flex>
            </>
          )}
          {attributes.length > 0 && (
            <>
              <Divider />
              <DL>
                {attributes.map(({ label, value }) => (
                  <DLItem key={label}>
                    <DT>{label}</DT>
                    <DD>{value}</DD>
                  </DLItem>
                ))}
              </DL>
            </>
          )}
        </Container>
      </StyledScrollArea>
    </ForcedTheme>
  );
};

export { ExtraPanelInfo };
