import { PrivateTag } from '@fabric/woody-client';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useShowExpandedFdoc } from '@/src/hooks/useExpandedFdoc';
import { pick } from '@/src/lib/store';
import { getResourceTitleWithDefault } from '@/src/modules/resources/utils/getResourceTitle';
import Modal, { defaultModalContentProps } from '@/src/modules/ui/components/Modal';
import useUIStore from '@/src/store/ui';
import { Fdoc } from '@/src/types/api';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import clsx from 'clsx';
import { shallow } from 'zustand/shallow';
import useFdocSwitcher from '../../store/fdocSwitcher';
import { OptimisticDraft } from '../../types/draftable';
import { isInteractiveElement } from '../../utils/elements';
import styles from './ExpandedFdoc.module.scss';
import ExpandedFdocContent from './ExpandedFdocContent';
import { ExpandedFdocProvider } from './ExpandedFdocProvider';
import ExpandedFdocSidebar from './ExpandedFdocSidebar';

import * as ExpandedFdocLoading from './ExpandedFdocLoading';

type ExpandedFdocProps = {
  fdoc?: OptimisticDraft<Fdoc>;
  onClose?: () => void;
  className?: string;
  style?: React.CSSProperties;
  hideBackdrop?: boolean;
  hideSimilar?: boolean;
  hideComments?: boolean;
  draftSaveFdoc?: (fdoc: OptimisticDraft<Fdoc>, state?: Uint8Array) => Promise<string | undefined>;
  draftOnSelectTag?: (tag: PrivateTag, selected: boolean) => void;
  draftTags?: PrivateTag[];
  noGestures?: boolean;
  contentStyle?: React.CSSProperties;

  overrideSidebarOpen?: boolean;
  setOverrideSidebarOpen?: (value: boolean) => void;
  extra?: React.ReactNode;
};

const ExpandedFdoc = forwardRef<HTMLElement, ExpandedFdocProps>(
  (
    {
      fdoc,
      onClose,
      className,
      hideBackdrop = false,
      hideComments = false,
      hideSimilar = false,
      draftSaveFdoc,
      draftOnSelectTag,
      draftTags,
      style,
      noGestures,
      contentStyle,
      overrideSidebarOpen,
      setOverrideSidebarOpen,
      extra,
    },
    ref,
  ) => {
    const [pendingChanges, setPendingChanges] = useState(false);
    const [isSimimlarOpen, setIsSimilarOpen] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);

    const { storedExpandedFdocSidebarOpen, expandedFdocSidebarTab } = useUIStore(
      (s) => ({
        storedExpandedFdocSidebarOpen: s.expandedFdocSidebarOpen,
        expandedFdocSidebarTab: s.expandedFdocSidebarTab,
      }),
      shallow,
    );

    const { setFdocId, switcherFdocIds, goToNextFdoc, goToPreviousFdoc } = useFdocSwitcher(
      (s) => pick(s, ['setFdocId', 'switcherFdocIds', 'goToNextFdoc', 'goToPreviousFdoc']),
      shallow,
    );

    const [closing, setClosing] = useState(false);

    const handleClose = useCallback(() => {
      if (isSimimlarOpen) {
        setIsSimilarOpen(false);
        return;
      }

      if (!onClose) return;

      if (pendingChanges) {
        setClosing(true);
        setTimeout(() => {
          onClose?.();
        }, 420);
      } else onClose?.();
    }, [isSimimlarOpen, onClose, pendingChanges]);

    const [showCommentsMobile, setShowCommentsMobile] = React.useState(false);
    const toggleComments = () => setShowCommentsMobile((s) => !s);

    const showExpandedFdoc = useShowExpandedFdoc();

    const goToNextFdocHandler = useCallback(() => {
      showExpandedFdoc(goToNextFdoc(), expandedFdocSidebarTab);
    }, [showExpandedFdoc, goToNextFdoc, expandedFdocSidebarTab]);

    const goToPreviousFdocHandler = useCallback(() => {
      showExpandedFdoc(goToPreviousFdoc(), expandedFdocSidebarTab);
    }, [expandedFdocSidebarTab, goToPreviousFdoc, showExpandedFdoc]);

    useEffect(() => {
      if (!fdoc) return;

      setFdocId(fdoc.id);
    }, [fdoc, setFdocId]);

    useEffect(() => {
      if (!fdoc || fdoc.type !== 'folder') return;
      onClose?.();
    }, [fdoc, onClose]);

    const [isEditorFocused, setIsEditorFocused] = useState(false);

    // close on escape
    useEffect(() => {
      if (noGestures) return;

      const handleKeyDown = (e: KeyboardEvent) => {
        if (e.key !== 'Escape') return;
        if (
          isInteractiveElement(document.activeElement, true) ||
          document.activeElement?.getAttribute('contenteditable') === 'true' ||
          document.activeElement?.closest('[data-slate-control]')
        ) {
          // unfocus if we're in an interactive element
          const element = document.activeElement as HTMLElement;
          element.blur();
          return;
        }
        e.stopPropagation();
        e.preventDefault();

        // Don't show active element outline when closing
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }

        handleClose();
      };

      window.addEventListener('keydown', handleKeyDown, { capture: true });
      return () => window.removeEventListener('keydown', handleKeyDown, { capture: true });
    }, [noGestures, handleClose]);

    useEffect(() => {
      if (!switcherFdocIds.length || !fdoc || noGestures) return;

      const handleKeyDown = (e: KeyboardEvent) => {
        if (isInteractiveElement(document.activeElement) && document.activeElement?.role !== 'tab')
          return;
        // don't switch if we're in a notepad and the notepad is focused
        if (fdoc.type === 'notepad' && isEditorFocused) return;

        if (e.key === 'ArrowLeft') {
          e.preventDefault();
          goToPreviousFdocHandler();
        } else if (e.key === 'ArrowRight') {
          e.preventDefault();
          goToNextFdocHandler();
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      return () => window.removeEventListener('keydown', handleKeyDown);
    }, [
      noGestures,
      switcherFdocIds,
      fdoc,
      goToPreviousFdocHandler,
      goToNextFdocHandler,
      isEditorFocused,
    ]);

    const hasFdoc = fdoc !== null;

    const [sidebarRef, setSidebarRef] = useState<HTMLDivElement | null>(null);
    const [rootRef, setRootRef] = useState<HTMLElement | null>(null);

    const [splitContentElement, setSplitContentElement] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
      if (noGestures || !rootRef) return;
      // upon opening reset focus on the first focusable element in rootRef

      const focusable = rootRef.querySelector<HTMLElement>(
        'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
      );

      if (!focusable) return;

      focusable.focus();
      setTimeout(() => {
        focusable.blur();
      }, 0);
    }, [noGestures, rootRef]);

    const fdocTitle = fdoc ? getResourceTitleWithDefault(fdoc) : 'Item';

    if (!hasFdoc || closing) return null;

    return (
      <ExpandedFdocProvider
        overrideSidebarOpen={overrideSidebarOpen}
        setOverrideSidebarOpen={setOverrideSidebarOpen}
      >
        <Modal open={true}>
          <Modal.Portal>
            <DialogPrimitive.Content
              style={{ outline: 'none' }}
              asChild
              aria-describedby={undefined}
              {...defaultModalContentProps}
            >
              <section
                className={clsx(
                  styles.item_preview__wrapper,
                  noGestures && styles.no_gestures,
                  className,
                  {
                    [styles.sidebarExpanded]: storedExpandedFdocSidebarOpen,
                  },
                )}
                ref={(node) => {
                  setRootRef(node);

                  if (typeof ref === 'function') {
                    ref(node);
                  } else if (ref) {
                    ref.current = node;
                  }
                }}
                style={style}
              >
                <VisuallyHidden.Root asChild>
                  <DialogPrimitive.Title>{fdocTitle}</DialogPrimitive.Title>
                </VisuallyHidden.Root>

                {extra}
                {!hideBackdrop && <div className={styles.item_preview__bg} onClick={handleClose} />}

                <div className={clsx(styles.item_preview)} style={contentStyle} id="expanded-fdoc">
                  <div className={styles.closer} onClick={handleClose} />
                  {fdoc ? (
                    <>
                      <ExpandedFdocContent
                        fdoc={fdoc}
                        onClose={onClose ? handleClose : undefined}
                        sidebarRef={sidebarRef}
                        rootRef={rootRef}
                        setPendingChanges={setPendingChanges}
                        isEditorFocused={isEditorFocused}
                        setIsEditorFocused={setIsEditorFocused}
                        draftSaveFdoc={draftSaveFdoc}
                        draftOnSelectTag={draftOnSelectTag}
                        draftTags={draftTags}
                        isFullscreen={isFullscreen}
                        setIsFullscreen={setIsFullscreen}
                        ref={setSplitContentElement}
                        disableGestures={noGestures}
                        hideBackdrop={hideBackdrop}
                      />

                      {!hideComments && (
                        <ExpandedFdocSidebar
                          fdoc={fdoc}
                          onClose={handleClose}
                          showCommentsMobile={showCommentsMobile}
                          toggleComments={toggleComments}
                          ref={setSidebarRef}
                          hideSimilar={hideSimilar}
                          isFullscreen={isFullscreen}
                          splitContentElement={splitContentElement}
                          disableGestures={noGestures}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <ExpandedFdocLoading.ContentSkeleton onClose={handleClose} />
                      {!hideComments && <ExpandedFdocLoading.SidebarSkeleton />}
                    </>
                  )}
                </div>
              </section>
            </DialogPrimitive.Content>
          </Modal.Portal>
        </Modal>
      </ExpandedFdocProvider>
    );
  },
);

ExpandedFdoc.displayName = 'ExpandedFdoc';

export default ExpandedFdoc;
