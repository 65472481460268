import { BubbleMenu, Editor } from '@tiptap/react';
import React, { useEffect, useState } from 'react';
import * as Toolbar from '@radix-ui/react-toolbar';
import styles from './BubbleImageOptions.module.scss';

const sizesMap: {
  [key: string]: string;
} = {
  small: '100px',
  medium: '200px',
  large: '400px',
  'best-fit': '100%',
  'original-size': 'auto',
};

const BubbleImageOptions: React.FC<{
  editor: Editor;
}> = ({ editor }) => {
  const [imageSize, setImageSize] = useState('best-fit');

  useEffect(() => {
    if (!editor) return;

    const getImageSize = () => {
      const width = editor.getAttributes('embed').width;
      setImageSize(Object.keys(sizesMap).find((key) => sizesMap[key] === width) || 'best-fit');
    };

    getImageSize();
    editor.on('selectionUpdate', getImageSize);
    editor.on('transaction', getImageSize);

    return () => {
      editor.off('selectionUpdate', getImageSize);
      editor.off('transaction', getImageSize);
    };
  }, [editor]);

  const handleImageSizeChange = (value: string) => {
    if (!editor) return;

    const activeElement = editor.view.dom.querySelector('.ProseMirror-selectednode');
    if (!activeElement) return;
    const pos = editor.view.posAtDOM(activeElement, 0);

    // get the underlying image and update its style
    const img = activeElement.querySelector('img');
    img?.style.setProperty('width', sizesMap[value]);
    img?.style.setProperty('height', 'auto');

    editor
      .chain()
      .focus()
      .updateAttributes('embed', {
        width: sizesMap[value],
        height: 'auto',
      })
      .setNodeSelection(pos)
      .run();
  };

  return editor ? (
    <div className="absolute">
      <BubbleMenu
        editor={editor}
        shouldShow={({ editor }) => editor.isActive('embed')}
        pluginKey={'bubbleImageOptions'}
        tippyOptions={{
          placement: 'bottom',
          delay: 250,
          moveTransition: 'transform 0.2s ease-out',
        }}
      >
        <Toolbar.Root className={styles.root} aria-label="Formatting options">
          <Toolbar.ToggleGroup
            type="single"
            aria-label="Text formatting"
            value={imageSize}
            onValueChange={handleImageSizeChange}
          >
            <Toolbar.ToggleItem className={styles.item} value="small" aria-label="Small">
              Small
            </Toolbar.ToggleItem>
            <Toolbar.ToggleItem className={styles.item} value="medium" aria-label="Medium">
              Medium
            </Toolbar.ToggleItem>
            <Toolbar.ToggleItem className={styles.item} value="large" aria-label="Large">
              Large
            </Toolbar.ToggleItem>
            <Toolbar.ToggleItem className={styles.item} value="best-fit" aria-label="Best fit">
              Best fit
            </Toolbar.ToggleItem>
            <Toolbar.ToggleItem
              className={styles.item}
              value="original-size"
              aria-label="Original size"
            >
              Original size
            </Toolbar.ToggleItem>
          </Toolbar.ToggleGroup>
        </Toolbar.Root>
      </BubbleMenu>
    </div>
  ) : null;
};

export default BubbleImageOptions;
