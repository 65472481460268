import { ErrorType } from '@/src/core/types/errors';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { Usage } from '@fabric/woody-client';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { userQueryKeys } from './userQueryKeys';

const getQuotaStatusError = (quota?: Usage) => {
  if (!quota) {
    return null;
  }

  if (quota.items.used >= (quota.items.limit ?? Infinity)) {
    return ErrorType.EXCEEDS_ITEM_LIMIT;
  }

  if (quota.storage.used >= (quota.storage.limit ?? Infinity)) {
    return ErrorType.EXCEEDS_STORAGE_LIMIT;
  }

  return null;
};

/**
 * loads user quota
 * @param queryOptions
 * @returns
 */
export const useQueryUserQuota = (queryOptions?: Partial<QueryObserverOptions<Usage>>) => {
  const { client } = useWoody();

  const enabled = isQueryEnabled([queryOptions?.enabled]);

  const query = useQuery({
    queryKey: userQueryKeys.quota(),
    queryFn: async () => {
      const response = await client.getUsage();

      if (response.error) {
        throw response.error;
      }

      return response.data;
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    refetchInterval: 1000 * 60,
    staleTime: 1000 * 60,
    ...queryOptions,
    enabled,
  });

  return {
    ...query,
    quotaStatus: getQuotaStatusError(query.data),
  };
};
