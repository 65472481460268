import React from 'react';

import { MULTISELECT_IGNORE_AREA } from '../../lib/multiselect';
import { Position } from '../../types/global';
import styles from './MultiSelection.module.scss';

const MultiSelection: React.FC<{
  startPosition: Position;
  endPosition: Position;
  modifierOperation: boolean;
}> = ({ startPosition, endPosition, modifierOperation }) => {
  const width = Math.abs(startPosition.x - endPosition.x);
  const height = Math.abs(startPosition.y - endPosition.y);
  const area = width * height;

  const left = Math.min(startPosition.x, endPosition.x);
  const top = Math.min(startPosition.y, endPosition.y);

  return (
    <>
      <div className={styles.blockHoverStates} />
      <div
        style={{
          top: 0,
          left: 0,

          transform: `translate3d(${left}px, ${top}px, 0)`,
          width: `${width}px`,
          height: `${height}px`,
          willChange: 'transform, width, height',

          // if area is too small pointer events are disabled
          // and it's hidden
          pointerEvents: area < MULTISELECT_IGNORE_AREA && !modifierOperation ? 'none' : 'auto',
          display: area < MULTISELECT_IGNORE_AREA && !modifierOperation ? 'none' : undefined,
        }}
        className={styles.multiSelection}
      />
    </>
  );
};

export default MultiSelection;
