import React from 'react';

interface Props {
  className: string;
}

const EmptyState: React.FC<Props> = ({ className }) => {
  return (
    <svg
      data-notestack-empty-icon
      viewBox="0 0 78 86"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M3 43C3 38.5817 6.58172 35 11 35H35C39.4183 35 43 38.5817 43 43V75C43 79.4183 39.4183 83 35 83H11C6.58172 83 3 79.4183 3 75V43Z"
        stroke="#E9E9E9"
        strokeWidth="6"
      />
      <path
        d="M19 35H16V38H19V35ZM43 67H40V70H43V67ZM22 27C22 24.2386 24.2386 22 27 22V16C20.9249 16 16 20.9249 16 27H22ZM22 35V27H16V35H22ZM19 38H35V32H19V38ZM35 38C37.7614 38 40 40.2386 40 43H46C46 36.9249 41.0751 32 35 32V38ZM40 43V67H46V43H40ZM51 64H43V70H51V64ZM56 59C56 61.7614 53.7614 64 51 64V70C57.0751 70 62 65.0751 62 59H56ZM56 27V59H62V27H56ZM51 22C53.7614 22 56 24.2386 56 27H62C62 20.9249 57.0751 16 51 16V22ZM27 22H51V16H27V22Z"
        fill="#E9E9E9"
      />
      <path
        d="M35 19H32V22H35V19ZM59 51H56V54H59V51ZM38 11C38 8.23858 40.2386 6 43 6V0C36.9249 0 32 4.92487 32 11H38ZM38 19V11H32V19H38ZM35 22H51V16H35V22ZM51 22C53.7614 22 56 24.2386 56 27H62C62 20.9249 57.0751 16 51 16V22ZM56 27V51H62V27H56ZM67 48H59V54H67V48ZM72 43C72 45.7614 69.7614 48 67 48V54C73.0751 54 78 49.0751 78 43H72ZM72 11V43H78V11H72ZM67 6C69.7614 6 72 8.23858 72 11H78C78 4.92487 73.0751 0 67 0V6ZM43 6H67V0H43V6Z"
        fill="#E9E9E9"
      />
    </svg>
  );
};

export default EmptyState;
