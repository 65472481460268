import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { Ancestor } from '@/src/types/api';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { resourceDetailsQueryKeys } from './resourceDetailsQueryKeys';

export const useQueryResourceAncestors = (
  resourceId?: string,
  queryOptions?: Partial<QueryObserverOptions<Ancestor[]>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: resourceDetailsQueryKeys.ancestors(resourceId),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;
      return client.v2({
        endpoint: '/v2/resources/{resourceId}/ancestors',
        params: {
          resourceId: safeResourceId,
        },
      });
    },
    placeholderData: keepPreviousData,
    refetchInterval: 45000,
    ...queryOptions,
    enabled: isQueryEnabled([!!resourceId, queryOptions?.enabled]),
  });

  return {
    ...query,
  };
};
