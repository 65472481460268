import { FC } from 'react';

export type EmbedElementProps = {
  url: string;
  children?: React.ReactNode;
};

export enum EmbedType {
  YOUTUBE = 'youtube',
  VIMEO = 'vimeo',
  TWITTER = 'twitter',
  SPOTIFY = 'spotify',
}

export type PageResourceEmbed = {
  type: EmbedType;
  matchFn: (url: string) => string | null;
  renderElement: FC<EmbedElementProps>;
};

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    twttr: any;
  }
}
