import LimitSpacesModal from '@/src/components/LimitSpacesModal/LimitSpacesModal';
import { useAuthUser } from '@/src/hooks/auth';
import { withConditionalMutation } from '@/src/lib/react-query/utilities';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useQueryResourceRootSpaceSystemList } from '@/src/modules/spaces/queries/useQueryResourceRootSpaceSystemList';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { isSubscribedPlan } from '@/src/types/pricing';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useQueryCacheMemberHelpers } from '../../members/utils/useQueryCacheMembersHelpers';
import { resourceQueryPredicates } from '../../resources/queries/resourceQueryPredicates';
import { createOptimisticSpaceRoot } from '../utils/createOptimisticSpace';
import { halListToSpace } from '../utils/halListToSpace';
import { useQueryCacheSpaceHelpers } from '../utils/useQueryCacheSpaceHelpers';

type SpaceCreationActions = 'spaces-list' | 'multiselect-to-space' | 'space-not-found' | 'sidebar';
type MutationArg = { title?: string; action?: SpaceCreationActions };

const defaultMutationArg: Required<MutationArg> = {
  title: 'New Space',
  action: 'spaces-list',
};

export const useMutationCreateNewSpace = () => {
  const { client } = useWoody();
  const user = useAuthUser();
  const { track } = useAnalytics();

  const [overLimit, setOverLimit] = React.useState<number | null>(null);
  const { spaceRoots } = useQueryResourceRootSpaceSystemList(false);

  const queryClient = useQueryClient();

  const { addNewSpaceToCache } = useQueryCacheSpaceHelpers();
  const { addSelfToCachedSpace } = useQueryCacheMemberHelpers();

  const mutation = useMutation({
    mutationFn: async (vars?: MutationArg) => {
      if (!user) throw new Error('User not authenticated');

      const { title } = { ...defaultMutationArg, ...vars };

      return halListToSpace(
        getWoodyResponseData(
          await client.postNewList(user.id, {
            title,
            isPublic: false,
          }),
        ),
      );
    },
    onMutate: (vars) => {
      if (!user) return;

      const { title } = { ...defaultMutationArg, ...vars };

      const optimisticSpace = createOptimisticSpaceRoot(title);

      return {
        optimisticSpace,
        addNewSpaceToCacheContext: addNewSpaceToCache(optimisticSpace),
        addSelfToCachedSpaceContext: addSelfToCachedSpace(optimisticSpace.id, 'owner'),
      };
    },
    onError: (_err, _vars, ctx) => {
      toast({
        content: 'Could not create a space',
      });

      console.error(_err);

      ctx?.addNewSpaceToCacheContext?.resetCacheToPreOptimisticState();
      ctx?.addNewSpaceToCacheContext?.invalidateQueries();
      ctx?.addSelfToCachedSpaceContext?.resetCacheToPreOptimisticState();
      ctx?.addSelfToCachedSpaceContext?.invalidateQueries();
    },
    onSuccess: (space, variables, ctx) => {
      track(AnalyticsEvents.CreatedSpace, {
        action: variables?.action ?? 'spaces-list',
      });

      /**
       * refetch cache
       */
      ctx.addNewSpaceToCacheContext?.invalidateQueries();

      queryClient.invalidateQueries({
        predicate: (q) =>
          // Joining a space means new resources are available
          resourceQueryPredicates.filterAndSearchAll(q) || resourceQueryPredicates.summaryAll(q),
      });
    },
  });

  return {
    ...withConditionalMutation(mutation, {
      ignorePredicate: () => {
        if (!user) return false; // let the mutation handle the error

        if (!isSubscribedPlan(user.subscription.tier) && spaceRoots.length >= 10) {
          setOverLimit(spaceRoots.length);
          return true;
        }

        return false;
      },
    }),
    limitModal: overLimit && (
      <LimitSpacesModal listAmount={overLimit} onClose={() => setOverLimit(null)} />
    ),
  };
};
