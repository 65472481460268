import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useSocketIOContext } from '@/src/lib/socket.io/useSocketIOContext';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import { useWoody } from '@/src/services/woody/woody';
import {
  QueryObserverOptions,
  keepPreviousData,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import React from 'react';
import { resourceQueryKeys } from './resourceQueryKeys';

/**
 * Fetches resource details using v2.
 *
 * @param resourceId
 * @param queryOptions
 * @returns
 */
export const useQueryResourceDetail = (
  resourceId?: string,
  queryOptions?: Partial<QueryObserverOptions<ResourceDetail>>,
) => {
  const { client } = useWoody();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: resourceQueryKeys.resourceDetail(resourceId),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;

      return client.v2({
        endpoint: '/v2/resources/{resourceId}',
        params: {
          resourceId: safeResourceId,
        },
      });
    },
    placeholderData: keepPreviousData,
    refetchOnMount: true,
    ...queryOptions,
    enabled: isQueryEnabled([!!resourceId, queryOptions?.enabled]),
  });

  /**
   * Establish socket listener for attribute updates
   * This can happen when the data are not available in the initial response
   * as there's on demand processing of the data in the backend
   */
  const { socket } = useSocketIOContext();

  React.useEffect(() => {
    if (resourceId) {
      const updater = (socketData: { resourceId: string; attributes: Record<string, unknown> }) => {
        if (socketData.resourceId === resourceId) {
          const queryKey = resourceQueryKeys.resourceDetail(resourceId);
          queryClient.setQueryData(queryKey, (prevData: ResourceDetail | undefined) => {
            if (!prevData) return prevData;

            return {
              ...prevData,
              ...('data' in prevData
                ? {
                    data: {
                      ...prevData.data,
                      attributes: socketData.attributes,
                    },
                  }
                : {}),
            };
          });
        }
      };

      socket.on('RESOURCE:METADATA_ATTRIBUTES:UPDATE', updater);
      return () => {
        socket.off('RESOURCE:METADATA_ATTRIBUTES:UPDATE', updater);
      };
    }
  }, [socket, resourceId, queryClient]);

  return {
    ...query,
    resource: query.data,
  };
};
