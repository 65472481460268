import { MutableRefObject, useEffect, useState } from 'react';

const useOnScreen = <T extends Element>(
  ref: MutableRefObject<T | undefined | null> | T | null,
  rootMargin: string = '0px'
): boolean => {
  const [isIntersecting, setIntersecting] = useState<boolean>(false);
  const refElement = ref && 'current' in ref ? ref.current : ref;

  useEffect(() => {
    if (!refElement) return;

    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    observer.observe(refElement);

    return () => {
      observer.unobserve(refElement);
    };
  }, [refElement, rootMargin]);

  return isIntersecting;
};

export default useOnScreen;
