import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const ChevronUpIcon: FC<IconProps> = ({
  color = 'currentColor',
  size = 18,
  strokeWidth = 2,
  className,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <path
        d="M2 12.2306L9 5.76904L16 12.2306"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ChevronUpIcon;
