import { Fdoc } from '@/src/types/api';

export const getResourceGenericName = (resource: Fdoc): string => {
  switch (resource.type) {
    case 'page':
      return 'saved link';
    case 'text':
      return 'saved highlight';
    case 'image':
      return 'saved image';
    case 'notepad':
      return 'note';
    case 'stored_file':
      if (resource.data.contentType?.startsWith('image/')) {
        return 'image';
      } else if (resource.data.contentType?.startsWith('video/')) {
        return 'video';
      } else if (resource.data.contentType?.startsWith('audio/')) {
        return 'audio';
      } else if (resource.data.contentType?.startsWith('application/pdf')) {
        return 'PDF';
      } else {
        return 'file';
      }
    case 'folder':
      return 'Folder';
    default:
      return 'item';
  }
};
