import { createPortal } from 'react-dom';
import styles from './DropToMove.module.scss';
import { getOffCanvasPortal } from '@/src/lib/portals';
import { useDragAndSelect } from '@/src/lib/DragAndSelectContext';
import { useEffect, useState } from 'react';
import { ObjectDragEvent, ObjectDragOver } from '@/src/types/draggable';
import plur from 'plur';

const DropToMove: React.FC<{
  onDropAction: (fdocIds: string[]) => void;
}> = ({ onDropAction }) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const dragAndSelectContext = useDragAndSelect();

  const [draggingItemsOver, setDraggingItemsOver] = useState<number>(0);
  useEffect(() => {
    if (!ref) return;

    const onDragOver = (e: CustomEvent<ObjectDragOver>) => {
      const itemIds =
        e.detail.objectIds && (e.detail.objectIds.length ?? 0) > 0
          ? e.detail.objectIds
          : [e.detail.objectId];

      setDraggingItemsOver(itemIds.length);
    };

    const onDragOut = () => {
      setDraggingItemsOver(0);
    };

    const onDragDrop = (e: CustomEvent<ObjectDragEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      setDraggingItemsOver(0);

      const itemIds =
        e.detail.objectIds && (e.detail.objectIds.length ?? 0) > 0
          ? e.detail.objectIds
          : [e.detail.objectId];

      onDropAction(itemIds);
    };

    ref.addEventListener('object-drag-over', onDragOver);
    ref.addEventListener('object-drag-out', onDragOut);
    ref.addEventListener('object-drag-drop', onDragDrop);

    return () => {
      ref.removeEventListener('object-drag-over', onDragOver);
      ref.removeEventListener('object-drag-out', onDragOut);
      ref.removeEventListener('object-drag-drop', onDragDrop);
    };
  }, [onDropAction, ref]);

  if (!dragAndSelectContext?.isDragging) return null;

  return createPortal(
    <div className={styles.root} ref={setRef} data-droppable data-drop-over={draggingItemsOver > 0}>
      <span className={styles.text}>
        {draggingItemsOver > 0
          ? `${draggingItemsOver} ${plur('item', draggingItemsOver)}`
          : 'Drop here to move items'}
      </span>
    </div>,
    getOffCanvasPortal()
  );
};

export default DropToMove;
