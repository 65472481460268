import FdocLoadState from '@/src/components/FdocItem/FdocLoadState/FdocLoadState';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import styled from 'styled-components';

export const ResourcePreviewLoadingState = ({ resource }: { resource: OptimisticDraft<Fdoc> }) => {
  const isProcessing = isResourceStateProcessing(resource.stateProcessing);
  const isDraft = Boolean(resource.isDraft);
  const isLoadingState = isProcessing || isDraft;

  return isLoadingState && <StyledFdocLoadState isProcessing={isProcessing} />;
};

const StyledFdocLoadState = styled(FdocLoadState)`
  ${containerPreviewCompact} {
    align-items: flex-start;
  }
`;
