import React from 'react';

import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { Size } from '@/src/types/global';
import useUIStore from '../../../store/ui';
import { OfViewMode } from '../../ViewModeSwitcher/ViewModeSwitcher';
import { useFdocItemContext } from '../FdocItem';
import styles from './FdocItemContent.module.scss';
import FdocItemContentImage from './FdocItemContentImage';
import FdocItemContentNotepad from './FdocItemContentNotepad';
import FdocItemContentPage from './FdocItemContentPage';
import FdocItemContentStoredFile from './FdocItemContentStoredFile';
import FdocItemContentText from './FdocItemContentText';
import FolderResource from './FolderResource';

const FdocItemContent: React.FC<{
  fdoc: OptimisticDraft<Fdoc>;
  viewMode: OfViewMode;
  size?: Size;
  isNoteStack?: boolean;
  constrainedImages?: number | null; // if null then no constraint, if X then constrain image height to X
}> = ({ fdoc, viewMode, size, constrainedImages, isNoteStack }) => {
  const query = useUIStore((s) => s.searchQuery);
  const { isMobileCard } = useFdocItemContext();

  return (
    <div className={styles.item__content} data-testid="item-content-section" data-or-obscured>
      <FdocItemContentText fdoc={fdoc} query={query} isMobileCard={isMobileCard} />

      {fdoc.type === 'folder' && <FolderResource fdoc={fdoc} />}

      {fdoc.type === 'notepad' && (
        <FdocItemContentNotepad
          isMobileCard={isMobileCard}
          key={`notepad-${fdoc.id}`}
          fdoc={fdoc}
          query={query}
          isListView={viewMode === 'List'}
        />
      )}

      {viewMode !== 'List' && (
        <>
          {fdoc.type === 'image' && (
            <FdocItemContentImage
              fdoc={fdoc}
              constrainedImages={constrainedImages}
              size={size}
              viewMode={viewMode}
              isMobileCard={isMobileCard}
            />
          )}

          {fdoc.type === 'stored_file' && (
            <FdocItemContentStoredFile
              fdoc={fdoc}
              constrainedImages={constrainedImages}
              viewMode={viewMode}
              size={size}
              isMobileCard={isMobileCard}
              isNoteStack={isNoteStack}
            />
          )}
        </>
      )}
      {fdoc.type === 'page' && (
        <FdocItemContentPage
          fdoc={fdoc}
          viewMode={viewMode}
          constrainedImages={constrainedImages ?? viewMode === 'List' ? 39 : undefined}
          isMobileCard={isMobileCard}
          size={
            viewMode === 'List'
              ? {
                  width: 58,
                  height: 39,
                }
              : undefined
          }
          isNoteStack={isNoteStack}
        />
      )}
    </div>
  );
};

export default FdocItemContent;
