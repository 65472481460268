import { CURRENT_URL } from '@/src/constants/env';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useQueryCacheResourceHelpers } from '@/src/modules/resources/utils/useQueryCacheResourceHelpers';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { Fdoc } from '@/src/types/api';
import { DirectShareOffResponse, DirectShareOnResponse } from '@fabric/woody-client';
import { useMutation } from '@tanstack/react-query';

type MutationVariables = {
  resource: Fdoc;
  shared: boolean;
};

const replaceUrlHostname = (url: string) => {
  if (process.env.REACT_APP_ENV !== 'develop') {
    return url;
  }

  // replace the given URL with the CURRENT_URL
  try {
    const urlObject = new URL(url);
    const currentUrlObject = new URL(CURRENT_URL);
    urlObject.hostname = currentUrlObject.hostname;
    urlObject.protocol = currentUrlObject.protocol;
    urlObject.port = currentUrlObject.port;

    return urlObject.toString();
  } catch (e) {
    return url;
  }
};

export const useMutationChangeResourceShare = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { updateCachedResource } = useQueryCacheResourceHelpers();

  const mutation = useMutation({
    mutationFn: async ({ resource, shared }: MutationVariables): Promise<string | null> => {
      // had to separate or typescript become sad
      return shared
        ? replaceUrlHostname(
            getWoodyResponseData<DirectShareOnResponse>(await client.shareResource(resource.id))
              .shareUrl,
          )
        : getWoodyResponseData<DirectShareOffResponse>(await client.unshareResource(resource.id))
            ?.shareUrl ?? null;
    },
    onMutate: ({ resource, shared }) => {
      return updateCachedResource({
        id: resource.id,
        isDirectShared: shared,
      });
    },
    onError: (_, { shared }, context) => {
      toast({
        content: `Failed to ${shared ? 'share' : 'unshare'} item`,
      });

      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
    onSuccess: (_, { shared, resource }) => {
      toast({
        content: `Item ${shared ? 'shared' : 'unshared'}`,
      });

      track(shared ? AnalyticsEvents.SharedResource : AnalyticsEvents.UnsharedResource, {
        resourceId: resource.id,
      });
    },
  });

  return mutation;
};
