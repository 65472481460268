import React from 'react';

import StateSpinner from '@/src/modules/resources/components/StateSpinner';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: absolute;
  color: ${cssVar['color-app-primary']};

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 15px;
  z-index: 11;
  transition: background-color 0.3s ease-in-out;
  will-change: background-color;

  [data-draft='true'] & {
    background-color: rgba(${cssVar['color-bg-primary-rgb']}, 0.7);
  }

  [data-view-mode='List'] & {
    padding: 0;
    padding-left: 19.5px;
    justify-content: flex-start;
    align-items: center;

    border-radius: 4px;
  }
`;

const FdocLoadState: React.FC<{
  isProcessing?: boolean;
  className?: string;
}> = ({ isProcessing, className }) => {
  return (
    <Wrapper className={className}>
      <StateSpinner size={22} thickness={3} isIndexing={isProcessing} />
    </Wrapper>
  );
};

export default FdocLoadState;
