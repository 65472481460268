import { FabricDataValue } from '@/src/components/Tiptap/slate.types';
import { TruncatorV2 } from '@/src/components/Truncator/TruncatorV2';
import { EditorJSData } from '@fabric/woody-client';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { generateContentPreview } from '../Tiptap/content';
import { TiptapDataValue } from '../Tiptap/types';
import styles from './EditorPreview.module.scss';

interface Props {
  data: EditorJSData | FabricDataValue | TiptapDataValue;
  limit?: number | false; // The maximum number of chars + blocks to render
  style?: React.CSSProperties;
  isMobileCard?: boolean;
  replaceClassName?: string;
  simplifyTables?: boolean;
  isListView?: boolean;
  className?: string;
  withTruncator?: boolean;
}

const EditorPreview: React.FC<Props> = ({
  data,
  limit = 750,
  style,
  isMobileCard,
  isListView,
  replaceClassName,
  simplifyTables = true,
  className,
  withTruncator = false,
}) => {
  const preview = useMemo(() => {
    const dataContent = 'content' in data ? data.content : data;
    const content = typeof dataContent === 'string' ? dataContent : '';
    const html = generateContentPreview(content, limit);

    if (!simplifyTables) return html;

    /* Modify HTML string to have the table rows as a single text element,
  so that we can show the ellipsis at the end of the line when the text
  overflows the editor preview container */
    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;
    const rows = tempElement.querySelectorAll('tr');
    for (let i = 0; i < rows.length; i++) {
      const cells = rows[i].querySelectorAll('td, th');
      const rowText = Array.from(cells)
        .map((cell) => (cell.textContent ? cell.textContent.trim() : ''))
        .join(' ');
      const newParagraph = document.createElement('div');
      newParagraph.textContent = rowText;
      rows[i].innerHTML = '';
      rows[i].appendChild(newParagraph);
    }
    return tempElement.innerHTML;
  }, [data, limit, simplifyTables]);

  const ref = React.useRef<HTMLDivElement>(null);

  return (
    <div
      className={clsx(replaceClassName ?? styles.preview, className, {
        [styles.mobile_card]: isMobileCard,
        [styles.preview_in_list]: isListView,
      })}
      style={style}
      ref={ref}
    >
      {withTruncator ? (
        <TruncatorV2 parentRef={ref}>
          <div
            dangerouslySetInnerHTML={{
              __html: preview,
            }}
          />
        </TruncatorV2>
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: preview,
          }}
        />
      )}
    </div>
  );
};

export default EditorPreview;
