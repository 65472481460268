import React, { useEffect, useMemo } from 'react';
import styles from './ZoomSlider.module.scss';
import clsx from 'clsx';
import Tooltip from '@/src/ui/Tooltip';
import { hasMacOSKbd } from '@/src/types/global';
import { formatPercent } from '@/src/utils/formatters';

export const zoomSequence = [0.5, 0.67, 0.75, 0.8, 0.9, 1];

export interface ZoomSliderProps {
  steps?: number[];
  value: number;
  onChange: (value: number) => void;
  style?: React.CSSProperties;
  className?: string;
  hideButtonControls?: boolean;
  showZoomLevel?: boolean;
}

const ZoomSlider: React.FC<ZoomSliderProps> = ({
  steps = zoomSequence,
  value,
  onChange,
  style,
  className,
  hideButtonControls = false,
  showZoomLevel = false,
}) => {
  const currentStep = Math.max(0, Math.min(steps.indexOf(value), steps.length - 1));
  const currentPosition = Math.max(0, Math.min((currentStep * 100) / (steps.length - 1), 100));

  // Like this because navigator is not available in SSR
  const [hasMacOSKbdState, setHasMacOSKbdState] = React.useState(false);
  useEffect(() => {
    setHasMacOSKbdState(hasMacOSKbd());
  }, []);

  const minValue = useMemo(() => Math.min(...steps), [steps]);
  const maxValue = useMemo(() => Math.max(...steps), [steps]);
  const [trackRef, setTrackRef] = React.useState<HTMLDivElement | null>(null);

  const onMouseDown = React.useCallback(() => {
    if (!trackRef) return;

    const onMouseMove = (e: MouseEvent) => {
      const { left, width } = trackRef.getBoundingClientRect();

      const x = e.clientX - left;
      const step = Math.max(
        0,
        Math.min(Math.round((x / width) * (steps.length - 1)), steps.length - 1),
      );

      const value = steps[step];

      // make sure it's within the range
      if (value < minValue || value > maxValue) return;

      onChange(value);
    };

    const onMouseUp = () => {
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }, [maxValue, minValue, onChange, steps, trackRef]);

  // detect clicks on the track itself
  const onClickTrack = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!trackRef || e.target !== e.currentTarget) return;

      const { left, width } = trackRef.getBoundingClientRect();

      const x = e.clientX - left;
      const step = Math.max(
        0,
        Math.min(Math.round((x / width) * (steps.length - 1)), steps.length - 1),
      );

      const value = steps[step];

      // make sure it's within the range
      if (value < minValue || value > maxValue) return;

      onChange(value);
    },
    [maxValue, minValue, onChange, steps, trackRef],
  );

  const zoomUp = React.useCallback(() => {
    const index = steps.indexOf(value);
    if (index === steps.length - 1) return;

    onChange(steps[index + 1]);
  }, [onChange, steps, value]);

  const zoomDown = React.useCallback(() => {
    const index = steps.indexOf(value);
    if (index === 0) return;

    onChange(steps[index - 1]);
  }, [onChange, steps, value]);

  return (
    <Tooltip label={`Zoom (${hasMacOSKbdState ? '⌘' : 'Ctrl'}+Scroll)`} placement="bottom">
      <div className={clsx(styles.zoomSlider, className)} style={style}>
        <input type="hidden" value={value} />
        {!hideButtonControls && (
          <button className={styles.zoomSliderButton} onClick={zoomDown}>
            <span style={{ paddingTop: 1 }}>-</span>
          </button>
        )}
        <div
          className={styles.zoomSliderTrack}
          ref={setTrackRef}
          onClick={onClickTrack}
          onMouseDown={onMouseDown}
        >
          <div className={styles.zoomSliderThumb} style={{ left: `${currentPosition}%` }} />
        </div>
        {!hideButtonControls && (
          <button className={styles.zoomSliderButton} onClick={zoomUp}>
            <span>+</span>
          </button>
        )}
        {showZoomLevel && <div className={styles.zoomLevelValue}>{formatPercent(value)}</div>}
      </div>
    </Tooltip>
  );
};

export default ZoomSlider;
