import { create } from 'zustand';

export interface SharedStateStates {
  mediaPlaying: boolean;
  mediaTimestamp: number | null;
  pdfPage: number | null;
}

interface SharedStateActions {
  setMediaPlaying: (playing: boolean) => void;
  setMediaTimestamp: (timestamp: number | null) => void;
  setPdfPage: (page: number | null) => void;
}

type SharedStateStore = SharedStateStates & SharedStateActions;

export const useSharedStateStore = create<SharedStateStore>()((set) => ({
  mediaPlaying: false,
  mediaTimestamp: null,
  pdfPage: null,
  setMediaPlaying: (playing: boolean) => set({ mediaPlaying: playing }),
  setMediaTimestamp: (timestamp: number | null) => set({ mediaTimestamp: timestamp }),
  setPdfPage: (page: number | null) => set({ pdfPage: page }),
}));
