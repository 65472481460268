import DOMPurify from 'dompurify';
import { v4 } from 'uuid';
import { EditorJSData } from './editorjs.types';
import {
  BlockType,
  CustomText,
  Descendant,
  FabricDataValue,
  LinkElement,
  ListItemElement,
  QuoteFigureElement,
  WarningElement,
} from './slate.types';

interface INestedListDataItem {
  content: string;
  items: INestedListDataItem[];
}

const sanitizeContent = (content: string) => {
  return DOMPurify.sanitize(content, {
    ALLOWED_TAGS: ['em', 'strong', 'a', 'code', 's', 'i', 'b', 'u', 'mark', 'br', 'a'],
    ALLOWED_ATTR: ['href', 'target', 'rel', 'class'],
  });
};

const fixHtml = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const traverseAndFix = (node: ChildNode): void => {
    if (!node) return;

    if (node.nodeName === 'A' && node.parentNode && node.parentNode.nodeName !== 'BODY') {
      const parent = node.parentNode;
      const grandParent = parent.parentNode;
      if (grandParent) {
        grandParent.insertBefore(node.cloneNode(true), parent.nextSibling);
        parent.removeChild(node);
      }
    }
    if (node.hasChildNodes()) {
      node.childNodes.forEach((n) => traverseAndFix(n));
    }
  };

  traverseAndFix(doc.body);

  return doc.body.innerHTML;
};

const htmlToInlineElements = (_text: string): (CustomText | LinkElement)[] => {
  const text = fixHtml(sanitizeContent(_text));

  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');

  const walk = (node: ChildNode): (CustomText | LinkElement)[] => {
    let elements: (CustomText | LinkElement)[] = [];

    if (!node) return elements;

    switch (node.nodeName) {
      case '#text':
        elements.push({ text: node.textContent || '' });
        break;
      case 'EM':
      case 'I':
        const italicElements = walk(node.firstChild as ChildNode);
        italicElements.forEach((e) => ((e as CustomText).italic = true));
        elements = elements.concat(italicElements);
        break;
      case 'STRONG':
      case 'B':
        const boldElements = walk(node.firstChild as ChildNode);
        boldElements.forEach((e) => ((e as CustomText).bold = true));
        elements = elements.concat(boldElements);
        break;
      case 'U':
        const underlineElements = walk(node.firstChild as ChildNode);
        underlineElements.forEach((e) => ((e as CustomText).underline = true));
        elements = elements.concat(underlineElements);
        break;
      case 'S':
        const strikethroughElements = walk(node.firstChild as ChildNode);
        strikethroughElements.forEach((e) => ((e as CustomText).strikeThrough = true));
        elements = elements.concat(strikethroughElements);
        break;
      case 'CODE':
        const codeElements = walk(node.firstChild as ChildNode);
        codeElements.forEach((e) => ((e as CustomText).code = true));
        elements = elements.concat(codeElements);
        break;
      case 'MARK':
        const highlightElements = walk(node.firstChild as ChildNode);
        highlightElements.forEach((e) => ((e as CustomText).mark = true));
        elements = elements.concat(highlightElements);
        break;
      case 'BR':
        elements.push({ text: '\n' });
        break;
      case 'A':
        const url = (node as HTMLAnchorElement).href;
        const createdAt = new Date().toISOString(); // current timestamp
        const linkElement: LinkElement = {
          id: v4(),
          createdAt,
          type: BlockType.Link,
          url,
          children: [],
        };

        if (node.hasChildNodes()) {
          node.childNodes.forEach((n) => {
            linkElement.children = linkElement.children.concat(walk(n));
          });
        }

        elements.push(linkElement);
        break;
      default:
        if (node.hasChildNodes()) {
          node.childNodes.forEach((n) => {
            elements = elements.concat(walk(n));
          });
        }
    }

    return elements;
  };

  return walk(doc.body);
};

function mapItems(ordered: boolean, items: INestedListDataItem[] = []): ListItemElement[] {
  return items.flatMap((item) => [
    {
      id: v4(),
      type: BlockType.ListItem,
      children: htmlToInlineElements(item.content),
      createdAt: new Date().toISOString(),
    },
    ...(item.items.length > 0
      ? [
          {
            id: v4(),
            type: BlockType.ListItem,
            children: [
              {
                id: v4(),
                type: BlockType.List,
                ordered: ordered,
                children: mapItems(ordered, item.items), // Recursive call for nested items
                createdAt: new Date().toISOString(),
              },
            ],
            createdAt: new Date().toISOString(),
          },
        ]
      : []),
  ]) as ListItemElement[];
}

const convertLegacyBlockToFabricEditorBlock = (
  legacyBlock: EditorJSData['blocks'][number],
): Descendant | Descendant[] | null => {
  switch (legacyBlock.type) {
    case 'paragraph':
      return {
        id: legacyBlock.id ?? v4(),
        type: BlockType.Paragraph,
        children: htmlToInlineElements(legacyBlock.data.text),
        createdAt: new Date().toISOString(),
      };
    case 'header':
      return {
        id: legacyBlock.id ?? v4(),
        type: BlockType.Heading,
        level: legacyBlock.data.level,
        children: htmlToInlineElements(legacyBlock.data.text),
        createdAt: new Date().toISOString(),
      };
    case 'image':
      return {
        id: legacyBlock.id ?? v4(),
        type: BlockType.Image,
        url: legacyBlock.data.url,
        children: [{ text: '' }],
        createdAt: new Date().toISOString(),
      };
    case 'code':
      return {
        id: legacyBlock.id ?? v4(),
        type: BlockType.CodeBlock,
        language: 'text',
        children: legacyBlock.data.code.split('\n').map((line: string) => ({
          id: v4(),
          type: BlockType.CodeLine,
          children: [{ text: line }],
          createdAt: new Date().toISOString(),
        })),
        createdAt: new Date().toISOString(),
      };
    case 'quote': {
      const quoteFigure: QuoteFigureElement = {
        id: legacyBlock.id ?? v4(),
        type: BlockType.QuoteFigure,
        children: [
          {
            id: v4(),
            type: BlockType.Quote,
            children: htmlToInlineElements(legacyBlock.data.text),
            createdAt: new Date().toISOString(),
          },
        ],
        createdAt: new Date().toISOString(),
      };

      if (legacyBlock.data.caption) {
        quoteFigure.children.push({
          id: v4(),
          type: BlockType.QuoteAuthor,
          children: htmlToInlineElements(legacyBlock.data.caption),
          createdAt: new Date().toISOString(),
        });
      }

      return quoteFigure;
    }
    case 'warning':
      const warning: WarningElement = {
        id: legacyBlock.id ?? v4(),
        type: BlockType.Warning,
        children: legacyBlock.data.title?.length
          ? [
              {
                id: v4(),
                type: BlockType.Heading,
                level: 3,
                children: htmlToInlineElements(legacyBlock.data.title),
                createdAt: new Date().toISOString(),
              },
              {
                id: v4(),
                type: BlockType.Paragraph,
                children: htmlToInlineElements(legacyBlock.data.message),
                createdAt: new Date().toISOString(),
              },
            ]
          : htmlToInlineElements(legacyBlock.data.message),
        createdAt: new Date().toISOString(),
      };

      return warning;
    case 'checklist':
      return legacyBlock.data.items.map((item: { text: string; checked: boolean }) => ({
        id: v4(),
        type: BlockType.Check,
        checked: item.checked,
        children: htmlToInlineElements(item.text),
        createdAt: new Date().toISOString(),
      }));

    case 'list':
      return {
        id: legacyBlock.id ?? v4(),
        type: BlockType.List,
        ordered: legacyBlock.data.style === 'ordered',
        children: legacyBlock.data.items.map((item: string) => ({
          id: v4(),
          type: BlockType.ListItem,
          children: htmlToInlineElements(item),
          createdAt: new Date().toISOString(),
        })),
        createdAt: new Date().toISOString(),
      };
    case 'nestedList': {
      {
        return {
          id: legacyBlock.id ?? v4(),
          type: BlockType.List,
          ordered: legacyBlock.data.style === 'ordered',
          children: mapItems(legacyBlock.data.style === 'ordered', legacyBlock.data.items),
          createdAt: new Date().toISOString(),
        };
      }
    }

    case 'table':
      return {
        id: legacyBlock.id ?? v4(),
        type: BlockType.Table,
        children: legacyBlock.data.content.map((row: string[]) => ({
          id: v4(),
          type: BlockType.TableRow,
          children: row.map((cell: string) => ({
            id: v4(),
            type: BlockType.TableCell,
            children: htmlToInlineElements(cell),
            createdAt: new Date().toISOString(),
          })),
          createdAt: new Date().toISOString(),
        })),
        createdAt: new Date().toISOString(),
      };
    default: {
      if ('text' in legacyBlock.data) {
        return {
          id: legacyBlock.id ?? v4(),
          type: BlockType.Paragraph,
          children: htmlToInlineElements(legacyBlock.data.text),
          createdAt: new Date().toISOString(),
        };
      }

      return null;
    }
  }
};

export const convertEditorJsToSlate = (legacyData: EditorJSData): FabricDataValue => {
  return {
    version: '0.0.1',
    time: legacyData.time ?? Date.now(),
    data: legacyData.blocks
      .map(convertLegacyBlockToFabricEditorBlock)
      .filter((b) => b !== null)
      .flat() as Descendant[],
    blocks: legacyData.blocks,
  };
};
