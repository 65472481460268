import Moveable from 'react-moveable';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Editor, EditorEvents } from '@tiptap/react';
import styles from './Resizer.module.scss';

type Props = {
  editor: Editor;
};

const Resizer: React.FC<Props> = ({ editor }) => {
  const [activeElement, setActiveElement] = useState<null | HTMLElement>(null);
  const moveableRef = useRef<Moveable | null>(null);

  useEffect(() => {
    // check for changes in selection
    const handler: (event: EditorEvents['selectionUpdate']) => void = ({ editor }) => {
      // find element with class ProseMirror-selectednode
      const element = editor.view.dom.querySelector('.ProseMirror-selectednode');
      // now check if itself or a child contains the data-resizeable attribute
      const resizeable = element?.querySelector('[data-resizeable]');

      // make sure it exists and is HTMLElement
      if (!resizeable || !(resizeable instanceof HTMLElement)) {
        setActiveElement(null);
        return;
      }

      // set active element to resizeable element
      setActiveElement(resizeable);
    };

    const handleChanges: (event: EditorEvents['transaction']) => void = () => {
      // if changes happens we updateRect on the moveable to make sure it's still in the right place
      moveableRef.current?.updateRect();
    };

    const onScroll = () => {
      // if the editor scrolls we want to update the rect
      moveableRef.current?.updateRect();
    };

    editor.on('selectionUpdate', handler);
    editor.on('transaction', handleChanges);
    window.addEventListener('scroll', onScroll, true);
    window.addEventListener('wheel', onScroll, true);

    return () => {
      editor.off('selectionUpdate', handler);
      editor.off('transaction', handleChanges);
      window.removeEventListener('scroll', onScroll, true);
      window.removeEventListener('wheel', onScroll, true);
    };
  }, [editor]);

  const updateSize = useCallback(
    (width: number) => {
      if (!activeElement) return;

      // find pos from DOM element
      const pos = editor.view.posAtDOM(activeElement, 0);
      // get node attrs
      const activeNode = editor.state.doc.nodeAt(pos);

      if (!activeNode) return;

      editor
        .chain()
        .focus()
        .updateAttributes(activeNode.type.name, {
          width,
          height: 'auto',
        })
        .setNodeSelection(pos)
        .run();
    },
    [editor, activeElement],
  );

  if (!activeElement) return null;

  return (
    <Moveable
      ref={moveableRef}
      target={activeElement}
      className={styles.resizer}
      useAccuratePosition={true}
      container={null}
      origin={false}
      edge={false}
      useResizeObserver={true}
      useMutationObserver={true}
      keepRatio={true}
      resizable={true}
      throttleResize={0}
      onResize={({ target, width, delta }) => {
        delta[0] && (target.style.width = `${width}px`);
        delta[1] && (target.style.height = `auto`);
      }}
      onResizeEnd={({ target }) => {
        const width = parseInt(target.style.width);

        updateSize(width);
      }}
      // render left and right center handles
      renderDirections={['w', 'e']}
    />
  );
};

export default Resizer;
