import React from 'react';

type VoidProps = {
  [k: string]: unknown;
  children?: React.ReactNode;
  key?: React.Key;
};

/**
 * Void is a component that does nothing, it can be used like a React.Fragment, but it voids the props,
 * this way there are no console warnings about unknown props.
 */
export const Void: React.FC<VoidProps> = ({ key, children }) => {
  return <React.Fragment key={key}>{children}</React.Fragment>;
};
