import { SpotifyEmbed } from './components/embeds/SpotifyEmbed';
import { TwitterEmbed } from './components/embeds/TwitterEmbed';
import { VimeoEmbed } from './components/embeds/VimeoEmbed';
import { YoutubeEmbed } from './components/embeds/YoutubeEmbed';
import { EmbedType, PageResourceEmbed } from './expandedResource.types';
import {
  SPOTIFY_URL_REGEX,
  TWITTER_URL_REGEX,
  VIMEO_URL_REGEX,
  YOUTUBE_URL_REGEX,
} from './expandedResource.utils';

export const pageResourceEmbeds: PageResourceEmbed[] = [
  {
    type: EmbedType.YOUTUBE,
    matchFn: (url: string) => {
      const match = url.match(YOUTUBE_URL_REGEX);
      return match ? match[0] : null;
    },
    renderElement: YoutubeEmbed,
  },
  {
    type: EmbedType.VIMEO,
    matchFn: (url: string) => {
      const match = url.match(VIMEO_URL_REGEX);
      return match ? match[0] : null;
    },
    renderElement: VimeoEmbed,
  },
  {
    type: EmbedType.TWITTER,
    matchFn: (url: string) => {
      const match = url.match(TWITTER_URL_REGEX);
      return match ? match[0] : null;
    },
    renderElement: TwitterEmbed,
  },
  {
    type: EmbedType.SPOTIFY,
    matchFn: (url: string) => {
      const match = url.match(SPOTIFY_URL_REGEX);
      return match ? match[0] : null;
    },
    renderElement: SpotifyEmbed,
  },
];
