import React from 'react';

type InputControlsOptions = {
  trim?: boolean;
  onChange?: (value: string) => void;
  debounce?: number;
};

const defaultOptions: InputControlsOptions = {
  trim: false,
};

export const useInputControls = (defaultValue = '', optionsArg?: InputControlsOptions) => {
  const {
    trim,
    onChange: onChangeProp,
    debounce = 0,
  } = {
    ...defaultOptions,
    ...optionsArg,
  };

  const [value, setValue] = React.useState(defaultValue);

  // Not using debounced on change because it depends on the onChange being unique, and we can't guarantee
  // that here.
  const timeout = React.useRef<number | null>(null);
  const onChange = React.useCallback(
    (eventOrValue: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string) => {
      let nextValue =
        typeof eventOrValue === 'string'
          ? eventOrValue
          : (eventOrValue.target as HTMLInputElement).value;

      if (trim) {
        nextValue = nextValue.trim();
      }

      setValue(nextValue);

      if (timeout.current) clearTimeout(timeout.current);

      if (debounce > 0) {
        timeout.current = window.setTimeout(() => {
          onChangeProp?.(nextValue);
        }, debounce);
      } else onChangeProp?.(nextValue);
    },
    [trim, onChangeProp, setValue, debounce],
  );

  const clear = React.useCallback(() => {
    setValue('');
  }, [setValue]);

  const res = React.useMemo(
    () => ({
      value,
      sanitizedValue: value.trim(),
      onChange,
      clear,
      set: setValue,
    }),
    [value, onChange, clear, setValue],
  );

  return res;
};
