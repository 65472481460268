import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { mutateResourceParent } from '@/src/modules/resource-detail/utils/mutateResourceParent';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation } from '@tanstack/react-query';
import { useQueryCacheResourceHelpers } from '../utils/useQueryCacheResourceHelpers';

export type ResourceMoveActions =
  | 'move-modal'
  | 'drop-into-sidebar'
  | 'drop-into-folder'
  | 'drop-into-space'
  | 'list-context-create-sub-folder';

type MutationVariables = {
  resourceIds: string[];
  targetId: string;
  action?: ResourceMoveActions;
};

export const useMutationMoveResourcesById = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { deleteCachedResources, addNewResourcesToCache } = useQueryCacheResourceHelpers();

  const mutation = useMutation({
    mutationFn: async ({ resourceIds, targetId }: MutationVariables) => {
      return getWoodyResponseData(await client.moveResources(resourceIds, targetId));
    },
    onMutate: ({ resourceIds, targetId }) => {
      const deleteCachedResourcesContext = deleteCachedResources(resourceIds);
      const mutatedResources = deleteCachedResourcesContext.deletedResources.map((resource) =>
        // We only mutate the parent of the root resources, if they are not in the [resourceIds] it means
        // they where a child of a resource that was moved, so we don't need to mutate them
        resourceIds.includes(resource.id) ? mutateResourceParent(resource, targetId) : resource,
      );

      const addNewResourcesToCacheContext = addNewResourcesToCache(mutatedResources);

      return {
        deleteCachedResourcesContext,
        addNewResourcesToCacheContext,
      };
    },
    onSuccess: (_, { resourceIds, action = 'move-modal' }) => {
      track(AnalyticsEvents.MovedItems, {
        action,
        count: resourceIds.length,
      });

      const movedItemsTotal = resourceIds.length || 0;
      toast({
        content: movedItemsTotal > 1 ? `${movedItemsTotal} items moved` : 'Item moved',
      });
    },
    onError: (_, _v, context) => {
      toast({
        content: 'Failed to move',
      });

      context?.deleteCachedResourcesContext.resetCacheToPreOptimisticState();
      context?.deleteCachedResourcesContext.invalidateQueries();
      context?.addNewResourcesToCacheContext.resetCacheToPreOptimisticState();
      context?.addNewResourcesToCacheContext.invalidateQueries();
    },
    onSettled: (_, _e, _v, ctx) => {
      ctx?.addNewResourcesToCacheContext.clearPendingState();
    },
  });

  return mutation;
};
