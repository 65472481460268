import { useReferencedFn } from '@/src/hooks/useReferencedFn';
import { useThrottledCallback } from '@/src/hooks/useThrottledCallback';
import useModifierKeysStore from '@/src/store/modifierKeys';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { MULTISELECT_IGNORE_AREA } from '../lib/multiselect';
import useMobileSelectionStore from '../store/mobileSelection';
import { Position } from '../types/global';
import { useResponsive } from './responsive';

const getScrollableParent = (element: Element): Element | null => {
  let parent = element.parentElement;

  while (parent && parent !== document.body) {
    const style = window.getComputedStyle(parent);
    if (style.overflow !== 'visible') {
      return parent;
    }
    parent = parent.parentElement;
  }
  return null;
};

const isElementVisibleInScrollableParent = (element: Element): boolean => {
  const parent = getScrollableParent(element);
  if (!parent) return true;

  const parentRect = parent.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();

  // Check if any part of the element is visible in the scrollable parent
  return (
    elementRect.top < parentRect.bottom &&
    elementRect.bottom > parentRect.top &&
    elementRect.left < parentRect.right &&
    elementRect.right > parentRect.left
  );
};

const getIdsInsideArea = (
  start: Position,
  end: Position,
  options: {
    selector?: string;
  },
) => {
  const selector = options?.selector || '[data-selectable-id]';

  // might need to switch start and end depending which one is bigger
  const startX = Math.min(start.x, end.x);
  const endX = Math.max(start.x, end.x);
  const startY = Math.min(start.y, end.y);
  const endY = Math.max(start.y, end.y);

  // selectable element contains data-selectable-id
  const elements = document.querySelectorAll(selector);

  return Array.from(elements).reduce((acc: string[], element) => {
    const rect = element.getBoundingClientRect();
    const selectableId = element.getAttribute('data-selectable-id');
    if (!selectableId) return acc;

    const style = window.getComputedStyle(element);
    if (style.display === 'none' || style.visibility === 'hidden') return acc;

    if (!isElementVisibleInScrollableParent(element)) {
      return acc;
    }

    if (startX < rect.x && endX < rect.x) return acc;
    if (startY < rect.y && endY < rect.y) return acc;
    if (startX > rect.x + rect.width && endX > rect.x + rect.width) return acc;
    if (startY > rect.y + rect.height && endY > rect.y + rect.height) return acc;

    return [...acc, selectableId];
  }, []);
};

const useDragMultiselect = (
  canMultiSelect: boolean,
  onClearSelection?: () => void,
  _selectionFilter: (id: string) => boolean = () => true,
  /**
   * Selects items only within the layer with multiSelectLayerId
   * Mark your element with data-multiselect-layer-id="your-layer-id"
   * BUG FUT-3367, when selecting on search overlay, it was selecting items underneath as well, e.g. when on folders page
   */
  multiSelectLayerId?: string,
) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [activeSelectionIds, setActiveSelectionIds] = useState<string[]>([]);

  const { meta, shift } = useModifierKeysStore();
  const modifierOperation = meta || shift;

  const [multiSelect, setMultiSelect] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false);
  /**
   * isDragging is used to determine if the user is dragging the mouse
   * while mouse down is active
   */
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState<Position | null>(null);
  const [endPosition, setEndPosition] = useState<Position | null>(null);

  const { mobileSelectModeIsActive } = useMobileSelectionStore(
    (state) => ({
      mobileSelectModeIsActive: state.mobileSelectModeIsActive,
    }),
    shallow,
  );

  // Prevents unnecessary unmounts of useEffects in case the given _selectionFilter function changes
  const selectionFilter = useReferencedFn(_selectionFilter);

  const handleClearSelection = useCallback(() => {
    setSelectedIds([]);
    if (onClearSelection) onClearSelection();
  }, [onClearSelection]);

  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      // if esc we also want to clear the selection
      if (e.key === 'Escape') {
        setMultiSelect(false);
        setStartPosition(null);
        setEndPosition(null);
        setActiveSelectionIds([]);
        handleClearSelection();
        return;
      }
    };

    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [handleClearSelection]);

  const { isMobile } = useResponsive();

  useEffect(() => {
    if (isMobile()) return;

    const handleOnMouseDown = (e: MouseEvent) => {
      // if the targetElement or an ancestor don't contain the data-multiselect-allow attribute
      // we ignore
      if (!e.target) return;
      const targetElement = e.target as HTMLElement;

      if (
        targetElement.closest('[data-multiselect-clear]') ||
        targetElement.hasAttribute('data-multiselect-clear')
      ) {
        handleClearSelection();
        return;
      }

      if (
        !(
          targetElement.hasAttribute('data-multiselect-allow') ||
          targetElement.closest('[data-multiselect-allow]')
        )
      ) {
        return;
      }

      if (e.button !== 0 || !canMultiSelect) return;

      if (modifierOperation) {
        setEndPosition({ x: e.clientX, y: e.clientY });
        e.stopPropagation();
        e.preventDefault();
      } else {
        setEndPosition(null);
      }

      setMultiSelect(true);
      setIsMouseDown(true);
      setStartPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mousedown', handleOnMouseDown);
    return () => window.removeEventListener('mousedown', handleOnMouseDown);
  }, [canMultiSelect, modifierOperation, handleClearSelection, isMobile]);

  useEffect(() => {
    if (!mobileSelectModeIsActive) {
      setMultiSelect(false);
      setIsDragging(false);
      setIsMouseDown(false);
      setStartPosition(null);
      setEndPosition(null);
      setActiveSelectionIds([]);
      handleClearSelection();
    }
  }, [mobileSelectModeIsActive, handleClearSelection]);

  const recalculateActiveSelection = useCallback(
    (
      startPosition: Position,
      endPosition: Position,
      modifierOperation: boolean,
      multiSelectLayerId?: string,
    ) => {
      const width = Math.abs(startPosition.x - endPosition.x);
      const height = Math.abs(startPosition.y - endPosition.y);
      const area = width * height;

      if (area < MULTISELECT_IGNORE_AREA && !modifierOperation) return;

      const foundIds = getIdsInsideArea(startPosition, endPosition, {
        selector: multiSelectLayerId
          ? `[data-multiselect-layer-id="${multiSelectLayerId}"] [data-selectable-id]`
          : undefined,
      });
      setActiveSelectionIds(foundIds);

      return foundIds;
    },
    [],
  );

  const throttledRecalculateActiveSelection = useThrottledCallback(recalculateActiveSelection, 100);

  useEffect(() => {
    if (!multiSelect || mobileSelectModeIsActive || !startPosition) return;

    const handleOnMouseUp = (e: MouseEvent) => {
      if (e.button !== 0) return;

      // if shift key or cmd is not pressed we clear the multiSelectedFdocs
      if (!modifierOperation) handleClearSelection();
      else setEndPosition({ x: e.clientX, y: e.clientY });

      const selection =
        recalculateActiveSelection(
          startPosition,
          {
            x: e.clientX,
            y: e.clientY,
          },
          modifierOperation,
          multiSelectLayerId,
        ) ?? [];

      throttledRecalculateActiveSelection.current.cancel();

      setSelectedIds((prev) =>
        [
          ...prev.filter((id) => !selection.includes(id)),
          ...selection.filter((id) => !prev.includes(id)),
        ].filter(selectionFilter.current),
      );

      setMultiSelect(false);
      setIsDragging(false);
      setIsMouseDown(false);
      setStartPosition(null);
      setEndPosition(null);
      setActiveSelectionIds([]);
    };

    const handleOnMouseMove = (e: MouseEvent) => {
      if (isMouseDown) {
        setIsDragging(true);
      }
      setEndPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener('mouseup', handleOnMouseUp);
    const t = setTimeout(() => {
      window.addEventListener('mousemove', handleOnMouseMove);
    }, 10);

    return () => {
      window.removeEventListener('mouseup', handleOnMouseUp);
      window.removeEventListener('mousemove', handleOnMouseMove);
      clearTimeout(t);
    };
  }, [
    handleClearSelection,
    modifierOperation,
    multiSelect,
    mobileSelectModeIsActive,
    startPosition,
    recalculateActiveSelection,
    multiSelectLayerId,
    selectionFilter,
    throttledRecalculateActiveSelection,
    isMouseDown,
  ]);

  useEffect(() => {
    if (!multiSelect || !startPosition || !endPosition || mobileSelectModeIsActive) return;

    throttledRecalculateActiveSelection.current(
      startPosition,
      endPosition,
      modifierOperation,
      multiSelectLayerId,
    );
  }, [
    endPosition,
    mobileSelectModeIsActive,
    modifierOperation,
    multiSelect,
    multiSelectLayerId,
    recalculateActiveSelection,
    startPosition,
    throttledRecalculateActiveSelection,
  ]);

  useEffect(() => {
    if (multiSelect || activeSelectionIds.length === 0 || mobileSelectModeIsActive) return;

    // overlap with current selection
    setSelectedIds((prev) =>
      [
        ...prev.filter((id) => !activeSelectionIds.includes(id)),
        ...activeSelectionIds.filter((id) => !prev.includes(id)),
      ].filter(selectionFilter.current),
    );
    setActiveSelectionIds([]);
  }, [multiSelect, activeSelectionIds, selectionFilter, mobileSelectModeIsActive]);

  const selectedIdsDiff = useMemo(() => {
    // if modifier operation is active  we return selectedIds and activeSelectionIds merged
    // otherwise we return activeSelectionIds if multiSelect is active
    // or selectedIds if multiSelect is not active
    if (modifierOperation) {
      return [
        ...selectedIds.filter((id) => !activeSelectionIds.includes(id)),
        ...activeSelectionIds.filter((id) => !selectedIds.includes(id)),
      ].filter(selectionFilter.current);
    }

    if (multiSelect) return activeSelectionIds.filter(selectionFilter.current);
    return selectedIds.filter(selectionFilter.current);
  }, [modifierOperation, multiSelect, selectedIds, activeSelectionIds, selectionFilter]);

  return {
    selectedIds,
    setSelectedIds,
    activeSelectionIds,
    setActiveSelectionIds,
    canMultiSelect,
    multiSelect,
    setMultiSelect,
    modifierOperation,
    startPosition,
    setStartPosition,
    endPosition,
    setEndPosition,
    selectedIdsDiff,
    handleClearSelection,
    isDragging,
  };
};

export default useDragMultiselect;
