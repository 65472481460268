import { useAuthUser } from '@/src/hooks/auth';
import { Comment } from '@/src/modules/comments/comments.types';
import { createOptimisticComment } from '@/src/modules/comments/utils/createOptimisticComment';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation } from '@tanstack/react-query';
import { useQueryCacheCommentHelpers } from '../utils/useQueryCacheCommentHelpers';

type MutationVariables = {
  resourceId: string;
  content: string;
};

export const useMutationCreateComment = () => {
  const user = useAuthUser();
  const { client } = useWoody();

  const { addCachedComment, replaceCommentInCache } = useQueryCacheCommentHelpers();

  const mutation = useMutation({
    mutationFn: async ({ resourceId, content }: MutationVariables): Promise<Comment> => {
      return client.v2(
        {
          endpoint: '/v2/resources/{resourceId}/comments',
          params: {
            resourceId,
          },
        },
        {
          query: {
            accessToken: undefined,
          },
          method: 'post',
          body: {
            content: content,
          },
        },
      );
    },
    onMutate: ({ resourceId, content }) => {
      if (!user) return;

      const optimisticComment = createOptimisticComment(user, content, resourceId);

      return {
        optimisticComment,
        ...addCachedComment(optimisticComment),
      };
    },
    onError: (_error, _variables, context) => {
      toast({
        content: 'Failed to create comment',
      });

      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
    onSuccess: (data, _v, context) => {
      replaceCommentInCache(context.optimisticComment, data);
    },
  });

  return mutation;
};
