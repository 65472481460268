import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { PrivateTag } from '@fabric/woody-client';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { tagQueryKeys } from './tagsQueryKeys';

/**
 * Fetches all tags for the current user, optionally filtered by a search query.
 */
export const useQueryTags = (
  searchQuery?: string,
  queryOptions?: Partial<QueryObserverOptions<PrivateTag[]>>,
) => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();

  const query = useQuery({
    queryKey: tagQueryKeys.all(searchQuery || undefined),
    queryFn: async (params) => {
      const searchQuery = params.queryKey[1] as string | undefined;

      const {
        data: { tags },
      } = await client.v2('/v2/tags', {
        query: {
          name: searchQuery,
        },
      });

      return tags;
    },
    placeholderData: keepPreviousData,
    refetchInterval: 55000,
    ...queryOptions,
    enabled: isQueryEnabled([isLoggedIn, queryOptions?.enabled]),
  });

  return query;
};
