import React, { useCallback } from 'react';

/**
 * Defines the shape of the shared hover state object.
 */
interface SharedHoverState {
  /**
   * Retrieves the hover state for the element with the specified ID.
   * @param id The ID of the element to retrieve the hover state for.
   * @returns The hover state for the element with the specified ID.
   */
  getHover: (id: string) => boolean;

  /**
   * Sets the hover state for the element with the specified ID.
   * @param id The ID of the element to set the hover state for.
   * @param hovered The new hover state for the element.
   */
  setHover: (id: string, hovered: boolean) => void;

  /**
   * Toggles the hover state for the element with the specified ID.
   * @param id The ID of the element to toggle the hover state for.
   */
  toggleHover: (id: string) => void;

  /**
   * Removes any active hover state for any element.
   */
  removeHover: () => void;

  /**
   * The ID of the currently hovered element.
   */
  hoverId: string | null;
}

/**
 * The shared hover state context, which provides access to the shared hover state object.
 */
const SharedHoverContext = React.createContext<SharedHoverState>({
  // Default implementations for the context functions.
  getHover: () => false,
  setHover: () => {},
  toggleHover: () => {},
  removeHover: () => {},
  hoverId: null,
});

/**
 * A hook that provides a easy access to the shared hover state object.
 * @returns The shared hover state object.
 */
const useSharedHover = () => React.useContext(SharedHoverContext);

/**
 * The shared hover state provider component, which provides the shared hover state object to its child components.
 */
const SharedHoverProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Changed from a map to only one ID, because it doesn't make sense to have multiple hover states in this example.
  // Usually you are comparing multiple children of a parent, and you can't hover 2 children at the same time.
  const [hoverId, setHoverId] = React.useState<string | null>(null);

  const getHover = useCallback((id: string) => hoverId === id, [hoverId]);

  const setHover = useCallback((id: string, hovered: boolean) => {
    setHoverId((hId) => (id === hId && !hovered ? null : hovered ? id : hId));
  }, []);

  const removeHover = useCallback(() => {
    setHoverId(null);
  }, []);

  const toggleHover = useCallback(
    (id: string) => {
      setHoverId((hId) => (hId === id ? null : id));
    },
    [setHoverId]
  );

  return (
    <SharedHoverContext.Provider value={{ hoverId, getHover, setHover, toggleHover, removeHover }}>
      {children}
    </SharedHoverContext.Provider>
  );
};

export { SharedHoverProvider, useSharedHover };
