import { getUserColorFromId } from '@/src/utils/color';
import React, { forwardRef } from 'react';
import Avatar from '../Avatar/Avatar';

type SimplifiedUser = {
  id?: string;
  name?: string | null;
  picture?: string | null;
  pictureUrlCdn?: string | null;
};

interface AvatarUserProps extends React.ComponentProps<typeof Avatar> {
  user: SimplifiedUser | null;
}

const AvatarUser = forwardRef<React.ElementRef<typeof Avatar>, AvatarUserProps>(
  ({ user, ...props }, ref) => (
    <Avatar
      color={user?.id ? getUserColorFromId(user.id) : undefined}
      tooltip={user?.name}
      {...props}
      ref={ref}
    >
      {user ? (
        <Avatar.Image
          src={user.picture || user.pictureUrlCdn || undefined}
          alt={user.name ?? 'Avatar'}
        >
          <Avatar.Text>{user.name?.charAt(0)}</Avatar.Text>
        </Avatar.Image>
      ) : (
        <Avatar.Spinner />
      )}
    </Avatar>
  ),
);

AvatarUser.displayName = 'AvatarUser';

export default AvatarUser;
