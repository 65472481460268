import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';
import { useQueryFolder } from '@/src/modules/resources/queries/useQueryFolder';
import { useQueryResourceRootSpaceSystemList } from '@/src/modules/spaces/queries/useQueryResourceRootSpaceSystemList';
import { FolderFdoc } from '@/src/types/api';
import React from 'react';

type UseQuerySpaceOrFolderByIdReturn =
  | {
      type: 'space';
      data: ResourceRoot;
    }
  | {
      type: 'folder';
      data: FolderFdoc;
    }
  | null;

export const useQuerySpaceOrFolderById = (id?: string): UseQuerySpaceOrFolderByIdReturn => {
  const { isLoading: spacesIsLoading, spaceRoots } = useQueryResourceRootSpaceSystemList(
    undefined,
    {
      includeInbox: true,
    },
  );

  const foundSpace = spaceRoots?.find((space) => space.id === id);

  const { data: folder } = useQueryFolder(id, {
    /**
     * disabled when spaces are not loaded yet
     * then check if the provided id is space. If true,we don't need to fetch the folder
     */
    enabled: !spacesIsLoading && !foundSpace,
  });

  return React.useMemo(() => {
    if (foundSpace) {
      return {
        type: 'space',
        data: foundSpace,
      };
    }

    if (folder) {
      return {
        type: 'folder',
        data: folder,
      };
    }

    return null;
  }, [folder, foundSpace]);
};
