import { Coordinate } from '@/src/types/global';

/**
 * Formats disk space usage into a human-readable string using decimal (metric) units.
 *
 * This function converts bytes to the largest possible unit (KB, MB, GB, etc.)
 * where the value is less than 1000. It uses decimal (1000-based) units rather than
 * binary (1024-based) units. The minimum represented size is 4 KB, which is the
 * typical minimum allocation unit for most modern disk systems.
 *
 * @param bytes - The number of bytes of disk space to format.
 * @returns A string representing the formatted disk space value with its appropriate unit.
 */
const formatDiskSpace = (bytes: number): string => {
  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let value = Math.max(bytes, 4000); // Minimum 4 KB
  let unitIndex = -1; // Start at -1 to account for KB
  while (value >= 1000 && unitIndex < units.length - 1) {
    value /= 1000;
    unitIndex++;
  }

  // Handle KB separately
  if (unitIndex === -1) {
    return `${Math.ceil(value / 1000)} KB`;
  }

  return `${value.toFixed(1)} ${units[unitIndex]}`.replace('.0', '');
};

/**
 * This function will try to convert any value into an int, even if it is already
 * a number. This is to help with unknown types.
 */
const parseIntOrString = (value: string | number): number => {
  if (typeof value === 'number') return value;
  return parseInt(value, 10);
};

/**
 * Formats geographic coordinates into a human-readable string.
 *
 * This function takes a Coordinate object with latitude and longitude properties,
 * rounds the coordinates to five decimal places, determines the hemisphere (N/S for latitude, E/W for longitude),
 * and returns the formatted string.
 *
 * @param location - An object containing `latitude` and `longitude` properties as numbers.
 * @returns A string in the format "XX.XXXXX° [N|S], YY.YYYYY° [E|W]" representing the coordinates.
 */
function formatCoordinates(location: Coordinate): string {
  const { latitude, longitude } = location;

  // Round coordinates to 5 decimal places
  let lat = Math.abs(latitude).toFixed(5);
  let lon = Math.abs(longitude).toFixed(5);

  // Determine hemisphere based on sign of the coordinate
  if (latitude > 0) {
    lat += '° N';
  } else {
    lat += '° S';
  }

  if (longitude > 0) {
    lon += '° E';
  } else {
    lon += '° W';
  }

  return `${lat}, ${lon}`;
}

/**
 * Given a number in seconds will convert it to a human readable string.
 *
 * Either HH:MM:SS or MM:SS if less than an hour.
 * @param totalSeconds - The total number of seconds to be converted.
 * @returns A human readable string representing the time duration.
 */
function formatDuration(totalSeconds: number): string {
  if (totalSeconds < 0) {
    return '00:00';
  }

  const hours = Math.round(Math.floor(totalSeconds / 3600));
  const minutes = Math.round(Math.floor((totalSeconds % 3600) / 60));
  const remainingSeconds = Math.round(totalSeconds % 60);

  if (hours > 0) {
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  } else {
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }
}

function formatBitrate(bitrate: number): string {
  const units = ['bps', 'Kbps', 'Mbps', 'Gbps'];
  let unitIndex = 0;

  while (bitrate >= 1000 && unitIndex < units.length - 1) {
    bitrate /= 1000;
    unitIndex++;
  }

  return `${bitrate.toFixed(2)} ${units[unitIndex]}`;
}

function formatSampleRate(sampleRate: number): string {
  return `${(sampleRate / 1000).toFixed(1)} kHz`;
}

export {
  formatBitrate,
  formatCoordinates,
  formatDiskSpace,
  formatDuration,
  formatSampleRate,
  parseIntOrString,
};
