import { FolderIcon, SpacesIcon } from '@/src/components/DashboardSidebarV2/icons';
import { useBoolState } from '@/src/hooks/useBooleanState';
import ChevronUpIcon from '@/src/icons/ChevronUpIcon';
import { useLocationSelectorContext } from '@/src/modules/resources/components/DestinationSelector/LocationSelectorContext';
import { LocationSelectorNodeData } from '@/src/modules/resources/components/DestinationSelector/types';
import { folderToLocationSelectorNodeData } from '@/src/modules/resources/components/DestinationSelector/utils';
import { useQueryFolders } from '@/src/modules/resources/queries/useQueryFolders';
import { Button } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import { IntersectionObserverAnchor } from '@/src/modules/ui/components/IntersectionObserver/IntersectionObserver';
import { P } from '@/src/modules/ui/components/Typography';
import { AnimateChangeInHeight } from '@/src/modules/ui/components/animations/AnimateChangeInHeight';
import { ButtonExpandCollapse } from '@/src/modules/ui/components/button/ButtonExpandCollapse';
import { framerAnimationFade } from '@/src/modules/ui/constants/framerAnimations';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { motion } from 'framer-motion';
import React from 'react';
import styled, { css } from 'styled-components';

const NodeWrapper = styled.div`
  border-top: 1px solid ${cssVar['color-border-secondary']};
  height: 59px;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  padding-left: 4px;
  padding-right: 10px;
  overflow: hidden;
  width: 100%;
`;

const cssNameButtonHighlightState = css`
  background: rgba(${cssVar['color-app-primary-rgb']}, 0.1);
  box-shadow: none;
  color: ${cssVar['color-app-primary']};
  svg,
  ${P} {
    color: ${cssVar['color-app-primary-text']};
  }
`;

const NameButton = styled(Button).attrs({ variant: 'transparent' })`
  flex-grow: 1;
  justify-content: flex-start;
  outline-width: 1px;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.9375rem;
  color: ${cssVar['color-text-primary']};
  border-radius: 0.5rem;
  gap: 1rem;
  overflow: hidden;
  flex-shrink: 1;
  svg {
    color: ${cssVar['color-text-quaternary']};
  }
  ${mediaHover} {
    &:hover:enabled {
      ${cssNameButtonHighlightState}
      background: rgba(${cssVar['color-app-primary-rgb']}, 0.04);
      &[data-node-selector-selected='true'] {
        background: rgba(${cssVar['color-app-primary-rgb']}, 0.1);
      }
    }
  }
  &[data-node-selector-selected='true'] {
    ${cssNameButtonHighlightState}
  }

  &:focus {
    outline: none;
  }

  &:disabled,
  &:disabled:hover {
    background: transparent;
    color: ${cssVar['color-text-placeholder']};
    opacity: 0.5;
    ${P}, svg {
      color: ${cssVar['color-text-placeholder']};
    }
  }
`;

const CollapseButtonWrapper = styled(Flex).attrs({
  justifyContent: 'center',
  alignItems: 'center',
})`
  height: 40px;
  width: 40px;
`;

export const LocationSelectorNode: React.FC<{
  data: LocationSelectorNodeData;
  level: number;
  prevNodePath: LocationSelectorNodeData[];
}> = ({ data, level, prevNodePath }) => {
  const { selectedNode, onSelectNode, activeNodePath, unselectableDestinationIds } =
    useLocationSelectorContext();

  const isFolderBeingMoved = unselectableDestinationIds.includes(data.id);

  const { folders, fetchNextPage, hasNextPage } = useQueryFolders(data.id, {
    /**
     * do not fetch descendants if this folder is being moved
     */
    enabled: !isFolderBeingMoved,
  });

  const subfolders: LocationSelectorNodeData[] = folders.map(folderToLocationSelectorNodeData);

  const isDescendantSelected = !!activeNodePath?.slice(0, -1).find((item) => item.id === data.id);
  const isExpandedState = useBoolState(isDescendantSelected);
  const isExpanded = !!isDescendantSelected || isExpandedState.value;

  const thisNodePath = [...prevNodePath, data];

  const isNodeSelected = selectedNode?.id === data.id;

  return (
    <>
      <NodeWrapper
        as={motion.div}
        {...framerAnimationFade}
        style={{ paddingLeft: `${level * 20 + 10}px` }}
      >
        <NameButton
          data-node-selector-selected={isNodeSelected}
          data-testid={`location-select-item-[${data.title}]`}
          role="button"
          onClick={(e) => {
            if (isNodeSelected) {
              onSelectNode(null);
            } else {
              onSelectNode(data);
              e.currentTarget.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
          }}
          disabled={isFolderBeingMoved}
        >
          {['space', 'inbox'].includes(data.displayType) ? (
            <SpacesIcon
              width="18"
              height="20"
              style={{
                width: 18,
                height: 20,
              }}
            />
          ) : (
            <FolderIcon
              style={{
                width: 18,
                height: 15,
              }}
              width="18"
              height="15"
              isOpen={isExpanded}
            />
          )}
          <P weight={600} color="primary" ellipsis>
            {data.title}
          </P>
        </NameButton>
        <CollapseButtonWrapper>
          <ButtonExpandCollapse
            onClick={isExpandedState.handleToggle}
            disabled={isDescendantSelected || subfolders.length === 0 || isFolderBeingMoved}
          >
            <ChevronUpIcon
              style={{
                height: 14,
                width: 14,
                transition: '.2s rotate',
                rotate: isExpanded ? '0deg' : '180deg',
              }}
            />
          </ButtonExpandCollapse>
        </CollapseButtonWrapper>
      </NodeWrapper>

      <AnimateChangeInHeight duration={0.02}>
        {isExpanded && (
          <>
            {subfolders.map((node) => (
              <LocationSelectorNode
                key={node.id}
                data={node}
                level={level + 1}
                prevNodePath={thisNodePath}
              />
            ))}
            {hasNextPage && <IntersectionObserverAnchor onIntersect={fetchNextPage} />}
          </>
        )}
      </AnimateChangeInHeight>
    </>
  );
};
