import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { Comment } from '../comments.types';
import { commentQueryKeys } from './commentQueryKeys';

const emptyComments: Comment[] = [];

export const useQueryComments = (
  resourceId?: string,
  queryOptions?: Partial<QueryObserverOptions<Comment[]>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: commentQueryKeys.resourceComments(resourceId),
    queryFn: async (params) => {
      const resourceId = params.queryKey[1] as string;

      return (
        await client.v2({
          endpoint: '/v2/resources/{resourceId}/comments',
          params: {
            resourceId,
          },
        })
      ).data.comments;
    },
    placeholderData: keepPreviousData,
    refetchInterval: 45000,
    ...queryOptions,
    enabled: isQueryEnabled([queryOptions?.enabled, !!resourceId]),
    retry: 3,
  });

  return {
    ...query,
    comments: query.data ?? emptyComments,
  };
};
