import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import { NotepadFdoc } from '@/src/types/api';
import { QueryObserverOptions, useQuery } from '@tanstack/react-query';
import { resourceDetailsQueryKeys } from './resourceDetailsQueryKeys';

/**
 * Fetch only the diff of the notepad resource state to sync-up.
 *
 * @param fdoc
 * @param currentState
 * @param queryOptions
 * @returns
 */
export const useQueryNotepadResourceSync = (
  fdoc?: NotepadFdoc,
  currentState?: Uint8Array,
  queryOptions?: Partial<QueryObserverOptions<Uint8Array>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: resourceDetailsQueryKeys.notepadSync(fdoc),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;
      const response = await client.v2(
        {
          endpoint: '/v2/notepads/{resourceId}/sync',
          params: {
            resourceId: safeResourceId,
          },
        },
        {
          query: {
            accessToken: undefined,
          },
          method: 'post',
          body: currentState
            ? new Blob([currentState], { type: 'application/content-stream' })
            : new Blob(),
        },
      );

      return new Uint8Array(await response.arrayBuffer());
    },
    staleTime: 10000,
    refetchInterval: 9000,
    ...queryOptions,
    enabled: isQueryEnabled([!!fdoc?.id, !!currentState, queryOptions?.enabled]),
  });

  return {
    ...query,
  };
};
