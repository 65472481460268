import React from 'react';

import { getStyles } from '@/src/components/NoteStack/notestack.config';
import EmptyState from '@/src/icons/EmptyState';
import { ResourcePreview } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import clsx from 'clsx';
import Tilt from 'react-parallax-tilt';
import styled from 'styled-components';
import styles from './NoteStack.module.scss';

const NoteStack: React.FC<{
  fdocs: OptimisticDraft<Fdoc>[];
  className?: string;
  stackSize?: number;
  noTilt?: boolean;
  scale?: number;
  opacityTransition?: boolean;
  horizontal?: boolean;
  noTransition?: boolean;
  noReorder?: boolean;
  isHoveredView?: boolean;
  /**
   * increase or decrease horizontal distance between items
   */
  horizontalSpread?: number;
  /**
   * increase or decrease vertical distance between items
   */
  verticalSpread?: number;
}> = ({
  fdocs,
  className,
  scale = 1,
  noTilt,
  horizontal = false,
  noTransition,
  isHoveredView,
  horizontalSpread = 1,
  verticalSpread = 1,
}) => {
  const items = fdocs.slice(0, Math.min(3, fdocs.length));
  return (
    <Tilt
      perspective={500}
      transitionSpeed={1000}
      scale={1}
      className={styles.tilt}
      trackOnWindow={!noTilt}
      tiltMaxAngleY={10}
      tiltMaxAngleX={10}
      gyroscope={!noTilt}
      tiltEnable={!noTilt}
    >
      <ul
        className={clsx(styles.list, className)}
        style={{
          transform: `scale(${scale})`,
        }}
      >
        {items.length > 0 ? (
          items.map((fdoc, index) => {
            return (
              <NewResourceWrapper
                key={fdoc.id}
                style={getStyles({
                  listSize: items.length,
                  transitionState: isHoveredView ? 'hovered' : 'default',
                  index,
                  noTransition,
                  horizontalSpread,
                  verticalSpread,
                  addDepth: !noTilt,
                })}
              >
                <ResourcePreview
                  resource={fdoc}
                  variant="default"
                  isSelectable={false}
                  appearence={{
                    hideMeta: true,
                  }}
                />
              </NewResourceWrapper>
            );
          })
        ) : (
          <li
            className={clsx(styles.note, horizontal && styles.note_horizontal)}
            style={{
              transition: noTransition ? 'none' : undefined,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <EmptyState className={styles.empty__icon} />
          </li>
        )}
      </ul>
    </Tilt>
  );
};

export default NoteStack;

const NewResourceWrapper = styled.li`
  height: 240px;
  width: 260px;
  position: absolute;
  transition: 0.2s all;
  & > * {
    height: 240px;
    width: 260px;
    overflow: hidden;
  }

  [data-preview-content-wrapper!='stored_file_icon'] {
    /* border: 3px solid ${cssVar['color-bg-primary']}; */
    border-width: 3px;
    border-radius: 1.125rem;
  }
  [data-preview-content-wrapper='storef_file_thumbnail'] {
    border: 3px solid ${cssVar['color-bg-primary']};
  }
`;
