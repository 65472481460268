'use client';

import { Directory, Filesystem } from '@capacitor/filesystem';
import { useCallback } from 'react';
import { toast } from '../store/alerts';
import { Fdoc } from './../types/api';

import { Share } from '@capacitor/share';
import { FileLikeDetails } from '../modules/resource-detail/resource-details.types';
import inNextServer from '../utils/next';
import { isInMobile } from './mobile';

export const downloadMobile = async (url: string, name: string, _contentType?: string) => {
  if (inNextServer()) return;

  let path: string | undefined;

  // we will download the file into a downloads folder then use the FileSharer plugin to share it
  try {
    toast({
      content: 'Downloading...',
    });

    // on Android there is a bug that prevents the downloadFile function from recursively creating the directory
    // as such we create it separately first to avoid the bug
    if (isInMobile('android')) {
      try {
        await Filesystem.mkdir({
          path: 'downloads',
          directory: Directory.Cache,
          recursive: true,
        });
      } catch (e) {
        console.error(e);
      }
    }

    const { path: newPath } = await Filesystem.downloadFile({
      url: url,
      path: `downloads/${name}`,
      recursive: true,
      directory: Directory.Cache,
    });

    path = newPath;

    if (!path) throw new Error('Could not download file');

    let pathUri: string = path;
    if (isInMobile('android')) {
      pathUri = `file://${path}`;
    }

    const { activityType } = await Share.share({
      title: name,
      files: [pathUri],
      dialogTitle: 'Downloaded file',
    });

    console.log('Share was successful? ' + activityType);

    // delete the file after sharing
    await Filesystem.deleteFile({
      path,
      directory: Directory.Cache,
    });
  } catch (e) {
    console.error(e);
  } finally {
    if (!path) return;

    try {
      // delete the file after sharing
      await Filesystem.deleteFile({
        path,
        directory: Directory.Cache,
      });
    } catch (e) {
      console.error(e);
    }
  }
};

const downloadWeb = async (url: string, name: string, contentType?: string) => {
  if (inNextServer()) return;

  const link = document.createElement('a');
  const data = await fetch(url).then((r) => r.blob());
  const blob = new Blob([data], { type: contentType ?? 'application/octet-stream' });

  const objectUrl = window.URL.createObjectURL(blob);
  link.href = objectUrl;
  link.download = name;
  document.body.appendChild(link);
  link.click();

  toast({
    content: 'Downloading...',
  });

  // Clean up the DOM and release the object URL
  setTimeout(() => {
    document.body.removeChild(link);
    window.URL.revokeObjectURL(objectUrl);
  }, 100);
};

const formatTitleForDownload = (title: string) => {
  // spaces to _
  // first letter to uppercase for each word
  return title
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('_');
};

const useDownloadFdoc = () => {
  return useCallback(async (fdoc: Fdoc, fileDetails?: FileLikeDetails) => {
    if (
      (fdoc.type !== 'stored_file' && fdoc.type !== 'image') ||
      !fileDetails?.url ||
      inNextServer()
    )
      return;

    const name =
      fdoc.type === 'stored_file'
        ? `${fdoc.data.title}${fdoc.data.extension}`
        : `${formatTitleForDownload(fdoc.data.pageTitle)}.png`;
    const contentType = fileDetails?.contentType ?? 'application/octet-stream';
    const url = fileDetails.url;

    if (isInMobile()) {
      await downloadMobile(url, name, contentType);
    } else {
      await downloadWeb(url, name, contentType);
    }
  }, []);
};

export default useDownloadFdoc;
