import { MutableRefObject, useEffect } from 'react';

/**
 * Hook that alerts clicks outside of the passed ref
 * Shamefully copied from https://usehooks.com/useOnClickOutside/
 * Just added typescript annotations
 */
// Hook
const useOnClickOutside = (
  ref: MutableRefObject<HTMLElement | undefined | null> | HTMLElement | null,
  handler: (e: TouchEvent | MouseEvent) => void,
  exceptions?: MutableRefObject<HTMLElement | undefined | null>[],
  priority: boolean = false
) => {
  useEffect(
    () => {
      const listener = (event: TouchEvent | MouseEvent) => {
        const targetRef = ref instanceof HTMLElement || ref === null ? { current: ref } : ref;

        // Do nothing if clicking ref's element or descendent elements
        if (!targetRef.current || targetRef.current.contains(event.target as HTMLElement)) {
          return;
        }

        if (exceptions && exceptions.length > 0) {
          for (const exception of exceptions) {
            if (exception.current && exception.current.contains(event.target as HTMLElement)) {
              return;
            }
          }
        }

        handler(event);
      };

      // Capture to avoid the event being cancelled
      window.addEventListener(priority ? 'mousedown' : 'click', listener);
      return () => {
        window.removeEventListener(priority ? 'mousedown' : 'click', listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler, exceptions, priority]
  );
};

export { useOnClickOutside };
