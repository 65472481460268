import { isDefined } from '@/src/lib/utils';
import {
  attributeDateFormatter,
  formatter,
} from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/utils';
import { ResourceDetail } from '@/src/modules/resources/resources.types';
import {
  isResourceDetailAudio,
  isResourceDetailBookmark,
  isResourceDetailDocument,
  isResourceDetailHighlight,
  isResourceDetailImage,
  isResourceDetailNotepad,
  isResourceDetailVideo,
} from '@/src/modules/resources/utils/resourceDetailGuards';
import { isTruthy } from '@/src/utils/guards';
import {
  formatBitrate,
  formatCoordinates,
  formatDuration,
  formatSampleRate,
  parseIntOrString,
} from '@/src/utils/number';
import { boolToYesOrNo } from '@/src/utils/text';

export const detailsToAttributeMaps = (detail: ResourceDetail) => {
  if (isResourceDetailImage(detail)) {
    return [
      {
        label: 'Dimensions',
        value: formatter(detail.data.attributes?.dimensions, (dim) => `${dim.width}×${dim.height}`),
      },
      { label: 'Color Space', value: detail.data.attributes?.color?.space },
      { label: 'Color Profile', value: detail.data.attributes?.color?.profile },
      {
        label: 'Alpha Channel',
        value: formatter(detail.data.attributes?.color?.alphaChannel, boolToYesOrNo),
      },
      {
        label: 'Device',
        value: formatter(detail.data.attributes?.device, (dev) =>
          Object.values(dev).filter(isTruthy).join(', '),
        ),
      },
      {
        label: 'Location',
        value: formatter(detail.data.attributes?.location, (loc) =>
          isDefined(loc.latitude) && isDefined(loc.longitude)
            ? formatCoordinates({
                latitude: loc.latitude,
                longitude: loc.longitude,
              })
            : undefined,
        ),
      },
    ];
  }

  if (isResourceDetailBookmark(detail) || isResourceDetailHighlight(detail))
    return [
      {
        label: 'Page title',
        value: detail.data.webpage?.title,
      },
      {
        label: 'Source URL',
        value: detail.data.webpage?.url,
      },
    ];

  if (isResourceDetailDocument(detail))
    return [
      {
        label: 'Title',
        value: detail.data.attributes.document?.title,
      },
      {
        label: 'Subject',
        value: detail.data.attributes.document?.subject,
      },
      {
        label: 'Authors',
        value: detail.data.attributes.document?.author,
      },
      {
        label: 'Encoding Software',
        value: detail.data.attributes.document?.producer,
      },
      {
        label: 'Version',
        value: detail.data.attributes.document?.version,
      },
      {
        label: 'Pages',
        value: detail.data.attributes.document?.pages,
      },
      {
        label: 'Resolution',
        value: formatter(detail.data.attributes.dimensions, (dim) =>
          isDefined(dim.width) && isDefined(dim.height)
            ? `${dim?.width}×${dim?.height}`
            : undefined,
        ),
      },
      {
        label: 'Security',
        value: formatter(detail.data.attributes?.document?.security, boolToYesOrNo),
      },
      {
        label: 'Content creator',
        value: detail.data.attributes.document?.creator,
      },
      {
        label: 'Keywords',
        value: formatter(detail.data.attributes.document?.keywords, (v) => (!!v ? v : undefined)),
      },
      {
        label: 'Creation Date',
        value: formatter(detail.data.attributes?.document?.creationDate, attributeDateFormatter),
      },
      {
        label: 'Modification Date',
        value: formatter(
          detail.data.attributes?.document?.modificationDate,
          attributeDateFormatter,
        ),
      },
    ];

  if (isResourceDetailNotepad(detail))
    return [
      {
        label: 'Author',
        value: detail.user.name,
      },
    ];

  if (isResourceDetailVideo(detail))
    return [
      {
        label: 'Dimensions',
        value: formatter(detail.data.attributes?.dimensions, (dim) =>
          isDefined(dim?.width) && isDefined(dim?.height)
            ? `${dim?.width}×${dim?.height}`
            : undefined,
        ),
      },
      {
        label: 'Codec',
        value: detail.data.attributes?.video?.codec,
      },
      {
        label: 'Color Space',
        value: detail.data.attributes?.color?.space,
      },
      {
        label: 'Color Profile',
        value: detail.data.attributes?.color?.profile,
      },
      {
        label: 'Duration',
        value: formatter(detail.data.attributes?.video?.duration, formatDuration),
      },
      {
        label: 'Bit Rate',
        value: formatter(
          detail.data.attributes?.video?.bitRate,
          (bitRate) =>
            `${parseIntOrString(bitRate).toLocaleString('en-US', {
              style: 'decimal',
              maximumFractionDigits: 0,
              useGrouping: true,
            })} bps (${formatBitrate(bitRate)})`,
        ),
      },
      {
        label: 'Frame Rate',
        value: formatter(detail.data.attributes?.video?.frameRate, (fps) => `${fps} fps`),
      },
      {
        label: 'Audio channels',
        value: detail.data.attributes?.audio?.channels,
      },
      {
        label: 'Alpha Channel',
        value: formatter(detail.data.attributes?.color?.alphaChannel, boolToYesOrNo),
      },
      {
        label: 'Device',
        value: formatter(detail.data.attributes?.device, (dev) =>
          Object.values(dev).filter(isTruthy).join(', '),
        ),
      },
      {
        label: 'Location',
        value: formatter(detail.data.attributes?.location, (loc) =>
          isDefined(loc.latitude) && isDefined(loc.longitude)
            ? formatCoordinates({
                latitude: loc.latitude,
                longitude: loc.longitude,
              })
            : undefined,
        ),
      },
    ];

  if (isResourceDetailAudio(detail))
    return [
      {
        label: 'Title',
        value: detail.data.attributes?.song?.title,
      },
      {
        label: 'Artist',
        value: detail.data.attributes?.song?.artist,
      },
      {
        label: 'Album',
        value: detail.data.attributes?.song?.album,
      },
      {
        label: 'Genre',
        value: detail.data.attributes?.song?.genre,
      },
      {
        label: 'Duration',
        value: formatter(detail.data.attributes?.audio?.duration, formatDuration),
      },
      {
        label: 'Codec',
        value: detail.data.attributes?.audio?.codec,
      },
      {
        label: 'Audio Channels',
        value: detail.data.attributes?.audio?.channels,
      },
      {
        label: 'Sample Rate',
        value: formatter(
          detail.data.attributes?.audio?.sampleRate,
          (sampleRate) => `${sampleRate} Hz (${formatSampleRate(sampleRate)})`,
        ),
      },
      {
        label: 'Bit Rate',
        value: formatter(
          detail.data.attributes?.audio?.bitRate,
          (bitRate) =>
            `${parseIntOrString(bitRate).toLocaleString('en-US', {
              style: 'decimal',
              maximumFractionDigits: 0,
              useGrouping: true,
            })} bps (${formatBitrate(bitRate)})`,
        ),
      },
    ];

  return [];
};
