import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { FdocOfType } from '@/src/types/api';
import { useMutation } from '@tanstack/react-query';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { TiptapDataValue } from '@/src/components/Tiptap/types';
import { mutateNotepadContent } from '@/src/modules/resource-detail/utils/mutateNotepadContent';
import { useQueryCacheResourceHelpers } from '@/src/modules/resources/utils/useQueryCacheResourceHelpers';

type MutationVariables = {
  notepad: FdocOfType<'notepad'>;
  content: TiptapDataValue;
  state?: Uint8Array;
};

export const useMutationUpdateNotepadContent = () => {
  const { client } = useWoody();
  const { updateCachedResource } = useQueryCacheResourceHelpers();

  const mutation = useMutation({
    mutationFn: async ({ notepad, content, state }: MutationVariables) => {
      const updatedNotepad = (
        await Promise.all([
          client
            .updateNotepad(notepad.id, notepad.data.title, null, content)
            .then(getWoodyResponseData),

          // We wait for the state because we want to error out if it fails
          state ? client.setYDocState(notepad.id, state).then(getWoodyResponseData) : undefined,
        ])
      )[0];

      return {
        ...notepad,
        data: {
          editorjs: content,
          createdAt: updatedNotepad.createdAt,
          modifiedAt: updatedNotepad.modifiedAt,
        },
        createdAt: updatedNotepad.createdAt,
        modifiedAt: updatedNotepad.modifiedAt,
      };
    },
    onMutate: ({ content, notepad }) => {
      return updateCachedResource(mutateNotepadContent(notepad, content));
    },
    onError: (_, _v, context) => {
      toast({
        content: 'Failed to save notepad, please try again',
      });

      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
  });

  return mutation;
};
