import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { pick } from '@/src/lib/store';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import { ExtraPanelTab } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/types';
import useUIStore from '@/src/store/ui';
import { noop } from '@/src/utils/noop';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import { shallow } from 'zustand/shallow';

interface ExpandedFdocContext {
  selectedExtraPanel: ExtraPanelTab;
  setSelectedExtraPanel: (panel: ExtraPanelTab) => void;
  similarText?: string;
  setSimilarText?: (text: string | undefined) => void;
  sidebarOpen?: boolean;
  setSidebarOpen?: (value: boolean) => void;
  sidebarAnimateIn?: boolean;

  /**
   * tags (new)
   */
  editTagsModalOpen: boolean;
  setEditTagsModalOpen: (value: boolean) => void;

  /**
   * share modal
   */
  shareModalOpen: boolean;
  setShareModalOpen: (value: boolean) => void;

  /**
   * pdf related
   */

  pdf: DocumentCallback | null;
  setPdf: (pdf: DocumentCallback) => void;

  /**
   * handlers
   */
  handleOnDelete: () => void;

  /**
   * toolbar height
   */
  toolbarHeight: number;
  setToolbarHeight: (tab: number) => void;
  /**
   *
   */
  isEditorFocused: boolean;
  setIsEditorFocused: (tab: boolean) => void;

  onGetSimilarItemsToText: (text: string) => void;
  onSummarizeText: (text: string) => void;
  isAnon: boolean;
}

const ExpandedFdocContext = createContext<ExpandedFdocContext>({
  selectedExtraPanel: null,
  setSelectedExtraPanel: noop,
  sidebarAnimateIn: true,
  setSimilarText: noop,
  sidebarOpen: false,
  setSidebarOpen: noop,
  editTagsModalOpen: false,
  setEditTagsModalOpen: noop,
  shareModalOpen: false,
  setShareModalOpen: noop,
  handleOnDelete: noop,
  pdf: null,
  setPdf: noop,
  toolbarHeight: 0,
  setToolbarHeight: noop,
  isEditorFocused: false,
  setIsEditorFocused: noop,
  onGetSimilarItemsToText: noop,
  onSummarizeText: noop,
  isAnon: false,
});

export const ExpandedFdocProvider: React.FC<{
  children: React.ReactNode;
  overrideSidebarOpen?: boolean;
  setOverrideSidebarOpen?: (value: boolean) => void;
  handleOnDelete?: () => void;
}> = ({ children, overrideSidebarOpen, setOverrideSidebarOpen, handleOnDelete }) => {
  const isLoggedIn = useAuthIsLoggedIn();

  const { setSummarizeText } = useAssistantStore((s) => pick(s, ['setSummarizeText']), shallow);
  const {
    expandedFdocSidebarOpen,
    setExpandedFdocSidebarOpen,
    expandedFdocSidebarTab,
    setExpandedFdocSidebarTab,
    expandedFdocSidebarInitTab,
  } = useUIStore(
    (s) =>
      pick(s, [
        'expandedFdocSidebarOpen',
        'setExpandedFdocSidebarOpen',
        'expandedFdocSidebarTab',
        'setExpandedFdocSidebarTab',
        'expandedFdocSidebarInitTab',
      ]),
    shallow,
  );
  const [similarText, setSimilarText] = useState<string | undefined>(undefined);
  const [editTagsModalOpen, setEditTagsModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isEditorFocused, setIsEditorFocused] = useState(false);
  const [pdf, setPdf] = useState<DocumentCallback | null>(null);
  const [toolbarHeight, setToolbarHeight] = useState<number>(0);

  const onGetSimilarItemsToText = useCallback(
    (text: string) => {
      setExpandedFdocSidebarOpen(true);
      setExpandedFdocSidebarTab('similar');
      setSimilarText(text);
    },
    [setExpandedFdocSidebarOpen, setExpandedFdocSidebarTab],
  );

  const onSummarizeText = useCallback(
    (text: string) => {
      setExpandedFdocSidebarOpen(true);
      setSummarizeText(text);
      setExpandedFdocSidebarTab('assistant');
    },
    [setExpandedFdocSidebarOpen, setExpandedFdocSidebarTab, setSummarizeText],
  );

  const value = useMemo(
    () => ({
      selectedExtraPanel: expandedFdocSidebarTab,
      setSelectedExtraPanel: setExpandedFdocSidebarTab,
      sidebarAnimateIn: !!expandedFdocSidebarInitTab,
      similarText,
      setSimilarText,
      sidebarOpen: overrideSidebarOpen ?? expandedFdocSidebarOpen,
      setSidebarOpen: setOverrideSidebarOpen ?? setExpandedFdocSidebarOpen,
      editTagsModalOpen,
      setEditTagsModalOpen,
      shareModalOpen,
      setShareModalOpen,
      handleOnDelete: handleOnDelete ?? noop,
      pdf,
      setPdf,
      toolbarHeight,
      setToolbarHeight,
      isEditorFocused,
      setIsEditorFocused,
      onGetSimilarItemsToText,
      onSummarizeText,
      isAnon: !isLoggedIn,
    }),
    [
      expandedFdocSidebarTab,
      setExpandedFdocSidebarTab,
      expandedFdocSidebarInitTab,
      similarText,
      overrideSidebarOpen,
      expandedFdocSidebarOpen,
      setOverrideSidebarOpen,
      setExpandedFdocSidebarOpen,
      editTagsModalOpen,
      shareModalOpen,
      handleOnDelete,
      pdf,
      toolbarHeight,
      isEditorFocused,
      onGetSimilarItemsToText,
      onSummarizeText,
      isLoggedIn,
    ],
  );

  return <ExpandedFdocContext.Provider value={value}>{children}</ExpandedFdocContext.Provider>;
};

export const useExpandedFdocContext = () => {
  return useContext(ExpandedFdocContext);
};
