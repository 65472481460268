import { useAuthIsLoggedIn, useAuthUser } from '@/src/hooks/auth';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { RecapForDateSortField } from '@/src/modules/recap/recap.types';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { UserRecap } from '@fabric/woody-client';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { recapQueryKeys } from './recapQueryKeys';

export const useQueryRecapForDate = (
  date?: string,
  frequency: 'daily' = 'daily',
  field: RecapForDateSortField = 'created',
  queryOptions?: Partial<QueryObserverOptions<UserRecap>>,
) => {
  const user = useAuthUser();
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();

  const query = useQuery({
    queryKey: recapQueryKeys.recapForDate(user?.id, date, frequency, field),
    queryFn: async (params) => {
      const safeStartDate = params.queryKey[2] as string;
      const safeFrequency = params.queryKey[3] as string;

      return getWoodyResponseData(
        await client.getUserRecapForDate(
          dayjs(safeStartDate).format('YYYY-MM-DD'),
          safeFrequency,
          field,
        ),
      );
    },
    placeholderData: keepPreviousData,
    ...queryOptions,
    // long stale time if the date is from before today
    staleTime: dayjs(date).isBefore(dayjs(), 'date') ? 1000 * 60 * 60 * 3 : 1000 * 30, // 3 hours if date is before today, 30 seconds otherwise
    enabled: isQueryEnabled([isLoggedIn, queryOptions?.enabled, !!user?.id, !!date, !!frequency]),
    retry: 3,
  });

  return query;
};
