import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import React, { useState } from 'react';
import Truncator from '../../Truncator/Truncator';
import styles from './FolderResource.module.scss';

import FolderIcon from '@/public/images/icons/Folder.png';
import FolderDeviceIcon from '@/public/images/icons/FolderDevice.png';
import { isDeviceConnectionType } from '@/src/modules/connections/connections.utils';

const FolderResource: React.FC<{
  fdoc: OptimisticDraft<Fdoc & { type: 'folder' }>;
}> = ({ fdoc }) => {
  const [truncatedRef, setTruncatedRef] = useState<HTMLElement | null>(null);

  return (
    <div className={styles.folder}>
      {isDeviceConnectionType(fdoc.listData?.integration) ? (
        <img src={FolderDeviceIcon.src} alt="Device Folder" draggable={false} />
      ) : (
        <img src={FolderIcon.src} alt="Folder" draggable={false} />
      )}
      <div className={styles.folder__name} ref={setTruncatedRef}>
        <Truncator text={fdoc.data.name} maxRows={2} keepLastChars={8} parentRef={truncatedRef} />
      </div>
    </div>
  );
};

export default FolderResource;
