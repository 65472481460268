import { useResponsive } from '@/src/hooks/responsive';
import { ButtonIconDotMenu } from '@/src/modules/ui/components/button/ButtonIconDotMenu';
import { Content } from '@/src/modules/ui/components/DropdownMenu/Content';
import { ContentMobile } from '@/src/modules/ui/components/DropdownMenu/ContentMobile';
import { Item } from '@/src/modules/ui/components/DropdownMenu/Item';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { Separator } from '@radix-ui/react-context-menu';
import {
  DropdownMenuContentProps,
  DropdownMenuTriggerProps,
  Group,
  Label,
  Portal,
  Root,
  Trigger,
} from '@radix-ui/react-dropdown-menu';
import React from 'react';
import styled from 'styled-components';

const DropdownSeparator = styled(Separator)`
  height: 1px;
  background: ${cssVar['color-border-tertiary']};
  margin: 0.25rem 0.75rem;
`;

const StyledTrigger = styled(Trigger)`
  outline: none !important;
`;

interface DropdownMenuTriggerPropsExtended extends DropdownMenuTriggerProps {
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * necessary override https://github.com/radix-ui/primitives/issues/1912
 */
const OverrideTrigger = React.forwardRef<HTMLButtonElement, DropdownMenuTriggerPropsExtended>(
  function DropdownTriggerOverride({ onPointerDown, onPointerUp, onOpenChange, ...props }, ref) {
    return (
      <StyledTrigger
        onPointerDown={(e) => {
          if (e.pointerType === 'touch') {
            e.preventDefault();
          }
          onPointerDown?.(e);
        }}
        onPointerUp={(e) => {
          if (e.pointerType === 'touch' && onOpenChange && !e.defaultPrevented) {
            onOpenChange?.((state) => !state);
          }
          onPointerUp?.(e);
        }}
        {...props}
        ref={ref}
      />
    );
  },
);

interface DropdownMenuProps {
  children: React.ReactNode;
  mobileBaseHeight?: string;
  contentProps?: DropdownMenuContentProps;
  renderTriggerElement: (props: {
    onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
    open: boolean;
  }) => React.ReactNode;
  openProps?: { open: boolean; onOpenChange: React.Dispatch<React.SetStateAction<boolean>> };
  onEscapeKeyDown?: (event: KeyboardEvent) => void;
}

const DropdownMenuBase: React.FC<DropdownMenuProps> = ({
  children,
  renderTriggerElement,
  contentProps,
  openProps,
  onEscapeKeyDown,
}) => {
  const { isMobileView } = useResponsive();
  const [open, onOpenChange] = React.useState(false);

  const openControls = {
    open: openProps?.open ?? open,
    onOpenChange: openProps?.onOpenChange ?? onOpenChange,
  };

  return (
    <Root {...openControls}>
      {renderTriggerElement(openControls)}
      <Portal>
        {isMobileView ? (
          <ContentMobile onEscapeKeyDown={onEscapeKeyDown} onOpenChange={openControls.onOpenChange}>
            {children}
          </ContentMobile>
        ) : (
          <Content onEscapeKeyDown={onEscapeKeyDown} align="end" sideOffset={4} {...contentProps}>
            {children}
          </Content>
        )}
      </Portal>
    </Root>
  );
};

const DropdownGroup = styled(Group)`
  ${mediaMobile} {
    padding: 0 1rem;
    & + & {
      margin-top: 0.625rem;
    }
  }
`;

const ButtonIconDotMenuSlim = styled(ButtonIconDotMenu)`
  width: 1.5rem;
  padding: 0;
`;

const TriggerButtonThreeDots = styled(ButtonIconDotMenu).attrs((props) => {
  return {
    ...props,
    as: OverrideTrigger,
  };
})``;

const TriggerButtonThreeDotsSlim = styled(ButtonIconDotMenuSlim).attrs((props) => {
  return {
    ...props,
    as: OverrideTrigger,
  };
})``;

const DropdownLabel = styled(Label)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  color: ${cssVar['color-text-tertiary']};
  font-size: calc(var(--base-font-size) - 1px);
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.25rem 0.75rem;

  ${mediaMobile} {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: -0.45px;
    color: ${cssVar['color-text-primary']};
    padding: 1rem;
    text-transform: none;
  }
`;

export const DropdownMenu = Object.assign(DropdownMenuBase, {
  TriggerButtonThreeDots,
  TriggerButtonThreeDotsSlim,
  Trigger: OverrideTrigger,
  Item,
  Separator: DropdownSeparator,
  Group: DropdownGroup,
  Label: DropdownLabel,
});
