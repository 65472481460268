import BuzzNotePageIcon from '@/public/images/icons/BuzzNotePage.svg';
import BuzzNoteTextIcon from '@/public/images/icons/BuzzNoteText.svg';
import clsx from 'clsx';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';

import FolderIcon from '@/public/images/icons/Folder.png';
import FolderDeviceIcon from '@/public/images/icons/FolderDevice.png';

import { useResponsive } from '@/src/hooks/responsive';
import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import LinkExternalIcon from '@/src/icons/LinkExternalIcon';
import { isDeviceConnectionType } from '@/src/modules/connections/connections.utils';
import { getResourceTitleWithDefault } from '@/src/modules/resources/utils/getResourceTitle';
import { FaviconImage } from '@/src/modules/ui/components/Favicon/FaviconImage';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { Size } from '@/src/types/global';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import useUIStore from '../../../store/ui';
import stringToRegExp from '../../../utils/stringToRegExp';
import { cleanUrl } from '../../../utils/text';
import HoverItem from '../../HoverItem/HoverItem';
import { OfViewMode } from '../../ViewModeSwitcher/ViewModeSwitcher';
import { useFdocItemContext } from '../FdocItem';
import FdocItemContentImage from '../FdocItemContent/FdocItemContentImage';
import FdocItemContentStoredFile from '../FdocItemContent/FdocItemContentStoredFile';
import styles from './FdocItemHeader.module.scss';

const FolderImage = styled.img`
  width: 31px;
  height: auto;
`;

/**
 * FdocItemHeader is a component that is used to render the header of a fdoc in different view modes (Grid or List).
 * The header consists of the fdoc title and a link to the original page (if available), and highlights any matching query text.
 * When hovering over the header, a HoverItem component is displayed with the full title and url of the fdoc.
 *
 * @param fdoc the fdoc object that contains the data to render the header
 * @param viewMode the view mode (Grid or List) that determines how the header is rendered
 * @param query the search query that is used to highlight matching text in the header
 * @param openFdocInExternalPage a callback that is called when the header is clicked, to open the fdoc in an external page
 */
const FdocItemHeader: React.FC<{
  fdoc: OptimisticDraft<Fdoc>;
  viewMode: OfViewMode;
  openFdocInExternalPage: (fdoc: Fdoc) => void;
  disableHover?: boolean;
  linkToWebpage?: boolean; // if it's a webnote allow linking to the webpage
  wrapperRef: HTMLDivElement | null;
  size?: Size;
  isHoverHeaderVisible?: boolean;
  setIsHoverHeaderVisible?: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  fdoc,
  viewMode,
  linkToWebpage,
  openFdocInExternalPage,
  wrapperRef,
  disableHover = false,
  isHoverHeaderVisible,
  setIsHoverHeaderVisible,
}) => {
  const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
  const [isHoverVisible, setIsHoverVisible] = useState(false);
  const [isHoverVisibleTimeout, setIsHoverVisibleTimeout] = useState<number>(0);
  const [hoverFadeVisible, setHoverFadeVisible] = useState(false);

  const [hoverHeaderRef, setHoverHeaderRef] = useState<HTMLDivElement | null>(null);
  const [hoverHeaderPosition, setHoverHeaderPosition] = useState({ x: 0, y: 0 });
  const [hoverHeaderCanShow, setHoverHeaderCanShow] = useState(false);
  const { isMobileCard } = useFdocItemContext();

  const { isMobile } = useResponsive();

  const updatePosition = useCallback(() => {
    if (!wrapperRef) return;

    const { x, y, right } = wrapperRef.getBoundingClientRect();
    setHoverHeaderPosition({
      // top right
      x: right,
      y,
    });

    const elements = document.elementsFromPoint(x + 5, y + 5);

    // can show if wrapperRef is a part of the elements
    setHoverHeaderCanShow(!!elements.find((el) => el === wrapperRef));
  }, [wrapperRef]);

  useClientLayoutEffect(() => {
    if (!isHoverHeaderVisible) return;
    updatePosition();
  }, [isHoverHeaderVisible, updatePosition]);

  useEffect(() => {
    if (!wrapperRef || !hoverHeaderRef) return;

    // listen to scroll events and mutations and size observers on the wrapperref
    const scrollObserver = new IntersectionObserver(updatePosition, {
      root: null,
      rootMargin: '0px',
      threshold: 1.0,
    });

    const mutationObserver = new MutationObserver(updatePosition);
    const resizeObserver = new ResizeObserver(updatePosition);

    scrollObserver.observe(wrapperRef);
    mutationObserver.observe(wrapperRef, { attributes: true, childList: true, subtree: true });
    resizeObserver.observe(wrapperRef);

    window.addEventListener('scroll', updatePosition, true);
    window.addEventListener('fabric-custom-zoom', updatePosition);

    return () => {
      scrollObserver.disconnect();
      mutationObserver.disconnect();
      resizeObserver.disconnect();
      window.removeEventListener('scroll', updatePosition, true);
      window.removeEventListener('fabric-custom-zoom', updatePosition);
    };
  }, [wrapperRef, hoverHeaderRef, updatePosition]);

  useEffect(() => {
    if (!wrapperRef || !hoverHeaderRef) return;

    // set hoverheader visible on hover of wrapperref
    const updateHoverHeaderVisibleOn = () => {
      setIsHoverHeaderVisible && setIsHoverHeaderVisible(true);
    };

    const updateHoverHeaderVisibleOff = () => {
      setIsHoverHeaderVisible && setIsHoverHeaderVisible(false);
    };

    wrapperRef.addEventListener('mouseenter', updateHoverHeaderVisibleOn);
    wrapperRef.addEventListener('mouseleave', updateHoverHeaderVisibleOff);
    wrapperRef.addEventListener('click', updateHoverHeaderVisibleOff);
    hoverHeaderRef.addEventListener('mouseenter', updateHoverHeaderVisibleOn);
    hoverHeaderRef.addEventListener('mouseleave', updateHoverHeaderVisibleOff);

    return () => {
      wrapperRef.removeEventListener('mouseenter', updateHoverHeaderVisibleOn);
      wrapperRef.removeEventListener('mouseleave', updateHoverHeaderVisibleOff);
      wrapperRef.removeEventListener('click', updateHoverHeaderVisibleOff);
      hoverHeaderRef.removeEventListener('mouseenter', updateHoverHeaderVisibleOn);
      hoverHeaderRef.removeEventListener('mouseleave', updateHoverHeaderVisibleOff);
    };
  }, [wrapperRef, hoverHeaderRef, setIsHoverHeaderVisible]);

  const query = useUIStore((s) => s.searchQuery);

  // clean the url from protocol and other unnecessary parts
  const cleanedUrl = fdoc.isWebnote ? cleanUrl(fdoc.data.pageUrl) : '';

  /**
   * hoverHandlers is an object that contains event handlers that allows
   * allows the HoverItem component to be shown and hidden, and its position to be updated.
   */
  const hoverHandlers = {
    onClick: (e: React.MouseEvent) => {
      if (!linkToWebpage) return;

      e.stopPropagation();
      openFdocInExternalPage(fdoc);

      clearTimeout(isHoverVisibleTimeout);
      setIsHoverVisible(false);
    },
    onMouseEnter: () => {
      if (disableHover) return;
      clearTimeout(isHoverVisibleTimeout);

      setIsHoverVisibleTimeout(
        window.setTimeout(() => {
          setIsHoverVisible(true);
        }, 100),
      );
    },
    onMouseLeave: () => {
      clearTimeout(isHoverVisibleTimeout);
      setIsHoverVisible(false);
    },
    onMouseMove: (e: React.MouseEvent) => setHoverPosition({ x: e.clientX, y: e.clientY }),
  };

  const hoverFadeHandlers = {
    onMouseEnter: () => {
      if (disableHover) return;
      setHoverFadeVisible(true);
      hoverHandlers.onMouseEnter();
    },
    onMouseLeave: () => {
      setHoverFadeVisible(false);
      hoverHandlers.onMouseLeave();
    },
    onMouseMove: hoverHandlers.onMouseMove,
    onClick: hoverHandlers.onClick,
  };

  const portalTarget = document.getElementById('tooltip-portal');

  const placeholderTitle = useMemo(() => {
    switch (fdoc.type) {
      case 'notepad':
        return 'Text note (untitled)';
      case 'stored_file':
        return 'File note (untitled)';
      case 'image':
        return 'Image note (untitled)';
      case 'page':
        return 'Bookmark note (untitled)';
      case 'text':
        return 'Highlight note (untitled)';
      default:
        return '(untitled)';
    }
  }, [fdoc.type]);

  const fdocTitle = getResourceTitleWithDefault(fdoc, true);

  if (
    !fdoc.isWebnote &&
    viewMode !== 'List' &&
    fdoc.type !== 'notepad' &&
    fdoc.type !== 'stored_file'
  )
    return null;
  if (fdoc.type === 'stored_file' && !fdoc.originUrl && viewMode !== 'List') return null;

  if (fdoc.type === 'stored_file' && viewMode !== 'List') {
    return (
      <>
        {linkToWebpage && fdoc.type === 'stored_file' && fdoc.originUrl && (
          <HoverItem
            fdocId={fdoc.id}
            title={fdoc.data.title}
            url={fdoc.originUrl}
            show={isHoverVisible}
            setShow={setIsHoverVisible}
            {...hoverPosition}
          />
        )}
        {(viewMode === 'Grid' || viewMode === 'Sort') &&
          portalTarget &&
          !isMobile() &&
          !disableHover &&
          createPortal(
            <div
              data-testid="item-header-section"
              className={styles.item__hover__header}
              ref={setHoverHeaderRef}
              style={{
                top: hoverHeaderPosition.y,
                left: hoverHeaderPosition.x,
                visibility: isHoverHeaderVisible && hoverHeaderCanShow ? 'visible' : 'hidden',
              }}
              {...hoverFadeHandlers}
            >
              <LinkExternalIcon
                style={{
                  width: 13.7,
                  height: 13.7,
                }}
              />
            </div>,
            portalTarget,
          )}
        {/* Temporary removed
         {(viewMode === 'Grid' || viewMode === 'Sort') && isMobile() && (
          <div
            data-testid="item-header-section"
            className={styles.item__hover__header}
            ref={setHoverHeaderRef}
            style={{
              visibility: 'visible',
              position: 'absolute',
              top: -9,
              right: -9,
              transform: 'none',
            }}
            onClick={hoverFadeHandlers.onClick}
          >
            <LinkExternalIcon
              style={{
                width: 13.7,
                height: 13.7,
              }}
            />
          </div>
        )} */}
      </>
    );
  }

  if (fdoc.type === 'notepad' && viewMode !== 'List' && fdoc.data.title) {
    return (
      <div
        className={clsx(
          styles.item__header__notepad,
          isMobileCard && styles.item__header__notepad__mobile,
        )}
      >
        {fdoc.data.title}
      </div>
    );
  } else if (fdoc.type === 'notepad' && viewMode !== 'List' && !fdoc.data.title) {
    return null;
  }

  return !fdoc.isWebnote ? (
    <>
      <div data-testid="item-header-section" className={styles.item__header}>
        {viewMode === 'List' && (
          <div className={styles.item__header__slot}>
            {fdoc.type === 'folder' &&
              (isDeviceConnectionType(fdoc.listData?.integration) ? (
                <FolderImage src={FolderDeviceIcon.src} alt="Device Folder" />
              ) : (
                <FolderImage src={FolderIcon.src} alt="Folder" />
              ))}

            {fdoc.type === 'notepad' && !fdoc.isDraft && <BuzzNoteTextIcon />}
            {fdoc.type === 'stored_file' && !fdoc.isDraft && (
              <FdocItemContentStoredFile
                fdoc={fdoc}
                viewMode={viewMode}
                constrainedImages={39}
                size={{
                  width: 32,
                  height: 39,
                }}
              />
            )}
          </div>
        )}
        {(viewMode === 'List' || fdocTitle) && (
          <div
            className={clsx(
              styles.item__hover__header__title,
              styles.item__hover__header__webnote_title,
            )}
            style={{ gridTemplateRows: '1fr', gap: '0px 4px' }}
          >
            <h2 style={viewMode === 'List' ? { fontSize: '13px', minWidth: '200px' } : {}}>
              <Highlighter
                highlightTag="span"
                highlightClassName="highlight"
                className={clsx(!fdocTitle && styles.placeholder)}
                textToHighlight={fdocTitle ? fdocTitle : placeholderTitle}
                searchWords={[stringToRegExp(query)]}
              />
            </h2>
          </div>
        )}
      </div>
    </>
  ) : (
    <>
      {linkToWebpage && !disableHover && (
        <HoverItem
          fdocId={fdoc.id}
          title={fdoc.data.pageTitle}
          url={fdoc.data.pageUrl}
          show={isHoverVisible}
          setShow={setIsHoverVisible}
          {...hoverPosition}
        />
      )}

      {viewMode !== 'List' && (
        <div
          className={clsx(
            styles.item__hover__header__fade,
            hoverFadeVisible && styles.item__hover__header__fade__visible,
          )}
        />
      )}

      {(viewMode === 'Grid' || viewMode === 'Sort') &&
        portalTarget &&
        !isMobile() &&
        !disableHover &&
        createPortal(
          <div
            data-testid="item-header-section"
            data-non-draggable
            className={styles.item__hover__header}
            ref={setHoverHeaderRef}
            style={{
              top: hoverHeaderPosition.y,
              left: hoverHeaderPosition.x,
              visibility: isHoverHeaderVisible && hoverHeaderCanShow ? 'visible' : 'hidden',
              opacity: isHoverHeaderVisible && hoverHeaderCanShow ? 1 : 0, // Initial opacity
              transition: `opacity 150ms ease-in-out`, // Transition property
            }}
            {...hoverFadeHandlers}
          >
            <LinkExternalIcon
              style={{
                width: 13.7,
                height: 13.7,
              }}
            />
          </div>,
          portalTarget,
        )}

      {/* Removed for now
      {(viewMode === 'Grid' || viewMode === 'Sort') && isMobile() && (
        <div
          data-testid="item-header-section"
          className={styles.item__hover__header}
          ref={setHoverHeaderRef}
          style={{
            visibility: 'visible',
            position: 'absolute',
            top: -9,
            right: -9,
            transform: 'none',
          }}
          onClick={hoverFadeHandlers.onClick}
        >
          <LinkExternalIcon
            style={{
              width: 13.7,
              height: 13.7,
            }}
          />
        </div>
      )} */}

      {(viewMode === 'List' || fdoc.type === 'page' || (fdoc.type === 'text' && isMobileCard)) && (
        <div
          data-testid="item-header-section"
          className={clsx(styles.item__header, isMobileCard && styles.item__header__mobile_card)}
        >
          {viewMode === 'List' && (
            <div className={styles.item__header__slot}>
              {fdoc.type === 'image' && !fdoc.isDraft && (
                <FdocItemContentImage
                  fdoc={fdoc}
                  constrainedImages={39}
                  viewMode={viewMode}
                  isMobileCard={isMobileCard}
                  size={{
                    width: 32,
                    height: 39,
                  }}
                />
              )}

              {fdoc.type === 'page' && !fdoc.isDraft && <BuzzNotePageIcon />}
              {fdoc.type === 'text' && !fdoc.isDraft && <BuzzNoteTextIcon />}
            </div>
          )}

          <div
            className={clsx(
              styles.item__hover__header__webnote_title,
              styles.item__hover__header__title,
              isMobileCard && styles.item__hover__header__title__mobile,
            )}
            data-or-obscured
          >
            <FaviconImage
              pageUrl={fdoc.data.pageUrl}
              className={clsx(
                styles.item__hover__header__favicon,
                styles.item__hover__header__favicon__mobile,
              )}
            />
            <h2
              className={styles.item__hover__header__title_h2}
              style={viewMode === 'List' ? { fontSize: '13px' } : {}}
            >
              {fdoc.type !== 'text' ? (
                <Highlighter
                  highlightTag="span"
                  highlightClassName="highlight"
                  textToHighlight={fdoc.data.pageTitle}
                  searchWords={[stringToRegExp(query)]}
                />
              ) : (
                <span>{cleanedUrl}</span>
              )}
            </h2>

            {viewMode === 'List' && (
              <div
                className={styles.item__hover__header__url}
                style={
                  viewMode === 'List'
                    ? {
                        fontSize: '12px',
                        color: 'rgba(var(--fabric-color-text-tertiary-rgb), 0.7)',
                      }
                    : {}
                }
              >
                <span>{cleanedUrl}</span>
              </div>
            )}

            {viewMode !== 'List' && <LinkExternalIcon />}
          </div>
        </div>
      )}

      {fdoc.type === 'page' && viewMode !== 'List' && (
        <div
          data-testid="item-header-section"
          className={clsx(styles.item__header_url, isMobileCard && styles.item__header_url__mobile)}
        >
          <div className={styles.item__header_url__text}>
            <span>{cleanedUrl}</span>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(FdocItemHeader);
