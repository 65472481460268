import { Json, JsonObject } from '@liveblocks/client';
import { SharedStateStates } from './../store/sharedState';
import { Position } from './global';

interface PressuredPosition extends Position, JsonObject {
  pressure: number | null;
}

// Cursor CSS types
const cursorType = ['default', 'pointer', 'text'] as const;
type CursorType = (typeof cursorType)[number];

export enum CursorMode {
  Normal,
  Hidden,
  Chat,
  Ephemeral,
}

type CursorState =
  | {
      mode: CursorMode.Hidden;
    }
  | {
      mode: CursorMode.Normal;
      type: CursorType;
    }
  | {
      mode: CursorMode.Chat;
      message: string;
      previousMessage: string | null;
    }
  | {
      mode: CursorMode.Ephemeral;
      drawing: boolean;
      hasPressure: boolean;
    };

export type BaseCursorData = {
  state: CursorState;
  position: PressuredPosition;
  lastWiggle: number;
};

export type DashboardPresence = {
  [key: string]: Json;
  following: string | null;
  cursor: BaseCursorData;
  expandedFdocId: string | null;
  normalizedScrollTop: number;
  normalizedScrollLeft: number;
} & SharedStateStates;

export type DashboardUserMeta = {
  id: string;
  info: {
    name: string;
    picture: string;
  };
};

export type DashboardEvent =
  | ({
      [key: string]: Json;
      type: 'spotlight';
      expandedFdocId: string | null;
    } & SharedStateStates)
  | {
      type: 'clearSpotlight';
    }
  | {
      type: 'followPath';
      expandedFdocId: string | null;
    }
  | {
      type: 'userClick';
      expandedFdocId: string | null;
      position: Position;
    };
