import { Fdoc } from '@/src/types/api';
import dayjs from 'dayjs';
import { ResourceFilterOptions } from '../resources.types';

/**
 * This is a temporary client-side sort function for resources.
 * It helps with optimistic data updates, but should possibly be rethinked later, since it possibly might not scale well.
 */
export const resourceFilterClientSort = (
  resources: Fdoc[],
  sort: ResourceFilterOptions['sort'],
) => {
  if (!sort) return resources;

  return resources.sort((a, b) => {
    switch (sort.field) {
      case 'createdAt':
        return sort.order === 'asc'
          ? dayjs(a.createdAt).diff(b.createdAt)
          : dayjs(b.createdAt).diff(a.createdAt);
      case 'modifiedAt':
        return sort.order === 'asc'
          ? dayjs(a.modifiedAt).diff(b.modifiedAt)
          : dayjs(b.modifiedAt).diff(a.modifiedAt);
      case 'type':
        return sort.order === 'asc' ? a.type.localeCompare(b.type) : b.type.localeCompare(a.type);
      case 'title':
        return 0;
    }
  });
};
