import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useWoody } from '@/src/services/woody/woody';
import { useMutation } from '@tanstack/react-query';
import { useQueryCacheCommentHelpers } from '../utils/useQueryCacheCommentHelpers';

type MutationVariables = {
  resourceId: string;
  commentId: string;
};

export const useMutationDeleteComment = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { deleteCachedComment } = useQueryCacheCommentHelpers();

  const mutation = useMutation({
    mutationFn: async ({ resourceId, commentId }: MutationVariables): Promise<null> => {
      return await client.v2(
        {
          endpoint: '/v2/resources/{resourceId}/comments/{commentId}',
          params: {
            commentId,
            resourceId,
          },
        },
        {
          query: {
            accessToken: undefined,
          },
          method: 'delete',
        },
      );
    },
    onSuccess: (_, { commentId }) => {
      track(AnalyticsEvents.DeletedComment, { commentId });
    },
    onMutate: ({ commentId, resourceId }) => {
      return deleteCachedComment(commentId, resourceId);
    },
    onError: (_error, _variables, context) => {
      console.error('Failed to delete comment', _error);
      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
  });

  return mutation;
};
