import { useResponsive } from '@/src/hooks/responsive';
import SearchIcon from '@/src/icons/SearchIcon';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import React from 'react';
import styled, { css } from 'styled-components';
import { mediaMobile } from '../../styled-utils';
import { cssVar } from '../../theme/variables';
import { Flex } from '../Flex';

type InputHeight = 'lg' | 'xl' | 'sm';

type ContainerProps = {
  inputHeight?: InputHeight;
  variant?: keyof typeof variants;
  isEmpty?: boolean;
};

const variants = {
  /**
   * A more discreet variant of the text input that blends more with the UI.
   * Currently used for the optional title of notepads in mobile.
   */
  discreet: css`
    background: transparent;
    border-radius: 0.375rem;
    border: none;
    font-weight: 400;

    &[data-empty='true'] {
      background: ${cssVar['color-bg-secondary-button']};
    }

    &:focus-within {
      border: none;
      box-shadow: none;
    }
  `,
  /**
   * The default variant is more obvious and contrasts well with the UI.
   */
  default: '',
} as const;

const cssPropsBasedOnHeight = css<ContainerProps>`
  ${(p) => {
    switch (p.inputHeight) {
      case 'xl':
        return css`
          padding-left: 1rem;
          font-size: 0.9375rem; // 15px
          font-weight: 500;
          height: ${cssVar['height-input-xl']};
          ${mediaMobile} {
            height: 48px;
          }
        `;
      case 'lg':
        return css`
          height: ${cssVar['height-input-lg']};
          ${mediaMobile} {
            height: 48px;
          }
        `;
      case 'sm':
        return css`
          height: ${cssVar['height-input-sm']};
        `;
      default:
        return css`
          height: ${cssVar['height-input']};

          ${mediaMobile} {
            height: 48px;
          }
        `;
    }
  }}
`;

const InputContainer = styled(Flex)
  .attrs<ContainerProps>(({ isEmpty, ...props }) => {
    return {
      gap: 'element',
      alignItems: 'center',
      'data-empty': isEmpty,
      ...props,
    };
  })
  .withConfig(preventForwardPropsConfig(['isEmpty', 'inputHeight', 'variant']))`
  padding: 0px 0.75rem;
  min-width: 200px;
  color: ${cssVar['color-text-secondary']};
  background: ${cssVar['color-bg-primary']};

  ${cssPropsBasedOnHeight};
  border-radius: 13px;
  border: 1px solid ${cssVar['color-border-primary']};

  &:focus-within {
    border: 1px solid ${cssVar['color-app-primary']};
    box-shadow: 0px 1px 34px 0px rgba(42, 55, 238, 0.14);
  }

  ${mediaMobile} {
    width: 100%;
  }

  ${(p) => p.variant && variants[p.variant]}
`;

const Input = styled.input.attrs((props) => {
  const { isMobileView } = useResponsive();
  return {
    type: 'text',
    ...props,
    autoFocus: !isMobileView && props.autoFocus,
  };
})`
  width: 100%;
  background: transparent;
  font-size: 15px;
  color: ${cssVar['color-text-primary']};

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: ${cssVar['color-text-placeholder']};
  }
`;

type TextInputProps = React.ComponentPropsWithoutRef<typeof Input> & {
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  inputHeight?: InputHeight;
  variant?: keyof typeof variants;

  containerProps?: React.ComponentPropsWithoutRef<typeof InputContainer>;
};

/**
 * autoFocus automatically disabled on mobile
 */
const TextInputBase = React.forwardRef<HTMLInputElement, TextInputProps>(function TextInputBase(
  { startAdornment, endAdornment, inputHeight, variant, containerProps, ...props },
  ref,
) {
  return (
    <InputContainer
      inputHeight={inputHeight}
      variant={variant}
      isEmpty={!props.value}
      {...containerProps}
    >
      {startAdornment}
      <Input ref={ref} {...props} />
      {endAdornment}
    </InputContainer>
  );
});

export const TextInput = Object.assign(TextInputBase, {
  SearchIcon: () => <SearchIcon size={22} style={{ width: 22, height: 22 }} />,
});
