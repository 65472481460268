import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { Comment } from '@/src/modules/comments/comments.types';
import { useWoody } from '@/src/services/woody/woody';
import { useMutation } from '@tanstack/react-query';
import { useQueryCacheCommentHelpers } from '../utils/useQueryCacheCommentHelpers';

type MutationVariables = {
  comment: Comment;
  newContent: string;
};

export const useMutationUpdateComment = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { updateCachedComment } = useQueryCacheCommentHelpers();

  const mutation = useMutation({
    mutationFn: async ({ comment, newContent }: MutationVariables): Promise<Comment> => {
      return await client.v2(
        {
          endpoint: '/v2/resources/{resourceId}/comments/{commentId}',
          params: {
            commentId: comment.id,
            resourceId: comment.resourceId,
          },
        },
        {
          query: {
            accessToken: undefined,
          },
          method: 'patch',
          body: {
            content: newContent,
          },
        },
      );
    },
    onMutate: ({ comment, newContent: content }) => {
      return updateCachedComment({
        ...comment,
        content,
      });
    },
    onError: (error, _v, context) => {
      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
      console.error('Error updating comment', error);
    },
    onSuccess: (_, { comment }) => {
      track(AnalyticsEvents.EditedComment, { commentId: comment.id });
    },
  });

  return mutation;
};
