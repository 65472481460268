import { useDefaultedEvent } from '@/src/lib/events';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import * as TabsPrimitive from '@radix-ui/react-tabs';
import styled, { css } from 'styled-components';
import { cssVar } from '../theme/variables';

const TabsRoot = TabsPrimitive.Root;

const listVariants = {
  default: css`
    background: ${cssVar['color-bg-quarternary']};
    padding: 5px;
    border-radius: 6px;
  `,
  flush: css`
    background: transparent;
    padding: 0;
    border-radius: 0;
    gap: 0.453rem;
  `,
};

interface TabsListProps {
  variant?: keyof typeof listVariants;
}

const TabsList = styled(TabsPrimitive.List).withConfig(
  preventForwardPropsConfig(['variant']),
)<TabsListProps>`
  flex-grow: 1;
  display: inline-flex;
  flex-shrink: 0;

  ${({ variant = 'default' }) => listVariants[variant]}
`;

const triggerVariants = {
  default: css`
    background: transparent;
    border: none;
    cursor: pointer;

    will-change: background-color, box-shadow;

    --tabs-trigger-hover-shadow-color: rgba(${cssVar['color-bg-primary-rgb']}, 0.4);

    &[data-state='active']:not([data-state-ignore='true']) {
      color: ${cssVar['color-text-secondary']};
      background: ${cssVar['color-bg-primary']};

      --tabs-trigger-hover-shadow-color: rgba(${cssVar['color-bg-octonary-rgb']}, 0.1);
    }

    ${mediaHover} {
      &:hover:enabled {
        box-shadow: ${cssVar['shadow-spread-within']} var(--tabs-trigger-hover-shadow-color) inset;
      }
    }
  `,
  apparent: css`
    background: rgba(${cssVar['color-bg-contrast-reverse-rgb']}, 0.04);
    color: ${cssVar['color-text-primary']};
    border: 1px solid transparent;
    border-radius: 0.625rem;
    padding: 7px 8px;
    height: 30px;
    font-size: 13px;
    gap: 0.375rem;

    will-change: background-color, box-shadow;

    &[data-state='active']:not([data-state-ignore='true']) {
      background: rgba(${cssVar['color-bg-contrast-reverse-rgb']}, 0.14);
      border: 1px solid ${cssVar['color-border-primary']};
    }

    ${mediaHover} {
      &:hover:enabled {
        box-shadow: ${cssVar['shadow-spread-within']} rgba(${cssVar['color-bg-octonary-rgb']}, 0.1)
          inset;
      }
    }
  `,
};

const preventArrowKeyNavigation = (e: React.KeyboardEvent) => {
  if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
    e.preventDefault();
  }
};

interface TabsTriggerProps {
  variant?: keyof typeof triggerVariants;
  ignoreState?: boolean;
  disableArrowNavigation?: boolean;
  square?: boolean;
}

const TabsTrigger = styled(TabsPrimitive.Trigger)
  .attrs<TabsTriggerProps>((p) => {
    return {
      ...p,
      'data-state-ignore': p.ignoreState,
      ...(p.disableArrowNavigation && {
        onKeyDown: useDefaultedEvent(preventArrowKeyNavigation, p.onKeyDown),
      }),
    };
  })
  .withConfig(
    preventForwardPropsConfig(['variant', 'ignoreState', 'disableArrowNavigation', 'square']),
  )`
  display: flex;
  padding: 6px 12px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 3px;
  flex-shrink: 0;
  flex-grow: 1;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  color: ${cssVar['color-text-tertiary']};

  ${({ square }) =>
    square
      ? css`
          aspect-ratio: 1 / 1;
        `
      : undefined};

  transition:
    background-color 200ms,
    box-shadow 200ms;

  ${({ variant = 'default' }) => triggerVariants[variant]}
`;

const TabsContent = styled(TabsPrimitive.Content)`
  display: none;

  &[data-state='active'] {
    display: flex;
  }
`;

const Root = TabsRoot;
const List = TabsList;
const Trigger = TabsTrigger;
const Content = TabsContent;

export { Content, List, Root, TabsContent, TabsList, TabsRoot, TabsTrigger, Trigger };
