import produce from 'immer';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import { useControls } from 'react-zoom-pan-pinch';

type PlaceholderImage = {
  normal: string;
  small: string;
};

interface IPDFContentContext {
  pdf?: DocumentCallback | null;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  isReady: boolean;
  getEstimatedHeight: (pageNumber: number, width: number, ignoreScale?: boolean) => number;
  getPagePlaceholderImage: (pageNumber: number) => PlaceholderImage | null;
  tinyPagesVisible: boolean;
  scale: number;
  userScrolled?: boolean;
  setUserScrolled?: (value: boolean) => void;
  scrollToPage?: (pageNumber: number) => void;
  totalPages: number;
  tinyPagesRef?: HTMLElement | null;
  setTinyPagesRef?: (value: HTMLElement | null) => void;
  scrollToTinyPage: (page: number) => void;
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
}

const PDFContentContext = createContext<IPDFContentContext>({
  pdf: undefined,
  currentPage: 1,
  setCurrentPage: () => {},
  totalPages: 0,
  isReady: false,
  getEstimatedHeight: () => 0,
  getPagePlaceholderImage: () => null,
  tinyPagesVisible: false,
  scale: 1,
  userScrolled: false,
  setUserScrolled: () => {},
  scrollToPage: () => {},
  tinyPagesRef: null,
  setTinyPagesRef: () => {},
  scrollToTinyPage: () => {},
  contentRef: { current: null },
});

export const usePDFContent = () => useContext(PDFContentContext);

type PDFContentProviderProps = PropsWithChildren<{
  tinyPagesVisible?: boolean;
  pdf?: DocumentCallback | null;
  userScrolled?: boolean;
  setUserScrolled?: (value: boolean) => void;
  scrollToPage?: (pageNumber: number) => void;
  currentPage: number;
  setCurrentPage: (value: number) => void;
  scale: number;
  contentRef: React.MutableRefObject<HTMLDivElement | null>;
}>;

const PDFContentProvider: React.FC<PDFContentProviderProps> = ({
  pdf,
  children,
  tinyPagesVisible,
  userScrolled,
  setUserScrolled,
  contentRef,
  currentPage,
  scale,
  setCurrentPage,
}) => {
  const [baseViewportWidths, setBaseViewportWidths] = useState<number[]>([]);
  const [baseHeights, setBaseHeights] = useState<number[]>([]);
  const [isReady, setIsReady] = useState(false);
  const [pagePlaceholderImages, setPagePlaceholderImages] = useState<
    Record<number, PlaceholderImage>
  >({});
  const pagePlaceholderImageQueue = useRef<number[]>([]);
  const pagePlaceholderImagesRef = useRef(pagePlaceholderImages);
  const [totalPages, setTotalPages] = useState(0);
  const [tinyPagesRef, setTinyPagesRef] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setTotalPages(pdf?.numPages ?? 0);
  }, [pdf, setTotalPages]);

  // this ref holds an array of instant fetch pages
  // so it will avoid to call the generatePlaceholderImage function while
  // the page is already being generated
  const generatingPages = useRef<number[]>([]);
  const generatePlaceholderImage = useCallback(
    async (pageNumber: number) => {
      if (!pdf) return;

      if (generatingPages.current.includes(pageNumber)) return;

      try {
        generatingPages.current.push(pageNumber);
        const page = await pdf.getPage(pageNumber);
        const normalViewport = page.getViewport({ scale: 2 });
        const smallViewport = page.getViewport({ scale: 0.3 });
        const canvas = document.createElement('canvas');
        canvas.width = normalViewport.width;
        canvas.height = normalViewport.height;

        await page.render({
          canvasContext: canvas.getContext('2d')!,
          viewport: normalViewport,
        }).promise;

        const normal = canvas.toDataURL('image/jpg', 1);

        canvas.width = smallViewport.width;
        canvas.height = smallViewport.height;

        await page.render({
          canvasContext: canvas.getContext('2d')!,
          viewport: smallViewport,
        }).promise;

        const small = canvas.toDataURL('image/jpg', 1);

        setPagePlaceholderImages((prev) => {
          const newValue = produce(prev, (draft) => {
            draft[pageNumber] = { normal, small };
          });

          pagePlaceholderImagesRef.current = newValue;

          return newValue;
        });
      } catch (error) {
        console.error('Error', error);
      } finally {
        generatingPages.current = generatingPages.current.filter((item) => item !== pageNumber);
      }
    },
    [pdf],
  );

  useEffect(() => {
    if (!pdf) return;

    const calculate = async () => {
      const viewportWidths: number[] = [];
      const heights: number[] = [];

      for (let i = 0; i < pdf.numPages; i++) {
        try {
          const page = await pdf.getPage(i + 1);
          const viewport = page.getViewport({ scale: 1 });

          viewportWidths.push(viewport.width);
          heights.push(viewport.height);
        } catch (error) {
          console.error('Error', error);
          continue;
        }
      }

      setBaseViewportWidths(viewportWidths);
      setBaseHeights(heights);
      setIsReady(true);
    };

    if (pdf.numPages > Object.keys(pagePlaceholderImagesRef.current).length) {
      for (let i = 1; i <= pdf.numPages; i++) {
        if (!pagePlaceholderImagesRef.current[i]) {
          pagePlaceholderImageQueue.current.push(i);
        }
      }
    }

    let workerTimeout: number | undefined;
    let stop = false;
    const processNextImage = async () => {
      const pageNumber = pagePlaceholderImageQueue.current.shift();

      if (pageNumber) {
        await generatePlaceholderImage(pageNumber);
      }

      if (!stop && pagePlaceholderImageQueue.current.length > 0)
        workerTimeout = window.setTimeout(processNextImage, 1500);
    };

    processNextImage();

    calculate();

    return () => {
      if (workerTimeout) window.clearTimeout(workerTimeout);
      stop = true;
    };
  }, [pdf, generatePlaceholderImage]);

  const getPagePlaceholderImage = useCallback(
    (pageNumber: number) => {
      if (!isReady || !pdf) return null;

      const placeholderImage = pagePlaceholderImages[pageNumber];

      if (!placeholderImage) {
        pagePlaceholderImageQueue.current = pagePlaceholderImageQueue.current.filter(
          (item) => item !== pageNumber,
        );

        generatePlaceholderImage(pageNumber);
        return null;
      }

      return placeholderImage;
    },
    [pagePlaceholderImages, generatePlaceholderImage, isReady, pdf],
  );

  const getEstimatedHeight = useCallback(
    (pageNumber: number, width: number, ignoreScale: boolean = false) => {
      if (!pdf || !isReady) return 1;

      const baseWidth = baseViewportWidths[pageNumber - 1] ?? 1 * (ignoreScale ? 1 : scale);
      const baseHeight = baseHeights[pageNumber - 1] ?? 1 * (ignoreScale ? 1 : scale);
      const widthScale = width / baseWidth;

      return baseHeight * widthScale;
    },
    [pdf, baseViewportWidths, baseHeights, isReady, scale],
  );

  const { zoomToElement } = useControls();

  const scrollToTinyPage = useCallback(
    (page: number) => {
      if (!tinyPagesRef) return;

      const pageElement = tinyPagesRef.querySelector(`[data-page="${page}"]`) as HTMLElement;
      if (!pageElement) return;

      pageElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    },
    [tinyPagesRef],
  );

  const scrollToPage = useCallback(
    (page: number) => {
      if (!contentRef?.current) return;

      const pageElement = contentRef.current.querySelector(
        `[data-page-index="${page}"]`,
      ) as HTMLElement;
      if (!pageElement) return;

      zoomToElement(pageElement, undefined, 0);
      scrollToTinyPage(page);
    },
    [contentRef, zoomToElement, scrollToTinyPage],
  );

  const value = useMemo(
    () => ({
      pdf,
      currentPage,
      getEstimatedHeight,
      isReady,
      getPagePlaceholderImage,
      tinyPagesVisible: tinyPagesVisible ?? false,
      scale,
      userScrolled,
      setUserScrolled,
      scrollToPage,
      totalPages,
      setCurrentPage,
      tinyPagesRef,
      setTinyPagesRef,
      scrollToTinyPage,
      contentRef,
    }),
    [
      pdf,
      currentPage,
      getEstimatedHeight,
      isReady,
      getPagePlaceholderImage,
      tinyPagesVisible,
      userScrolled,
      setUserScrolled,
      scale,
      scrollToPage,
      totalPages,
      setCurrentPage,
      tinyPagesRef,
      setTinyPagesRef,
      scrollToTinyPage,
      contentRef,
    ],
  );

  return <PDFContentContext.Provider value={value}>{children}</PDFContentContext.Provider>;
};

export default PDFContentProvider;
