import { DrawerExpandedStyle } from '@/src/modules/ui/components/Drawer/drawerGlobalStyles';
import { PanMoreInfo, usePanning, UsePanningHandler } from '@/src/modules/ui/hooks/usePanning';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { motion, PanInfo } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Base = styled(motion.div)`
  background: ${cssVar['color-bg-primary']};
  padding-top: 1rem;
  padding-bottom: max(
    calc(1rem + env(safe-area-inset-bottom)),
    calc(var(--keyboard-height, 0px) + 1rem)
  );

  height: max-content;
  max-height: calc(100dvh - env(safe-area-inset-top, 0px));
  border-radius: 2rem 2rem 0 0;
  will-change: transform;
  position: relative;
  display: flex;
  flex-direction: column;

  // hidden interactive area
  &:before {
    content: '';
    position: absolute;
    display: block;
    height: 80px;
    width: 100%;
    bottom: calc(100% - 40px);
    left: 0;
  }
`;

interface PanMoreInfoExtended extends PanMoreInfo {
  shouldClose: boolean;
}

export type DrawerPanEndHandler = (
  e: PointerEvent,
  info: PanInfo,
  moreInfo: PanMoreInfoExtended,
) => void;

interface DrawerProps {
  children: React.ReactNode;
  onOpenChange: (value: boolean) => void;
  open?: boolean;
  className?: string;
  panningLayerId?: string;
  onOffsetYChange?: (offset: number) => void;
  onPanEnd?: DrawerPanEndHandler;
  style?: React.CSSProperties;
}

/**
 * Drawer component not dependant on any context etc.
 * Meant to be direct child of radix content wrapping the rest of the content
 */
export const Drawer: React.FC<DrawerProps> = ({
  children,
  open = true,
  onOpenChange,
  className,
  panningLayerId,
  onOffsetYChange,
  onPanEnd: onPanEndProp,
  style,
}) => {
  /**
   * panning
   */

  const [panCloseOffset, setPanCloseOffset] = React.useState(0);

  const onPanEnd: UsePanningHandler = React.useCallback(
    (e, info, moreInfo) => {
      const { velocity, offset } = info;
      if (velocity.y > 50) {
        onOpenChange(false);
        onPanEndProp?.(e, info, { ...moreInfo, shouldClose: true });
        setPanCloseOffset(offset.y);
        return;
      } else {
        onPanEndProp?.(e, info, { ...moreInfo, shouldClose: false });
      }
    },
    [onOpenChange, onPanEndProp],
  );

  const { motionProps, panningYOffset } = usePanning({
    onPan: (e, info) => {
      onOffsetYChange?.(info.offset.y);
    },
    onPanEnd,
    panningLayerId,
  });

  return (
    <>
      {open && <DrawerExpandedStyle />}
      <Base
        className={className}
        style={{
          transform: `translateY(${panCloseOffset || Math.max(panningYOffset, 0)}px)`,
          transition:
            panningYOffset < 0 ? 'none' : 'transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1)',
          ...style,
        }}
        {...motionProps}
      >
        {children}
      </Base>
    </>
  );
};
