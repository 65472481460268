import React from 'react';



type BeforeUnloadEventHandler = (e: BeforeUnloadEvent) => void;

export const useOnWindowClose = (enabled: boolean, handler?: BeforeUnloadEventHandler) => {
  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const handleBeforeUnload: BeforeUnloadEventHandler = (e) => {
      e.preventDefault();
      e.returnValue = '';

      handler?.(e);

    }

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [handler, enabled]);
};
