import { create } from 'zustand';

interface MobileSelectionStore {
  mobileSelectModeIsActive: boolean;
  toggleMobileSelectModeIsActive: () => void;
  disableMobileSelectMode: () => void;
}

const useMobileSelectionStore = create<MobileSelectionStore>((set) => ({
  mobileSelectModeIsActive: false,
  toggleMobileSelectModeIsActive: () =>
    set((state) => ({ mobileSelectModeIsActive: !state.mobileSelectModeIsActive })),
  disableMobileSelectMode: () => set({ mobileSelectModeIsActive: false }),
}));

export default useMobileSelectionStore;
