import GeneratedIcon from '@/src/icons/GeneratedIcon';
import { RecapForDateSortField } from '@/src/modules/recap/recap.types';
import { Button } from '@/src/modules/ui/components/Button';
import styled from 'styled-components';
import RecapSummaryModal from './RecapSummaryModal';

const SummaryButton = styled(Button).attrs({
  variant: 'bg-tertiary',
  size: 'sm',
})`
  display: inline-flex;
  border-radius: 10px;
  font-weight: 500;
  height: 32px;
  padding: 0 8px;
`;

type RecapSummaryButtonProps = {
  date: string;
  frequency: 'daily';
  field: RecapForDateSortField;
};

const RecapSummaryButton: React.FC<RecapSummaryButtonProps> = ({ date, frequency, field }) => {
  return (
    <RecapSummaryModal date={date} frequency={frequency} field={field}>
      <SummaryButton>
        <GeneratedIcon size={16} />
        Summary
      </SummaryButton>
    </RecapSummaryModal>
  );
};

export default RecapSummaryButton;
