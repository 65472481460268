import { IconGlobe } from '@/src/modules/icons';
import { cssVar } from '@/src/modules/ui/theme/variables';
import Tooltip from '@/src/ui/Tooltip';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';

const ShareIconWrapper = styled.div`
  color: ${cssVar['color-text-tertiary']};
  height: 1.125rem;
  width: 1.125rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(${cssVar['color-bg-primary-reverse-rgb']}, 0.13);
  flex-shrink: 0;
`;

export const IconSharedWithPublic: React.FC<{ style?: CSSProperties }> = ({ style }) => {
  return (
    <Tooltip label="Link sharing enabled">
      <ShareIconWrapper style={style}>
        <IconGlobe style={{ height: '100%', width: '100%' }} />
      </ShareIconWrapper>
    </Tooltip>
  );
};
