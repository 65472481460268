import { FabricResourceTypes, HalFdocStoredFile, HalNoteImage } from '@fabric/woody-client';
import { FileLikeDetails } from '../resource-details.types';

export const halStoredFileToFileLikeDetails = (storedFile: HalFdocStoredFile): FileLikeDetails => {
  return {
    type: FabricResourceTypes.STORED_FILE,
    title: storedFile.title,
    url: storedFile.url,
    contentType: storedFile.contentType,
    contentLength: storedFile.contentLength,
    thumbnail: storedFile.thumbnail,
  };
};

export const halImageToFileLikeDetails = (image: HalNoteImage): FileLikeDetails => {
  return {
    type: FabricResourceTypes.IMAGE,
    url: image.url,
    contentType: image.mimeType,
  };
};
