import { useEffect, useState } from 'react';
import { useDomContentRect } from './useDomRect';

/**
 * This hook is used to fix the height of the list inside a ScrollArea when the zoom level is not 1, since the height of the list is not calculated correctly.
 * And creates a enormous gap at the bottom of the list.
 *
 * This is a bit hacky potentially, but it works, should become unnecessary once we refactor the FdocList component.
 *
 * To solve FUT-3872:
 * https://linear.app/futurebrowser/issue/FUT-3872/fix-giant-gaps-on-the-bottom-of-the-lists-inside-scrollareas-which
 * @param listRef
 * @param zoomLevel
 * @returns
 */
export const useScrollAreaZoomHeightFix = (
  listRef: HTMLElement | null,
  zoomLevel: number,
  enabled = true,
) => {
  const [maxHeight, setMaxHeight] = useState<number | undefined>(undefined);
  const rect = useDomContentRect(listRef);

  useEffect(() => {
    if (!listRef || zoomLevel === 1 || !enabled) {
      setMaxHeight(undefined);
      return;
    }

    const parent = listRef.parentElement;
    if (!parent) {
      setMaxHeight(undefined);
      return;
    }

    const cloned = listRef.cloneNode(true) as HTMLElement;
    cloned.style.position = 'absolute';
    cloned.style.minHeight = '0';
    cloned.style.height = 'auto';
    cloned.style.visibility = 'hidden';

    parent.appendChild(cloned);
    const clonedRect = cloned.getBoundingClientRect();
    parent.removeChild(cloned);
    // The 5px buffer allows the resize observer to trigger if new items are added
    setMaxHeight(clonedRect.height + 5);
  }, [rect, listRef, enabled, zoomLevel]);

  return maxHeight;
};
