import { Fdoc } from '@/src/types/api';

export const mutateResourceTitle = (resource: Fdoc, title: string): Fdoc => {
  switch (resource.type) {
    case 'notepad':
      return {
        ...resource,
        data: {
          ...resource.data,
          title,
        },
      };
    case 'stored_file':
      return {
        ...resource,
        data: {
          ...resource.data,
          title,
        },
      };
    case 'folder':
      return {
        ...resource,
        data: {
          ...resource.data,
          name: title,
        },
      };
    default:
      return resource;
  }
};
