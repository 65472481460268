import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useQueryCacheResourceHelpers } from '@/src/modules/resources/utils/useQueryCacheResourceHelpers';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation } from '@tanstack/react-query';

type MutationVariables = {
  resourceId: string;
  spaceId: string;
  labelId: number | null;
};

export const useMutationChangeResourceLabel = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { updateCachedResource } = useQueryCacheResourceHelpers();

  const mutation = useMutation({
    mutationFn: async ({
      resourceId,
      spaceId,
      labelId,
    }: MutationVariables): Promise<string | null> => {
      return getWoodyResponseData(await client.setListFdocColorLabel(spaceId, resourceId, labelId));
    },
    onMutate: ({ resourceId, labelId }) => {
      return updateCachedResource({
        id: resourceId,
        label: labelId,
      });
    },
    onError: (_, _v, context) => {
      toast({
        content: 'Failed to change label',
      });
      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
    onSuccess: (_, { labelId, resourceId }) => {
      track(labelId ? AnalyticsEvents.ChangeLabel : AnalyticsEvents.RemovedLabel, {
        resourceId,
        labelId,
      });
    },
  });

  return mutation;
};
