import { useChabot } from '@/src/hooks/chatbot';
import CloseIcon from '@/src/icons/CloseIcon';
import { AssistantContextSubType } from '@/src/modules/assistant/types';
import { Button } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import Select from '@/src/modules/ui/components/Select';
import { cssVar } from '@/src/modules/ui/theme/variables';
import plur from 'plur';
import React, { forwardRef } from 'react';
import styled from 'styled-components';

const AssistantHeaderRoot = styled(Flex).attrs((p) => {
  return {
    justifyContent: 'space-between',
    alignItems: 'center',
    ...p,
  };
})`
  flex-shrink: 0;
  padding: 0.75rem;
  border-bottom: 1px solid rgba(${cssVar['color-bg-contrast-reverse-rgb']}, 0.1);
`;

const AssistantHeaderLeft = styled(Flex).attrs((p) => {
  return {
    alignItems: 'center',
    ...p,
  };
})`
  gap: 0.375rem;
`;

const Root = AssistantHeaderRoot;
const Left = AssistantHeaderLeft;

const AssistantButton = styled(Button).attrs((p) => {
  return {
    variant: 'bg-contrast-reverse-semi-transparent',
    size: 'xs',
    roundness: 'lg',
    ...p,
  };
})`
  font-size: 13px;
  height: 30px;
  font-weight: 400;
`;

const AssistantButtonIcon = styled(AssistantButton).attrs((p) => {
  return {
    square: 'shape',
    ...p,
  };
})`
  width: 30px;
  height: 30px;
`;

const SelectionBadge = styled.span`
  border: 1px dashed ${cssVar['color-border-primary']};
  border-radius: ${cssVar['radius-input']};
  padding: 3px 10px;
  font-size: 0.813rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: ${cssVar['color-text-primary']};
`;

const ContextSelector = forwardRef<
  React.ElementRef<typeof Select>,
  React.ComponentPropsWithoutRef<typeof Select>
>((props, ref) => {
  const { activeContext, setActiveContext, resetSelection, contexts } = useChabot();

  if (
    activeContext.subType === AssistantContextSubType.Selection &&
    Array.isArray(activeContext.value) &&
    activeContext.value.length > 0
  ) {
    return (
      <>
        <SelectionBadge>
          {activeContext.value.length} {plur('source', activeContext.value.length)}
        </SelectionBadge>
        <AssistantButtonIcon onClick={resetSelection}>
          <CloseIcon size={15} />
        </AssistantButtonIcon>
      </>
    );
  }

  return (
    <Select
      value={activeContext.subType}
      onChange={(value) => setActiveContext(contexts.find((c) => c.subType === value)!)}
      disabled={contexts.length === 1}
      ref={ref}
      {...props}
      triggerProps={{
        size: 'xs',
        roundness: 'lg',
        variant: 'outline-quaternary',
        disabled: contexts.length === 1,
        style: {
          fontSize: 13,
        },
        ...props.triggerProps,
      }}
    >
      {contexts.map((context) => (
        <Select.ItemOption key={context.subType} value={context.subType}>
          {context.subType === AssistantContextSubType.Everything
            ? 'Everything'
            : `This ${context.subType}`}
        </Select.ItemOption>
      ))}
    </Select>
  );
});

ContextSelector.displayName = 'ContextSelector';

const ResetButton = forwardRef<
  React.ElementRef<typeof Button>,
  React.ComponentPropsWithoutRef<typeof Button>
>((props, ref) => {
  const { clearMessages } = useChabot();

  return (
    <AssistantButton onClick={clearMessages} ref={ref} {...props}>
      Reset chat
    </AssistantButton>
  );
});

ResetButton.displayName = 'ResetButton';

export { AssistantHeaderLeft, AssistantHeaderRoot, ContextSelector, Left, ResetButton, Root };
