import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  extension: string;
}

const FileIcon: React.FC<Props> = ({ extension, height = 225, width = 215, ...props }) => {
  const isPDF = extension.toLowerCase() === 'pdf';

  return (
    <svg
      data-file-icon
      width={width}
      height={height}
      viewBox="0 0 215 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMidYMid meet"
      {...props}
    >
      <g transform="translate(20, 4)">
        <g filter="url(#filter0_dd_6050_17218)">
          <path
            d="M 8 5.997 C 8 4.894 8.894 4 9.996 4 L 80.678 4 C 89.765 4 98.515 7.446 105.164 13.642 L 156.227 61.231 C 163.521 68.03 167.664 77.556 167.664 87.53 L 167.664 210.668 C 167.664 211.771 166.771 212.664 165.669 212.664 L 9.996 212.664 C 8.894 212.664 8 211.771 8 210.668 L 8 5.997 Z"
            fill="url(#paint0_linear_6050_17218)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M167 211V87.6648C167 77.9515 162.964 68.6751 155.856 62.0545L104.687 14.3897C98.2083 8.35522 89.6839 5 80.8304 5H10C9.44772 5 9 5.44772 9 6.00001V211C9 211.552 9.44771 212 10 212H166C166.552 212 167 211.552 167 211ZM10 4C8.89543 4 8 4.89544 8 6.00001V211C8 212.105 8.89543 213 10 213H166C167.105 213 168 212.105 168 211V87.6648C168 77.674 163.848 68.1326 156.538 61.3228L105.368 13.658C98.7048 7.45108 89.9368 4 80.8304 4H10Z"
          fill="#CBCBCB"
        />
        <mask
          id="mask0_6050_17218"
          style={{
            maskType: 'alpha',
          }}
          maskUnits="userSpaceOnUse"
          x={8}
          y={4}
          width={160}
          height={209}
        >
          <path
            d="M167.5 87.6648V211C167.5 211.828 166.828 212.5 166 212.5H10C9.17157 212.5 8.5 211.828 8.5 211V6.00001C8.5 5.17158 9.17157 4.5 10 4.5H80.8304C89.8103 4.5 98.4566 7.90315 105.027 14.0239L156.197 61.6887C163.406 68.4038 167.5 77.8128 167.5 87.6648Z"
            fill="white"
            stroke="#CBCBCB"
          />
        </mask>
        <g mask="url(#mask0_6050_17218)">
          <rect
            x={59.0967}
            y={-31.043}
            width={191.035}
            height={36.0833}
            transform="rotate(42.8329 59.0967 -31.043)"
            fill="url(#paint1_linear_6050_17218)"
            fillOpacity={0.4}
          />
          <g filter="url(#filter1_ddd_6050_17218)">
            <path
              d="M110 28.9991V52.4991C121.166 50.4991 146.285 52.0205 159.991 65.0205C159.991 65.0205 159.588 64.4474 157.707 62.4639C156.246 60.9873 151.844 56.9845 150.846 56.0168C148.946 54.2662 133.578 39.9364 133.578 39.9364L106.82 14.9991C104.653 12.9991 100.214 8.55682 92.667 6C108.721 11.7414 110 22.9991 110 28.9991Z"
              fill="url(#paint2_linear_6050_17218)"
            />
          </g>
        </g>
        <path
          d="M81.5 6H9V54.5H109V29C109 22 108 18 102 13C96 8 86.5 6 81.5 6Z"
          fill="url(#paint3_linear_6050_17218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M82.2474 6.5H9V5.5H82.2474C89.4142 5.5 100.694 8.53035 107.386 16.6828L106.614 17.3172C100.172 9.46965 89.225 6.5 82.2474 6.5Z"
          fill="url(#paint4_linear_6050_17218)"
          fillOpacity={0.3}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 69.5V5.5H10V69.5H9Z"
          fill="url(#paint5_linear_6050_17218)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M166 86.4982C166 75.0813 159.428 66.6439 156.176 63.8791L156.823 63.1172C160.237 66.0191 167 74.715 167 86.4982V171.498H166V86.4982Z"
          fill="url(#paint6_linear_6050_17218)"
        />
      </g>
      {isPDF && (
        <g filter="url(#filter0_d_6053_17275)">
          <rect x={0} y={100} width={134} height={67} rx={12} fill="#F15742" />
        </g>
      )}

      <text
        x={isPDF ? 67 : '50%'}
        y={isPDF ? 139 : 178}
        fill={isPDF ? 'white' : 'rgba(0,0,0,0.4)'}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fontFamily: 'Inter, sans-serif',
          fontSize: '41px',
          fontWeight: isPDF ? 600 : 500,
          lineHeight: '50px',
        }}
      >
        {extension.slice(0, 4).toUpperCase()}
      </text>

      <defs>
        <filter
          id="filter0_dd_6050_17218"
          x={0}
          y={0}
          width={176}
          height={225}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6050_17218" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={4} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_6050_17218"
            result="effect2_dropShadow_6050_17218"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_6050_17218"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_ddd_6050_17218"
          x={45.667}
          y={-36}
          width={161.324}
          height={153.02}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={2} />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6050_17218" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={5} />
          <feGaussianBlur stdDeviation={23.5} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.55 0" />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_6050_17218"
            result="effect2_dropShadow_6050_17218"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation={2} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_6050_17218"
            result="effect3_dropShadow_6050_17218"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_6050_17218"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_6050_17218"
          x1={88}
          y1={4}
          x2={88}
          y2={213}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F0F0" />
          <stop offset={0.447917} stopColor="white" />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6050_17218"
          x1={154.614}
          y1={-31.043}
          x2={154.614}
          y2={5.04032}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4E4E4E" />
          <stop offset={1} stopColor="#D9D9D9" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6050_17218"
          x1={130}
          y1={35.999}
          x2={111}
          y2={53.499}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#CDCDCD" />
          <stop offset={0.223958} stopColor="#EAEAEA" />
          <stop offset={0.447917} stopColor="white" />
          <stop offset={0.703125} stopColor="white" />
          <stop offset={1} stopColor="#E2E2E2" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6050_17218"
          x1={56.75}
          y1={5.5}
          x2={56.75}
          y2={31}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D9D9" />
          <stop offset={1} stopColor="#D9D9D9" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6050_17218"
          x1={53.4536}
          y1={6.50001}
          x2={88.7998}
          y2={11.6015}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset={0.385417} stopColor="white" />
          <stop offset={1} stopColor="white" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6050_17218"
          x1={10}
          y1={5.5}
          x2={10}
          y2={69.5}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset={0.807292} stopColor="white" />
          <stop offset={1} stopColor="white" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6050_17218"
          x1={161.5}
          y1={64.5536}
          x2={161.5}
          y2={201.998}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity={0} />
          <stop offset={0.255208} stopColor="white" />
          <stop offset={1} stopColor="white" />
        </linearGradient>
        <filter
          id="filter0_d_6053_17275"
          x={0}
          y={0}
          width={162}
          height={200}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy={4} />
          <feGaussianBlur stdDeviation={7} />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6053_17275" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_6053_17275"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default FileIcon;
