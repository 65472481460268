import { ShortcutHandler } from './types';

type ChainShortcutHandlersOptions = {
  /**
   * true by default
   * stops the chain of handlers when first handler is successful
   */
  stopOnFirstSuccess?: boolean;
  /**
   * if there's a successful handler, stop the event from propagating
   */
  stopPropagationOnHandle?: boolean;
  /**
   * if there's a successful handler, prevent the default action
   */
  preventDefaultOnHandle?: boolean;
};

const defaultOptions: ChainShortcutHandlersOptions = {
  stopOnFirstSuccess: true,
  stopPropagationOnHandle: false,
  preventDefaultOnHandle: false,
};

/**
 * Chains multiple shortcut handlers and executes them sequentially.
 *
 * @param event - The React keyboard event to handle
 * @param handlers - An array of shortcut handler functions
 * @param optionsArg - Optional configuration for the chain execution
 * @returns boolean - True if any handler was successful, false otherwise
 *
 * @example
 * // Basic usage
 * const result = chainShortcutHandlers(
 *   event,
 *   [
 *     (e) => isHotkey('ctrl+s', e) && handleSave(),
 *     (e) => isHotkey('ctrl+z', e) && handleUndo(),
 *   ],
 *   { stopOnFirstSuccess: true, preventDefaultOnHandle: true }
 * );
 *
 * @see FilterCombobox.tsx for a real-world example
 */
export const chainShortcutHandlers = (
  event: React.KeyboardEvent | KeyboardEvent,
  handlers: ShortcutHandler[],
  optionsArg?: ChainShortcutHandlersOptions,
) => {
  const options = {
    ...defaultOptions,
    ...optionsArg,
  };

  let isAnyHandled = false;

  for (const handler of handlers) {
    const isHandled = handler(event);
    if (isHandled) {
      isAnyHandled = true;
    }

    if (options.stopOnFirstSuccess && isHandled) {
      break;
    }
  }

  if (isAnyHandled) {
    options.stopPropagationOnHandle && event.stopPropagation();
    options.preventDefaultOnHandle && event.preventDefault();
  }

  return isAnyHandled;
};
