import InvertedFabricIcon from '@/public/images/icons/Fabric.svg';
import {
  ChatbotConversationMessage,
  ChatbotConversationUserAction,
  ChatbotConversationUserActionType,
} from '@/src/modules/assistant/types';
import { parseMarkdown } from '@/src/modules/markdown/markdown.utils';
import { useQueryResource } from '@/src/modules/resources/queries/useQueryResource';
import * as Conversation from '@/src/modules/ui/components/Conversation';
import { Flex } from '@/src/modules/ui/components/Flex';
import { renderCssBasedOnColorScheme } from '@/src/modules/ui/theme/renderCssBasedOnColorScheme';
import { cssVar } from '@/src/modules/ui/theme/variables';
import Avatar from '@/src/modules/user/components/Avatar/Avatar';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import { isChatbotAssistantMessage, isChatbotUserMessage } from '@/src/modules/utils';
import { ChatbotMessageRole } from '@fabric/woody-client';
import { CSSProperties, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import { shallow } from 'zustand/shallow';
import useAuthStore from '../../hooks/auth';
import { useShowExpandedFdoc } from '../../hooks/useExpandedFdoc';
import FdocItem from '../FdocItem/FdocItem';
import styles from './Chatbot.module.scss';

function isValidUUID(uuid: string): boolean {
  const regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return regex.test(uuid);
}

const RenderedContentWrapper = styled.div<{ isResourceList?: boolean }>`
  ${(p) =>
    p.isResourceList &&
    css`
      ol,
      ul {
        margin-bottom: 0;
        margin-top: 0;
        li {
          min-height: 164px;
          margin-top: 0;
          margin-bottom: 1rem;
        }
        & > p {
          margin-top: 0.5rem;
        }
      }
    `}

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }
`;

const ResourcePreviewList = styled(Flex).attrs((props) => {
  return {
    ...props,
    direction: 'column',
    gap: 'elementsContainer',
  };
})`
  float: left;
  margin-right: 0.5rem;
`;

const RenderedContent: React.FC<{ content: string; isResourceList?: boolean }> = ({
  content = '',
  isResourceList,
}) => {
  /**
   * This component is used to render the content of a message.
   *
   * The content is a string and resource links are in the format [text](fabric:resourceId).
   *
   * We first convert the links to markdown, then we render the markdown.
   *
   * We also add a click handler on the links to expand the resources instead of opening the link.
   */
  const expand = useShowExpandedFdoc();
  const divRef = useRef<HTMLDivElement>(null);

  const markdownContent = useMemo(() => {
    return parseMarkdown(content || '', {
      includeEmptyLines: false,
    });
  }, [content]);

  useEffect(() => {
    const div = divRef.current;

    const linkClickHandler = (e: Event) => {
      const target = e.target as HTMLElement;
      if (target.tagName === 'A') {
        const href = (target as HTMLAnchorElement).href;
        if (href.startsWith('fabric://')) {
          let resourceId;
          try {
            resourceId = href.split('://')[1];
          } catch (e) {
            console.error(e);
          }

          if (!resourceId || !isValidUUID(resourceId)) {
            console.error('Invalid resource id', resourceId);
            return;
          }

          expand(resourceId, 'assistant');
          e.preventDefault(); // Prevent the default action (optional)
        }
      }
    };

    div?.addEventListener('click', linkClickHandler);

    return () => {
      div?.removeEventListener('click', linkClickHandler);
    };
  }, [expand]);

  return (
    <RenderedContentWrapper
      isResourceList={isResourceList}
      className={styles.rendered}
      ref={divRef}
      dangerouslySetInnerHTML={{ __html: markdownContent }}
    />
  );
};

export const FabricAvatar = styled(Avatar)`
  ${renderCssBasedOnColorScheme({
    dark: css`
      background-color: ${cssVar['color-bg-primary-reverse']};
    `,
    light: css`
      background-color: ${cssVar['color-bg-primary']};
    `,
  })}
`;

const ChatbotMessage: React.FC<{ message: ChatbotConversationMessage }> = ({
  message,
}): JSX.Element => {
  const user = useAuthStore((state) => state.user, shallow);

  const isUser = isChatbotUserMessage(message);
  const isAssistant = isChatbotAssistantMessage(message);

  const content =
    isUser && message.action?.type === ChatbotConversationUserActionType.SUMMARIZE_TEXT
      ? `Summarize highlight:\n_“${message.content}”_`
      : message.content;

  const firstParagraph = Boolean(message.action)
    ? content
    : content.substring(0, content.indexOf('\n'));
  const restOfMessage = Boolean(message.action)
    ? undefined
    : content.substring(content.indexOf('\n') + 1);

  const firstRenderedContent = firstParagraph.length > 0 ? firstParagraph : content;

  return (
    <Conversation.Item>
      <Conversation.ItemHeader>
        {isUser ? (
          <>
            <AvatarUser user={user} tooltip={undefined} size={26} />
            <Conversation.ItemHeaderName>{user?.name}</Conversation.ItemHeaderName>
          </>
        ) : (
          <>
            <FabricAvatar size={26}>
              <InvertedFabricIcon
                style={{
                  imageRendering: 'pixelated',
                }}
                width={20}
              />
            </FabricAvatar>
            <Conversation.ItemHeaderName>Fabric Assistant</Conversation.ItemHeaderName>
          </>
        )}
      </Conversation.ItemHeader>
      <div className="table clear-both w-full overflow-none">
        <ContentBlock className="rounded-lg group" role={message.role} action={message.action}>
          <RenderedContent content={firstRenderedContent} />
          {(firstParagraph && restOfMessage) ||
          (isAssistant && message.resourceIds?.length && !message.action) ? (
            <div>
              {isAssistant && message.resourceIds?.length > 0 && (
                <ResourcePreviewList
                  style={{
                    width:
                      firstParagraph.length > 0 && (restOfMessage?.length ?? 0) > 0
                        ? '180px'
                        : '100%',
                    shapeOutside: 'inset(0)',
                  }}
                >
                  {message.resourceIds?.map((resourceId) => {
                    return (
                      <ChatbotMessageFdoc
                        key={resourceId}
                        resourceId={resourceId}
                        style={{
                          width: '164px',
                          height: '164px',
                          marginRight: '10px',
                          marginTop: '10px',
                        }}
                      />
                    );
                  })}
                </ResourcePreviewList>
              )}
              {firstParagraph && restOfMessage && (
                <RenderedContent
                  isResourceList={isAssistant && message.resourceIds?.length > 0}
                  content={restOfMessage}
                />
              )}
            </div>
          ) : null}
        </ContentBlock>
      </div>
    </Conversation.Item>
  );
};

const ChatbotMessageFdoc: React.FC<{ resourceId: string; style: CSSProperties }> = ({
  resourceId,
}) => {
  const { resource } = useQueryResource(resourceId);

  const expand = useShowExpandedFdoc();

  if (!resource) {
    return null;
  }

  return (
    <FdocItem
      fdoc={resource}
      size={{ width: 164, height: 164 }}
      handleOnClick={() => expand(resourceId, 'assistant')}
      viewMode="Grid"
      style={{
        width: '164px',
        height: '164px',
      }}
    />
  );
};

export default ChatbotMessage;

interface ContentBlockProps {
  role?: ChatbotMessageRole;
  action?: ChatbotConversationUserAction;
}

const ContentBlock = styled(Flex).attrs((props) => {
  return {
    direction: 'column',
    gap: 'elementsContainer',
    ...props,
  };
})<ContentBlockProps>`
  position: relative;
  padding-top: 0.5rem;
  color: ${cssVar['color-text-primary']};
  font-size: 0.8125rem;
  line-height: 1.25rem;
`;
