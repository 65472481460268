/**
 * might rather want to use copy-to-clipboard package
 */
import React, { useState } from 'react';

import copyToClipboard from 'copy-to-clipboard';

type CopiedValue = string | null;
type CopyFn = (text: string) => Promise<boolean>; // Return success

function useCopyToClipboard(): [CopiedValue, CopyFn] {
  const [copiedText, setCopiedText] = useState<CopiedValue>(null);

  const copy: CopyFn = React.useCallback(async (text) => {
    // Try to save to clipboard then save it in the state if worked
    try {
      const res = copyToClipboard(text);
      if (res) {
        setCopiedText(text);
        return true;
      } else {
        throw new Error('Copy failed');
      }
    } catch (error) {
      console.warn('Copy failed', error);
      setCopiedText(null);
      return false;
    }
  }, []);

  return [copiedText, copy];
}

export default useCopyToClipboard;
