import { EmbedElementProps } from '../../expandedResource.types';
import { parseUrlSafe } from '../../expandedResource.utils';
import { EmbedWrapper } from './EmbedWrapper';

export const VimeoEmbed: React.FC<EmbedElementProps> = ({ url, children }) => {
  const parsedUrl = parseUrlSafe(url);
  const videoId = parsedUrl?.pathname.split('/')[1];

  if (!videoId) {
    return children;
  }

  return (
    <EmbedWrapper>
      <iframe
        id="vimeo-video-player"
        width="100%"
        height="315"
        src={`https://player.vimeo.com/video/${videoId}`}
        allowFullScreen
      />
    </EmbedWrapper>
  );
};
