import useVisualViewport from '@/src/hooks/useVisualViewport';
import { AnimateChangeInHeight } from '@/src/modules/ui/components/animations/AnimateChangeInHeight';
import { cssRadixPopoverContentOverride } from '@/src/modules/ui/components/Drawer/cssRadixPopoverOverride';
import { Drawer } from '@/src/modules/ui/components/Drawer/Drawer';
import { Content as ContentPrimitive } from '@radix-ui/react-dropdown-menu';
import React from 'react';
import styled from 'styled-components';

const DropdownContentMobileBase = styled(ContentPrimitive)`
  font-size: 0.9375rem;
  ${cssRadixPopoverContentOverride}
`;

/**
 * We're rendering the Content primitive (DropdownContentMobileBase) to override the defaults
 * The visible part (background and further) is rendered in ContentInner. This is due to constraint of the Content primitive
 * which doesn't accept style prop which is necessary for the panning effect
 */
export const ContentMobile = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    onOpenChange: (value: boolean) => void;
    onEscapeKeyDown?: (event: KeyboardEvent) => void;
  }
>(function DropdownContentMobile({ children, onOpenChange, onEscapeKeyDown }, ref) {
  const viewport = useVisualViewport();

  return (
    <DropdownContentMobileBase onEscapeKeyDown={onEscapeKeyDown} ref={ref}>
      <Drawer
        onOpenChange={onOpenChange}
        style={
          viewport?.heightCrop
            ? {
                height: viewport.windowActualHeight,
                minHeight: viewport.windowActualHeight,
              }
            : undefined
        }
      >
        {children}
        <AnimateChangeInHeight duration={0.1}>
          {viewport?.heightCrop ? (
            <div style={{ height: viewport.heightCrop, flexShrink: 0 }}></div>
          ) : null}
        </AnimateChangeInHeight>
      </Drawer>
    </DropdownContentMobileBase>
  );
});
