import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const LockIcon: FC<IconProps> = ({ color = 'currentColor', size = 18, className, ...props }) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <path
        d="M6.79383 5.9375V7.25H11.2056V5.9375C11.2056 4.72891 10.2185 3.75 8.99971 3.75C7.78096 3.75 6.79383 4.72891 6.79383 5.9375ZM5.02912 7.25V5.9375C5.02912 3.76367 6.80762 2 8.99971 2C11.1918 2 12.9703 3.76367 12.9703 5.9375V7.25H13.4115C14.3848 7.25 15.1762 8.03477 15.1762 9V14.25C15.1762 15.2152 14.3848 16 13.4115 16H4.58795C3.6146 16 2.82324 15.2152 2.82324 14.25V9C2.82324 8.03477 3.6146 7.25 4.58795 7.25H5.02912Z"
        fill={color}
      />
    </svg>
  );
};

export default LockIcon;
