import { Query } from '@tanstack/react-query';
import { tagQueryKeys } from './tagsQueryKeys';

export const tagsQueryPredicates = {
  tag: (query: Query, tagId: string) => {
    const tagKey = tagQueryKeys.tag(tagId);
    return query.queryKey[0] === tagKey[0] && query.queryKey[1] === tagKey[1];
  },
  all: (query: Query) => {
    return query.queryKey[0] === tagQueryKeys.all()[0];
  },
  resources: (query: Query, resourceIds: string[]) => {
    const resourceKey = tagQueryKeys.resources(resourceIds);
    if (query.queryKey[0] !== resourceKey[0]) {
      return false;
    }

    const queryResourceIds = query.queryKey[1];
    // make sure queryResourceIds is an array
    if (!Array.isArray(queryResourceIds)) {
      return false;
    }

    // includes one or more resourceIds
    return resourceIds.some((resourceId) => queryResourceIds.includes(resourceId));
  },
  resourcesAll: (query: Query) => {
    return query.queryKey[0] === tagQueryKeys.resources()[0];
  },
};
