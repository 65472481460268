import { mediaMobile } from '@/src/modules/ui/styled-utils';
import {
  CssBackgroundFromBorderColorProp,
  getCssBackgroundFromBorderColor,
} from '@/src/modules/ui/theme/cssBackgroundBorderColor';
import styled, { css } from 'styled-components';

const variants = {
  thin: css`
    height: 0.5px;
  `,
  default: css`
    height: 1px;
  `,
};

interface DividerProps extends CssBackgroundFromBorderColorProp {
  variant?: keyof typeof variants;
}

const Divider = styled.hr<DividerProps>`
  width: 100%;
  height: ${({ variant = 'default' }) => variants[variant]};
  flex-shrink: 0;
  ${getCssBackgroundFromBorderColor('primary')}

  ${mediaMobile} {
    max-width: 316px;
  }
`;

export default Divider;
