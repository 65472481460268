import { useDebouncedCallback } from '@/src/hooks/useDebouncedCallback';
import React, { RefObject } from 'react';
import { useClientLayoutEffect } from '../../../../hooks/useClientLayoutEffect';

export type IntersectionObserverArgs = {
  defaultInView?: boolean;
  root?: RefObject<HTMLElement>;
  rootEl?: HTMLElement | null;
  target: RefObject<HTMLElement> | HTMLElement | null;
  onIntersect?: VoidFunction | undefined;
  /**
   * debounce delay in ms
   */
  debounceDelay?: number;
  threshold?: number;
  rootMargin?: string;
  enabled?: boolean;

  // Allows providing a key to force recalculation of the observer
  recalculateKey?: number | string;
};

export const useIntersectionObserver = ({
  defaultInView = false,
  root,
  target,
  onIntersect,
  threshold = 0.001,
  rootMargin = '400px',
  enabled = true,
  debounceDelay = 100,
  rootEl,
  recalculateKey,
}: IntersectionObserverArgs) => {
  const onIntersectDebounced = useDebouncedCallback(onIntersect, debounceDelay);
  const [isInView, setIsInView] = React.useState(defaultInView);

  useClientLayoutEffect(() => {
    const el = target instanceof HTMLElement ? target : target?.current;
    if (!el || !enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((entry) => {
          if (entry.isIntersecting || entry.intersectionRatio > 0) {
            onIntersectDebounced?.();
            setIsInView(true);
          } else {
            setIsInView(false);
          }
        }),
      {
        root: (root && root.current) || rootEl || null,
        rootMargin,
        threshold,
      },
    );

    observer.observe(el);

    return () => {
      observer.disconnect();
    };
  }, [target, root, enabled, onIntersect, rootMargin, threshold, rootEl, recalculateKey]);

  return isInView;
};
