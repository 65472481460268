import { useMemo } from 'react';
import { usePDFContent } from './PDF';
import RowPage from './RowPage';

const RowPageList: React.FC<{
  width: number;
  root: HTMLElement;
}> = ({ root, width }) => {
  const { pdf, isReady } = usePDFContent();
  const pdfPages = useMemo(() => Array.from(Array(pdf?.numPages ?? 0).keys()), [pdf]);

  if (!isReady) return null;

  return (
    <>
      {pdfPages.map((index) => (
        <RowPage key={index + 1} pageNumber={index + 1} width={width} root={root} />
      ))}
    </>
  );
};

export default RowPageList;
