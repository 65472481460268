import { TiptapDataValue } from '@/src/components/Tiptap/types';
import { useOptimisticallyUpdateQueriesWithPredicate } from '@/src/lib/react-query/utilities';
import { resourceDetailsQueryPredicates } from '@/src/modules/resource-detail/queries/resourceDetailsQueryPredicates';
import { mutateNotepadContent } from '@/src/modules/resource-detail/utils/mutateNotepadContent';
import { useQueryCacheResourceHelpers } from '@/src/modules/resources/utils/useQueryCacheResourceHelpers';
import { useWoody } from '@/src/services/woody/woody';
import { NotepadFdoc } from '@/src/types/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as Y from 'yjs';

type MutationVariables = {
  resource: NotepadFdoc;
  update: Uint8Array;
  optimisticContent?: TiptapDataValue;
};

export const useOptimisticNotepadStateUpdate = () => {
  const queryClient = useQueryClient();
  const { updateCachedResource } = useQueryCacheResourceHelpers();
  const optimisticallyUpdateQueries = useOptimisticallyUpdateQueriesWithPredicate();

  return ({ resource, update, optimisticContent }: MutationVariables) => {
    queryClient.cancelQueries({
      predicate: (q) => resourceDetailsQueryPredicates.notepadStateById(q, resource.id),
    });

    const optimisticallyUpdateState = optimisticallyUpdateQueries<Uint8Array>(
      (q) => resourceDetailsQueryPredicates.notepadStateById(q, resource.id),
      (data) => {
        return data ? Y.mergeUpdates([data, update]) : update;
      },
    );

    return {
      optimisticallyUpdateState,
      optimisticallyUpdateResource: optimisticContent
        ? updateCachedResource(mutateNotepadContent(resource, optimisticContent))
        : undefined,
    };
  };
};

export const useMutationNotepadStateUpdate = () => {
  const { client } = useWoody();
  const optimisticNotepadStateUpdate = useOptimisticNotepadStateUpdate();

  const mutation = useMutation({
    mutationFn: async ({ resource, update }: MutationVariables) => {
      await client.v2(
        {
          endpoint: '/v2/notepads/{resourceId}/state',
          params: {
            resourceId: resource.id,
          },
        },
        {
          query: {
            accessToken: undefined,
          },
          method: 'patch',
          body: new Blob([update], { type: 'application/octet-stream' }),
        },
      );
    },
    onMutate: (vars) => {
      return optimisticNotepadStateUpdate(vars);
    },
    onError: (_, _v, context) => {
      context?.optimisticallyUpdateResource?.resetCacheToPreOptimisticState();
      context?.optimisticallyUpdateState?.resetQueriesData();
      context?.optimisticallyUpdateResource?.invalidateQueries();
      context?.optimisticallyUpdateState?.invalidateQueries();
    },
  });

  return mutation;
};
