import { useChabot } from '@/src/hooks/chatbot';
import { AssistantContextSubType } from '@/src/modules/assistant/types';
import { isFileBasedContext } from '@/src/modules/assistant/utils/context';
import { useQueryResource } from '@/src/modules/resources/queries/useQueryResource';
import { getResourceGenericName } from '@/src/modules/resources/utils/getResourceGenericName';
import * as Conversation from '@/src/modules/ui/components/Conversation';
import { P } from '@/src/modules/ui/components/Typography';
import { useMemo } from 'react';

const AssistantQuickActions: React.FC = () => {
  const { messages, activeContext, streamingAnswer, summarizeActiveContext } = useChabot();

  const { subType, value } = activeContext;

  const { resource, isLoading } = useQueryResource(Array.isArray(value) ? value[0] : undefined, {
    placeholderData: undefined,
    enabled: subType === AssistantContextSubType.File,
  });

  const summaryDescription = useMemo(() => {
    if (subType === AssistantContextSubType.Selection) return 'current selection';
    if (resource) return `this ${getResourceGenericName(resource)}'s content`;
    return "this item's content";
  }, [resource, subType]);

  const handleSummarize = () => {
    summarizeActiveContext(
      `Give me a summary for ${subType === AssistantContextSubType.Selection ? 'the' : ''} ${summaryDescription}.`,
    );
  };

  if (messages.length > 0 || !isFileBasedContext(activeContext) || isLoading || streamingAnswer) {
    return null;
  }

  return (
    activeContext && (
      <Conversation.QuickActions>
        <Conversation.QuickAction onClick={handleSummarize}>
          <P size="xs" weight={700}>
            Summarize
          </P>
          <P size="xs">{summaryDescription}</P>
        </Conversation.QuickAction>
      </Conversation.QuickActions>
    )
  );
};

export { AssistantQuickActions };
