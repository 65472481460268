import LinkExternalIcon from '@/public/images/icons/LinkExternal.svg';
import { ResourcePreviewContextMenu } from '@/src/components/FdocItem/ResourcePreviewContextMenu';
import { isInMobile } from '@/src/hooks/mobile';
import { useResponsive } from '@/src/hooks/responsive';
import { isInDesktop } from '@/src/hooks/todesktop';
import useDraggable from '@/src/hooks/useDraggable';
import GlobeIcon from '@/src/icons/GlobeIcon';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useQueryResourceFileLikeDetails } from '@/src/modules/resource-detail/queries/useQueryResourceFileLikeDetails';
import { useDroppableAsTargetToResourceMove } from '@/src/modules/resources/hooks/useDroppableAsTargetToResourceMove';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { isStoredFileFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { Space } from '@/src/modules/spaces/spaces.types';
import { useMutationResourcesTagAssign } from '@/src/modules/tags/mutations/useMutationResourcesTagAssign';
import { useQueryResourceTags } from '@/src/modules/tags/queries/useQueryResourceTags';
import { useIntersectionObserver } from '@/src/modules/ui/components/IntersectionObserver/useIntersectionObserver';
import useMobileSelectionStore from '@/src/store/mobileSelection';
import { Fdoc, getFdocURL } from '@/src/types/api';
import { Size } from '@/src/types/global';
import DashboardButton from '@/src/ui/DashboardButton/DashboardButton';
import Tooltip from '@/src/ui/Tooltip';
import { isEditableElement } from '@/src/utils/elements';
import { SPOTIFY_URL_REGEX } from '@/src/utils/url-regex';
import { FabricResourceTypes, PrivateTag } from '@fabric/woody-client';
import { platform } from '@todesktop/client-core';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, {
  CSSProperties,
  SetStateAction,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { shallow } from 'zustand/shallow';
import { useSharedHover } from '../../hooks/sharedHover';
import ShareModal from '../../modules/ui/components/ShareModal';
import { OptimisticDraft } from '../../types/draftable';
import EditTagsModal from '../Tags/EditTagsModal';
import { OfViewMode } from '../ViewModeSwitcher/ViewModeSwitcher';
import styles from './FdocItem.module.scss';
import FdocItemComments from './FdocItemComments/FdocItemComments';
import FdocItemContent from './FdocItemContent/FdocItemContent';
import FdocItemContentSpotify from './FdocItemContent/FdocItemContentSpotify';
import FdocItemHeader from './FdocItemHeader/FdocItemHeader';
import FdocLoadState from './FdocLoadState/FdocLoadState';

type FdocItemContext = {
  calculatedSize: Size | null;
  isMobileCard: boolean;
  isHovered: boolean;

  customCardFailed: boolean;
  setCustomCardFailed: React.Dispatch<React.SetStateAction<boolean>>;
};

const FdocItemContext = React.createContext<FdocItemContext>({
  calculatedSize: null,
  isMobileCard: false,
  isHovered: false,

  customCardFailed: false,
  setCustomCardFailed: (_state: SetStateAction<boolean>) => {},
});

export const useFdocItemContext = () => {
  return React.useContext(FdocItemContext);
};

export type FdocItemProps = {
  fdoc?: OptimisticDraft<Fdoc>;
  colorLabels?: ApiColorLabel[];
  list?: Space;
  index?: number;
  noBoxShadow?: boolean;
  keyPrefix?: string;

  handleOnRemove?: (fdoc: Fdoc) => void;
  handleOnClick?: (fdoc: Fdoc) => void;

  viewMode?: OfViewMode;
  selectionMode?: boolean;
  canMultiSelect?: boolean;
  selected?: boolean;
  showOwner?: boolean;
  disableHover?: boolean;
  canDelete?: boolean; // represents if the user can delete or remove the note
  canMove?: boolean; // represents if the user can move the note to another list
  hideComments?: boolean;
  constrainedImages?: number | null; // if null then no constraint, if X then constrain image height to X
  linkToWebpage?: boolean; // if it's a webnote allow linking to the webpage
  disabled?: boolean;
  isFolderClickOverriden?: boolean;
  disableDrag?: boolean;
  isSidepanel?: boolean;

  isNoteStack?: boolean;

  disableContextMenu?: boolean;

  slimSize?: boolean;

  size?: Size;

  isInfiniteTriggerEntity?: boolean;
  showDate?: boolean;
  renderOnlyIfVisible?: boolean;
  renderOnlyIfVisibleOffset?: number;
  renderOnlyIfVisibleRoot?: HTMLElement | null;

  zoomLevel?: number;

  style?: React.CSSProperties;

  isMiniature?: boolean;
  isFullscreen?: boolean;

  disableFramerMotion?: boolean;
};

const FdocItem: React.FC<FdocItemProps> = (props) => {
  const {
    fdoc,
    keyPrefix = 'fdoc-item',
    handleOnClick = () => {},
    viewMode = 'List',
    selectionMode,
    canMultiSelect = false,
    noBoxShadow = false,
    selected,
    disableHover = false,
    canDelete = false,
    canMove = true,
    isInfiniteTriggerEntity = false,
    showDate = true,
    hideComments = false,
    constrainedImages = null,
    linkToWebpage = true,
    disabled = false,
    slimSize = false,
    renderOnlyIfVisible = false,
    renderOnlyIfVisibleOffset = 1000,
    renderOnlyIfVisibleRoot = null,
    disableContextMenu = false,
    isFolderClickOverriden = false,
    disableDrag = false,
    isNoteStack,
    index = 0,
    isSidepanel,
    isFullscreen,
    disableFramerMotion,
    zoomLevel = 1,

    size: parentSize,

    colorLabels = [],
    list,

    style,

    isMiniature,
  } = props;

  const mainRef = React.useRef<HTMLDivElement | null>(null);

  const { isDraggingItemsOver } = useDroppableAsTargetToResourceMove(mainRef, fdoc, {
    enabled: Boolean(fdoc?.listData?.type !== 'INTEGRATION'),
    action: 'drop-into-folder',
  });

  const isProcessing = isResourceStateProcessing(fdoc?.stateProcessing);
  const isDraft = Boolean(fdoc?.isDraft);
  const isLoadingState = isProcessing || isDraft;

  const { setHover: _setHover, getHover: _getHover, removeHover } = useSharedHover();
  const [isHovered, setIsHovered] = useState(false);

  const [customCardFailed, setCustomCardFailed] = useState(false);
  // const { isMobile } = useResponsive();
  const [isHoverHeaderVisible, setIsHoverHeaderVisible] = useState(false);
  const { mobileSelectModeIsActive } = useMobileSelectionStore(
    (state) => ({
      mobileSelectModeIsActive: state.mobileSelectModeIsActive,
    }),
    shallow,
  );

  const isIntegrationItem = !!fdoc?.listData?.integration;

  const { dragState, isDragging, onMouseDown, draggingStyles, shouldRender, isDragParent } =
    useDraggable({
      ref: mainRef.current,
      disabled: viewMode === 'List' || disableDrag || isInMobile() || isIntegrationItem || isDraft,
      objectId: fdoc?.id,
      stopPropagation: viewMode !== 'Sort',
      scale: 0.3,
      objectParentId: fdoc?.parentResourceId,
    });

  const inView = useIntersectionObserver({
    target: mainRef,
    enabled: renderOnlyIfVisible,
    rootEl: renderOnlyIfVisibleRoot,
    rootMargin: `${renderOnlyIfVisibleOffset}px`,
    recalculateKey: `${index}-${isDragging}`, // we force the intersection observer to recalculate when the index changes or dragging, because the element moves position
  });

  const router = useRouter();

  const handleOnClickWrapper = useCallback(
    (fdoc: OptimisticDraft<Fdoc>) => {
      if (fdoc.isDraft) return;
      if (fdoc.type === 'folder' && !isFolderClickOverriden && !mobileSelectModeIsActive) {
        router.push(`/folders/${fdoc.id}`);
        return;
      }
      handleOnClick(fdoc);
    },
    [handleOnClick, isFolderClickOverriden, router, mobileSelectModeIsActive],
  );

  // Obfuscating the shared hover state because it's not always available
  const setHover = useCallback(
    (id: string, value: boolean) => {
      _setHover(id, value);
      setIsHovered(value);
    },
    [_setHover],
  );

  useEffect(() => {
    if (!isDragging || !fdoc) return;

    setHover(fdoc.id, false);
  }, [fdoc, isDragging, setHover]);

  const getHover = useCallback(
    (id: string) => {
      return _getHover(id) || isHovered;
    },
    [_getHover, isHovered],
  );

  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);

  const { data: fileLikeDetails } = useQueryResourceFileLikeDetails(
    isStoredFileFdoc(fdoc) ? fdoc : undefined,
  );

  // on hover, preload all images (if type stored_file and it's an image)
  const preloadImages = useCallback(() => {
    if (
      !fileLikeDetails ||
      fileLikeDetails.type !== FabricResourceTypes.STORED_FILE ||
      !fileLikeDetails.contentType?.startsWith('image/')
    )
      return;

    const images = [
      fileLikeDetails.url,
      fileLikeDetails.thumbnail?.sm,
      fileLikeDetails.thumbnail?.md,
      fileLikeDetails.thumbnail?.lg,
    ];
    images.forEach((image) => {
      if (!image) return;
      const img = new Image();
      img.src = image;
    });
  }, [fileLikeDetails]);

  const [tagEditorModalOpen, setTagEditorModalOpen] = useState(false);
  const queryResourceTags = useQueryResourceTags(fdoc?.id, {
    enabled: tagEditorModalOpen,
  });

  const { mutate: mutateResourcesTagAssign } = useMutationResourcesTagAssign();

  const onSelectTag = useCallback(
    (tag: PrivateTag, selected: boolean) => {
      if (!fdoc) return;

      mutateResourcesTagAssign({
        tag,
        resourceIds: [fdoc.id],
        operation: selected ? 'assign' : 'unassign',
      });
    },
    [fdoc, mutateResourcesTagAssign],
  );

  // In useCallback to avoid unecessary rerenders on children
  const openFdocInExternalPage = useCallback((fdoc: Fdoc) => {
    const url = getFdocURL(fdoc);
    if (!url) return;

    if (isInDesktop()) {
      platform.os.openURL(url);
      return;
    }

    window.open(url, '_blank');
  }, []);

  const handleClickExternal = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!fdoc) return;
    openFdocInExternalPage(fdoc);
  };

  useEffect(() => {
    const onDocumentContextMenu = () => {
      setTouchStarted(false);
    };

    document.addEventListener('contextmenu', onDocumentContextMenu);
    return () => document.removeEventListener('contextmenu', onDocumentContextMenu);
  }, []);

  const onHover = () => {
    if (!fdoc) return;
    if (fdoc.isDraft) {
      removeHover();
      return;
    }
    setHover(fdoc.id, true);
    preloadImages();
  };

  const [touchStarted, setTouchStarted] = useState(false);
  const size = parentSize;

  const isMobileCard = useMemo(
    () => (size?.width ?? 200) < 200 && viewMode === 'Grid',
    [size?.width, viewMode],
  );

  useEffect(() => {
    if (!fdoc || !getHover(fdoc.id) || !fdoc.isWebnote) return;

    // when pressing enter and it's a webnote and the fdoc is hovered, open the webnote in the web
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key !== 'Enter') return;

      openFdocInExternalPage(fdoc);
      e.preventDefault();
      e.stopPropagation();
    };

    document.addEventListener('keydown', onKeyDown, true);
    return () => document.removeEventListener('keydown', onKeyDown, true);
  }, [fdoc, getHover, openFdocInExternalPage, viewMode]);

  // same as above but when clicking space and the item is hovered we will
  // do the same action as clicking the item, an alternative to left click
  useEffect(() => {
    if (!fdoc || !getHover(fdoc.id) || fdoc.isDraft || isEditableElement(document.activeElement))
      return;

    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key !== ' ') return;

      e.preventDefault();
      e.stopPropagation();
      handleOnClickWrapper(fdoc);
    };

    document.addEventListener('keydown', onKeyDown, true);
    return () => document.removeEventListener('keydown', onKeyDown, true);
  }, [fdoc, getHover, handleOnClickWrapper, viewMode]);

  const [showingShareModal, setShowingShareModal] = useState(false);

  const [calculatedSize, setCalculatedSize] = useState<Size | null>(null);

  useEffect(() => {
    if (!mainRef.current || viewMode === 'List') return;

    const getCalculatedSize = () => {
      if (!mainRef.current) return;

      const rect = mainRef.current.getBoundingClientRect();
      setCalculatedSize({ width: rect.width / zoomLevel, height: rect.height / zoomLevel });
    };

    getCalculatedSize();

    const observer = new ResizeObserver(getCalculatedSize);
    observer.observe(mainRef.current);

    return () => {
      observer.disconnect();
    };
  }, [mainRef, viewMode, zoomLevel]);

  useEffect(() => {
    if (viewMode !== 'List') return;

    setCalculatedSize(size ?? null);
  }, [size, viewMode]);

  const contextValue = useMemo(
    () => ({ calculatedSize, isMobileCard, isHovered, customCardFailed, setCustomCardFailed }),
    [calculatedSize, customCardFailed, isHovered, isMobileCard],
  );

  const isCustomSpotifyCard = useMemo(
    () =>
      fdoc?.type === 'page' && fdoc.data.pageUrl.match(SPOTIFY_URL_REGEX) && viewMode !== 'List',
    [fdoc, viewMode],
  );

  const { isDesktop, isMobileView } = useResponsive();

  useEffect(
    () => setIsHoverHeaderVisible(false),
    [isDragging, draggingStyles, setIsHoverHeaderVisible],
  );

  const draggingStylesMerged: CSSProperties = React.useMemo(() => {
    if (!isDragging) {
      return {};
    }

    if (!isDragParent) {
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        // width: size?.width, // broken when zoomed out, 100% works
        // height: size?.height, // broken when zoomed out, 100% works
        width: '100%',
        height: '100%',
        ...draggingStyles,
      };
    }

    if (isDragParent && dragState) {
      return {
        width: dragState.width,
        height: dragState.height,
      };
    }

    return {};
  }, [isDragging, draggingStyles, dragState, isDragParent]);

  const element = (
    <FdocItemContext.Provider value={contextValue} key={`${keyPrefix}-context-${fdoc?.id}`}>
      {showingShareModal && fdoc && (
        <ShareModal
          key={`${keyPrefix}-share-modal-${fdoc.id}`}
          resourceId={fdoc.id}
          onClose={() => setShowingShareModal(false)}
          sharingObject="item"
        />
      )}

      {fdoc && (
        <EditTagsModal
          key={`${keyPrefix}-edit-tags-modal-${fdoc.id}`}
          open={tagEditorModalOpen}
          onOpenChange={setTagEditorModalOpen}
          selectedTags={queryResourceTags.data}
          onSelect={onSelectTag}
        />
      )}

      <ResourcePreviewContextMenu
        contextDisabled={isDragging || disableContextMenu}
        onItemClickAction={() => fdoc && handleOnClickWrapper(fdoc)}
        onGoToSourceClick={() => fdoc && openFdocInExternalPage(fdoc)}
        selectionMode={selectionMode}
        setShowingShareModal={setShowingShareModal}
        selected={selected}
        hideDelete={fdoc?.isDraft || !canDelete}
        hideMove={fdoc?.isDraft || !canMove}
        sourceUrl={fdoc?.isWebnote ? fdoc.data.pageUrl : undefined}
        colorLabels={colorLabels}
        openTagModal={() => setTagEditorModalOpen(true)}
        list={list}
        fdoc={fdoc}
        style={{
          opacity: touchStarted ? 0 : undefined,
        }}
      >
        <motion.div
          // https://github.com/vercel/next.js/issues/55642
          // It seems having a key prop in front of a spread operator causes a bug somewhere in react, so I moved the key prop before the spread operator
          key={`${keyPrefix}-main-${fdoc?.id}`}
          {...(disableFramerMotion
            ? {}
            : {
                initial: { opacity: index < 10 && isSidepanel ? 0 : 1 },
                animate: { opacity: 1 },
                transition: { delay: index / 7 },
              })}
          className={clsx(
            styles.item,
            noBoxShadow && styles.no_box_shadow,
            viewMode === 'Grid' || viewMode === 'Sort'
              ? styles.item__view_mode__grid
              : viewMode === 'List' && styles.item__view_mode__list,
            viewMode === 'Sort' && styles.item__view_mode__sort,
            isNoteStack && fdoc?.type !== 'folder' && styles.is__note__stack,
            (((selectionMode || canMultiSelect) && selected && !isDragging) ||
              isDraggingItemsOver) &&
              styles.selected,
            isDraggingItemsOver && styles.draggingItemsOver,
            selected && styles.in_collection,
            (disableHover || fdoc?.isDraft) && styles.disableHover,
            !fdoc && styles.item__loading,
            fdoc && getHover(fdoc.id) && styles['item--hover'],
            isSidepanel && !isFullscreen && styles.is_sidepanel_non_fullscreen,
            fdoc?.type === 'notepad' && styles.item__type__notepad,
            viewMode === 'Sort' && dragState && isDragging && styles.item__dragging,
            disabled && styles.disabled,
            slimSize && styles.slimSize,
            fdoc?.type === 'folder' && styles.folder,
          )}
          whileTap="whileTap"
          variants={
            isMobileView || isInMobile()
              ? {
                  whileTap: {
                    transition: { duration: 0.01 },
                    scale: 1.04,
                  },
                }
              : {}
          }
          data-hovered={fdoc && getHover(fdoc.id)}
          onClick={() => fdoc && handleOnClickWrapper(fdoc)}
          ref={mainRef}
          data-drag-over={isDraggingItemsOver}
          data-droppable={Boolean(fdoc?.type === 'folder' && !isDragging)}
          onMouseEnter={onHover}
          onMouseLeave={() => fdoc && setHover(fdoc.id, false)}
          data-testid={fdoc?.type + '-fdoc-item'}
          data-processing={isProcessing ? 'true' : undefined}
          data-draft={fdoc?.isDraft ? 'true' : undefined}
          data-loading={isLoadingState ? 'true' : undefined}
          data-selectable-id={
            fdoc?.isDraft || fdoc?.listData?.integration || isMiniature ? undefined : fdoc?.id
          }
          data-resource-id={fdoc?.id}
          data-is-integration-item={isIntegrationItem ? 'true' : undefined}
          data-category={fdoc?.type}
          data-is-infinite-trigger-entity={isInfiniteTriggerEntity ? 'true' : undefined}
          data-view-mode={viewMode}
          data-in-view={inView ? 'true' : undefined}
          onMouseDown={onMouseDown}
          style={{
            ...(isMiniature && { width: '100%', height: '100%' }),

            ...draggingStylesMerged,

            aspectRatio: isMobileCard ? '1 / 1' : undefined,
            ...style,
            ...(viewMode === 'List' && { overflow: 'visible' }),
          }}
        >
          {/* DEBUG INFO, used when trying to debug fdoc item issues change at will.
        <span
          style={{
            position: 'absolute',
            top: 5,
            left: 5,
            opacity: 0.8,
            zIndex: 10,
            background: 'rgba(255,255,255, 0.5)',
            backdropFilter: 'blur(9px)',
            padding: 2,
          }}
        >
          <P>{fdoc?.id}</P>
          <P>{fdoc?.createdAt}</P>
          <P>{fdoc?.modifiedAt}</P>
        </span>*/}

          {isLoadingState && <FdocLoadState isProcessing={isProcessing} />}

          {fdoc &&
          ((renderOnlyIfVisible && inView) || !renderOnlyIfVisible || (isDragging && dragState)) ? (
            <>
              {isCustomSpotifyCard && !customCardFailed ? (
                <FdocItemContentSpotify
                  fdoc={fdoc}
                  viewMode={viewMode}
                  isMobileCard={isMobileCard}
                  size={size}
                  list={list}
                />
              ) : (
                <div
                  className={clsx(styles.item__content_wrapper)}
                  style={{ position: 'relative', overflow: 'visible' }}
                  ref={setWrapperRef}
                >
                  {viewMode === 'List' && fdoc.label && colorLabels.length > 0 && (
                    <div
                      className={styles.labelBubble_list_mode}
                      style={{ position: 'absolute', left: !isDesktop() ? '-40px' : '-50px' }}
                    >
                      <span
                        className={styles.labelBubble}
                        style={{
                          backgroundColor: colorLabels.find((label) => label.id === fdoc.label)
                            ?.hexColor,
                        }}
                      />
                    </div>
                  )}
                  <FdocItemHeader
                    size={size}
                    fdoc={fdoc}
                    viewMode={viewMode}
                    openFdocInExternalPage={openFdocInExternalPage}
                    disableHover={disableHover || Boolean(isDragging)}
                    linkToWebpage={linkToWebpage}
                    wrapperRef={wrapperRef}
                    isHoverHeaderVisible={isHoverHeaderVisible}
                    setIsHoverHeaderVisible={setIsHoverHeaderVisible}
                  />

                  {(viewMode !== 'List' || fdoc?.type === 'notepad') && (
                    <FdocItemContent
                      viewMode={viewMode}
                      fdoc={fdoc}
                      constrainedImages={constrainedImages}
                      size={size}
                      isNoteStack={isNoteStack}
                    />
                  )}

                  {viewMode === 'List' && (
                    <div className={styles.label_comments_and_date_wrapper}>
                      {fdoc.isDirectShared && (
                        <Tooltip label="Shared with public link">
                          <div className={styles.direct_share}>
                            <GlobeIcon />
                          </div>
                        </Tooltip>
                      )}
                      {!hideComments && fdoc.commentCount !== null && fdoc.commentCount > 0 ? (
                        <FdocItemComments viewMode={viewMode} fdoc={fdoc} list={list} />
                      ) : null}
                      {showDate && !slimSize && (
                        <div className={styles.item__created_at}>
                          {new Date(fdoc.createdAt).toLocaleDateString('en-GB', {
                            day: 'numeric',
                            month: 'numeric',
                            year: 'numeric',
                          })}
                        </div>
                      )}
                    </div>
                  )}

                  {!hideComments &&
                  fdoc.commentCount !== null &&
                  fdoc.commentCount > 0 &&
                  viewMode !== 'List' ? (
                    <FdocItemComments viewMode={viewMode} fdoc={fdoc} list={list} />
                  ) : null}

                  {viewMode === 'List' &&
                    (fdoc.isWebnote || (fdoc.type === 'stored_file' && fdoc.originUrl)) &&
                    !fdoc.isDraft && (
                      <DashboardButton
                        color="black"
                        className={styles.open_external}
                        onClick={handleClickExternal}
                      >
                        <LinkExternalIcon />
                      </DashboardButton>
                    )}

                  {viewMode === 'Grid' && fdoc.label && colorLabels.length > 0 && (
                    <span
                      className={styles.labelBubble}
                      style={{
                        backgroundColor: colorLabels.find((label) => label.id === fdoc.label)
                          ?.hexColor,
                      }}
                    />
                  )}

                  {fdoc.isDirectShared && viewMode === 'Grid' && !isDraft && !isProcessing && (
                    <Tooltip label="Shared with public link">
                      <div className={styles.direct_share_grid}>
                        <GlobeIcon />
                      </div>
                    </Tooltip>
                  )}
                </div>
              )}
            </>
          ) : (
            <div />
          )}
        </motion.div>
      </ResourcePreviewContextMenu>
    </FdocItemContext.Provider>
  );

  const targetPortal =
    !isDragging || isDragParent
      ? document.getElementById('drag-and-drop-portal')
      : (document.querySelector('[data-drag-parent="true"]') as HTMLElement | null);

  const fdocElement = () => {
    if (!isDragging) return element;

    if (isDragging && !targetPortal) return null;

    return targetPortal
      ? createPortal(
          isDragParent ? (
            <div
              data-drag-parent={isDragParent}
              className={styles.item__drag__parent}
              style={{
                ...(dragState && {
                  ...draggingStyles,
                  ...dragState,
                  width: dragState.width,
                  height: dragState.height,
                  top: dragState.y,
                  left: dragState.x,
                  // pointerEvents: 'none',
                }),
              }}
            >
              {element}
            </div>
          ) : (
            element
          ),
          targetPortal,
        )
      : element;
  };

  return (
    <>
      {isDragging && viewMode !== 'Sort' && (
        <>
          <FdocItem
            {...props}
            style={{ opacity: 0.2, ...style }}
            disableDrag={true}
            disableHover
            disableFramerMotion
            size={size}
            keyPrefix="dragging"
          />
        </>
      )}
      {(!isDragging || shouldRender) && fdocElement()}
    </>
  );
};

export default memo(FdocItem, shallow);
