import { useResponsive } from '@/src/hooks/responsive';
import { ButtonIcon } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import { CSS_THEME_COLOR } from '@/src/modules/ui/theme/theme';
import { useColorScheme } from '@/src/modules/ui/theme/useColorScheme';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { ChevronRightIcon } from '@radix-ui/react-icons';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const SidebarRoot = styled(motion.div)`
  min-width: 360px;
  max-width: 360px;
  width: 100%;
  height: 100%;

  flex-shrink: 0;
  background: rgba(${cssVar['color-bg-contrast-rgb']}, 0.8);
  backdrop-filter: blur(5px);

  border-radius: 20px;
`;

const SidebarHeader = styled(Flex).attrs((p) => {
  return {
    alignItems: 'center',
    flexGrow: 0,
    ...p,
  };
})`
  padding: 0.75rem;
  gap: 0.453rem;
  flex-shrink: 0;
  border-bottom: 1px solid rgba(${cssVar['color-bg-contrast-reverse-rgb']}, 0.1);
`;

const SidebarContent = styled(Flex).attrs((p) => {
  return {
    direction: 'column',
    ...p,
  };
})`
  border-radius: 20px;
  overflow: hidden;
  height: 100%;
`;

const SidebarCloseButton = styled(ButtonIcon).attrs((p) => {
  return {
    ...p,
    variant: 'bg-quinary',
    size: 'xs',
    roundness: 'lg',
    children: (
      <ChevronRightIcon
        style={{
          width: 24,
          height: 24,
        }}
      />
    ),
  };
})`
  height: 30px;
  width: 30px;
  background: rgba(${cssVar['color-bg-contrast-reverse-rgb']}, 0.04);
`;

const useSidebarScrollbarFadeColor = (): CSS_THEME_COLOR => {
  const { parsedColorScheme } = useColorScheme();
  const { isMobileView } = useResponsive();

  // The light mode uses the reverse because the scroll area is forced into dark mode.
  // Something to consider is a small refactor to the bottom drawer so it uses the forced theme for the expanded items
  // Also possibly reversed colors should be automated like -rgb and -hsl.
  const mobileViewFadeColor =
    parsedColorScheme === 'dark'
      ? 'bg-expanded-resource-extra-panel'
      : 'bg-expanded-resource-extra-panel-reverse';
  const desktopViewFadeColor =
    parsedColorScheme === 'dark' ? 'color-text-primary-reverse' : 'color-bg-secondary';

  return isMobileView ? mobileViewFadeColor : desktopViewFadeColor;
};

const Root = SidebarRoot;
const Header = SidebarHeader;
const CloseButton = SidebarCloseButton;
const Content = SidebarContent;

export {
  CloseButton,
  Content,
  Header,
  Root,
  SidebarCloseButton,
  SidebarContent,
  SidebarHeader,
  SidebarRoot,
  useSidebarScrollbarFadeColor,
};
