import { Fdoc } from '@/src/types/api';
import { EditorJSData } from '@fabric/woody-client';

export const mutateNotepadContent = (resource: Fdoc, content: EditorJSData): Fdoc => {
  if (resource.type !== 'notepad') {
    return resource;
  }

  return {
    ...resource,
    data: {
      ...resource.data,
      editorjs: content,
      createdAt: new Date(),
      modifiedAt: new Date(),
    },
  };
};
