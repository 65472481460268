import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { ExtraPanelTab } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/types';
import { shallow } from 'zustand/shallow';
import ExpandedFdoc from '../components/ExpandedFdoc/ExpandedFdoc';
import { pick } from '../lib/store';
import { useQueryResource } from '../modules/resources/queries/useQueryResource';
import useFdocSwitcher from '../store/fdocSwitcher';
import useUIStore, { setExpandedFdocId } from '../store/ui';
import { Fdoc } from '../types/api';
import { isWoodyError } from '../utils/error';
import { useAuthUser } from './auth';

const useExpandedFdocElement = () => {
  const router = useRouter();
  const { expandedFdocId, setExpandedFdocId, setExpandedFdocPath, isNewModalOpen } = useUIStore(
    (state) =>
      pick(state, ['expandedFdocId', 'setExpandedFdocId', 'setExpandedFdocPath', 'isNewModalOpen']),
    shallow,
  );

  const [initializedQuery, setInitializedQuery] = useState<string | null>(null);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      // check for the expandedFdocId query param, if it's true we clear it out and set the expandedFdocId
      const { expandedFdocId: queryId } = router.query;

      if (
        !router.isReady ||
        !queryId ||
        typeof queryId !== 'string' ||
        expandedFdocId === queryId ||
        initializedQuery === queryId
      )
        return;

      setExpandedFdocId(queryId);
      setInitializedQuery(queryId);
      setExpandedFdocPath(router.pathname);
    }, 10);

    return () => {
      window.clearTimeout(timeout);
    };
  }, [router, setExpandedFdocId, setExpandedFdocPath, expandedFdocId, initializedQuery]);

  const onCloseExpandedFdoc = useCallback(() => {
    const { expandedFdocId: _, ...routerQuery } = router.query;

    router
      .replace(
        {
          pathname: router.pathname,
          query: routerQuery,
        },
        undefined,
        { shallow: true },
      )
      .then(() => {
        setExpandedFdocId(null);
        setInitializedQuery(null);
      });
  }, [setExpandedFdocId, router]);

  const { resource, error } = useQueryResource(expandedFdocId ?? undefined, {
    placeholderData: undefined,
  });

  useEffect(() => {
    if (
      !error ||
      !isWoodyError(error) ||
      ![401, 403, 404].includes(error.status) ||
      !expandedFdocId
    )
      return;

    onCloseExpandedFdoc();
  }, [error, onCloseExpandedFdoc, expandedFdocId]);

  const lastFdocRef = useRef<Fdoc | null>(null);

  const { track } = useAnalytics();
  const user = useAuthUser();

  useEffect(() => {
    if (!resource || !user?.id || resource === lastFdocRef.current) return;

    track(AnalyticsEvents.ViewFdoc, {
      type: resource.type,
      action: 'expanded_fdoc',
    });

    lastFdocRef.current = resource;
  }, [resource, track, user, user?.id]);

  useEffect(() => {
    if (resource) return;
    lastFdocRef.current = null;
  }, [resource]);

  const { switcherFdocIds } = useFdocSwitcher(
    (s) => pick(s, ['setFdocId', 'switcherFdocIds', 'goToNextFdoc', 'goToPreviousFdoc']),
    shallow,
  );

  const nextFdocId = useMemo(() => {
    if (!resource) return null;

    // need to make sure it wraps around
    const index = switcherFdocIds.indexOf(resource.id);
    if (index === -1) return null;
    const nextIndex = index + 1;
    if (nextIndex >= switcherFdocIds.length) return switcherFdocIds[0];
    return switcherFdocIds[nextIndex];
  }, [resource, switcherFdocIds]);

  const previousFdocId = useMemo(() => {
    if (!resource) return null;

    // need to make sure it wraps around
    const index = switcherFdocIds.indexOf(resource.id);
    if (index === -1) return null;
    const prevIndex = index - 1;
    if (prevIndex < 0) return switcherFdocIds[switcherFdocIds.length - 1];
    return switcherFdocIds[prevIndex];
  }, [resource, switcherFdocIds]);

  useQueryResource(nextFdocId ?? undefined, {
    refetchInterval: 45000,
  });

  useQueryResource(previousFdocId ?? undefined, {
    refetchInterval: 45000,
  });

  const component = useMemo(() => {
    return expandedFdocId && !isNewModalOpen ? (
      <ExpandedFdoc
        fdoc={resource}
        onClose={onCloseExpandedFdoc}
        key={`expanded-fdoc-${expandedFdocId}`}
      />
    ) : null;
  }, [resource, expandedFdocId, isNewModalOpen, onCloseExpandedFdoc]);

  return {
    expandedFdoc: component,
    expandedFdocId,
  };
};

export const useShowExpandedFdoc = () => {
  const router = useRouter();

  return useCallback(
    (fdocId: string | null, tab?: ExtraPanelTab) => {
      router
        .replace(
          {
            pathname: router.pathname,
            query: {
              ...router.query,
              expandedFdocId: fdocId,
            },
          },
          undefined,
          { shallow: true },
        )
        .then(() => {
          setExpandedFdocId(fdocId, tab);
        });
    },
    [router],
  );
};

/**
 * @deprecated
 * use ExpandedResource, which currently supports mobile only, in the future
 * We're wrapping useExpandedFdocElement with a component so we can conditionally render it (hooks can't be conditionally rendered)
 */
export const ExpandedFdoc_OLD = () => {
  const { expandedFdoc } = useExpandedFdocElement();
  return expandedFdoc;
};
