import VideoIcon from '@/public/images/icons/Video.svg';
import CustomImage from '@/src/components/CustomImage';
import { useFdocItemContext } from '@/src/components/FdocItem/FdocItem';
import FileDisplay from '@/src/components/FileDisplay/FileDisplay';
import Skeleton from '@/src/components/Skeleton/Skeleton';
import Truncator from '@/src/components/Truncator/Truncator';
import { useQueryResourceFileLikeDetails } from '@/src/modules/resource-detail/queries/useQueryResourceFileLikeDetails';
import { Flex } from '@/src/modules/ui/components/Flex';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import { StoredFileFdoc } from '@/src/types/api';
import { useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { OptimisticDraft } from '../../../types/draftable';
import { OfViewMode } from '../../ViewModeSwitcher/ViewModeSwitcher';
import styles from './FdocItemContent.module.scss';

type FdocItemContentStoredFileProps = {
  fdoc: OptimisticDraft<StoredFileFdoc>;
  constrainedImages?: number | null;
  viewMode: OfViewMode;
  isMobileCard?: boolean;
  isNoteStack?: boolean;

  size?: {
    width: number;
    height: number;
  };
};

interface PDFPreviewContainerProps {
  isMobileCard?: boolean;
}

const PDFPreviewContainer = styled(Flex)
  .withConfig(preventForwardPropsConfig(['isMobileCard']))
  .attrs<PDFPreviewContainerProps>((p) => {
    return {
      direction: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: true,
      gap: p.isMobileCard ? 'element' : 'sectionsShort',
    };
  })`
  padding: 1.5rem 0.5rem;
  min-height: 0;
`;

const PDFPreviewLabel = styled.div`
  background: ${cssVar['color-bg-tertiary']};
  color: ${cssVar['color-text-quaternary']};
  padding: 6px 8px;
  flex-shrink: 0;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 8px;

  max-width: 90%;
  white-space: nowrap;
`;

interface PDFPreviewImageProps {
  viewMode: OfViewMode;
  isMobileCard?: boolean;
}

const PDFPreviewImage = styled.img.withConfig(
  preventForwardPropsConfig(['viewMode', 'isMobileCard']),
)<PDFPreviewImageProps>`
  max-width: 105px;
  object-fit: contain;
  border-radius: 8px;
  min-height: 0;

  ${({ viewMode }) =>
    viewMode === 'List' &&
    css`
      max-width: 39px;
      min-height: auto;
    `}

  ${({ isMobileCard }) =>
    isMobileCard &&
    css`
      // The margin top makes it so the image is at the same Y axis as other file display icons
      // Unsure why but the FileDisplay has some weird sizing issues which makes it center a bit higher on mobile cards
      // Which is actually useful because it makes it so the file name never goes below comments.
      margin-top: -10px;
      max-height: 60px;
    `}
`;

function FdocItemContentStoredFile({
  fdoc,
  constrainedImages,
  viewMode,
  isMobileCard,
  isNoteStack,
}: FdocItemContentStoredFileProps) {
  const { calculatedSize } = useFdocItemContext();
  const { data, isLoading } = useQueryResourceFileLikeDetails(fdoc);

  const [pdfLabelRef, setPDFLabelRef] = useState<HTMLDivElement | null>(null);
  const [imageError, setImageError] = useState(false);

  const fileDisplayScale = useMemo(() => {
    if (viewMode === 'List') return 0.15;
    if (isMobileCard) return 1;
    return 0.4;
  }, [viewMode, isMobileCard]);

  if (fdoc.type !== 'stored_file') return null;

  const isImage = fdoc.data.contentType?.startsWith('image/');
  const isVideo = fdoc.data.contentType?.startsWith('video/');
  const isPDF = fdoc.data.contentType?.startsWith('application/pdf');
  const canShowThumbnail = isImage || isVideo || isPDF;

  const hasImageThumbnail = isImage && (data?.url || fdoc.isDraft) && !imageError;
  const hasVideoThumbnail = isVideo && data?.thumbnail?.md && !imageError;
  const hasPDFThumbnail = isPDF && data?.thumbnail?.md;
  const hasThumbnail = hasImageThumbnail || hasVideoThumbnail || hasPDFThumbnail;

  const blurUrl =
    fdoc._meta?.placeholderImage ??
    fdoc.data.url ??
    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mM8VA8AAgkBQ6KtxDkAAAAASUVORK5CYII=';

  if ((!hasThumbnail || !fdoc.data.contentType) && (!isLoading || !canShowThumbnail)) {
    return (
      <div className={styles.stored_file_wrapper}>
        <div className={styles.stored_file}>
          <FileDisplay
            fileExtension={fdoc.data.extension}
            fileName={fdoc.data.title}
            scale={isMobileCard ? 1 : fileDisplayScale}
            hideFileName={viewMode === 'List'}
            compact={isMobileCard}
          />
        </div>
      </div>
    );
  }

  if (hasImageThumbnail || hasVideoThumbnail) {
    return (
      <div
        className={styles.item__content__image}
        data-testid="item-content-section"
        style={{
          flex: calculatedSize ? 'none' : undefined,
          height: isNoteStack ? '100%' : undefined,
        }}
      >
        {isVideo && (
          <div className={styles.item__content__image__video_icon}>
            <VideoIcon />
          </div>
        )}

        {(fdoc.data.url === 'load' || !data?.url || isLoading) && !fdoc.isDraft ? (
          <Skeleton width={335} height={335} />
        ) : (
          <CustomImage
            src={
              // we fallback to blurUrl to make sure the SRC is never empty
              fdoc.isDraft
                ? fdoc.data.url ?? blurUrl
                : (data?.thumbnail ? data.thumbnail.md : data?.url) ?? fdoc.data.url ?? blurUrl
            }
            alt={fdoc.data.title}
            placeholder={blurUrl ? 'blur' : undefined}
            blurDataURL={blurUrl}
            fill={!calculatedSize}
            width={viewMode === 'Sort' ? 260 : calculatedSize?.width ?? undefined}
            height={viewMode === 'Sort' ? 260 : calculatedSize?.height ?? undefined}
            style={{
              maxHeight: constrainedImages
                ? constrainedImages
                : calculatedSize?.height ?? undefined,
              objectFit: 'cover',
              minWidth: calculatedSize?.width ?? undefined,
              minHeight: isNoteStack
                ? '100%'
                : viewMode === 'Sort'
                  ? '260px'
                  : calculatedSize?.height ?? undefined,
            }}
            draggable={false}
            onError={() => setImageError(true)}
          />
        )}
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        className={styles.item__content__image}
        data-testid="item-content-section"
        style={{
          flex: calculatedSize ? 'none' : undefined,
          height: isNoteStack ? '100%' : undefined,
        }}
      >
        <Skeleton width={335} height={335} />
      </div>
    );
  }

  if (hasPDFThumbnail) {
    return (
      <PDFPreviewContainer isMobileCard={isMobileCard} ref={setPDFLabelRef}>
        <PDFPreviewImage
          isMobileCard={isMobileCard}
          src={data?.thumbnail?.md}
          viewMode={viewMode}
        />
        {viewMode !== 'List' && (
          <PDFPreviewLabel>
            <Truncator
              text={`${fdoc.data.title}${fdoc.data.extension}`}
              maxRows={1}
              keepLastChars={3}
              parentRef={pdfLabelRef}
              offsetBefore={16}
            />
          </PDFPreviewLabel>
        )}
      </PDFPreviewContainer>
    );
  }

  return null;
}

export default FdocItemContentStoredFile;
