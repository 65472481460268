import {
  BlockType,
  CustomElement,
  CustomText,
  Descendant,
  FabricDataValue,
  ListElement,
} from '@/src/components/Tiptap/slate.types';
import escapeHtml from 'escape-html';
import { v4 } from 'uuid';
import { tiptapSanitizeContent } from './content';
import { TiptapDataValue } from './types';

function isPlainObject(value: unknown): value is Record<string, unknown> {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const prototype = Object.getPrototypeOf(value);
  return (
    (prototype === null ||
      prototype === Object.prototype ||
      Object.getPrototypeOf(prototype) === null) &&
    !(Symbol.toStringTag in value) &&
    !(Symbol.iterator in value)
  );
}

function isText(value: unknown): value is CustomText {
  return isPlainObject(value) && typeof value.text === 'string';
}

function isElement(value: unknown): value is CustomElement {
  return !isText(value) && isPlainObject(value) && typeof value.type === 'string';
}

const MARK_MAPPING: {
  [key in keyof CustomText]?: string;
} = {
  bold: 'bold',
  italic: 'italic',
  underline: 'underline',
  strikeThrough: 'strike',
  code: 'code',
  mark: 'highlight',
};

const getAttributes = (element?: CustomElement, attrs?: Record<string, unknown>): string => {
  const attributes = {
    ...(element
      ? {
          'data-uuid': element.id,
          'data-created-at': new Date(element.createdAt).toISOString(),
        }
      : {}),
    ...attrs,
  };

  return Object.entries(attributes)
    .map(([key, value]) => `${key}="${escapeHtml(value)}"`)
    .join(' ');
};

const serialize = (descendant: Descendant): string => {
  if (isText(descendant)) {
    let string = escapeHtml(descendant.text);

    for (const [key, value] of Object.entries(descendant) as unknown as Array<
      [keyof CustomText, unknown]
    >) {
      if (key in MARK_MAPPING && value === true) {
        const mark = MARK_MAPPING[key];
        if (mark) {
          string = `<${mark}>${string}</${mark}>`;
        }
      }
    }

    return string;
  }

  const children = descendant.children.map(serialize).join('');

  switch (descendant.type) {
    case BlockType.Paragraph:
      return `<p ${getAttributes(descendant)}>${children}</p>`;
    case BlockType.Heading:
      return `<h${descendant.level} ${getAttributes(descendant)}>${children}</h${
        descendant.level
      }>`;
    case BlockType.Link:
      return `<a ${getAttributes(descendant, {
        href: descendant.url,
        rel: 'noopener noreferrer nofollow',
        target: '_blank',
      })}>${children}</a>`;
    case BlockType.List:
      return `<${descendant.ordered ? 'ol' : 'ul'} ${getAttributes(
        descendant,
        descendant.isTaskList ? { 'data-type': 'taskList' } : undefined,
      )}>${children}</${descendant.ordered ? 'ol' : 'ul'}>`;
    case BlockType.ListItem:
      return `<li ${getAttributes(descendant)}>${children}</li>`;
    case BlockType.Check:
      return `<li ${getAttributes(descendant, {
        'data-checked': descendant.checked,
        'data-type': 'taskItem',
      })}><label><input type='checkbox' ${
        descendant.checked ? "checked='checked'" : ''
      }/></label><p>${children}</p></li>`;
    case BlockType.Warning:
      return `<div ${getAttributes(descendant)} data-role='callout'>${children}</div>`;
    case BlockType.Image:
      return `<img ${getAttributes(descendant, {
        src: descendant.url,
        alt: descendant.alt,
        width: 'auto',
      })} />`;
    case BlockType.Table:
      return `<table ${getAttributes(descendant)}>${children}</table>`;
    case BlockType.TableRow:
      return `<tr ${getAttributes(descendant)}>${children}</tr>`;
    case BlockType.TableCell:
      return `<td ${getAttributes(descendant)}>${children}</td>`;
    case BlockType.CodeBlock:
      return `<pre ${getAttributes(descendant)}><code ${getAttributes(
        undefined,
        descendant.language ? { class: `language-${descendant.language}` } : undefined,
      )}>${children}</code></pre>`;
    case BlockType.CodeLine:
      return children + '\n';
    case BlockType.QuoteFigure:
      return `<blockquote ${getAttributes(descendant)}>${children}</blockquote>`;
    case BlockType.Quote:
      return `<p ${getAttributes(descendant)}>${children}</p>`;
    case BlockType.QuoteAuthor:
      return `<p ${getAttributes(descendant)}>${children}</p>`;
    default:
      return children;
  }
};

const processNodes = (nodes: Descendant[]): Descendant[] => {
  const wrapped: Descendant[] = [];

  for (let i = 0; i < nodes.length; i++) {
    const node = nodes[i];

    if (isText(node)) {
      wrapped.push(node);
      continue;
    }

    if (node.type === BlockType.Check) {
      // Check if the previous node was a list and if it was not ordered (i.e., unordered)
      const lastNode = wrapped[wrapped.length - 1];

      if (
        i > 0 &&
        isElement(lastNode) &&
        lastNode.type === BlockType.List &&
        !lastNode.ordered &&
        lastNode.isTaskList
      ) {
        // Add the check block to the list
        lastNode.children.push(node);
      } else {
        // Create a new unordered list and add the check block
        const list: ListElement = {
          id: v4(),
          createdAt: new Date().toISOString(),
          type: BlockType.List,
          children: [node],
          ordered: false,
          isTaskList: true,
        };
        wrapped.push(list);
      }
    } else {
      const processedNode: Descendant = isText(node)
        ? (node as CustomText)
        : ({ ...node, children: processNodes(node.children) } as CustomElement);

      wrapped.push(processedNode);
    }
  }

  return wrapped;
};

const wrapCheckBlock = (descendants: Descendant[]): Descendant[] => {
  return processNodes(descendants);
};
export const convertToHTML = (fabricEditorValue: FabricDataValue): TiptapDataValue => {
  return {
    version: '0.0.1',
    lastUpdated: Date.now(),
    content: tiptapSanitizeContent(wrapCheckBlock(fabricEditorValue.data).map(serialize).join('')),

    // Compatibility
    time: fabricEditorValue.time,
    data: fabricEditorValue.data,
    blocks: fabricEditorValue.blocks,
  };
};
