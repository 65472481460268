import { Button } from '@/src/modules/ui/components/Button';
import Link from 'next/link';
import styled from 'styled-components';

const Container = styled.div`
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  color: white;
  font-size: 0.875rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
`;

export const AnonBottomBarSignup: React.FC<{ children?: React.ReactNode; text?: string }> = ({
  children,
}) => {
  return (
    <Container>
      {children}
      <Button
        variant="primary-inverse"
        as={Link}
        style={{ width: '100%' }}
        href={`/beta-sign-up-exclusive-link/from-shared`}
      >
        Sign up / Login
      </Button>
    </Container>
  );
};
