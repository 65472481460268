import { isInMobile } from '@/src/hooks/mobile';
import { downloadMobile } from '@/src/hooks/useDownloadFdoc';
import { IconLinkArrow } from '@/src/modules/icons';
import { toast } from '@/src/store/alerts';
import { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import { useControls } from 'react-zoom-pan-pinch';
import Download from '../../../../public/images/icons/Download.svg';
import Print from '../../../../public/images/icons/Print.svg';
import { useExpandedFdocContentContext } from '../ExpandedFdocContent';
import styles from './HeaderControls.module.scss';
import { usePDFContent } from './PDF';

const HeaderControls: React.FC<{
  pdf: DocumentCallback;
  isOwner: boolean;
  fileName: string;
  storedFile: { url?: string };
}> = ({ pdf, isOwner, fileName, storedFile }) => {
  const { dropdownMenuRef, topHeaderControlsRef, isFullscreen } = useExpandedFdocContentContext();

  const { totalPages, currentPage, scale } = usePDFContent();
  const { zoomIn, zoomOut } = useControls();
  const zoomLevel = useMemo(() => scale * 100, [scale]);

  const previousFullscreen = useRef(isFullscreen);
  useEffect(() => {
    if (previousFullscreen.current === isFullscreen) return;
    previousFullscreen.current = isFullscreen;
    if (!isFullscreen) setTimeout(() => zoomIn(0.01), 1);
  }, [isFullscreen, zoomIn]);

  const onDownload = async () => {
    try {
      if (isInMobile()) {
        if (!storedFile.url) return;
        downloadMobile(storedFile.url, fileName, 'application/pdf');
        return;
      }

      toast({
        content: 'Downloading...',
      });

      const data = await pdf.getData();

      const link = document.createElement('a');
      const blob = new Blob([data], { type: 'application/pdf' });

      const objectUrl = window.URL.createObjectURL(blob);
      link.href = objectUrl;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();

      // Clean up the DOM and release the object URL
      setTimeout(() => {
        document.body.removeChild(link);
        window.URL.revokeObjectURL(objectUrl);
      }, 100);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const zoomControls =
    topHeaderControlsRef && isFullscreen
      ? createPortal(
          <>
            <div className={styles.zoom_and_page_tracker_wrapper}>
              <div className={styles.page_tracker}>
                <span>{totalPages ? currentPage : 0}</span> /<span>{totalPages}</span>
              </div>
              <div className={styles.divider} />
              <div className={styles.zoom_control}>
                <span
                  className={styles.zoom_icon}
                  onClick={() => {
                    zoomOut();
                  }}
                  style={{ userSelect: 'none' }}
                >
                  -
                </span>
                <span className={styles.current_percentage}>{Math.floor(zoomLevel)}%</span>
                <span
                  className={styles.zoom_icon}
                  onClick={() => {
                    zoomIn();
                  }}
                  style={{ userSelect: 'none' }}
                >
                  +
                </span>
              </div>
            </div>
          </>,
          topHeaderControlsRef,
        )
      : null;

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    // This function will be called when the component is unmounted
    return () => {
      if (!iframeRef.current) return;
      document.body.removeChild(iframeRef.current);
    };
  }, []);

  const onPrint = async () => {
    if (!iframeRef.current) {
      // Create an iframe
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      document.body.appendChild(iframe);
      iframeRef.current = iframe;
    }

    // Load the PDF data
    const data = await pdf.getData();
    const blob = new Blob([data], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(blob);
    iframeRef.current.src = objectUrl;

    // Wait for the PDF to load and then print it
    iframeRef.current.onload = () => {
      try {
        iframeRef.current?.contentWindow?.print();
      } catch (error) {
        console.error('Error printing PDF:', error);
      }
    };
  };

  const onOpenExternal = async () => {
    const data = await pdf.getData();
    const blob = new Blob([data], { type: 'application/pdf' });
    const objectUrl = window.URL.createObjectURL(blob);

    window.open(objectUrl);

    window.URL.revokeObjectURL(objectUrl);
  };

  return (
    <>
      {dropdownMenuRef && !isInMobile()
        ? createPortal(
            <>
              <button onClick={onOpenExternal}>
                <IconLinkArrow style={{ width: 12, height: 12 }} /> Open in browser
              </button>
              <button onClick={onDownload}>
                <Download />
                Download
              </button>
              <button onClick={onPrint}>
                <Print />
                Print
              </button>
              {isOwner && <hr />}
            </>,
            dropdownMenuRef,
          )
        : null}
      {zoomControls}
    </>
  );
};

export default HeaderControls;
