import EditTagsModal, { EditTagsProps } from '@/src/components/Tags/EditTagsModal';
import React from 'react';
import { useMutationResourcesTagAssign } from '../mutations/useMutationResourcesTagAssign';
import { useQueryMultipleResourceTags } from '../queries/useQueryResourceTags';
import { getSelectedAndSemiselectedTagIds } from '../utils';
import { ModalControls } from '../../ui/components/Modal';

export const EditTagsPerMultipleResourcesModal: React.FC<{
  resourceIds: string[];
  modalControls: ModalControls;
}> = ({ resourceIds, modalControls }) => {
  const mutationResourcesTagAssign = useMutationResourcesTagAssign();

  const queryResourcesTags = useQueryMultipleResourceTags(resourceIds, {
    enabled: modalControls.open,
  });

  const { selectedTags, indeterminateTags } = React.useMemo(
    () => getSelectedAndSemiselectedTagIds(queryResourcesTags.data),
    [queryResourcesTags.data],
  );

  const selectHandler: EditTagsProps['onSelect'] = (tag, selected) => {
    mutationResourcesTagAssign.mutate({
      tag,
      resourceIds,
      operation: selected ? 'assign' : 'unassign',
    });
  };

  return (
    <EditTagsModal
      {...modalControls}
      selectedTags={selectedTags}
      indeterminateTags={indeterminateTags}
      onSelect={selectHandler}
    />
  );
};
