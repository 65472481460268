import rehypeExternalLinks from 'rehype-external-links';
import rehypeMinifyWhitespace from 'rehype-minify-whitespace';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize, { defaultSchema } from 'rehype-sanitize';
import rehypeStringify from 'rehype-stringify';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import remarkParse from 'remark-parse';
import remarkRehype from 'remark-rehype';
import { merge } from 'ts-deepmerge';
import { unified } from 'unified';
import { Node } from 'unified/lib';
import { MarkdownParserOptions } from './markdown.types';
import { fixMalformedLists } from './plugins/fixMalformedLists';
import { fixSpacedStrong } from './plugins/fixSpacedStrong';

const emptyLineRegex = /^[ \t]*(\n|$)/gm;

/**
 * Given a markdown string, returns a string with the markdown parsed and
 * converted to HTML.
 * @param markdown - The markdown string to parse.
 * @returns The HTML string.
 * @example
 * parseMarkdown('# Hello, world!');
 * // => '<h1>Hello, world!</h1>'
 */
export function parseMarkdown(
  markdown: string,
  options: MarkdownParserOptions = {
    fixMalformedLists: false,
    fixSpacedStrong: false,
    includeEmptyLines: true,
  },
): string {
  let treatedMarkdown = markdown;

  if (options.includeEmptyLines)
    treatedMarkdown = treatedMarkdown.replace(emptyLineRegex, '&nbsp;\n');
  if (options.fixMalformedLists) treatedMarkdown = fixMalformedLists(treatedMarkdown);
  if (options.fixSpacedStrong) treatedMarkdown = fixSpacedStrong(treatedMarkdown);

  const processor = unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(options.includeEmptyLines ? remarkBreaks : () => {})
    .use(remarkRehype)
    .use(rehypeMinifyWhitespace)
    .use(
      rehypeSanitize,
      merge(defaultSchema, {
        protocols: {
          href: ['fabric'],
        },
      }),
    )
    .use(rehypeExternalLinks, {
      target: '_blank',
      rel: ['noopener', 'noreferrer', 'nofollow'],
    })
    .use(rehypeStringify);

  return processor.processSync(treatedMarkdown).toString();
}

export function parseInlineMarkdown(markdown: string): string {
  const treatedMarkdown = markdown.replace(/\n/g, ' ');

  const processor = unified()
    .use(remarkParse)
    .use(remarkGfm)
    .use(remarkRehype, {
      allowDangerousHtml: true,
    })
    .use(rehypeMinifyWhitespace)
    .use(rehypeRaw)
    .use(
      rehypeSanitize,
      merge(defaultSchema, {
        protocols: {
          href: ['fabric'],
          src: ['http', 'https'],
        },
        tagNames: ['a', 'code', 'em', 'strong', 'img', 'u'],
        attributes: {
          a: ['href', 'target', 'rel'],
          img: ['src', 'alt'],
        },
      }),
    )
    .use(rehypeExternalLinks, {
      target: '_blank',
      rel: ['noopener', 'noreferrer', 'nofollow'],
    })
    // Find images, those with a url ending in #no-mobile get added a data-no-mobile=true attribute
    .use(() => (tree) => {
      const walkTree = (node: Node) => {
        if ('children' in node && Array.isArray(node.children)) {
          node.children = node.children.map((child) => {
            if (child.type === 'element' && child.tagName === 'img') {
              const src = child.properties.src;
              if (typeof src === 'string' && src.endsWith('#no-mobile')) {
                child.properties['data-no-mobile'] = true;
                child.properties.src = src.replace('#no-mobile', '');
              }
            }
            return walkTree(child);
          });
        }
        return node;
      };

      return walkTree(tree);
    })
    .use(rehypeStringify);

  return processor.processSync(treatedMarkdown).toString();
}
