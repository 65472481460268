import {
  upsertItemInArrayById,
  useOptimisticallyUpdateQueriesWithPredicate,
} from '@/src/lib/react-query/utilities';
import { ResourceTags } from '@/src/modules/tags/queries/useQueryResourceTags';
import { PrivateTag } from '@fabric/woody-client';
import { useQueryClient } from '@tanstack/react-query';
import { useQueryCacheResourceHelpers } from '../../resources/utils/useQueryCacheResourceHelpers';
import { tagsQueryPredicates } from '../queries/tagsQueryPredicates';
import { mutateResourceTag } from './mutateResourceTag';

type TagWithOptionalProperties = Partial<Omit<PrivateTag, 'id'>> & Pick<PrivateTag, 'id'>;

export const useQueryCacheTagHelpers = () => {
  const queryClient = useQueryClient();
  const optimisticallyUpdateQueries = useOptimisticallyUpdateQueriesWithPredicate();
  const { deleteCachedResources, addNewResourcesToCache } = useQueryCacheResourceHelpers();

  return {
    updateCachedTag: (tagWithUpdatedProperties: TagWithOptionalProperties) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          tagsQueryPredicates.all(q) || tagsQueryPredicates.tag(q, tagWithUpdatedProperties.id),
        type: 'active',
      });

      // updating list of tags where the tag is present
      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<PrivateTag[]>(
        tagsQueryPredicates.all,
        (data) =>
          upsertItemInArrayById(data ?? [], tagWithUpdatedProperties, {
            createIfNotFound: false,
          }),
      );

      // updating the tag directly
      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<PrivateTag>(
        (q) => tagsQueryPredicates.tag(q, tagWithUpdatedProperties.id),
        (tag) =>
          tag && {
            ...tag,
            ...tagWithUpdatedProperties,
          },
      );

      return {
        optimisticUpdateDirectQueries,
        optimisticUpdateAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    deleteCachedTag: (tagId: string) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          tagsQueryPredicates.all(q) ||
          tagsQueryPredicates.tag(q, tagId) ||
          tagsQueryPredicates.resourcesAll(q),
        type: 'active',
      });

      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<PrivateTag[]>(
        tagsQueryPredicates.all,
        (data) => data?.filter((t) => t.id !== tagId),
      );

      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<PrivateTag>(
        (q) => tagsQueryPredicates.tag(q, tagId),
        () => undefined,
      );

      const optimisticUpdateAllResourceQueries = optimisticallyUpdateQueries<ResourceTags[]>(
        tagsQueryPredicates.resourcesAll,
        (data) => {
          if (!data) {
            return;
          }

          return data.map((resource) => ({
            ...resource,
            tags: resource.tags.filter((t) => t.id !== tagId),
          }));
        },
      );

      return {
        optimisticUpdateDirectQueries,
        optimisticUpdateAllQueries,
        optimisticUpdateAllResourceQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
          optimisticUpdateAllResourceQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
          optimisticUpdateAllResourceQueries.invalidateQueries();
        },
      };
    },
    addNewTagToCache: (tag: PrivateTag) => {
      queryClient.cancelQueries({
        predicate: (q) => tagsQueryPredicates.all(q) || tagsQueryPredicates.tag(q, tag.id),
        type: 'active',
      });

      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<PrivateTag[]>(
        tagsQueryPredicates.all,
        (data) =>
          upsertItemInArrayById(data ?? [], tag, {
            createIfNotFound: true,
          }),
      );

      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<PrivateTag>(
        (q) => tagsQueryPredicates.tag(q, tag.id),
        (data) => ({
          ...data,
          ...tag,
        }),
      );

      return {
        optimisticUpdateDirectQueries,
        optimisticUpdateAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    replaceTagInCache: (oldTag: PrivateTag, newTag: PrivateTag) => {
      queryClient.cancelQueries({
        predicate: (q) => tagsQueryPredicates.all(q) || tagsQueryPredicates.tag(q, oldTag.id),
        type: 'active',
      });

      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<PrivateTag[]>(
        tagsQueryPredicates.all,
        (data) => data && data.map((t) => (t.id === oldTag.id ? newTag : t)),
      );

      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<PrivateTag>(
        (q) => tagsQueryPredicates.tag(q, oldTag.id),
        () => newTag,
      );

      return {
        optimisticUpdateDirectQueries,
        optimisticUpdateAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    assignTagFromResources: (
      tag: PrivateTag,
      resourceIds: string[],
      operation: 'assign' | 'unassign',
    ) => {
      queryClient.cancelQueries({
        predicate: (q) => tagsQueryPredicates.resources(q, resourceIds),
        type: 'active',
      });

      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<ResourceTags[]>(
        (q) => tagsQueryPredicates.resources(q, resourceIds),
        (data) => {
          if (operation === 'assign') {
            const nextData = (data || []).map((resource) => ({
              ...resource,
              tags: [...resource.tags, tag],
            }));

            // add if it doesn't exist
            resourceIds.forEach((resourceId) => {
              if (!nextData.find((item) => item.id === resourceId)) {
                nextData.push({
                  id: resourceId,
                  tags: [tag],
                });
              }
            });
            return nextData;
          } else {
            return (data || []).map((resource) => {
              return {
                ...resource,
                tags: resource.tags.filter((t) => t.id !== tag.id),
              };
            });
          }
        },
      );

      const deleteCachedResourcesContext = deleteCachedResources(resourceIds);
      const mutatedResources = deleteCachedResourcesContext.deletedResources.map((resource) =>
        // We only mutate the parent of the root resources, if they are not in the [resourceIds] it means
        // they where a child of a resource that was moved, so we don't need to mutate them
        resourceIds.includes(resource.id) ? mutateResourceTag(resource, tag, operation) : resource,
      );

      const addNewResourcesToCacheContext = addNewResourcesToCache(mutatedResources);

      return {
        optimisticUpdateAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          deleteCachedResourcesContext.resetCacheToPreOptimisticState();
          addNewResourcesToCacheContext.resetCacheToPreOptimisticState();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          deleteCachedResourcesContext.invalidateQueries();
          addNewResourcesToCacheContext.invalidateQueries();
        },
      };
    },
  };
};
