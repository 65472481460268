import StarterKit from '@tiptap/starter-kit';
import styles from '../Tiptap.module.scss';
import Placeholder from '@tiptap/extension-placeholder';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Link from '@tiptap/extension-link';
import { Markdown } from 'tiptap-markdown';
import { common, createLowlight } from 'lowlight';
import Callout from './Callout';
import NodeInfo from './NodeInfo';
import Embed from './Embed';

const tiptapExtensions = [
  StarterKit.configure({
    codeBlock: false,
    history: false,
  }),
  Placeholder.configure({
    placeholder: 'Type something here…',
    includeChildren: true,
    emptyNodeClass: styles.is_empty,
  }),
  Highlight.configure(),
  Underline.configure(),
  Link.configure(),
  CodeBlockLowlight.configure({
    lowlight: createLowlight(common),
    defaultLanguage: 'js',
  }),
  TaskList,
  TaskItem.configure({
    nested: true,
  }),
  Table.configure({
    resizable: true,
  }),
  TableRow,
  TableHeader,
  TableCell,
  Callout,
  NodeInfo.configure({
    types: [
      'heading',
      'paragraph',
      'blockquote',
      'codeBlock',
      'embed',
      'listItem',
      'table',
      'tableRow',
      'tableCell',
      'callout',
      'link',
      'orderedList',
      'bulletList',
      'taskList',
      'listItem',
    ],
  }),
  Embed,
  Markdown.configure({
    html: true,
    bulletListMarker: '-',
    transformPastedText: true,
    transformCopiedText: false,
  }),
];

export default tiptapExtensions;
