import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const CrispSimilarIcon: FC<IconProps> = ({
  color = 'currentColor',
  size = 18,
  className,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <path
        d="M9.53621 6.37599L12.3162 7.22208C12.4074 7.24982 12.4074 7.3789 12.3162 7.40665L9.53621 8.25273C8.92098 8.43998 8.43949 8.92146 8.25224 9.5367L7.40616 12.3167C7.37841 12.4079 7.24933 12.4079 7.22159 12.3167L6.3755 9.5367C6.18826 8.92146 5.70677 8.43998 5.09154 8.25273L2.31154 7.40665C2.22037 7.3789 2.22037 7.24982 2.31154 7.22208L5.09154 6.37599C5.70677 6.18875 6.18826 5.70726 6.3755 5.09202L7.22159 2.31203C7.24933 2.22086 7.37841 2.22086 7.40616 2.31203L8.25224 5.09202C8.43949 5.70726 8.92098 6.18875 9.53621 6.37599Z"
        fill={color}
      />
      <path
        d="M15.2435 13.2087L16.1746 13.4921C16.2658 13.5198 16.2658 13.6489 16.1746 13.6767L15.2435 13.96C14.6283 14.1473 14.1468 14.6288 13.9596 15.244L13.6762 16.1751C13.6484 16.2663 13.5194 16.2663 13.4916 16.1751L13.2082 15.244C13.021 14.6288 12.5395 14.1473 11.9243 13.96L10.9932 13.6767C10.902 13.6489 10.902 13.5198 10.9932 13.4921L11.9243 13.2087C12.5395 13.0215 13.021 12.54 13.2082 11.9248L13.4916 10.9937C13.5194 10.9025 13.6484 10.9025 13.6762 10.9937L13.9596 11.9248C14.1468 12.54 14.6283 13.0215 15.2435 13.2087Z"
        fill={color}
      />
    </svg>
  );
};

export default CrispSimilarIcon;
