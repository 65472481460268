import { createLogger } from '@/src/lib/logger/createLogger';
import { ResourcePreviewListItemFolder } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemFolder';
import { ResourcePreviewListItemImage } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemImage';
import { ResourcePreviewListItemNote } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemNote';
import { ResourcePreviewListItemPage } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemPage';
import { ResourcePreviewListItemStoredFile } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemStoredFile';
import { ResourcePreviewListItemText } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/preview/ResourcePreviewListItemText';
import {
  isFolderFdoc,
  isImageFdoc,
  isNotepadFdoc,
  isPageFdoc,
  isStoredFileFdoc,
  isTextFdoc,
} from '@/src/modules/resources/utils/resourceTypes';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';

interface ResourcePreviewContentProps {
  resource: OptimisticDraft<Fdoc>;
}

const logger = createLogger('Resource list item');

export const ResourcePreviewListPreview: React.FC<ResourcePreviewContentProps> = ({ resource }) => {
  if (isStoredFileFdoc(resource)) {
    return <ResourcePreviewListItemStoredFile resource={resource} />;
  }

  if (isPageFdoc(resource)) {
    return <ResourcePreviewListItemPage resource={resource} />;
  }

  if (isFolderFdoc(resource)) {
    return <ResourcePreviewListItemFolder resource={resource} />;
  }

  if (isTextFdoc(resource)) {
    return <ResourcePreviewListItemText resource={resource} />;
  }

  if (isImageFdoc(resource)) {
    return <ResourcePreviewListItemImage resource={resource} />;
  }

  if (isNotepadFdoc(resource)) {
    return <ResourcePreviewListItemNote resource={resource} />;
  }

  logger.error('Unhandled render', resource);

  return null;
};
