import EmptyCactusIcon from '@/public/images/icons/EmptyCactus.svg';
import { ResourceListContextMenuSelectedItemsContent } from '@/src/components/FdocList/ContextMenus/ResourceListContextMenuSelectedItemsContent';
import { useAuthUser } from '@/src/hooks/auth';
import { useResponsive } from '@/src/hooks/responsive';
import { desktopFloatUrls } from '@/src/hooks/todesktop';
import { useBoolState } from '@/src/hooks/useBooleanState';
import useDragMultiselect from '@/src/hooks/useDragMultiselect';
import useOpenInTabs from '@/src/hooks/useOpenInTabs';
import { useScrollAreaZoomHeightFix } from '@/src/hooks/useScrollAreaZoomHeightFix';
import { DragAndSelectProvider } from '@/src/lib/DragAndSelectContext';
import { useAskAssistantSelection } from '@/src/modules/assistant/hooks/useAskAssistantSelection';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { RecapForDateSortField } from '@/src/modules/recap/recap.types';
import { ModalMoveResources } from '@/src/modules/resources/components/ModalMoveResources/ModalMoveResources';
import { ResourcePreviewContentProps } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { ResourcesPreviewUiConfigProvider } from '@/src/modules/resources/components/ResourcePreview/resourcePreviewUiConfigContext';
import { useMutationCreateSubFolder } from '@/src/modules/resources/mutations/useMutationCreateSubFolder';
import { useMutationDeleteResourcesById } from '@/src/modules/resources/mutations/useMutationDeleteResourcesById';
import { useMutationMoveResourcesById } from '@/src/modules/resources/mutations/useMutationMoveResourcesById';
import { ResourceUiStoreContext } from '@/src/modules/resources/stores/useResourcesUiStore';
import { isFolderFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { Space } from '@/src/modules/spaces/spaces.types';
import { EditTagsPerMultipleResourcesModal } from '@/src/modules/tags/components/EditTagsPerMultipleResourcesModal';
import { ContextMenu } from '@/src/modules/ui/components/ContextMenu/ContextMenu';
import { IntersectionObserverAnchor } from '@/src/modules/ui/components/IntersectionObserver/IntersectionObserver';
import useMobileSelectionStore from '@/src/store/mobileSelection';
import useUIStore, {
  setGlobalSelectionOptions,
  setNewModalOpen,
  useSearchOverlay,
} from '@/src/store/ui';
import { Fdoc } from '@/src/types/api';
import { isTruthy } from '@/src/utils/guards';
import { ResourceFilterSort } from '@fabric/woody-client';
import clsx from 'clsx';
import dayjs from 'dayjs';
import produce from 'immer';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { CSSProperties, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { OptimisticDraft } from '../../types/draftable';
import { Size, hasMacOSKbd } from '../../types/global';
import DropToMove from '../DropToMove/DropToMove';
import { FdocItemProps } from '../FdocItem/FdocItem';
import MultiSelection from '../MultiSelection/MultiSelection';
import SelectionBox from '../SelectionBox/SelectionBox';
import Spinner from '../Spinner/Spinner';
import { OfViewMode } from '../ViewModeSwitcher/ViewModeSwitcher';
import { zoomSequence } from '../ZoomSlider/ZoomSlider';
import styles from './FdocList.module.scss';
import FdocListDivision from './FdocListDivision';

export type SplitFdocs = {
  title?: string | undefined;
  colorLabel?: ApiColorLabel | null;
  resourceId?: string;
  space?: Space;
  id?: string;
  fdocs: OptimisticDraft<Fdoc>[];

  recapDate?: string;
  recapFrequency?: 'daily';
  recapField?: RecapForDateSortField;
};

const splitFdocsByTime = (
  fdocs: OptimisticDraft<Fdoc>[],
  sortProperty: 'modifiedAt' | 'createdAt',
) => {
  const today = dayjs();
  const yesterday = today.subtract(1, 'day');
  const last7Days = today.subtract(7, 'day');
  const last30Days = today.subtract(30, 'day');
  const last12Months = today.subtract(12, 'month');

  const divided = fdocs
    .reduce(
      (acc, fdoc) => {
        const fdocDate = dayjs(fdoc?.[sortProperty]);

        if (fdocDate.isSame(today, 'day')) {
          acc[0].fdocs.push(fdoc);
        } else if (fdocDate.isSame(yesterday, 'day')) {
          acc[1].fdocs.push(fdoc);
        } else if (fdocDate.isAfter(last7Days)) {
          acc[2].fdocs.push(fdoc);
        } else if (fdocDate.isAfter(last30Days)) {
          acc[3].fdocs.push(fdoc);
        } else if (fdocDate.isAfter(last12Months)) {
          acc[4].fdocs.push(fdoc);
        } else {
          acc[5].fdocs.push(fdoc);
        }

        return acc;
      },
      [
        {
          title: 'Today',
          fdocs: [],
        },
        {
          title: 'Yesterday',
          fdocs: [],
        },
        {
          title: 'Last 7 days',
          fdocs: [],
        },
        {
          title: 'Last 30 days',
          fdocs: [],
        },
        {
          title: 'Last 12 months',
          fdocs: [],
        },
        {
          title: 'Older than 12 months',
          fdocs: [],
        },
      ] as SplitFdocs[],
    )
    .filter((division) => division.fdocs.length > 0);

  const firstFdoc = fdocs[0];
  const lastFdoc = fdocs[fdocs.length - 1];

  if (firstFdoc && lastFdoc && dayjs(firstFdoc.createdAt).isBefore(dayjs(lastFdoc.createdAt))) {
    return divided.slice(0).reverse();
  } else {
    return divided;
  }
};

/**
 * Splits fdocs by color label, includes a Unsorted category if there are fdocs without a label
 * and will show empty labels as well to allow drag and drop
 * @param fdocs All fdocs to be split
 * @param labels The list of labels to split by
 * @returns An array of SplitFdocs objects
 */
const splitFdocsByColorLabel = (
  fdocs: OptimisticDraft<Fdoc>[],
  labels: ApiColorLabel[],
  space: Space,
) => {
  const fdocsByLabel = labels.reduce(
    (acc, label) => ({
      ...acc,
      [label.id]: {
        title: label.name,
        colorLabel: label,
        fdocs: [],
        space,
      },
    }),
    {} as Record<string, SplitFdocs>,
  );

  const unsortedFdocs = fdocs.reduce((acc, fdoc) => {
    if (fdoc?.label) {
      const labelId = fdoc.label;
      if (fdocsByLabel[labelId]) {
        fdocsByLabel[labelId].fdocs.push(fdoc);
      } else {
        acc.push(fdoc);
      }
    } else {
      acc.push(fdoc);
    }

    return acc;
  }, [] as OptimisticDraft<Fdoc>[]);

  // flatten
  const fdocsByLabelArray = Object.values(fdocsByLabel);
  // Set first label to unsorted even if it's empty
  fdocsByLabelArray.unshift({
    title: 'Unsorted',
    fdocs: unsortedFdocs,
    colorLabel: null,
    space,
  });

  return fdocsByLabelArray;
};

const zoomTrigger = (currentValue: number, direction: 'up' | 'down') => {
  const currentIndex = zoomSequence.indexOf(currentValue);

  return direction === 'up'
    ? zoomSequence[currentIndex + 1] || currentValue
    : zoomSequence[currentIndex - 1] || currentValue;
};

const plusKeyCodes = [187, 107];
const minusKeyCodes = [189, 109];
const zeroKeyCode = [96, 48];

const FdocList: React.FC<{
  fdocs: OptimisticDraft<Fdoc>[];
  fdocTotals?: number;
  showSkeleton?: boolean;
  fdocClickHandler: (fdoc?: Fdoc) => void;
  onInfiniteTrigger?: () => void;
  allowOverrideFolderClick?: boolean;
  fadeEffect?: boolean;
  isRecentItemsList?: boolean;
  isSidepanel?: boolean;
  isFullscreen?: boolean;

  hideScrollbars?: boolean;

  slimStyle?: boolean;
  sort?: ResourceFilterSort['field'];

  nonVerticallyCenteredEmptyState?: boolean;
  emptyStateTitle?: string;
  emptyStateReason?: string;
  emptyStateImage?: string | null;
  emptyStateImageSize?: Size;
  alwaysShowFirstDivision?: boolean;
  hideContextMenu?: boolean;

  optimizeDivisions?: boolean;

  forcedMobile?: boolean;

  hasSeparateDivisions?: boolean;

  style?: React.CSSProperties;
  infiniteTriggerEntityId?: string | null | (string | null)[];
  selectionMode?: boolean;
  spaceFdocsIds?: string[];
  disabledFdocsIds?: string[];
  canMultiSelect?: boolean;
  multiSelectLayerId?: string;
  showMultiselectBox?: boolean;
  padding?: string;
  gap?: CSSProperties['gap'];
  canDelete?: boolean;
  canMove?: boolean;
  canAddToSpace?: boolean;
  viewMode: OfViewMode;
  colorLabels?: ApiColorLabel[];
  list?: Space;
  parentResourceId?: string;
  forcedColumns?: number;
  skipAnimation?: boolean;
  splitFunction?: (fdocs: OptimisticDraft<Fdoc>[]) => SplitFdocs[];
  customStyleFunction?: (fdoc: OptimisticDraft<Fdoc>) => React.CSSProperties;
  customItemPropsFunction?: (fdoc: OptimisticDraft<Fdoc>) => Partial<FdocItemProps>;

  mainListStyle?: React.CSSProperties;
  subListStyles?: (index: number) => React.CSSProperties;
  subListRootStyles?: (index: number) => React.CSSProperties;

  parentScrollRoot?: HTMLElement | null;

  setScrollPosition?: (scrollPosition: number) => void;

  zoomLevel?: number;
  setZoomLevel?: (zoomLevel: number) => void;

  emptyListMessage?: string;
  emptyListLink?: string;
  splitByTime?: boolean;
  readOnly?: boolean;

  /**
   * new card previews
   */
  resourcePreviewProps?: Omit<ResourcePreviewContentProps, 'resource'>;
  uiContext?: ResourceUiStoreContext;
}> = ({
  fdocs,
  fdocTotals,
  showSkeleton,
  fdocClickHandler,
  forcedMobile = false,
  hasSeparateDivisions = false,
  hideContextMenu = false,
  optimizeDivisions = false,
  style,
  readOnly,
  // Not including a default for infinite trigger or it will cause re-renders
  onInfiniteTrigger,
  allowOverrideFolderClick = false,
  sort = 'createdAt:desc,objectId:desc',
  fadeEffect = true,
  infiniteTriggerEntityId,
  selectionMode,
  canMultiSelect = false,
  multiSelectLayerId,
  showMultiselectBox = canMultiSelect,
  spaceFdocsIds: spaceFdocsIds = [],
  subListStyles,
  mainListStyle,
  subListRootStyles,
  disabledFdocsIds = [],
  padding = '8px',
  gap = '32px',
  canDelete = false,
  canMove = true,
  canAddToSpace: canAddToSpace = false,
  nonVerticallyCenteredEmptyState = false,
  alwaysShowFirstDivision = false,
  emptyStateTitle,
  emptyStateReason,
  emptyStateImage,
  emptyStateImageSize,
  viewMode,
  colorLabels,
  list,
  parentResourceId,
  forcedColumns = undefined,
  splitFunction,
  slimStyle = false,
  skipAnimation = false,
  customStyleFunction,
  customItemPropsFunction,
  parentScrollRoot,
  hideScrollbars = false,
  isFullscreen,

  zoomLevel = 1,
  setZoomLevel = () => {},
  setScrollPosition = () => {},

  emptyListMessage,
  emptyListLink = undefined,
  splitByTime = false,

  isSidepanel = false,
  resourcePreviewProps,
  uiContext,
}) => {
  const router = useRouter();
  const user = useAuthUser();
  // const [moveModalAtRoot, setMoveModalAtRoot] = useState(false);
  const { mobileSelectModeIsActive, disableMobileSelectMode } = useMobileSelectionStore(
    (state) => ({
      mobileSelectModeIsActive: state.mobileSelectModeIsActive,
      disableMobileSelectMode: state.disableMobileSelectMode,
    }),
    shallow,
  );

  const multiTagEditModalOpen = useBoolState();

  const defaultEmptyStateTitle = !user ? 'Nothing here yet!' : "Let's add our first item!";
  const defaultEmptyListMessage = !user
    ? undefined
    : 'You can drag, paste anything – if you can think of it, you can do it here';

  useEffect(() => {
    // trigger fabric-custom-zoom when zoomlevel changes
    const zoomEvent = new CustomEvent('fabric-custom-zoom', {
      detail: {
        zoomLevel,
      },
    });

    window.dispatchEvent(zoomEvent);
  }, [zoomLevel]);

  // listen to CMD+Scroll or CTRL+Scroll (windows) to zoom in and out
  // also listen to CMD+Plus and CMD+Minus to zoom in and out (or CTRL+Plus and CTRL+Minus on windows)
  useEffect(() => {
    const handleScroll = (e: WheelEvent) => {
      if ((!hasMacOSKbd() && !e.ctrlKey) || (hasMacOSKbd() && !e.metaKey) || viewMode === 'Sort')
        return;

      e.preventDefault();
      const newZoomLevel = zoomTrigger(zoomLevel, e.deltaY > 0 ? 'down' : 'up');
      setZoomLevel(newZoomLevel);
    };

    const handleKeyDown = (e: KeyboardEvent) => {
      if ((!hasMacOSKbd() && !e.ctrlKey) || (hasMacOSKbd() && !e.metaKey)) return;

      const isPlus =
        plusKeyCodes.includes(e.keyCode) ||
        plusKeyCodes.includes(e.which) ||
        e.code === 'Equal' ||
        e.code === 'NumpadAdd' ||
        e.key === '+';
      const isMinus =
        minusKeyCodes.includes(e.keyCode) ||
        minusKeyCodes.includes(e.which) ||
        e.code === 'Minus' ||
        e.code === 'NumpadSubtract' ||
        e.key === '-';
      const isZero =
        zeroKeyCode.includes(e.keyCode) ||
        zeroKeyCode.includes(e.which) ||
        e.code === 'Numpad0' ||
        e.key === '0';

      if (!isPlus && !isMinus && !isZero) return;

      if (isZero) {
        setZoomLevel(0.9);
        return;
      }

      e.preventDefault();
      e.stopPropagation();
      const newZoomLevel = zoomTrigger(zoomLevel, isPlus ? 'up' : 'down');
      setZoomLevel(newZoomLevel);
    };

    window.addEventListener('wheel', handleScroll, { passive: false });
    window.addEventListener('keydown', handleKeyDown, true);

    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [zoomLevel, setZoomLevel, viewMode]);

  const [listRef, setRef] = useState<HTMLElement | null>(null);

  const maxHeight = useScrollAreaZoomHeightFix(listRef, zoomLevel, fdocs.length > 0);

  const ref = useMemo(() => parentScrollRoot ?? listRef, [listRef, parentScrollRoot]);

  const [notOnTop, setNotOnTop] = useState(false);

  const [quickAddToSpaceOpen, setQuickAddToSpaceOpen] = useState(false);

  const deselectAllHandler = useCallback(() => {
    setSelectedFdocsIds([]);
    // chicken and egg problem, deselectAllHandler is called from useMultiSelect which this depends on
    // so we don't add it as a dependency
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    multiSelect,
    modifierOperation,
    selectedIds: selectedFdocsIds,
    activeSelectionIds: activeSelectionFdocsIds,
    setMultiSelect,
    startPosition,
    endPosition,
    setSelectedIds: setSelectedFdocsIds,
    handleClearSelection,
  } = useDragMultiselect(
    canMultiSelect,
    deselectAllHandler,
    (id) => !fdocs.find((f) => f.id === id)?.isDraft,
    multiSelectLayerId,
  );

  const selectedFdocs = selectedFdocsIds
    .map((id) => fdocs.find((f) => f.id === id))
    .filter(isTruthy);

  const handleSelectFdoc = useCallback(
    (fdoc?: Fdoc) => {
      if (!fdoc) return;
      if (selectedFdocsIds.includes(fdoc.id)) {
        setSelectedFdocsIds((prev) => prev.filter((item) => item !== fdoc.id));
        return;
      }

      setSelectedFdocsIds((prev) => [...prev, fdoc.id]);
    },
    [setSelectedFdocsIds, selectedFdocsIds],
  );

  const [contextMenuView, setContextMenuView] = useState<'FOLDER' | 'SELECTED_ITEMS'>('FOLDER');

  /**
   * our context is based on radix
   * the radix version doesn't provide any API to intercept the context menu event
   * so we create our own here, attaching it to the first child
   */
  const onContextMenu = (e: React.MouseEvent<HTMLDivElement>) => {
    if (quickAddToSpaceOpen || readOnly) return;
    const target = e.target as HTMLElement;
    const isTargetSelectableItem = !!(target.closest('[data-selectable-id]') as HTMLElement);

    if (isTargetSelectableItem) {
      if (selectedFdocsIds.length > 1) {
        setContextMenuView('SELECTED_ITEMS');
      } else {
        e.preventDefault();
        e.stopPropagation();
      }
    } else {
      setContextMenuView('FOLDER');
    }
  };

  /******************************************************************************************************************** */
  // check router query for selectedFdocIds and if there set the selectedFdocsIds and
  // open the quickAddToSpace

  const globalSelectionOptions = useUIStore((s) => s.globalSelectionOptions, shallow);
  const { isOverlayOpen: isSearchOverlayOpen } = useSearchOverlay();
  /**
   * @HOTFIX
   * we block this if there's a search overlay open because if it is open, 2 fdoc lists
   * are mounted and both would otherwise display the move modal
   *
   * we should rather move the move modal out completely and have it as a global component so it doesn't happen
   * that there could be more than 1 instance
   */
  const shouldHandleMoveResourcesFromGlobalSelection = isSearchOverlayOpen
    ? [
        'desktop-global-search',
        'searchbar-with-overlay',
        'searchbar-with-overlay-experimental',
      ].includes(multiSelectLayerId || '')
    : true;

  useEffect(() => {
    if (!globalSelectionOptions.selectedFdocsIds || !shouldHandleMoveResourcesFromGlobalSelection)
      return;

    setSelectedFdocsIds(globalSelectionOptions.selectedFdocsIds);

    // If postAdd don't open quickAddToSpace
    if (globalSelectionOptions.postAdd) {
      setGlobalSelectionOptions({});
      return;
    }
    setQuickAddToSpaceOpen(true);
  }, [
    list,
    setSelectedFdocsIds,
    globalSelectionOptions.selectedFdocsIds,
    globalSelectionOptions.postAdd,
    shouldHandleMoveResourcesFromGlobalSelection,
  ]);

  /******************************************************************************************************************** */

  // This will return all currently selected fdocs and space fdocs, or
  // if multiSelect is active a realtime preview of the selection
  // including modifier operation if the user is holding down a modifier key
  // which overlaps the active selection and selected fdocs
  const previewSelectedFdocs = useMemo(() => {
    if (!multiSelect) {
      // set to get unique ids
      return Array.from(new Set([...(selectionMode ? spaceFdocsIds : []), ...selectedFdocsIds]));
    }
    if (modifierOperation) {
      // overlap of active selection and selected fdocs

      // set to get unique ids
      const selectedIdsSet = new Set([
        ...selectedFdocsIds,
        ...activeSelectionFdocsIds,
        ...(selectionMode ? spaceFdocsIds : []),
      ]);

      return Array.from(selectedIdsSet);
    }

    return Array.from(
      new Set([...(selectionMode ? spaceFdocsIds : []), ...activeSelectionFdocsIds]),
    );
  }, [
    selectionMode,
    multiSelect,
    modifierOperation,
    spaceFdocsIds,
    selectedFdocsIds,
    activeSelectionFdocsIds,
  ]);

  const askAssistantSelection = useAskAssistantSelection();
  const mutationDeleteResources = useMutationDeleteResourcesById();
  const deleteSelectionHandler = () =>
    mutationDeleteResources.confirmAndMutate(previewSelectedFdocs, {
      onSuccess: handleClearSelection,
    });

  const addToSpaceHandler = useCallback(
    (fdocIds?: string[]) => {
      // setMoveModalAtRoot(false);
      if (fdocIds !== undefined && fdocIds.length > 0) {
        // setMoveModalAtRoot(true);
        setSelectedFdocsIds(fdocIds);
      }

      setQuickAddToSpaceOpen(true);

      setGlobalSelectionOptions({});
    },
    [setSelectedFdocsIds],
  );

  const askAssistantSelectionHandler = useCallback(() => {
    askAssistantSelection(selectedFdocs.filter((f) => !isFolderFdoc(f)).map((f) => f.id));
  }, [askAssistantSelection, selectedFdocs]);

  const onCloseMoveResourceModal = useCallback(() => {
    globalSelectionOptions.clearOnClose && handleClearSelection();

    setQuickAddToSpaceOpen(false);
    setGlobalSelectionOptions({});
  }, [globalSelectionOptions.clearOnClose, handleClearSelection]);

  useEffect(() => {
    const optimisticIds = router.query.optimisticIds;
    if (!optimisticIds?.length) return;

    router.replace(
      {
        pathname: router.pathname,
        query: produce(router.query, (draft) => {
          delete draft.optimisticIds;
        }),
      },
      undefined,
      { shallow: true },
    );
  }, [router]);

  const onScroll = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    setScrollPosition?.(e.currentTarget.scrollTop);

    const target = e.target as HTMLDivElement;
    setNotOnTop(target.scrollTop > 0);
  };

  const [currentWidth, setCurrentWidth] = useState(0);
  useEffect(() => {
    if (!ref) return;

    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        setCurrentWidth(entry.contentRect.width);
      });
    });

    resizeObserver?.observe(ref);
    setCurrentWidth(ref.clientWidth);

    return () => resizeObserver?.disconnect();
  }, [ref]);

  const [columns, setColumns] = useState(0);
  const { isMobileView } = useResponsive();

  const realZoomLevel = isMobileView ? 1 : zoomLevel;

  useEffect(() => {
    if (!ref || currentWidth === 0) return;

    // 260px and a few more pixels so it takes into account gaps
    const cardSize = (isMobileView ? 182 : 280) * realZoomLevel;

    const scaledWidth = currentWidth * realZoomLevel;

    const newColumns = Math.max(1, Math.floor(scaledWidth / cardSize));

    if (newColumns === columns) return;

    setColumns(newColumns);
  }, [columns, currentWidth, isMobileView, ref, realZoomLevel]);

  const splitFdocs: SplitFdocs[] = useMemo(() => {
    if (splitFunction) return splitFunction(fdocs);
    if (splitByTime)
      return splitFdocsByTime(fdocs, sort?.indexOf('modified') !== -1 ? 'modifiedAt' : 'createdAt');
    if (viewMode === 'Sort' && list) {
      if (!colorLabels)
        return [
          {
            title: 'Unsorted',
            fdocs: fdocs,
          },
        ];

      return splitFdocsByColorLabel(fdocs, colorLabels, list);
    }

    return [
      {
        title: undefined,
        fdocs: fdocs,
      },
    ];
  }, [colorLabels, fdocs, list, sort, splitByTime, splitFunction, viewMode]);

  const { openInTabs, confirmationModal } = useOpenInTabs();

  const openSelectionAsTabs = useCallback(async () => {
    openInTabs(selectedFdocs);
    handleClearSelection();
  }, [openInTabs, selectedFdocs, handleClearSelection]);

  const handleClickAddItems = () => {
    setNewModalOpen(true);
  };

  const showEmpty = !showSkeleton && fdocs.length === 0 && viewMode !== 'Sort';

  const { mutateAsync: mutateCreateSubFolder } = useMutationCreateSubFolder();
  const mutationMoveResources = useMutationMoveResourcesById();

  const handleCreateSubFolder = useCallback(async () => {
    if (!parentResourceId) return;

    return await mutateCreateSubFolder({
      parentResourceId: parentResourceId,
      action: 'list-context-menu',
    });
  }, [mutateCreateSubFolder, parentResourceId]);

  const handleCreateSubFolderAndMove = useCallback(
    async (fdocs: OptimisticDraft<Fdoc>[]) => {
      if (!parentResourceId) return;

      try {
        const folder = await handleCreateSubFolder();
        if (!folder) return;

        mutationMoveResources.mutate({
          resourceIds: fdocs.map((fdoc) => fdoc.id),
          targetId: folder.id,
          action: 'list-context-create-sub-folder',
        });
      } catch (e) {}
    },
    [handleCreateSubFolder, mutationMoveResources, parentResourceId],
  );

  return (
    <ResourcesPreviewUiConfigProvider uiContext={uiContext}>
      <EditTagsPerMultipleResourcesModal
        resourceIds={selectedFdocsIds}
        modalControls={multiTagEditModalOpen.modalProps}
      />

      <ModalMoveResources
        open={quickAddToSpaceOpen && selectedFdocs.length > 0}
        onClose={onCloseMoveResourceModal}
        resources={selectedFdocs}
        onMoveSuccess={() => {
          setSelectedFdocsIds([]);
          onCloseMoveResourceModal();
          setMultiSelect(false);
          disableMobileSelectMode();
        }}
      />

      {startPosition && endPosition && multiSelect && (
        <MultiSelection
          startPosition={startPosition}
          endPosition={endPosition}
          modifierOperation={modifierOperation}
        />
      )}

      <DragAndSelectProvider selectedIds={selectedFdocsIds} clearSelection={handleClearSelection}>
        <ContextMenu
          contextDisabled={readOnly || hideContextMenu || isMobileView}
          content={
            contextMenuView === 'FOLDER' ? (
              <>
                <ContextMenu.Item
                  onClick={handleClickAddItems}
                  data-testid="fdoc-list-context-menu-add-items"
                >
                  Add items...
                </ContextMenu.Item>

                {parentResourceId && (
                  <ContextMenu.Item
                    onClick={handleCreateSubFolder}
                    data-testid="fdoc-list-context-menu-create-subfolder"
                  >
                    Create subfolder
                  </ContextMenu.Item>
                )}
              </>
            ) : (
              selectedFdocsIds.length > 1 && (
                <ResourceListContextMenuSelectedItemsContent
                  onAskAssistantSelectionClick={askAssistantSelectionHandler}
                  onAddSelectionToSpaceClick={() => addToSpaceHandler()}
                  onDeleteSelectionClick={deleteSelectionHandler}
                  onOpenSelectionAsTabsClick={openSelectionAsTabs}
                  onCreateSubfolderFromSelection={
                    parentResourceId ? handleCreateSubFolderAndMove : undefined
                  }
                  onMultiTagEditClick={() => multiTagEditModalOpen.handleTrue()}
                  colorLabels={colorLabels}
                  fdocs={
                    selectedFdocsIds.length > 0
                      ? fdocs.filter((fdoc) => selectedFdocsIds.includes(fdoc.id))
                      : undefined
                  }
                  list={list}
                  canAddToSpace={canAddToSpace}
                />
              )
            )
          }
        >
          <div
            className={clsx(
              styles.dashboard__list__wrapper,
              notOnTop &&
                fadeEffect &&
                !isSidepanel &&
                styles['dashboard__list__wrapper--fade-top'],
              parentScrollRoot && styles['dashboard__list__view_mode--no-scroll'],
            )}
            style={{
              opacity:
                !showSkeleton || desktopFloatUrls.includes(router.pathname) || skipAnimation
                  ? 1
                  : 0,
              ...style,
              ...(viewMode === 'List' ? { minWidth: '640px' } : {}),
              maxHeight: viewMode === 'Grid' ? `max(${maxHeight}px, 100vh)` : undefined,
            }}
            data-multiselect-allow
            data-multiselect-layer-id={multiSelectLayerId}
          >
            <section
              onContextMenu={onContextMenu}
              ref={setRef}
              onScroll={onScroll}
              className={clsx(
                styles.list,
                fdocs.length === 0 && styles['dashboard__list__view_mode--empty'],
                viewMode === 'Sort' && styles['dashboard__list__view_mode--sort'],
                viewMode === 'Grid' && styles['dashboard__list__view_mode--grid'],
                parentScrollRoot && styles['dashboard__list__view_mode--no-scroll'],
                hideScrollbars && styles['hide-scrollbars'],
              )}
              style={{
                padding,
                overflow: showSkeleton ? 'hidden' : undefined,
                ...(viewMode === 'Grid'
                  ? {
                      transform: `scale(${realZoomLevel})`,
                      width: `calc(100% / ${realZoomLevel})`,
                      minHeight: `calc(100% / ${realZoomLevel})`,
                    }
                  : undefined),
                ...mainListStyle,
              }}
              data-testid="list-items-section"
              data-view-mode={viewMode.toLowerCase()}
            >
              {splitFdocs.map((item, index) => {
                const { title, colorLabel, fdocs: fdocsSplitted, id, resourceId } = item;

                return fdocs.length ||
                  viewMode === 'Sort' ||
                  (index === 0 && viewMode === 'Grid' && alwaysShowFirstDivision) ? (
                  <FdocListDivision
                    // on mobile view we still allow preview per item
                    // on desktop, it's invoking the context menu for all items
                    disableItemPreviewContextMenu={!isMobileView && selectedFdocsIds.length > 1}
                    optimize={optimizeDivisions}
                    parentResourceId={parentResourceId}
                    key={`${colorLabel ? colorLabel.id : title}-${id}-${resourceId}-division`}
                    forcedMobile={forcedMobile}
                    customStyleFunction={customStyleFunction}
                    customItemPropsFunction={customItemPropsFunction}
                    zoomLevel={realZoomLevel}
                    root={ref ?? undefined}
                    slimStyle={slimStyle}
                    fdocTotals={fdocTotals}
                    columns={forcedColumns ?? columns}
                    titleProp={title}
                    viewMode={viewMode}
                    colorLabel={colorLabel}
                    splitFdocs={item}
                    index={index}
                    isSidepanel={isSidepanel}
                    fdocs={fdocsSplitted}
                    hasSeparateDivisions={hasSeparateDivisions}
                    fdocsLoaded={fdocs.length}
                    amountOfDivisions={splitFdocs.length}
                    gap={gap}
                    allowOverrideFolderClick={allowOverrideFolderClick}
                    // This is based on showSkeleton because that forces observers to re-check when it changes
                    rootList={showSkeleton ? undefined : ref}
                    fdocClickHandler={
                      mobileSelectModeIsActive ? handleSelectFdoc : fdocClickHandler
                    }
                    selectionMode={selectionMode}
                    previewSelectedFdocs={previewSelectedFdocs}
                    disabledFdocsIds={disabledFdocsIds}
                    canMultiSelect={canMultiSelect}
                    canDelete={canDelete}
                    canMove={canMove}
                    infiniteTriggerEntityId={infiniteTriggerEntityId}
                    colorLabels={colorLabels}
                    list={list}
                    style={{
                      height: showEmpty ? 100 : undefined,
                      ...(subListStyles ? subListStyles(index) : undefined),
                    }}
                    rootStyle={subListRootStyles ? subListRootStyles(index) : undefined}
                    isFullscreen={isFullscreen}
                    anchor={
                      index === splitFdocs.length - 1 ? (
                        /**
                         * Passing key so we reinitialize the observer on receiving new resources.
                         * On bigger screens and fast scrolling, the observer is pushed down
                         * but not outside the viewport. This means that it won't trigger
                         * onIntersect. By reinitializing (new reference for the dom and new observer),
                         * the onIntersect is triggered again.
                         */
                        <IntersectionObserverAnchor
                          key={`${fdocs.length}-${fdocs[fdocs.length - 1]?.id}`}
                          onIntersect={onInfiniteTrigger}
                          rootMargin={`${400 / zoomLevel}px`}
                        />
                      ) : undefined
                    }
                    resourcePreviewProps={resourcePreviewProps}
                  />
                ) : null;
              })}

              {showEmpty && (
                <div
                  className={clsx(
                    styles.empty_state,
                    nonVerticallyCenteredEmptyState && styles.non_vertically_centered_empty_state,
                    !emptyStateReason && user && styles.empty_state__no_reason,
                  )}
                >
                  {emptyStateImage !== null && (
                    <>
                      {emptyStateReason && !emptyStateImage ? (
                        <EmptyCactusIcon height={201} width={181} />
                      ) : (
                        <Image
                          src={emptyStateImage ?? '/images/empty-fdoc-list.png'}
                          height={emptyStateImageSize?.width ?? 141}
                          width={emptyStateImageSize?.height ?? 201}
                          alt="Empty"
                          draggable={false}
                        />
                      )}
                    </>
                  )}
                  <h1>{emptyStateTitle ?? defaultEmptyStateTitle}</h1>
                  {emptyStateReason ? (
                    <p>{emptyStateReason}</p>
                  ) : (
                    <>
                      {emptyListLink && (
                        <Link href={emptyListLink}>
                          {emptyListMessage ?? defaultEmptyListMessage}
                        </Link>
                      )}
                      {!emptyListLink && <p>{emptyListMessage ?? defaultEmptyListMessage}</p>}
                    </>
                  )}
                </div>
              )}

              {fdocs.length === 0 && showSkeleton && (
                <div className={styles.empty_state} style={{ opacity: 0.2 }}>
                  <Spinner size={21} />
                </div>
              )}
            </section>
            {canMultiSelect && showMultiselectBox && (
              <SelectionBox
                addToSpace={() => addToSpaceHandler()}
                deleteSelection={deleteSelectionHandler}
                selectedFdocsAmount={previewSelectedFdocs.length}
                clearSelection={handleClearSelection}
                deleteText={canDelete ? 'Delete' : 'Remove'}
                canAddToSpace={canAddToSpace}
                canDelete={canDelete}
              />
            )}
            <DropToMove onDropAction={addToSpaceHandler} />
          </div>
        </ContextMenu>
      </DragAndSelectProvider>
      {confirmationModal}
    </ResourcesPreviewUiConfigProvider>
  );
};

export default memo(FdocList);
