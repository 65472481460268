import React from 'react';

export const useImageDimension = (src: string) => {
  const [dimension, setDimension] = React.useState({ width: 0, height: 0 });

  React.useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setDimension({ width: image.naturalWidth, height: image.naturalHeight });
    };
    image.src = src;
  }, [src]);

  return dimension;
};
