import styled from 'styled-components';

import { mediaHover, mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import { Item as ItemPrimitive } from '@radix-ui/react-dropdown-menu';
import { css } from 'styled-components';

type DropdownItemVariant = 'default' | 'danger';

interface DropdownItemProps {
  iconElement?: React.ReactNode;
  variant?: DropdownItemVariant;
}

export const DropdownItemIconContainer = styled.div<{ nonMobileVisible?: boolean }>`
  align-items: center;
  justify-content: center;
  height: 1.5rem;
  width: 1.5rem;
  svg {
    height: 1.5em;
    width: 1.5em;
  }
  display: none;
  ${(p) => css`
    display: ${p.nonMobileVisible ? 'flex' : 'none'};
  `}
  ${mediaMobile} {
    display: flex;
  }
`;

export const cssDropdownItemMobile = css<{ variant?: DropdownItemVariant }>`
  ${mediaMobile} {
    color: ${cssVar['color-text-primary']};
    font-weight: 500;
    background: ${cssVar['color-bg-secondary-button']} !important;
    min-height: 3.75rem;
    border-radius: 0;
    font-size: 0.9375rem;
    padding: 0 1rem;
    gap: 1rem;
    align-items: center;
    display: flex;
    align-items: center;
    ${mediaHover} {
      &:hover {
        border: none;
      }
    }
    &:not(:last-child) {
      border-bottom: 1px solid ${cssVar['color-border-secondary']};
    }

    &:first-child {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }

    &:last-child {
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
    }

    ${(p) =>
      p.variant === 'danger' &&
      css`
        color: ${cssVar['color-app-delete']};
      `}
    kbd {
      display: none;
    }
  }
`;

const cssDropdownItemNonMobile = css`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: fit-content;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  height: max-content;
  min-height: 2rem;
  width: 100%;
  padding: 0.25rem 0.75rem;
  overflow-wrap: anywhere;
  color: ${cssVar['color-text-primary']};
  cursor: pointer;
  transition: background 150ms cubic-bezier(0.165, 0.84, 0.44, 1);
  gap: 6px;
  outline: none;
  min-width: 170px;

  &:is(:hover, :focus-visible) {
    background: ${cssVar['color-bg-quarternary']};

    &:active {
      background: ${cssVar['color-bg-quarternary']};
    }
  }
`;

export const DropdownItemBase = styled.button
  .attrs<DropdownItemProps>(({ iconElement, children, ...rest }) => {
    return {
      ...rest,
      children: (
        <>
          {iconElement && <DropdownItemIconContainer>{iconElement}</DropdownItemIconContainer>}
          {children}
        </>
      ),
    };
  })
  .withConfig(preventForwardPropsConfig(['iconElement', 'variant']))`
  ${cssDropdownItemNonMobile};
  ${cssDropdownItemMobile};

  ${(p) =>
    p.variant === 'danger' &&
    css`
      color: ${cssVar['color-app-delete']};
    `}
  ${(p) =>
    p.disabled &&
    css`
      pointer-events: none;
      color: ${cssVar['color-text-placeholder']};
    `}
`;

export const Item = styled(DropdownItemBase).attrs<DropdownItemProps>((props) => {
  return {
    ...props,
    as: ItemPrimitive,
  };
})``;
