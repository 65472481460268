import React, { useEffect, useRef, useState } from 'react';
import { EmbedElementProps } from '../../expandedResource.types';
import { parseUrlSafe } from '../../expandedResource.utils';

const methodName = 'createTweet';
const twitterWidgetJs = 'https://platform.x.com/widgets.js';

export const TwitterEmbed: React.FC<EmbedElementProps> = ({ url, children }) => {
  const parsedUrl = parseUrlSafe(url);
  const tweetId = parsedUrl?.pathname.split('/')[3];

  const [loading, setLoading] = useState<boolean>(true);
  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const [error, setError] = useState<Error | null>(null);

  const [tweetTarget, setTweetTarget] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!tweetTarget || !loadingRef.current) return;

    tweetTarget.style.position = 'absolute';
    setLoading(true);
    setError(null);

    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const scriptjs = require('scriptjs');

    scriptjs(twitterWidgetJs, 'twitter-embed', async () => {
      if (!window.twttr) {
        const error = new Error('Failure to load window.twttr, aborting load');
        console.error(error.message);
        setLoading(false);
        setError(error);
        return;
      }

      if (!window.twttr.widgets[methodName]) {
        const error = new Error(`Method ${methodName} is not present anymore in twttr.widget api`);
        console.error(error.message);
        setLoading(false);
        setError(error);
        return;
      }

      try {
        tweetTarget.innerHTML = '';
        await window.twttr.widgets[methodName](tweetId, tweetTarget);
      } catch (error: unknown) {
        console.error(error);
        if (error instanceof Error) setError(error);
      } finally {
        setLoading(false);
        tweetTarget.style.position = 'static';
      }
    });

    return () => {
      tweetTarget.innerHTML = '';
    };
  }, [tweetId, tweetTarget]);

  if (!tweetId || error) {
    return children;
  }

  return (
    <React.Fragment>
      {loading && children}
      <div ref={setTweetTarget} />
    </React.Fragment>
  );
};
