import React from 'react';

import { IntersectionObserverArgs, useIntersectionObserver } from './useIntersectionObserver';

type IntersectionObserverComponentProps = Pick<
  IntersectionObserverArgs,
  'enabled' | 'onIntersect' | 'debounceDelay' | 'rootMargin' | 'root' | 'threshold'
>;

export const IntersectionObserverAnchor: React.FC<IntersectionObserverComponentProps> = ({
  enabled = true,
  rootMargin = '400px',
  ...rest
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  useIntersectionObserver({
    target: ref,
    enabled,
    rootMargin,
    ...rest,
  });

  return <div data-anchor ref={ref} />;
};
