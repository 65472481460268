export const RESOURCE_GRID_PREVIEW_CONTENT = {
  height: 240,
  width: 260,
};

export const COMMENT_SECTION_HEIGHT = 33;
export const TITLE_SECTION_HEIGHT = 34;
export const TAGS_SECTION_HEIGHT = 19;

export const DESKTOP_CARD_HEIGHT = 360;
export const DESKTOP_CARD_WIDTH = 260;
