import { FC } from 'react';
import { IconProps } from './types';

const IndexingIcon: FC<IconProps> = ({
  size = 10,
  color = 'currentColor',
  strokeWidth = 0.5,
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      {...props}
    >
      <path
        d="M7.69735 4.29919L9.5285 4.8565C9.67026 4.89965 9.67026 5.10035 9.5285 5.1435L7.69734 5.70081C6.74067 5.99197 5.99197 6.74067 5.70081 7.69735L5.1435 9.5285C5.10035 9.67026 4.89965 9.67026 4.8565 9.5285L4.29919 7.69734C4.00803 6.74067 3.25933 5.99197 2.30265 5.70081L0.471504 5.1435C0.329736 5.10035 0.329736 4.89965 0.471504 4.8565L2.30265 4.29919C3.25933 4.00803 4.00803 3.25933 4.29919 2.30265L4.8565 0.471504C4.89965 0.329736 5.10035 0.329736 5.1435 0.471504L5.70081 2.30266C5.99197 3.25933 6.74067 4.00803 7.69735 4.29919Z"
        fill={color}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default IndexingIcon;
