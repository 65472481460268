import React, { MouseEventHandler } from 'react';

import { useResponsive } from '@/src/hooks/responsive';
import { useDragAndSelect } from '@/src/lib/DragAndSelectContext';
import { getOffCanvasPortal } from '@/src/lib/portals';
import { pick } from '@/src/lib/store';
import useMobileSelectionStore from '@/src/store/mobileSelection';
import inNextServer from '@/src/utils/next';
import { createPortal } from 'react-dom';
import { shallow } from 'zustand/shallow';
import DashboardButton from '../../ui/DashboardButton/DashboardButton';
import styles from './SelectionBox.module.scss';

const SelectionBox: React.FC<{
  canAddToSpace?: boolean;
  addToSpace?: () => void;
  deleteSelection: MouseEventHandler | null;
  selectedFdocsAmount: number;
  clearSelection: () => void;
  deleteText?: string;
  canDelete?: boolean;
}> = ({
  canAddToSpace = false,
  addToSpace = () => {},
  deleteSelection,
  selectedFdocsAmount,
  clearSelection,
  deleteText = 'Delete',
  canDelete,
}) => {
  const dragAndSelectContext = useDragAndSelect();
  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  const { isMobile } = useResponsive();
  const { mobileSelectModeIsActive, disableMobileSelectMode } = useMobileSelectionStore(
    (state) => pick(state, ['mobileSelectModeIsActive', 'disableMobileSelectMode']),
    shallow,
  );

  // no actions available
  if ((!deleteSelection || !canDelete) && !canAddToSpace) return null;

  const element =
    selectedFdocsAmount > 0 || mobileSelectModeIsActive ? (
      <div className={styles.selection_box}>
        <DashboardButton
          className={styles.selection_box__unselect}
          color="transparent"
          onClick={clearSelection}
          onMouseDown={(e) => {
            disableMobileSelectMode();
            stopPropagation(e);
          }}
          data-testid="selection-unselect-all-button"
        >
          {isMobile() ? 'Cancel' : 'Unselect all'}
        </DashboardButton>

        {deleteSelection && (
          <DashboardButton
            className={styles.button}
            onClick={deleteSelection}
            onMouseDown={stopPropagation}
            data-testid="selection-delete-button"
            color="light-red"
          >
            {deleteText}
            <span>{selectedFdocsAmount}</span>
          </DashboardButton>
        )}

        {canAddToSpace && (
          <DashboardButton
            className={styles.button}
            onClick={addToSpace}
            onMouseDown={stopPropagation}
            data-testid="selection-add-button"
            color="light-primary"
          >
            Move <span>{selectedFdocsAmount}</span>
          </DashboardButton>
        )}
      </div>
    ) : null;

  if (dragAndSelectContext?.isDragging) return null;
  if (inNextServer()) return element;

  return createPortal(element, getOffCanvasPortal());
};

export default SelectionBox;
