import FdocItemV2 from '@/src/components/FdocItem/FdocItemV2';
import { OfViewMode } from '@/src/components/ViewModeSwitcher/ViewModeSwitcher';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { ResourcesPreviewUiConfigProvider } from '@/src/modules/resources/components/ResourcePreview/resourcePreviewUiConfigContext';
import { ResourceUiStoreContext } from '@/src/modules/resources/stores/useResourcesUiStore';
import { Space } from '@/src/modules/spaces/spaces.types';
import { IntersectionObserverAnchor } from '@/src/modules/ui/components/IntersectionObserver/IntersectionObserver';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import React from 'react';

/**
 * This is a barebone list of resources without additional features
 * Wrap the list with a container which controls width of items.
 *
 * This is not meant to replace the FdocList but rather meant to serve as a base layer of smarter lists.
 */
export const ResourceList: React.FC<{
  optimize?: boolean;
  viewMode: OfViewMode;
  disableItemPreviewContextMenu?: boolean;

  colorLabels?: ApiColorLabel[];
  resources: OptimisticDraft<Fdoc>[];
  list?: Space;

  fdocClickHandler: (fdoc: Fdoc) => void;
  handleOnRemove?: (fdoc: Fdoc) => void;
  selectionMode?: boolean;
  disabledFdocsIds?: string[];
  canMultiSelect?: boolean;
  canDelete?: boolean;
  canMove?: boolean;
  allowOverrideFolderClick?: boolean;
  fetchNextPage?: () => void;

  uiContext?: ResourceUiStoreContext;
}> = ({
  viewMode,
  colorLabels,
  list,
  disableItemPreviewContextMenu,
  handleOnRemove,
  fdocClickHandler,
  allowOverrideFolderClick = false,
  selectionMode,
  disabledFdocsIds = [],
  canMultiSelect,
  canDelete,
  canMove = true,

  // resources
  resources,
  fetchNextPage,

  uiContext,
}) => {
  return (
    <>
      <ResourcesPreviewUiConfigProvider uiContext={uiContext}>
        {resources.map((resource) => (
          <FdocItemV2
            key={resource.id}
            viewMode={viewMode}
            handleOnRemove={handleOnRemove}
            handleOnClick={fdocClickHandler}
            isFolderClickOverriden={allowOverrideFolderClick}
            fdoc={resource}
            selectionMode={selectionMode}
            // selected={previewSelectedFdocs.includes(resources.id ?? '')}
            disableContextMenu={disableItemPreviewContextMenu}
            canMultiSelect={canMultiSelect}
            canDelete={canDelete}
            canMove={canMove}
            colorLabels={colorLabels}
            list={list}
            disabled={disabledFdocsIds.includes(resource?.id ?? '')}
          />
        ))}
      </ResourcesPreviewUiConfigProvider>

      <IntersectionObserverAnchor
        onIntersect={fetchNextPage}
        enabled={!!fetchNextPage}
        rootMargin="1000px"
      />
    </>
  );
};
