import VideoIcon from '@/public/images/icons/Video.svg';
import FileIcon from '@/src/components/FileIcon';
import { useResourceStoredMetadata } from '@/src/modules/resources/hooks/useResourceStoredMetadata';
import { Image } from '@/src/modules/ui/components/Image';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { StoredFileFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import styled from 'styled-components';

interface ResourcePreviewStoredFileProps {
  resource: OptimisticDraft<StoredFileFdoc>;
}

export const ResourcePreviewListItemStoredFile: React.FC<ResourcePreviewStoredFileProps> = ({
  resource,
}) => {
  const { isVideo, thumbnail, title } = useResourceStoredMetadata(resource);

  return (
    <Wrapper>
      {/** image  */}
      {thumbnail ? (
        <ThumbnailWrapper>
          {isVideo && (
            <IconContainer>
              <VideoIcon />
            </IconContainer>
          )}
          <ThumbnailImage src={thumbnail.sm} alt={title} />
        </ThumbnailWrapper>
      ) : (
        <>
          <FileIcon
            height={32}
            width={32}
            extension={resource.data.extension?.replace('.', '') || '?'}
          />
        </>
      )}
    </Wrapper>
  );
};

const ThumbnailImage = styled(Image)`
  height: auto;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  display: block;
  border-radius: 0.25rem;
  border: 1px solid rgba(${cssVar['color-text-primary-rgb']}, 0.06);
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex-grow: 1;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 3rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 1rem !important;
    width: 1rem !important;
  }
`;

const ThumbnailWrapper = styled.div`
  position: relative;
`;
