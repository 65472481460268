'use client';

import { cssVar } from '@/src/modules/ui/theme/variables';
import * as LabelPrimitive from '@radix-ui/react-label';
import styled from 'styled-components';

export const Label = styled(LabelPrimitive.Root)`
  font-size: 14px;
  font-weight: 500;
  color: ${cssVar['color-text-secondary']};
  user-select: none;
  cursor: default;
`;
