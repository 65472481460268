import React from 'react';
import { Editor, EditorEvents } from '@tiptap/react';
import ImageIcon from 'remixicon-react/Image2LineIcon';
import * as Popover from '@radix-ui/react-popover';
import styles from './ToolbarCustomImageButton.module.scss';
import { Link1Icon } from '@radix-ui/react-icons';
import isUrl from 'is-url';
import clsx from 'clsx';
import { toast } from '@/src/store/alerts';
import { useResponsive } from '@/src/hooks/responsive';
import { EmbedPluginKey } from '../../../Extensions/Embed';
import { TextInput } from '@/src/modules/ui/components/TextInput/TextInput';
import { Button } from '@/src/modules/ui/components/Button';

const ToolbarCustomImageButton: React.FC<{
  editor: Editor;
}> = ({ editor }) => {
  const [open, setOpen] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [url, setUrl] = React.useState<string | null>(null);

  const [canUploadImage, setCanUploadImage] = React.useState(false);

  React.useEffect(() => {
    const onUpdate = ({ editor }: EditorEvents['update']): void => {
      const embedStorage = EmbedPluginKey.getState(editor.state);

      setCanUploadImage(!!embedStorage.onUpload);
    };

    editor.on('update', onUpdate);

    return () => {
      editor.off('update', onUpdate);
    };
  }, [editor]);

  const isValidUrl = React.useMemo(() => {
    if (!url || !isUrl(url)) return false;

    // prevent base64 or local blob urls
    if (url.startsWith('data:') || url.startsWith('blob:')) return false;

    return true;
  }, [url]);

  const handleSetImageWithUrl = async () => {
    if (!url || !isValidUrl) return;

    // test if the image is real and toast if invalid
    const isImage = await new Promise((resolve) => {
      const img = new Image();
      img.onload = () => resolve(true);
      img.onerror = () => resolve(false);
      img.src = url;
    });

    if (!isImage || !isUrl(url) || url.startsWith('data:') || url.startsWith('blob:')) {
      toast({ content: 'Unable to load image.' });
      setOpen(false);
      return;
    }

    editor.chain().focus().setImage({ src: url }).run();
    setOpen(false);
  };

  const handleUploadImage = (file: File) => {
    if (!canUploadImage) return;

    editor.chain().focus().uploadImage(file).run();
    setOpen(false);
  };

  const onClickDropZone = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const [filesOver, setFilesOver] = React.useState(false);
  const handleDragEnter = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFilesOver(true);
  };

  const handleDragOver = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFilesOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFilesOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!e.dataTransfer.files || e.dataTransfer.files.length === 0) return;

    setFilesOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      handleUploadImage(e.dataTransfer.files[0]);
    }
  };

  const { isMobileView } = useResponsive();

  const dropZoneText = React.useMemo(() => {
    if (isMobileView) return 'Click to upload';

    return filesOver ? 'Drop to upload' : 'Drop or click to upload';
  }, [filesOver, isMobileView]);

  return (
    <Popover.Root open={open && canUploadImage} onOpenChange={setOpen}>
      <Popover.Trigger asChild>
        <button
          className={styles.trigger}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchEnd={(e) => e.stopPropagation()}
          onClick={(e) => e.stopPropagation()}
        >
          <ImageIcon size={16} />
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          data-tiptap-toolbar
          onFocusOutside={(e) => {
            e.preventDefault();
          }}
          sideOffset={10}
          align="start"
          className={styles.popover}
          side={isMobileView ? 'top' : 'bottom'}
        >
          <TextInput
            type="text"
            placeholder="Image URL"
            onTouchStart={(e) => e.stopPropagation()}
            onTouchEnd={(e) => e.stopPropagation()}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => setUrl(e.target.value)}
            value={url ?? ''}
            endAdornment={
              <Button
                style={{ marginRight: -5 }}
                size="xs"
                variant="icon"
                onClick={handleSetImageWithUrl}
                disabled={!isValidUrl}
              >
                <Link1Icon
                  style={{
                    width: 16,
                    height: 16,
                  }}
                />
              </Button>
            }
          />

          <button
            className={clsx(styles.dropZone, filesOver && styles.dropZoneOver)}
            onClick={onClickDropZone}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {dropZoneText}
          </button>

          {/* includes an input to use an URL and a box for drag and drop / click to upload */}
          <input
            type="file"
            style={{ display: 'none' }}
            ref={inputRef}
            accept="image/*"
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                handleUploadImage(e.target.files[0]);
              }
            }}
          />

          <Popover.Arrow className={styles.arrow} />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export default ToolbarCustomImageButton;
