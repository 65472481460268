import { useResourceStoredMetadata } from '@/src/modules/resources/hooks/useResourceStoredMetadata';
import { FaviconImage } from '@/src/modules/ui/components/Favicon/FaviconImage';
import { Flex } from '@/src/modules/ui/components/Flex';
import { P } from '@/src/modules/ui/components/Typography';
import { cssTextEllipsis } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { cleanUrl } from '@/src/utils/text';
import styled from 'styled-components';

interface ResourcePreviewContentProps {
  resource: OptimisticDraft<Fdoc>;
}

const PageUrl: React.FC<{ url: string }> = ({ url }) => {
  return (
    <Flex alignItems="center" gap="element">
      <StyledFavicon pageUrl={url} />
      <Url>{cleanUrl(url)}</Url>
    </Flex>
  );
};

export const ResourcePreviewListTitle: React.FC<ResourcePreviewContentProps> = ({ resource }) => {
  const { titleWithExtension, titlePlaceholder } = useResourceStoredMetadata(resource);

  return (
    <Wrapper direction="column" gap="element">
      <P size="xs" color="tertiary" ellipsis as="div">
        {titleWithExtension || <P color="placeholder">{titlePlaceholder}</P>}
      </P>
      {'pageUrl' in resource.data && resource.data.pageUrl && (
        <PageUrl url={resource.data.pageUrl} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled(Flex).attrs({ direction: 'column' })`
  gap: 0.25rem;
`;

const StyledFavicon = styled(FaviconImage)`
  height: 1rem;
  width: 1rem;
  border-radius: 0.125rem;
  padding: 0;
`;

const Url = styled.div`
  ${cssTextEllipsis};
  font-size: 0.75rem;
  color: ${cssVar['color-text-placeholder']};
`;
