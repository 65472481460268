import InvertedFabricIcon from '@/public/images/icons/Fabric.svg';
import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { EVERYTHING_CONTEXT, WELCOME_MESSAGE } from '@/src/modules/assistant/constants';
import {
  AssistantContextOption,
  AssistantContextSubType,
  ChatbotConversationMessage,
  ChatbotConversationUserAction,
} from '@/src/modules/assistant/types';
import { getContextKey, getContextKeyFromMessage } from '@/src/modules/assistant/utils/context';
import { useQueryResource } from '@/src/modules/resources/queries/useQueryResource';
import { getResourceTitleWithDefault } from '@/src/modules/resources/utils/getResourceTitle';
import * as Conversation from '@/src/modules/ui/components/Conversation';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { CSS_THEME_COLOR } from '@/src/modules/ui/theme/theme';
import Tooltip from '@/src/ui/Tooltip';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useChabot } from '../../hooks/chatbot';
import TypingDots from '../TypingDots/TypingDots';
import ChatbotError from './ChatbotError';
import ChatbotMessage, { FabricAvatar } from './ChatbotMessage';
import ChatbotWarning from './ChatbotWarning';

const MessageListScrollArea = styled(ScrollArea)`
  width: 100%;
  margin: 0 auto;

  & > [data-radix-scroll-area-viewport] > div {
    display: block !important;
  }
`;

interface MessageListProps extends React.PropsWithChildren {
  fadeColor?: CSS_THEME_COLOR;
}

const ContextChangeTag: React.FC<{
  context: AssistantContextOption;
  action?: ChatbotConversationUserAction;
}> = ({ context }) => {
  const { subType, value } = context;

  const { resource } = useQueryResource(Array.isArray(value) ? value[0] : undefined, {
    enabled: subType === AssistantContextSubType.File,
  });

  const resourceTitle = resource ? getResourceTitleWithDefault(resource) : null;
  const name = context.name ?? resourceTitle;

  const displayedName = name ?? context.subType;

  return (
    <Tooltip label={name}>
      <Conversation.InfoTag>Context changed to {displayedName}</Conversation.InfoTag>
    </Tooltip>
  );
};

export const MessagesListAnon: React.FC<MessageListProps> = () => {
  return (
    <div>
      <Conversation.List>
        <ChatbotWarning />
        <ChatbotMessage message={WELCOME_MESSAGE} />
      </Conversation.List>
    </div>
  );
};

const MessagesList: React.FC<MessageListProps> = ({ children, fadeColor }) => {
  const listRef = useRef<HTMLDivElement | null>(null);

  const [firstScroll, setFirstScroll] = useState<boolean>(false);
  const firstScrollRef = useRef<boolean>(false);
  firstScrollRef.current = firstScroll;

  const { messages, isLoading, isStreamingAnswer, streamingAnswer, activeContext } = useChabot();

  useClientLayoutEffect(() => {
    const performScroll = () => {
      const scrollDiv = listRef.current?.querySelector('[data-radix-scroll-area-viewport]');

      scrollDiv?.scrollBy({
        top: scrollDiv?.scrollHeight,
        behavior: 'auto',
      });
    };

    if (!firstScrollRef.current) performScroll();
    else setTimeout(performScroll, 100);

    setFirstScroll(true);
  }, [messages, streamingAnswer, isStreamingAnswer]);

  const messagesByContext = useMemo(() => {
    let lastContextKey: string | undefined = undefined;
    return messages?.reduce(
      (acc, message) => {
        const contextKey = getContextKeyFromMessage(message);

        if (!lastContextKey || contextKey !== lastContextKey) {
          acc.push({
            first: acc.length === 0,
            context: message.context ?? EVERYTHING_CONTEXT,
            action: message.action,
            messages: [],
          });
          lastContextKey = contextKey;
        }

        acc[acc.length - 1].messages.push(message);

        return acc;
      },
      [] as {
        first: boolean;
        context: AssistantContextOption;
        action?: ChatbotConversationUserAction;
        messages: ChatbotConversationMessage[];
      }[],
    );
  }, [messages]);

  const lastMessageContextKey = getContextKeyFromMessage(messages[messages.length - 1]);
  const isStreamingContextDifferent =
    streamingAnswer && getContextKeyFromMessage(streamingAnswer) !== lastMessageContextKey;
  const isActiveContextDifferent =
    !streamingAnswer && getContextKey(activeContext) !== lastMessageContextKey;

  return (
    <MessageListScrollArea
      ref={listRef}
      scrollbarVariant="subtle"
      fade
      fadeColor={fadeColor}
      style={{
        flexGrow: 1,
      }}
    >
      <Conversation.List>
        <ChatbotWarning />

        <ChatbotMessage message={WELCOME_MESSAGE} />

        {messagesByContext.map(({ context, messages, action, first }, i) => (
          <React.Fragment key={`${context.subType}-${context.value}-${i}`}>
            {!first && <ContextChangeTag context={context} action={action} />}
            {messages.map((message) => (
              <ChatbotMessage key={message.id} message={message} />
            ))}
          </React.Fragment>
        ))}

        {isStreamingContextDifferent && streamingAnswer && streamingAnswer.context && (
          <ContextChangeTag context={streamingAnswer.context} action={streamingAnswer.action} />
        )}

        {isStreamingAnswer && streamingAnswer && <ChatbotMessage message={streamingAnswer} />}

        {isLoading && (
          <div className="flex justify-start mb-4 self-start">
            <div className="flex items-center space-x-4">
              <FabricAvatar size={26}>
                <InvertedFabricIcon width={20} />
              </FabricAvatar>
            </div>
            <div className="relative flex items-center justify-between ml-3 text-gray-500 rounded-full loader">
              <TypingDots dotSize={8} />
            </div>
          </div>
        )}

        {isActiveContextDifferent && activeContext && messages.length > 0 && (
          <ContextChangeTag context={activeContext} />
        )}

        <ChatbotError />

        {children}
      </Conversation.List>
    </MessageListScrollArea>
  );
};

export default MessagesList;
