import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { ImageFdoc, PageFdoc, TextFdoc } from '@/src/types/api';
import { LinkPreview } from '@fabric/woody-client';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { resourceDetailsQueryKeys } from './resourceDetailsQueryKeys';

/**
 * Fetches the preview for a given page resource.
 *
 * @param fdoc
 * @param queryOptions
 * @returns
 */
export const useQueryPageResourcePreview = (
  fdoc: PageFdoc | TextFdoc | ImageFdoc,
  queryOptions?: Partial<QueryObserverOptions<LinkPreview>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: resourceDetailsQueryKeys.pagePreview(fdoc.id),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;
      return getWoodyResponseData(await client.fetchLinkPreview(safeResourceId));
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    refetchInterval: (query) => {
      // If not fresh we retry in the background with increasing intervals based on the error count.
      return query.state.error ? 1000 * 60 * query.state.fetchFailureCount : false;
    },
    retry: false,
    ...queryOptions,
    enabled: isQueryEnabled([!!fdoc.id, queryOptions?.enabled]),
  });

  return {
    ...query,
  };
};
