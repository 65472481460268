import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { SummarizeInlineButton } from '@/src/modules/assistant/components/SummarizeInlineButton';
import Tooltip from '@/src/ui/Tooltip';
import { isNoop } from '@/src/utils/noop';
import { BubbleMenuPluginProps } from '@tiptap/extension-bubble-menu';
import { BubbleMenu, Editor, isTextSelection } from '@tiptap/react';
import React, { useEffect, useMemo, useState } from 'react';
import SimilarItemsInlineButton from '../../SimilarItemsInlineButton/SimilarItemsInlineButton';
import Toolbar from '../ui/Toolbar/Toolbar';
import { textFormattingGroup } from '../ui/Toolbar/toolbarConfig';
import { ToolbarGroup, ToolbarItemType } from '../ui/Toolbar/types';

const InlineToolbar: React.FC<{
  editor: Editor;
  contentRef: HTMLDivElement;
}> = ({ editor, contentRef }) => {
  const { onGetSimilarItemsToText, onSummarizeText } = useExpandedFdocContext();

  const [selectedText, setSelectedText] = useState<string | null>(null);
  const [bubbleRef, setBubbleRef] = useState<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!editor || isNoop(isNoop)) return;

    const onSelectionChange = () => {
      const selection = editor.state.selection;
      if (selection) {
        const text = editor.state.doc.textBetween(selection.from, selection.to, '\n');
        setSelectedText(text);
      } else {
        setSelectedText(null);
      }
    };

    editor?.on('selectionUpdate', onSelectionChange);

    return () => {
      editor?.off('selectionUpdate', onSelectionChange);
    };
  }, [editor, onGetSimilarItemsToText]);

  const isSimilarDisabled = useMemo(() => {
    if (!selectedText) return 'No text selected.';

    return selectedText.length > 1000
      ? 'This highlight is too long, please highlight less than 1000 characters.'
      : undefined;
  }, [selectedText]);

  const aiGroup = useMemo<ToolbarGroup | undefined>(
    () =>
      !isNoop(onGetSimilarItemsToText) && !isNoop(onSummarizeText) && !!selectedText
        ? {
            name: 'AI',
            label: 'AI',
            items: [
              {
                type: ToolbarItemType.Custom,
                key: 'similar',
                render: () => (
                  <Tooltip
                    key="similar"
                    label={isSimilarDisabled ? isSimilarDisabled : 'See similar items'}
                  >
                    <div>
                      <SimilarItemsInlineButton
                        onClick={() => onGetSimilarItemsToText?.(selectedText)}
                        onPointerDown={(e) => e.preventDefault()}
                        style={{ marginLeft: 6 }}
                        disabled={!!isSimilarDisabled}
                      />
                    </div>
                  </Tooltip>
                ),
              },
              {
                type: ToolbarItemType.Custom,
                key: 'summarize',
                render: () => (
                  <Tooltip key="summarize" label="Summarize text">
                    <SummarizeInlineButton
                      onClick={() => onSummarizeText?.(selectedText)}
                      style={{ marginLeft: 2 }}
                      onPointerDown={(e) => e.preventDefault()}
                    />
                  </Tooltip>
                ),
              },
            ],
          }
        : undefined,

    [isSimilarDisabled, onGetSimilarItemsToText, onSummarizeText, selectedText],
  );

  const toolbarGroups = useMemo<ToolbarGroup[]>(
    () => [textFormattingGroup, ...(aiGroup ? [aiGroup] : [])],
    [aiGroup],
  );

  /**
   * This is from the official package, we want to hide the bubble menu when it is selecting an embed.
   * But the shouldShow replaces the default one and there is no way to keep the original functionality,
   * so this is how the original shouldShow works + the embed check.
   * Official package code:
   * https://github.com/ueberdosis/tiptap/blob/3a6c7a063c1fd51ed1ad136afaacb550fb50b7cb/packages/extension-bubble-menu/src/bubble-menu-plugin.ts#L47
   */
  const shouldShow: Exclude<BubbleMenuPluginProps['shouldShow'], null> = ({
    view,
    state,
    from,
    to,
    editor,
  }) => {
    if (editor.isActive('embed')) return false;
    const { doc, selection } = state;
    const { empty } = selection;
    const isEmptyTextBlock = !doc.textBetween(from, to).length && isTextSelection(state.selection);
    const isChildOfMenu = bubbleRef?.contains(document.activeElement);
    const hasEditorFocus = view.hasFocus() || isChildOfMenu;
    if (!hasEditorFocus || empty || isEmptyTextBlock || !editor.isEditable) {
      return false;
    }
    return true;
  };

  return (
    <div ref={setBubbleRef} className="absolute">
      <BubbleMenu
        editor={editor}
        shouldShow={shouldShow}
        tippyOptions={{
          duration: 0,
          delay: 250,
          popperOptions: {
            modifiers: [
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [0, 10],
                },
              },
              {
                name: 'flip',
                enabled: true,
                options: {
                  fallbackPlacements: ['top', 'bottom'],
                  boundary: contentRef ?? undefined,
                },
              },
            ],
          },
        }}
      >
        <Toolbar editor={editor} groups={toolbarGroups} floating noSeperator />
      </BubbleMenu>
    </div>
  );
};

export default InlineToolbar;
