import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import { useResponsive } from '@/src/hooks/responsive';
import { SummarizeInlineButton } from '@/src/modules/assistant/components/SummarizeInlineButton';
import Tooltip from '@/src/ui/Tooltip';
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import SimilarItemsInlineButton from '../../SimilarItemsInlineButton/SimilarItemsInlineButton';
import styles from './StoredPDFContent.module.scss';

const PDFToolbar: React.FC<{
  innerDivRef: HTMLElement | null;
}> = ({ innerDivRef }) => {
  const { onGetSimilarItemsToText, onSummarizeText } = useExpandedFdocContext();

  const { isMobile } = useResponsive();
  const [toolbarPosition, setToolbarPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);

  const [currentTextSize, setCurrentTextSize] = useState<number>(0);
  const [toolbarRef, setToolbarRef] = useState<HTMLDivElement | null>(null);

  const [hidden, setHidden] = useState<boolean>(false);

  useEffect(() => {
    const onMouseDown = (e: MouseEvent) => {
      if (!toolbarRef?.contains(e.target as Node)) {
        setHidden(true);
      }
    };

    const onMouseUp = (_: MouseEvent) => {
      setHidden(false);
    };

    window.addEventListener('mousedown', onMouseDown, true);
    window.addEventListener('mouseup', onMouseUp, true);

    return () => {
      window.removeEventListener('mousedown', onMouseDown, true);
      window.removeEventListener('mouseup', onMouseUp, true);
    };
  }, [toolbarRef]);

  useEffect(() => {
    if (!innerDivRef || isMobile()) return;

    // check for selections in the document, if inside the innerDivRef and it's text we will show toolbar
    const onSelectionChange = (_: Event) => {
      const selection = window.getSelection();

      if (!selection) {
        setToolbarPosition(null);
        return;
      }

      // if it's not inside the innerDivRef, we don't care
      if (!innerDivRef.contains(selection.anchorNode)) {
        setToolbarPosition(null);
        return;
      }

      // if it's not text, we don't care
      if (selection.type !== 'Range') {
        setToolbarPosition(null);
        return;
      }

      // get the bounding rect of the selection
      const range = selection.getRangeAt(0);
      const rect = range.getBoundingClientRect();

      // calculate the position of the toolbar
      const top = rect.top - 20;
      const left = rect.left + rect.width / 2;

      setToolbarPosition({
        top,
        left,
      });

      const textSize = selection.toString().length;
      setCurrentTextSize(textSize);
    };

    document.addEventListener('selectionchange', onSelectionChange);

    return () => {
      document.removeEventListener('selectionchange', onSelectionChange);
    };
  }, [innerDivRef, isMobile]);

  const handleOnGetSimilarItemsToText = () => {
    // get the selected text
    const selection = window.getSelection();
    if (!selection) return;

    const text = selection.toString();

    // call the callback
    onGetSimilarItemsToText(text);
    setToolbarPosition(null);
  };

  const handleOnSummarizeText = () => {
    // get the selected text
    const selection = window.getSelection();
    if (!selection) return;

    const text = selection.toString();

    onSummarizeText(text);
    setToolbarPosition(null);
  };

  const isSimilarDisabled = currentTextSize > 1000;

  if (isMobile() || hidden) return null;

  return (
    toolbarPosition &&
    createPortal(
      <div
        className={styles.pdf_toolbar}
        ref={setToolbarRef}
        style={{
          top: toolbarPosition.top,
          left: toolbarPosition.left,
        }}
      >
        <Tooltip
          label={
            isSimilarDisabled
              ? 'This highlight is too long, please highlight less than 1000 characters.'
              : 'See similar items'
          }
        >
          <div>
            <SimilarItemsInlineButton
              onClick={handleOnGetSimilarItemsToText}
              disabled={isSimilarDisabled}
            />
          </div>
        </Tooltip>
        <SummarizeInlineButton onClick={handleOnSummarizeText} />
      </div>,
      document.querySelector('#tooltip-portal') ?? document.body,
    )
  );
};

export default PDFToolbar;
