import { IconGlobe } from '@/src/modules/icons';
import {
  FaviconSize,
  useQueryPageFavicon,
} from '@/src/modules/resource-detail/queries/useQueryPageFavicon';
import { Image } from '@/src/modules/ui/components/Image';
import styled from 'styled-components';

interface FaviconImageProps {
  pageUrl: string;
  className?: string;
  sizeToLoad?: FaviconSize;
}

export const FaviconImage: React.FC<FaviconImageProps> = ({
  pageUrl,
  className,
  /**
   * you want to load an image bigger that the size rendering - retina displays
   * To control the rendered size pass a classname or use styled components with styled(FaviconImage)
   */
  sizeToLoad = 32,
}) => {
  const { data: faviconUrl } = useQueryPageFavicon(pageUrl, sizeToLoad);

  return (
    <IconImage src={faviconUrl || undefined} alt="" className={className}>
      <IconGlobe className={className} />
    </IconImage>
  );
};

const IconImage = styled(Image)`
  background: transparent;
  border-radius: 5px;
`;
