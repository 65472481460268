import { componentBaseConfig } from '@/src/lib/styled-components/componentBaseConfig';
import { Content as ContentPrimitive } from '@radix-ui/react-context-menu';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const ContextMenuItemsContainer = styled.div`
  padding: 3px;
  border-radius: 12px;
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.1);
  min-width: 190px;
  will-change: opacity;
  opacity: 0;
`;
export const ContextMenuContent = styled(ContentPrimitive)
  .withConfig(componentBaseConfig)
  .attrs((props) => {
    return {
      ...props,
      children: <ContextMenuItemsContainer>{props.children}</ContextMenuItemsContainer>,
    };
  })`
  transition: 0.1s opacity;
  opacity: 0;

  &[data-state='open'] {
    animation: ${fadeIn} 0.1s ease-out forwards;
    ${ContextMenuItemsContainer} {
      animation: ${fadeIn} 0.1s ease-out forwards;
    }
  }

  &[data-state='closed'] {
    animation: ${fadeOut} 0.1s ease-out forwards;
    ${ContextMenuItemsContainer} {
      animation: ${fadeOut} 0.1s ease-out forwards;
    }
  }
  div:has(> &) {
    // the content is still wrapped in a div which renders in the portal
    z-index: 126 !important;
  }
`;
