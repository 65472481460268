import { Descendant, FabricDataValue } from '@/src/components/Tiptap/slate.types';
import { EditorJSBlockData, EditorJSData } from '@fabric/woody-client';
import { Content } from '@tiptap/react';

export const TIPTAP_EDITOR_SCHEMA_VERSION = '1.0.0';

export type TiptapDataValue = {
  version: string;
  lastUpdated: number; // unix timestamp for last update from Tiptap
  content: Content;

  // For old compatibility
  time?: number; // unix timestamp for last update from old FabricEditor
  data?: Descendant[]; // old FabricEditor data
  blocks: EditorJSBlockData[]; // old editor.js blocks, also used for embeddings logic
};

export type AllNotepadValues = TiptapDataValue | FabricDataValue | EditorJSData;
