import { MouseEventHandler } from 'react';
import styled from 'styled-components';

const CornerTopLet = styled.div`
  position: absolute;
  top: calc(-1 * var(--corner-offset));
  left: calc(-1 * var(--corner-offset));
  width: calc(2 * var(--corner-offset));
  height: calc(2 * var(--corner-offset));
  z-index: 5;

  cursor: nwse-resize;
`;

const CornerTopRight = styled.div`
  position: absolute;
  top: calc(-1 * var(--corner-offset));
  right: calc(-1 * var(--corner-offset));
  width: calc(2 * var(--corner-offset));
  height: calc(2 * var(--corner-offset));
  z-index: 5;

  cursor: nesw-resize;
`;

const CornerBottomLeft = styled.div`
  position: absolute;
  bottom: calc(-1 * var(--corner-offset));
  left: calc(-1 * var(--corner-offset));
  width: calc(2 * var(--corner-offset));
  height: calc(2 * var(--corner-offset));
  z-index: 5;

  cursor: nesw-resize;
`;

const CornerBottomRight = styled.div`
  position: absolute;
  bottom: calc(-1 * var(--corner-offset));
  right: calc(-1 * var(--corner-offset));
  width: calc(2 * var(--corner-offset));
  height: calc(2 * var(--corner-offset));
  z-index: 5;

  cursor: nwse-resize;
`;

const BottomEdge = styled.div`
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 10px;

  z-index: 4;

  cursor: ns-resize;
`;

const RightEdge = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: -5px;
  width: 10px;

  z-index: 4;

  cursor: ew-resize;
`;

const LeftEdge = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: -5px;
  width: 10px;

  z-index: 4;

  cursor: ew-resize;
`;

const TopEdge = styled.div`
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  height: 10px;

  z-index: 4;

  cursor: ns-resize;
`;

export const ResizeableEdges: React.FC<{
  onResizeMouseDown: MouseEventHandler<HTMLDivElement>;
}> = ({ onResizeMouseDown }) => {
  return (
    <>
      <CornerTopLet data-from="top-left" onMouseDown={onResizeMouseDown} />
      <CornerTopRight data-from="top-right" onMouseDown={onResizeMouseDown} />
      <CornerBottomLeft data-from="bottom-left" onMouseDown={onResizeMouseDown} />
      <CornerBottomRight data-from="bottom-right" onMouseDown={onResizeMouseDown} />
      <BottomEdge data-from="bottom" onMouseDown={onResizeMouseDown} />
      <RightEdge data-from="right" onMouseDown={onResizeMouseDown} />
      <LeftEdge data-from="left" onMouseDown={onResizeMouseDown} />
      <TopEdge data-from="top" onMouseDown={onResizeMouseDown} />
    </>
  );
};
