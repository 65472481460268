import React from 'react';
import styles from './TypingDots.module.scss';

const TypingDots: React.FC<{
  dotSize?: number;
  dotColor?: string;
  gap?: number;
  speed?: number;
}> = ({ dotSize = 10, dotColor = 'black', gap = 5, speed = 1500 }) => {
  return (
    <div
      className={styles.typing}
      style={
        {
          '--dot-size': `${dotSize}px`,
          '--dot-color': dotColor,
          '--speed': `${speed}ms`,
          gap: `${gap}px`,
        } as React.CSSProperties
      }
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default TypingDots;
