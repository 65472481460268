'use client';

import ChevronUpIcon from '@/src/icons/ChevronUpIcon';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as SelectPrimitive from '@radix-ui/react-select';
import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Button } from './Button';
// @todo cleanup chevron down fill icon
import ChevronDownFill from '@/public/images/icons/ChevronDownFill.svg';
import useDebounce from '@/src/hooks/debounce';
import { useResponsive } from '@/src/hooks/responsive';
import { useBoolState } from '@/src/hooks/useBooleanState';
import { useInputControls } from '@/src/hooks/useInputControls';
import CheckIcon from '@/src/icons/CheckIcon';
import { componentBaseConfig } from '@/src/lib/styled-components/componentBaseConfig';
import { cssRadixPopoverContentOverride } from '@/src/modules/ui/components/Drawer/cssRadixPopoverOverride';
import { Drawer } from '@/src/modules/ui/components/Drawer/Drawer';
import {
  cssDropdownItemMobile,
  DropdownItemIconContainer,
} from '@/src/modules/ui/components/DropdownMenu/Item';
import {
  FieldDescription,
  FieldGroup,
  FieldHeader,
  FieldLabel,
} from '@/src/modules/ui/components/Field';
import { mediaMobile, mediaNotMobile } from '@/src/modules/ui/styled-utils';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import { motion } from 'framer-motion';
import { cssVar } from '../theme/variables';
import ScrollArea from './ScrollArea';

type SelectVariant = 'default' | 'inline';

// @TODO - Standardize animations
const animateIn = keyframes`
  from {
    opacity: 0;
    transform: translate(var(--select-translate-x), var(--select-translate-y));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// @TODO - Standardize animations
const animateOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translate(var(--select-translate-x), var(--select-translate-y));
  }
`;

const SelectChevronUp = styled(ChevronUpIcon)`
  color: ${cssVar['color-text-tertiary']};
  transition: transform 200ms;
  transform: rotate(180deg);
`;

const SelectChevronDownFill = styled(ChevronDownFill)`
  color: ${cssVar['color-text-tertiary']};
  transition: transform 200ms;
  transform: rotate(0deg);
`;

const SelectTriggerIcon = styled(SelectPrimitive.Icon).attrs((props) => {
  return {
    asChild: true,
    children: <SelectChevronUp />,
    ...props,
  };
})``;

type SelectTriggerButtonProps = {
  selectVariant?: SelectVariant;
  fixedWidth?: number;
};

const cssSelectTriggerButtonVariants = ({ selectVariant: variant }: SelectTriggerButtonProps) => {
  switch (variant) {
    case 'inline':
      return css`
        font-weight: 500;
        border-radius: 16px;

        &[data-state='open'] {
          border-radius: 16px 16px 0 0;
          border-bottom-color: transparent;
        }

        &[data-state='open'] {
          > svg {
            transform: rotate(180deg);
          }
        }

        > svg {
          font-size: 0.7em;
        }
      `;
    case 'default':
    default:
      return css``;
  }
};

const SelectTriggerButton = styled(Button)
  .withConfig(componentBaseConfig)
  .attrs<SelectTriggerButtonProps>((props) => {
    return {
      variant: 'bg-primary',
      size: props.selectVariant === 'inline' ? 'sm' : 'default',
      ...props,
      as: SelectPrimitive.Trigger,
    };
  })`
  font-size: 14px;
  font-weight: 400;
  padding: 8px 12px;

  display: flex;

  gap: 10px;

  svg {
    font-size: 0.8em;
  }

  &[data-state='open'] {
    > svg {
      transform: rotate(0deg);
    }
  }

  // Experimental inline flex, needs some testing,
  // the radix UI docs specify that both the item text and the value should not
  // be styled. But like this we can add stuff like icons without having to
  // modify too much of the base <Select> component.
  > span:first-child {
    flex: 1;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
  }

  ${(props) =>
    props.fixedWidth &&
    css`
      width: ${props.fixedWidth}px;
    `}

  &:disabled {
    ${SelectTriggerIcon} {
      display: none;
    }
  }

  ${cssSelectTriggerButtonVariants}
`;
SelectTriggerButton.displayName = SelectPrimitive.Trigger.displayName;

type SelectTriggerProps = {
  placeholder?: string;
  hideOptionIcon?: boolean;
};

const SelectTrigger = styled(SelectTriggerButton)
  .attrs<SelectTriggerProps>(({ placeholder, ...props }) => {
    return {
      ...props,
      children: (
        <>
          <SelectPrimitive.Value placeholder={placeholder} />
          <SelectTriggerIcon>
            {props.selectVariant === 'inline' ? <SelectChevronDownFill /> : <SelectChevronUp />}
          </SelectTriggerIcon>
        </>
      ),
    };
  })
  .withConfig(preventForwardPropsConfig(['hideOptionIcon']))`
  ${(p) =>
    p.hideOptionIcon &&
    css`
      ${DropdownItemIconContainer} {
        display: none;
      }
    `}
`;

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

type SelectContentProps = React.PropsWithChildren<{
  selectVariant?: SelectVariant;
  topContent?: React.ReactNode;
}>;

const cssSelectContentVariants = ({ selectVariant: variant }: SelectContentProps) => {
  switch (variant) {
    case 'inline':
      return css`
        border-top-color: transparent;
        border-radius: 0 0 16px 16px;
        padding-top: 0;

        min-width: var(--radix-select-trigger-width);
        width: var(--radix-select-trigger-width);
        max-width: var(--radix-select-trigger-width);
      `;
    case 'default':
    default:
      return css``;
  }
};

interface SelectContentMobileProps extends SelectContentProps {
  onOpenChange: (value: boolean) => void;
  open: boolean;
}

const SelectContentMobile = styled(SelectPrimitive.Content)
  .attrs<SelectContentMobileProps>(
    ({ topContent, selectVariant, open, onOpenChange, ...props }) => {
      /**
       * Fixes bug on chrome on Android with soft keyboard causing the drawer to instantly close,
       * Solution from here:
       * https://github.com/ariakit/ariakit/issues/3274#issuecomment-1994377862
       */
      React.useEffect(() => {
        const onResize = (event: Event) => {
          event.stopImmediatePropagation();
        };
        window.addEventListener('resize', onResize);
        return () => {
          window.removeEventListener('resize', onResize);
        };
      }, []);

      return {
        position: 'popper',
        side: 'bottom',
        sideOffset: selectVariant === 'inline' ? 0 : 6,
        ...props,
        'data-select-variant': selectVariant,
        children: (
          <Drawer onOpenChange={onOpenChange} open={open}>
            {topContent}
            <ScrollArea.Root type="auto">
              <SelectViewport>{props.children}</SelectViewport>
              <ScrollArea.Bar orientation="vertical">
                <ScrollArea.Thumb />
              </ScrollArea.Bar>
            </ScrollArea.Root>
          </Drawer>
        ),
      };
    },
  )
  .withConfig(preventForwardPropsConfig(['onOpenChange', 'open', 'selectVariant']))`
  ${cssRadixPopoverContentOverride};
`;

const SelectContent = styled(SelectPrimitive.Content)
  .attrs<SelectContentProps>(({ topContent, selectVariant, ...props }) => {
    return {
      position: 'popper',
      side: 'bottom',
      sideOffset: selectVariant === 'inline' ? 0 : 6,
      ...props,
      'data-select-variant': selectVariant,
      children: (
        <>
          {topContent}
          <ScrollArea.Root type="auto">
            <SelectViewport>{props.children}</SelectViewport>
            <ScrollArea.Bar orientation="vertical">
              <ScrollArea.Thumb />
            </ScrollArea.Bar>
          </ScrollArea.Root>
        </>
      ),
    };
  })
  .withConfig(preventForwardPropsConfig(['topContent', 'selectVariant', 'onOpenChange', 'open']))`
  background-color: ${cssVar['color-bg-primary']};
  border: 1px solid ${cssVar['color-border-primary']};
  border-radius: 6px;
  overflow: hidden;
  z-index: 125;

  max-height: var(--radix-select-content-available-height, 200px);

  box-shadow: ${cssVar['shadow-spread-xs']} hsla(${cssVar['color-text-primary-hsl']}, 0.09);

  min-width: 204px;

  --select-translate-x: 0;
  --select-translate-y: 5px;

  &[data-side='top'] {
    --select-translate-y: 5px;
  }

  &[data-side='bottom'] {
    --select-translate-y: -5px;
  }

  &[data-side='left'] {
    --select-translate-x: 5px;
  }

  &[data-side='right'] {
    --select-translate-x: -5px;
  }

  &[data-state='open'] {
    animation: ${animateIn} 200ms cubic-bezier(0.22, 1, 0.36, 1);
  }

  &[data-state='closed'] {
    animation: ${animateOut} 200ms cubic-bezier(0.22, 1, 0.36, 1);
  }

  ${ScrollArea.Root} {
    width: 100%;
    height: 100%;
    max-height: var(--radix-select-content-available-height, 199px);
  }

  ${cssSelectContentVariants}
`;

const SelectViewport = styled(SelectPrimitive.Viewport).attrs((props) => {
  return {
    ...props,
    asChild: true,
    children: <ScrollArea.Viewport>{props.children}</ScrollArea.Viewport>,
    style: {
      // https://github.com/radix-ui/primitives/issues/2059
      // This is a workaround to prevent spamming the console with warnings
      overflowY: undefined,
    },
  };
})`
  padding: 5px;
  width: 100%;
  height: 100%;
  max-height: var(--radix-select-content-available-height, 200px);
  ${mediaMobile} {
    padding: 0 1rem;
  }
`;

const SelectItemIndicator = styled(SelectPrimitive.ItemIndicator).attrs((props) => {
  return {
    ...props,
    children: <CheckIcon size={16} />,
  };
})`
  position: absolute;
  left: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;

  [data-select-variant='inline'] & {
    display: none;
  }
  ${mediaMobile} {
    left: auto;
    right: 1rem;
    display: inline-flex !important;
  }
`;

const SelectItemOption = styled(SelectPrimitive.Item).attrs((props) => {
  return {
    ...props,
    children: (
      <>
        <SelectItemIndicator />
        <SelectPrimitive.ItemText>{props.children}</SelectPrimitive.ItemText>
      </>
    ),
  };
})<{ variant?: 'danger' }>`
  position: relative;
  padding: 6px 8px 6px 32px;

  user-select: none;

  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 200ms;

  font-size: 14px;
  font-weight: 500;
  color: ${cssVar['color-text-primary']};

  // Experimental inline flex, needs some testing,
  // the radix UI docs specify that both the item text and the value should not
  // be styled. But like this we can add stuff like icons without having to
  // modify too much of the base <Select> component.
  > span:last-child {
    display: inline-flex;
    align-items: center;
    flex: 1;
    gap: 0.5rem;
    ${mediaMobile} {
      gap: 1rem;
    }
  }

  ${mediaNotMobile} {
    &[data-highlighted] {
      background-color: ${cssVar['color-bg-tertiary']};
    }
  }

  &[data-state='checked'] {
    color: ${cssVar['color-text-secondary']};
  }

  [data-select-variant='inline'] & {
    padding: 6px 8px;

    &[data-state='checked'] {
      display: none;
    }
  }

  &:focus {
    outline: none;
    ${mediaNotMobile} {
      background-color: ${cssVar['color-bg-tertiary']};
    }
  }

  &[data-disabled] {
    color: ${cssVar['color-text-tertiary']};
    cursor: not-allowed;
  }

  &,
  [data-select-variant='inline'] & {
    ${cssDropdownItemMobile};
  }
`;

const SelectItemGroup = SelectPrimitive.Group;

const SelectItemLabel = styled(SelectPrimitive.Label)`
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  color: ${cssVar['color-text-secondary']};
`;

const SelectSeparator = styled(SelectPrimitive.Separator)`
  background-color: ${cssVar['color-border-primary']};
  height: 1px;
  margin: 8px 0;
`;

interface SelectProps extends React.PropsWithChildren {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;

  label?: string | null;
  description?: string;

  selectVariant?: SelectVariant;
  id?: string;
  placeholder?: string;
  triggerProps?: React.ComponentProps<typeof SelectTrigger>;

  // e.g. use this for search bar
  topContent?: React.ReactNode;
  onOpenChange?: VoidFunction;
  searchInputControls?: ReturnType<typeof useInputControls>;
}

/**
 * There is a bug with Radix select on mobile OS' or browsers with touch events:
 * https://github.com/radix-ui/primitives/issues/1658
 * Open since 2022, and not fixed yet, and it essentially leaks touch events when selecting an option down to HTML elements below.
 * This is a workaround to prevent that from happening, basically we use DialogPrimitive with a delay when closing so when the user
 * clicks on an option, the touch event is intercepted by the overlay preventing other unwanted interactions.
 */
const SelectOverlay = styled.div`
  display: block;
  position: fixed;
  inset: 0;
  z-index: 125;
`;

const Select = React.forwardRef<React.ElementRef<typeof FieldGroup>, SelectProps>(
  (
    {
      children,
      value,
      onChange,
      disabled,
      label,
      description,
      selectVariant = 'default',
      id,
      placeholder = 'Select an option',
      triggerProps,
      topContent,
    },
    ref,
  ) => {
    const openState = useBoolState();
    const { isMobileView } = useResponsive();
    const debouncedValue = useDebounce(openState.value, 100);

    const Content = isMobileView ? SelectContentMobile : SelectContent;

    return (
      <FieldGroup ref={ref}>
        {(label || description) && (
          <FieldHeader>
            {label && <FieldLabel>{label}</FieldLabel>}
            {description && <FieldDescription>{description}</FieldDescription>}
          </FieldHeader>
        )}
        <DialogPrimitive.Root open={openState.value} onOpenChange={openState.set}>
          <SelectPrimitive.Root
            value={value}
            onValueChange={onChange}
            disabled={disabled}
            open={openState.value}
            onOpenChange={(isOpen) => {
              openState.set(isOpen);
            }}
          >
            <SelectTrigger
              selectVariant={selectVariant}
              id={id}
              placeholder={placeholder}
              {...triggerProps}
              /**
               * Github issue:
               * https://github.com/radix-ui/primitives/issues/1641
               *
               * Scrolling on mobile triggers the Radix select to open wrongfully which is against native behavior.
               * This makes it so that doesn't happen.
               */
              onPointerDown={(e) => {
                if (e.pointerType === 'touch') e.preventDefault();
                triggerProps?.onPointerDown?.(e);
              }}
              onPointerUp={(e) => {
                if (e.pointerType === 'touch' && !disabled) {
                  openState.handleToggle();
                }
              }}
            />
            <DialogPrimitive.Portal forceMount>
              {(debouncedValue || openState.value) && <SelectOverlay key="overlay" />}

              {/** 
                This seemingly random div prevents console errors, that is because the Portals are function components
                And they don't have refs.
                https://github.com/radix-ui/primitives/blob/main/packages/react/select/src/Select.tsx#L366-L386
                This means it would spew out errors because the dialog primitive will map all children to a slot, which
                requires refs.
                */}
              <div>
                <SelectPrimitive.Portal>
                  <Content
                    selectVariant={selectVariant}
                    onCloseAutoFocus={(e) => e.preventDefault()}
                    topContent={topContent}
                    onOpenChange={openState.set}
                    open={openState.value}
                  >
                    {children}
                  </Content>
                </SelectPrimitive.Portal>
              </div>
            </DialogPrimitive.Portal>
          </SelectPrimitive.Root>
        </DialogPrimitive.Root>
      </FieldGroup>
    );
  },
);

Select.displayName = 'Select';

export default Object.assign(Select, {
  Root: SelectPrimitive.Root,
  Portal: SelectPrimitive.Portal,
  Trigger: SelectTrigger,
  TriggerButton: SelectTriggerButton,
  TriggerIcon: SelectTriggerIcon,
  TriggerValue: SelectPrimitive.Value,
  Content: SelectContent,
  Separator: SelectSeparator,

  ItemOption: SelectItemOption,
  ItemOptionIconContainer: DropdownItemIconContainer,
  ItemGroup: SelectItemGroup,
  ItemLabel: SelectItemLabel,

  FieldGroup,
  FieldLabel: FieldHeader,
  FieldLabelTitle: FieldLabel,
  FieldLabelDescription: FieldDescription,
});

export const MotionSelect = styled(motion(Select)).attrs({
  initial: { opacity: 0, y: 10 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 10 },
  transition: { duration: 0.2 },
})``;
