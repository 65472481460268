import styled from 'styled-components';

import DotMenuIcon from '@/src/icons/DotMenuIcon';
import { ButtonIcon } from '@/src/modules/ui/components/Button';

export const ButtonIconDotMenu = styled(ButtonIcon).attrs((props) => {
  return {
    ...props,
    variant: 'bg-secondary',
    children: (
      <DotMenuIcon
        style={{
          width: 18,
          height: 'auto',
        }}
      />
    ),
  };
})``;
