import {
  CodeIcon,
  FontBoldIcon,
  FontItalicIcon,
  Link1Icon,
  QuoteIcon,
  StrikethroughIcon,
  UnderlineIcon,
} from '@radix-ui/react-icons';
import AddCircleIcon from 'remixicon-react/AddCircleLineIcon';
import AlertIcon from 'remixicon-react/AlertLineIcon';
import ArrowGoBackIcon from 'remixicon-react/ArrowGoBackLineIcon';
import ArrowGoForwardIcon from 'remixicon-react/ArrowGoForwardLineIcon';
import CodeBoxIcon from 'remixicon-react/CodeBoxLineIcon';
import DeleteBinIcon from 'remixicon-react/DeleteBinLineIcon';
import DeleteColumnIcon from 'remixicon-react/DeleteColumnIcon';
import DeleteRowIcon from 'remixicon-react/DeleteRowIcon';
import FunctionIcon from 'remixicon-react/FunctionFillIcon';
import HeadingIcon from 'remixicon-react/HeadingIcon';
import InsertColumnLeftIcon from 'remixicon-react/InsertColumnLeftIcon';
import InsertColumnRightIcon from 'remixicon-react/InsertColumnRightIcon';
import InsertRowBottomIcon from 'remixicon-react/InsertRowBottomIcon';
import InsertRowTopIcon from 'remixicon-react/InsertRowTopIcon';
import LayoutColumnIcon from 'remixicon-react/LayoutColumnFillIcon';
import LayoutRowIcon from 'remixicon-react/LayoutRowFillIcon';
import ListCheckIcon from 'remixicon-react/ListCheck2Icon';
import ListOrderedIcon from 'remixicon-react/ListOrderedIcon';
import ListUnorderedIcon from 'remixicon-react/ListUnorderedIcon';
import MarkPenIcon from 'remixicon-react/MarkPenLineIcon';
import MergeCellsHorizontal from 'remixicon-react/MergeCellsHorizontalIcon';
import TableIcon from 'remixicon-react/Table2Icon';
import { ToolbarGroup, ToolbarItemType } from './types';

import Shortcut from '@/src/ui/Shortcut';
import ToolbarCustomImageButton from './Custom/ToolbarCustomImageButton';

const basicGroup: ToolbarGroup = {
  name: 'basic',
  label: 'Basic',
  items: [
    {
      type: ToolbarItemType.Button,
      key: 'undo',
      label: 'Undo',
      icon: <ArrowGoBackIcon size={16} />,
      shortcut: (
        // Mod+Z
        <Shortcut modifiers={{ ctrl: true }} macModifiers={{ meta: true }} keybind="Z" compact />
      ),
      onClick: (editor) => editor.chain().focus().undo().run(),
    },
    {
      type: ToolbarItemType.Button,
      key: 'redo',
      label: 'Redo',
      icon: <ArrowGoForwardIcon size={16} />,
      shortcut: (
        // Mod+Shift+Z
        <Shortcut
          modifiers={{ ctrl: true, shift: true }}
          macModifiers={{ meta: true, shift: true }}
          keybind="Z"
          compact
        />
      ),
      onClick: (editor) => editor.chain().focus().redo().run(),
    },
    // {
    //   type: ToolbarItemType.Button,
    //   key: 'line-break',
    //   label: 'Line break',
    //   icon: <ParagraphIcon size={16} />,
    //   shortcut: (
    //     // Shift+Enter && Mod+Enter
    //     <>
    //       <Shortcut
    //         modifiers={{ shift: true }}
    //         macModifiers={{ shift: true }}
    //         keybind="Enter"
    //         compact
    //       />
    //       <Shortcut
    //         modifiers={{ ctrl: true }}
    //         macModifiers={{ meta: true }}
    //         keybind="Enter"
    //         compact
    //       />
    //     </>
    //   ),
    //   onClick: (editor) => editor.chain().focus().setHardBreak().run(),
    // },
  ],
};

const structuralFormattingGroup: ToolbarGroup = {
  name: 'structural-formatting',
  label: 'Structural formatting',
  items: [
    {
      type: ToolbarItemType.Dropdown,
      icon: <HeadingIcon size={16} />,
      label: 'Heading',
      key: 'heading',
      items: Array.from({ length: 6 }, (_, i) => i + 1).map((level) => ({
        type: ToolbarItemType.Toggle,
        key: `heading-${level}`,
        label: `Heading ${level}`,
        shortcut: (
          // Mod+Alt+{level}
          <Shortcut
            modifiers={{ ctrl: true, alt: true }}
            macModifiers={{ meta: true, alt: true }}
            keybind={String(level)}
            compact
          />
        ),
        getIsActive: (editor) => editor.isActive('heading', { level }),
        onClick: (editor) =>
          editor
            .chain()
            .focus()
            .toggleHeading({ level: level as 1 | 2 | 3 | 4 | 5 | 6 })
            .run(),
      })),
    },
    {
      type: ToolbarItemType.Toggle,
      icon: <QuoteIcon />,
      key: 'blockquote',
      label: 'Blockquote',
      shortcut: (
        // Mod+Shift+B
        <Shortcut
          modifiers={{ ctrl: true, shift: true }}
          macModifiers={{ meta: true, shift: true }}
          keybind="B"
          compact
        />
      ),
      getIsActive: (editor) => editor.isActive('blockquote'),
      onClick: (editor) => editor.chain().focus().toggleBlockquote().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      icon: <AlertIcon size={16} />,
      shortcut: (
        // Mod+Shift+C
        <Shortcut
          modifiers={{ ctrl: true, shift: true }}
          macModifiers={{ meta: true, shift: true }}
          keybind="C"
          compact
        />
      ),
      key: 'callout',
      label: 'Callout',
      getIsActive: (editor) => editor.isActive('callout'),
      onClick: (editor) => editor.chain().focus().toggleCallout().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      icon: <ListUnorderedIcon size={16} />,
      shortcut: (
        // Mod+Shift+8
        <Shortcut
          modifiers={{ ctrl: true, shift: true }}
          macModifiers={{ meta: true, shift: true }}
          keybind="8"
          compact
        />
      ),
      key: 'bulletList',
      label: 'Bullet list',
      getIsActive: (editor) => editor.isActive('bulletList'),
      onClick: (editor) => editor.chain().focus().toggleBulletList().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      icon: <ListOrderedIcon size={16} />,
      shortcut: (
        // Mod+Shift+7
        <Shortcut
          modifiers={{ ctrl: true, shift: true }}
          macModifiers={{ meta: true, shift: true }}
          keybind="7"
          compact
        />
      ),
      key: 'orderedList',
      label: 'Ordered list',
      getIsActive: (editor) => editor.isActive('orderedList'),
      onClick: (editor) => editor.chain().focus().toggleOrderedList().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      icon: <ListCheckIcon size={16} />,
      shortcut: (
        // Mod+Sift+9
        <Shortcut
          modifiers={{ ctrl: true, shift: true }}
          macModifiers={{ meta: true, shift: true }}
          keybind="9"
          compact
        />
      ),
      key: 'taskList',
      label: 'Task list',
      getIsActive: (editor) => editor.isActive('taskList'),
      onClick: (editor) => editor.chain().focus().toggleTaskList().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      icon: <CodeBoxIcon size={16} />,
      shortcut: (
        // Mod+Alt+C
        <Shortcut
          modifiers={{ ctrl: true, alt: true }}
          macModifiers={{ meta: true, alt: true }}
          keybind="C"
          compact
        />
      ),
      key: 'codeBlock',
      label: 'Code block',
      getIsActive: (editor) => editor.isActive('codeBlock'),
      onClick: (editor) => editor.chain().focus().toggleCodeBlock().run(),
    },
  ],
};

const textFormattingGroup: ToolbarGroup = {
  name: 'text-formatting',
  label: 'Text formatting',
  items: [
    {
      type: ToolbarItemType.Toggle,
      key: 'bold',
      label: 'Bold',
      shortcut: (
        // Mod+B
        <Shortcut modifiers={{ ctrl: true }} macModifiers={{ meta: true }} keybind="B" compact />
      ),
      icon: <FontBoldIcon />,
      getIsActive: (editor) => editor.isActive('bold'),
      onClick: (editor) => editor.chain().focus().toggleBold().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      key: 'italic',
      label: 'Italic',
      shortcut: (
        // Mod+I
        <Shortcut modifiers={{ ctrl: true }} macModifiers={{ meta: true }} keybind="I" compact />
      ),
      icon: <FontItalicIcon />,
      getIsActive: (editor) => editor.isActive('italic'),
      onClick: (editor) => editor.chain().focus().toggleItalic().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      key: 'underline',
      label: 'Underline',
      shortcut: (
        // Mod+U
        <Shortcut modifiers={{ ctrl: true }} macModifiers={{ meta: true }} keybind="U" compact />
      ),
      icon: <UnderlineIcon />,
      getIsActive: (editor) => editor.isActive('underline'),
      onClick: (editor) => editor.chain().focus().toggleUnderline().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      key: 'strike',
      label: 'Strike through',
      shortcut: (
        // Mod+Shift+X
        <Shortcut
          modifiers={{ ctrl: true, shift: true }}
          macModifiers={{ meta: true, shift: true }}
          keybind="X"
          compact
        />
      ),
      icon: <StrikethroughIcon />,
      getIsActive: (editor) => editor.isActive('strike'),
      onClick: (editor) => editor.chain().focus().toggleStrike().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      key: 'link',
      label: 'Link',
      icon: <Link1Icon />,
      getIsActive: (editor) => editor.isActive('link'),
      onClick: (editor) => {
        const isActive = editor.isActive('link');

        if (isActive) {
          editor.chain().focus().unsetLink().run();
          return;
        }

        const url = window.prompt('URL');
        if (url && url.length > 0) {
          editor?.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
        }
      },
    },
    {
      type: ToolbarItemType.Toggle,
      key: 'code',
      label: 'Inline code',
      shortcut: (
        // Mod+E
        <Shortcut modifiers={{ ctrl: true }} macModifiers={{ meta: true }} keybind="E" compact />
      ),
      icon: <CodeIcon />,
      getIsActive: (editor) => editor.isActive('code'),
      onClick: (editor) => editor.chain().focus().toggleCode().run(),
    },
    {
      type: ToolbarItemType.Toggle,
      key: 'highlight',
      label: 'Highlight',
      shortcut: (
        // Mod+Shift+H
        <Shortcut
          modifiers={{ ctrl: true, shift: true }}
          macModifiers={{ meta: true, shift: true }}
          keybind="H"
          compact
        />
      ),
      icon: <MarkPenIcon size={16} />,
      getIsActive: (editor) => editor.isActive('highlight'),
      onClick: (editor) => editor.chain().focus().toggleHighlight().run(),
    },
  ],
};

const mediaGroup: ToolbarGroup = {
  name: 'media',
  label: 'Media',
  items: [
    {
      type: ToolbarItemType.Custom,
      key: 'image',
      render: (key, editor) => <ToolbarCustomImageButton key={key} editor={editor} />,
    },
    {
      type: ToolbarItemType.Dropdown,
      key: 'table-dropdown',
      label: 'Table',
      icon: <TableIcon size={16} />,
      items: [
        {
          type: ToolbarItemType.Button,
          key: 'add-table',
          label: 'Add Table',
          icon: <AddCircleIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().insertTable().run();
          },
        },
        {
          type: ToolbarItemType.DropdownSeparator,
          key: 'add-cells-separator',
        },
        {
          type: ToolbarItemType.Button,
          key: 'add-row-before',
          label: 'Add Row Before',
          icon: <InsertRowTopIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().addRowBefore().run();
          },
        },
        {
          type: ToolbarItemType.Button,
          key: 'add-row-after',
          label: 'Add Row After',
          icon: <InsertRowBottomIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().addRowAfter().run();
          },
        },
        {
          type: ToolbarItemType.Button,
          key: 'add-column-before',
          label: 'Add Column Before',
          icon: <InsertColumnLeftIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().addColumnBefore().run();
          },
        },
        {
          type: ToolbarItemType.Button,
          key: 'add-column-after',
          label: 'Add Column After',
          icon: <InsertColumnRightIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().addColumnAfter().run();
          },
        },
        {
          type: ToolbarItemType.DropdownSeparator,
          key: 'merge-cells-separator',
        },
        {
          type: ToolbarItemType.Button,
          key: 'merge-split-cells',
          label: 'Merge/Split Cells',
          icon: <MergeCellsHorizontal size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().mergeOrSplit().run();
          },
        },
        {
          type: ToolbarItemType.DropdownSeparator,
          key: 'toggle-header-separator',
        },
        {
          type: ToolbarItemType.Button,
          key: 'toggle-header-column',
          label: 'Toggle Header Column',
          icon: <LayoutColumnIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().toggleHeaderColumn().run();
          },
        },
        {
          type: ToolbarItemType.Button,
          key: 'toggle-header-row',
          label: 'Toggle Header Row',
          icon: <LayoutRowIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().toggleHeaderRow().run();
          },
        },
        {
          type: ToolbarItemType.Button,
          key: 'toggle-header-cell',
          label: 'Toggle Header Cell',
          icon: <FunctionIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().toggleHeaderCell().run();
          },
        },
        {
          type: ToolbarItemType.DropdownSeparator,
          key: 'delete-table-separator',
        },
        {
          type: ToolbarItemType.Button,
          key: 'delete-row',
          label: 'Delete Row',
          icon: <DeleteRowIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().deleteRow().run();
          },
        },
        {
          type: ToolbarItemType.Button,
          key: 'delete-column',
          label: 'Delete Column',
          icon: <DeleteColumnIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().deleteColumn().run();
          },
        },
        {
          type: ToolbarItemType.Button,
          key: 'delete-table',
          label: 'Delete Table',
          icon: <DeleteBinIcon size={16} />,
          onClick: (editor) => {
            editor?.chain().focus().deleteTable().run();
          },
        },
      ],
    },
  ],
};

export { basicGroup, structuralFormattingGroup, textFormattingGroup, mediaGroup };
