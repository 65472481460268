import {
  cssRadixPopoverContentOverride,
  inAnimationMobileDrawer,
  outAnimationMobileDrawer,
} from '@/src/modules/ui/components/Drawer/cssRadixPopoverOverride';
import { Drawer, DrawerPanEndHandler } from '@/src/modules/ui/components/Drawer/Drawer';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { Content } from '@radix-ui/react-context-menu';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeScaleIn = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50%) scale(0.2);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
`;

const fadeScaleOut = keyframes`
  from {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  to {
    opacity: 0;
    transform: translateX(-50%) scale(0.2);
  }
`;

/**
 * this styling is very specific to resource preview
 * you'll need some abstraction for this to be more generic
 */
const ContextMenuPreviewAnchorContainer = styled.div`
  width: 47vw;
  height: 47vw;
  position: fixed;
  top: auto;
  bottom: calc(100dvh - 47dvw - 50px - env(safe-area-inset-top));
  left: 50%;
  transform: translateX(-50%);
  will-change: scale, opacity, transform;
  pointer-events: none !important;
  opacity: 0;
`;

const ContextMenuPreviewAnchorContainerInner = styled.div`
  height: 100%;
  width: 100%;
  transition: 0.1s opacity;
  & > * {
    max-width: none !important;
    max-height: none !important;
    height: 100% !important;
  }
`;
const StyledDrawer = styled(Drawer)`
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const ContextMenuContentMobileBase = styled(Content)`
  font-size: 0.9375rem;
  ${cssRadixPopoverContentOverride};

  div:has(> &) {
    top: 0% !important;
    /* height: 0; */
  }

  &[data-state='open'] {
    ${ContextMenuPreviewAnchorContainer} {
      animation: ${fadeScaleIn} 0.2s ease-out forwards !important;
      animation-delay: 0.1s !important;
    }
    /* ${StyledDrawer} {
      animation: ${inAnimationMobileDrawer} 0.2s ease-out forwards !important;
      animation-delay: 0.25s !important;
    } */
  }

  &[data-state='closed'] {
    transform: translateY(0px) !important;
    opacity: 1 !important;
    animation: none !important;
    ${ContextMenuPreviewAnchorContainer} {
      animation: ${fadeScaleOut} 0.2s ease-out forwards !important;
    }
    /* ${StyledDrawer} {
      animation: ${outAnimationMobileDrawer} 0.1s ease-out forwards !important;
    } */
  }
`;

const MobileContentInner = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 1px;
  padding-top: 1rem;
  overflow: hidden;
  padding-bottom: calc(1rem + env(safe-area-inset-bottom));
`;

const StyledScrollArea = styled(ScrollArea)`
  display: flex;
  flex-direction: column;
  max-height: calc(100dvh - 47dvw - env(safe-area-inset-top) - 100px);
  border-radius: 1rem 1rem 0 0;
`;

//panningLayerId="context-menu"

/**
 * We're rendering the Content primitive (DropdownContentMobileBase) to override the defaults
 * The visible part (background and further) is rendered in ContentInner. This is due to constraint of the Content primitive
 * which doesn't accept style prop which is necessary for the panning effect
 */
export const ContextMenuContentMobile = React.forwardRef<
  HTMLDivElement,
  {
    children: React.ReactNode;
    onOpenChange: (value: boolean) => void;
    previewAnchor?: React.ReactNode;
  }
>(function DropdownContentMobile({ children, onOpenChange, previewAnchor }, ref) {
  /**
   * panning
   */

  const [drawerOffsetY, setDrawerOffsetY] = React.useState(0);

  const drawScale = Math.min(
    1 - Math.min(Math.min(Math.abs(drawerOffsetY), 400) / 400 - 0.1, 1),
    1,
  );

  const onPanEnd: DrawerPanEndHandler = React.useCallback((e, i, moreInfo) => {
    if (!moreInfo.shouldClose) {
      /**
       * if not closing, reset the offset so the opacity comes back
       * otherwise we maintain the drawer offset to maintain the opacity while the drawer is closing
       */
      setDrawerOffsetY(0);
    }
  }, []);

  return (
    <ContextMenuContentMobileBase ref={ref}>
      {previewAnchor && (
        <ContextMenuPreviewAnchorContainer data-contextmenu-preview>
          <ContextMenuPreviewAnchorContainerInner
            // animation opacity is applied on parent with forward so we need to set another opacity controls on the inner
            style={{
              opacity: drawScale,
              transform: `scale(${drawScale})`,
            }}
          >
            {previewAnchor}
          </ContextMenuPreviewAnchorContainerInner>
        </ContextMenuPreviewAnchorContainer>
      )}
      <StyledDrawer
        onPanEnd={onPanEnd}
        onOpenChange={onOpenChange}
        onOffsetYChange={(value) => {
          if (value > 0) {
            setDrawerOffsetY(value);
          }
        }}
      >
        <StyledScrollArea
          innerDisplayFlex
          data-pan-id-ignore-context-menu="y"
          scrollbarVariant="none"
        >
          <MobileContentInner>{children}</MobileContentInner>
        </StyledScrollArea>
      </StyledDrawer>
    </ContextMenuContentMobileBase>
  );
});
