import { createLogger } from '@/src/lib/logger/createLogger';
import { PanInfo } from 'framer-motion';
import React from 'react';

type PanAxis = 'y' | 'x';

export type PanMoreInfo = {
  axis: PanAxis;
};

export type UsePanningHandler = (e: PointerEvent, info: PanInfo, moreInfo: PanMoreInfo) => void;

/**
 * Experimenting with distilling swipe offset and velocity into a single variable, so the
 * less distance a user has swiped, the more velocity they need to register as a swipe.
 * Should accomodate longer swipes and short flicks without having binary checks on
 * just distance thresholds and velocity > 0.
 */
export const swipeConfidenceThreshold = 5000;
export const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};

interface UsePanningOptions {
  onPan?: UsePanningHandler;
  onPanEnd?: UsePanningHandler;

  /**
   * only allow touch panning
   * @default true
   */
  touchOnly?: boolean;
  /**
   * prevents default
   * for example when dragging on top of the child radix scrollarea, it can happen that it cancels the panning
   */
  preventEventsDefault?: boolean;
  stopEventsPropagation?: boolean;

  /**
   * set an id for this layer, this is helpful when you want to ignore this layer's panning in some cases
   * This hook will ignore panning which has been triggered on nested elements
   * with data-pan-id-ignore-{panningLayerId}="x", "y", or "xy" on the target
   * @default undefined
   */
  panningLayerId?: string;
}

const targetHasPanningDisabled = (
  target: HTMLElement,
  panningLayerId: string,
  panningAxis: PanAxis,
) => {
  const targetAttribute = `data-pan-id-ignore-${panningLayerId}`;
  return (
    target.getAttribute(targetAttribute) === panningAxis ||
    target.getAttribute(targetAttribute) === 'xy' ||
    !!target.closest(`[${targetAttribute}="${panningAxis}"]`) ||
    !!target.closest(`[${targetAttribute}="xy"]`)
  );
};

const logger = createLogger('usePanning');

/**
 * informs about the panning
 * Only one axis pan at a time.
 * e.g. when user starts panning down, X axis will be marked as active
 */
export const usePanning = (args: UsePanningOptions) => {
  const [panningAxis, setPanningAxis] = React.useState<'y' | 'x' | null>(null);
  const [panningOffset, setPanningOffset] = React.useState(0);

  const touchOnly = args.touchOnly ?? true;
  const preventEventsDefault = args.preventEventsDefault ?? false;
  const stopEventsPropagation = args.stopEventsPropagation ?? false;

  /**
   * initial axis
   */
  const { panningLayerId } = args;
  const onPanStart = React.useCallback(
    (e: PointerEvent, info: PanInfo) => {
      logger.log('panStart', e, info);
      if (e.pointerType !== 'touch' && touchOnly) {
        return;
      }

      preventEventsDefault && e.preventDefault();
      stopEventsPropagation && e.stopPropagation();

      const panningAxis = Math.abs(info.delta.x) > Math.abs(info.delta.y) ? 'x' : 'y';
      if (
        panningLayerId &&
        e.target &&
        'closest' in e.target &&
        targetHasPanningDisabled(e.target as HTMLElement, panningLayerId, panningAxis)
      ) {
        return;
      }

      if (Math.abs(info.delta.x) > Math.abs(info.delta.y)) {
        setPanningAxis('x');
      } else {
        setPanningAxis('y');
      }
    },
    [panningLayerId, touchOnly, preventEventsDefault, stopEventsPropagation],
  );

  /**
   * while panning
   */
  const { onPan: onPanProp } = args;
  const onPanPropRef = React.useRef(onPanProp);
  onPanPropRef.current = onPanProp;

  const onPan = React.useCallback(
    (e: PointerEvent, info: PanInfo) => {
      logger.log('pan', e, info);
      if (panningAxis === null) {
        return null;
      }

      preventEventsDefault && e.preventDefault();
      stopEventsPropagation && e.stopPropagation();

      onPanPropRef.current?.(e, info, { axis: panningAxis! });

      if (panningAxis === 'y') {
        setPanningOffset(info.offset.y);
      } else {
        setPanningOffset(info.offset.x);
      }
    },
    [preventEventsDefault, panningAxis, stopEventsPropagation],
  );

  /**
   * pan end
   */
  const { onPanEnd: onPanEndProp } = args;
  const onPanEndPropRef = React.useRef(onPanEndProp);
  onPanEndPropRef.current = onPanEndProp;

  const onPanEnd = React.useCallback(
    (e: PointerEvent, info: PanInfo) => {
      logger.log('end', e, info);
      if (panningAxis === null) {
        return;
      }

      preventEventsDefault && e.preventDefault();
      stopEventsPropagation && e.stopPropagation();

      onPanEndPropRef.current?.(e, info, { axis: panningAxis! });

      setPanningAxis(null);
      setPanningOffset(0);
    },
    [panningAxis, preventEventsDefault, stopEventsPropagation],
  );

  return {
    /**
     * props to mass into a motion component
     */
    motionProps: {
      onPanStart,
      onPan,
      onPanEnd,
    },
    /**
     * direction the user is panning
     */
    panningAxis,
    /**
     * offset of the direciton user is panning
     */
    panningOffset,
    isPanningX: panningAxis === 'x',
    isPanningY: panningAxis === 'y',
    panningXOffset: panningAxis === 'x' ? panningOffset : 0,
    panningYOffset: panningAxis === 'y' ? panningOffset : 0,
  };
};
