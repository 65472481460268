import { useMemo } from 'react';
import styles from './PagePlaceholder.module.scss';
import Spinner from '../../Spinner/Spinner';
import clsx from 'clsx';
import { usePDFContent } from './PDF';

const PagePlaceholder: React.FC<{
  pageNumber: number;
  size?: 'normal' | 'small';
  smallSpinner?: boolean;
  absolute?: boolean;
}> = ({ pageNumber, absolute, smallSpinner = false, size = 'normal' }) => {
  const { getPagePlaceholderImage } = usePDFContent();

  const image = useMemo(
    () => getPagePlaceholderImage(pageNumber)?.[size] ?? null,
    [getPagePlaceholderImage, pageNumber, size]
  );

  return (
    <div
      className={clsx(styles.placeholder, absolute && styles.absolute)}
      style={image ? { backgroundImage: `url(${image})` } : {}}
    >
      {!image && <Spinner size={smallSpinner ? 15 : 30} thickness={3} />}
    </div>
  );
};

export default PagePlaceholder;
