import { useWindowEvent } from '@/src/hooks/useWindowEvent';
import { chainShortcutHandlers } from '@/src/modules/keyboardShortcuts/utils';
import { isTruthy } from '@/src/utils/guards';
import isHotkey from 'is-hotkey';
import React from 'react';

interface Options {
  onOpenExternal?: () => void;
  onOpen?: () => void;
  enabled?: boolean;
}

export const useResourceHoverKeyboardEvents = (options: Options) => {
  const [isMouseOver, setIsMouseOver] = React.useState(false);
  const enabled =
    (options.enabled ?? true) && [options.onOpenExternal, options.onOpen].some(isTruthy);

  const shortcutsEnabled = isMouseOver && enabled;

  /**
   * make sure to reset the isMouseOver state when enabled sets to false
   */
  React.useEffect(() => {
    if (!enabled) {
      setIsMouseOver(false);
    }
  }, [enabled]);

  useWindowEvent(
    'keydown',
    (e) => {
      chainShortcutHandlers(
        e,
        [
          (e) => {
            if (isHotkey('Enter', e) && options.onOpenExternal) {
              options.onOpenExternal();
              return true;
            }
          },
          (e) => {
            if (isHotkey('space', e) && options.onOpen) {
              options.onOpen();
              return true;
            }
          },
        ],
        {
          preventDefaultOnHandle: true,
          stopPropagationOnHandle: true,
          stopOnFirstSuccess: true,
        },
      );
    },
    {
      enabled: shortcutsEnabled,
    },
  );

  return {
    onMouseEnter: enabled ? () => setIsMouseOver(true) : undefined,
    onMouseLeave: enabled ? () => setIsMouseOver(false) : undefined,
    isMouseOver: enabled ? isMouseOver : false,
  };
};
