import FolderIcon from '@/public/images/icons/Folder.png';
import FdocItemV2 from '@/src/components/FdocItem/FdocItemV2';
import { useResponsive } from '@/src/hooks/responsive';
import { useDomContentRect } from '@/src/hooks/useDomRect';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useQueryInbox } from '@/src/modules/connections/queries/useQueryInbox';
import { useQueryResourceRootIntegration } from '@/src/modules/connections/queries/useQueryResourceRootIntegration';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useMutationRenameColorLabel } from '@/src/modules/labels/mutations/useMutationRenameColorLabel';

import RecapSummaryButton from '@/src/modules/recap/components/RecapSummaryButton';
import { useMutationChangeResourceLabel } from '@/src/modules/resource-detail/mutations/useMutationChangeResourceLabel';
import { ResourcePreviewContentProps } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { ResourcePreviewSkeleton } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewSkeleton/ResourcePreviewSkeleton';
import { useQueryFolder } from '@/src/modules/resources/queries/useQueryFolder';
import { Space } from '@/src/modules/spaces/spaces.types';
import { useIntersectionObserver } from '@/src/modules/ui/components/IntersectionObserver/useIntersectionObserver';
import { useWoody } from '@/src/services/woody/woody';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { ObjectDragEvent, ObjectDragOver } from '@/src/types/draggable';
import DashboardButton from '@/src/ui/DashboardButton/DashboardButton';
import { newArrayLength } from '@/src/utils/array';
import { nativeWindow } from '@todesktop/client-core';
import { publish } from '@todesktop/client-ipc';
import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, {
  CSSProperties,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { shallow } from 'zustand/shallow';
import { FdocItemProps } from '../FdocItem/FdocItem';
import { OfViewMode } from '../ViewModeSwitcher/ViewModeSwitcher';
import { SplitFdocs } from './FdocList';
import styles from './FdocListDivision.module.scss';

type DragOverElement = {
  position: number;
  fdocId: string;
  width: number;
  height: number;
};

const FdocListDivision: React.FC<{
  optimize?: boolean;
  viewMode: OfViewMode;
  colorLabel?: ApiColorLabel | null;
  fdocs: OptimisticDraft<Fdoc>[];
  titleProp?: string;
  customStyleFunction?: (fdoc: OptimisticDraft<Fdoc>) => React.CSSProperties;
  customItemPropsFunction?: (fdoc: OptimisticDraft<Fdoc>) => Partial<FdocItemProps>;
  disableItemPreviewContextMenu?: boolean;

  fdocTotals?: number;

  zoomLevel?: number;
  forcedMobile?: boolean;
  isFullscreen?: boolean;

  colorLabels?: ApiColorLabel[];
  splitFdocs?: SplitFdocs;
  list?: Space;
  parentResourceId?: string;
  resourceId?: string;
  style?: CSSProperties;
  rootStyle?: CSSProperties;
  root?: HTMLElement;
  slimStyle?: boolean;

  columns: number;
  gap?: CSSProperties['gap'];

  fdocClickHandler: (fdoc?: Fdoc) => void;
  handleOnRemove?: (fdoc: Fdoc) => void;
  selectionMode?: boolean;
  previewSelectedFdocs: string[];
  disabledFdocsIds?: string[];
  canMultiSelect?: boolean;
  canDelete?: boolean;
  canMove?: boolean;
  infiniteTriggerEntityId?: string | null | (string | null)[];
  allowOverrideFolderClick?: boolean;
  hasSeparateDivisions?: boolean;
  fdocsLoaded?: number;
  amountOfDivisions?: number;
  index?: number;
  isSidepanel?: boolean;

  rootList?: HTMLElement | null;
  anchor?: React.ReactNode;
  /**
   * new card preview props
   */
  resourcePreviewProps?: Omit<ResourcePreviewContentProps, 'resource'>;
}> = ({
  optimize = false,
  viewMode,
  zoomLevel,
  forcedMobile,
  colorLabel,
  fdocs,
  titleProp,
  style,
  rootStyle,
  slimStyle,
  gap = '32px',
  customStyleFunction,
  customItemPropsFunction,
  splitFdocs,
  fdocTotals,
  hasSeparateDivisions,
  fdocsLoaded,
  amountOfDivisions,
  index,
  isSidepanel,

  colorLabels,
  list,
  parentResourceId,
  rootList,
  columns,
  disableItemPreviewContextMenu,
  resourceId,

  isFullscreen,

  handleOnRemove,
  fdocClickHandler,
  allowOverrideFolderClick = false,
  selectionMode,
  previewSelectedFdocs,
  disabledFdocsIds = [],
  canMultiSelect,
  canDelete,
  canMove = true,
  anchor,
  resourcePreviewProps,
}) => {
  const { client } = useWoody();

  const [divisionRootEl, setDivisionRootEl] = useState<HTMLDivElement | null>(null);
  const [rootDom] = useDomContentRect(divisionRootEl);

  const inView = useIntersectionObserver({
    defaultInView: true,
    target: divisionRootEl,
    enabled: optimize,
    rootEl: rootList,
    rootMargin: `${2500 / (zoomLevel ?? 1)}px`,
    threshold: 0.001,
    recalculateKey: `${fdocs.length}.${splitFdocs?.id}`,
  });

  const { data: folder } = useQueryFolder(resourceId, {
    enabled: isQueryEnabled([!!resourceId, !!inView, !titleProp]),
  });

  const title = titleProp ?? folder?.data.name;

  const router = useRouter();
  const listId =
    splitFdocs?.space?.id ??
    (typeof router.query.listId === 'string' ? router.query.listId : undefined);

  const { integrationRoot } = useQueryResourceRootIntegration({
    fn: (resourceRoot) =>
      resourceRoot.integration.id === splitFdocs?.space?.id ||
      resourceRoot.id === splitFdocs?.resourceId,
  });

  const integration = integrationRoot?.integration;

  const [listRef, setListRef] = useState<HTMLDivElement | null>(null);
  const fdocRemoveHandler = useCallback(
    (fdoc?: Fdoc) => {
      if (!fdoc) return;

      handleOnRemove?.(fdoc);
    },
    [handleOnRemove],
  );

  const [dragOverElement, setDragOverElement] = useState<DragOverElement | null>(null);

  const [deferredDragOverElement, setDeferredDragOverElement] = useState<DragOverElement | null>(
    null,
  );

  useEffect(() => {
    setDeferredDragOverElement(dragOverElement);
  }, [dragOverElement]);

  const { isMobileView } = useResponsive();
  const fdocSizes = useMemo(() => {
    if (viewMode === 'List') return { width: 32, height: 39 };
    if (isMobileView || forcedMobile) return { width: 164, height: 164 };
    return { width: 260, height: 280 };
  }, [viewMode, isMobileView, forcedMobile]);

  /**
   * Fdoc item new vs old
   */

  const fdocMap = (fdoc: Fdoc, index: number) => (
    <FdocItemV2
      key={fdoc?.id ? fdoc.id : `skeleton-${index}`}
      viewMode={viewMode}
      zoomLevel={zoomLevel}
      handleOnRemove={fdocRemoveHandler}
      handleOnClick={fdocClickHandler}
      isFolderClickOverriden={allowOverrideFolderClick}
      fdoc={fdoc}
      selectionMode={selectionMode}
      selected={previewSelectedFdocs.includes(fdoc?.id ?? '')}
      disableContextMenu={disableItemPreviewContextMenu}
      canMultiSelect={canMultiSelect}
      canDelete={canDelete}
      canMove={canMove}
      slimSize={slimStyle}
      size={fdocSizes}
      colorLabels={colorLabels}
      list={list}
      isSelectable={true}
      style={{
        ...(customStyleFunction ? customStyleFunction(fdoc as OptimisticDraft<Fdoc>) : {}),
      }}
      disabled={disabledFdocsIds.includes(fdoc?.id ?? '')}
      renderOnlyIfVisible
      renderOnlyIfVisibleRoot={rootList ?? listRef}
      renderOnlyIfVisibleOffset={1500}
      index={index}
      isSidepanel={isSidepanel}
      isFullscreen={isFullscreen}
      {...(customItemPropsFunction ? customItemPropsFunction(fdoc as OptimisticDraft<Fdoc>) : {})}
      resourcePreviewProps={resourcePreviewProps}
    />
  );

  useEffect(() => {
    if (!listRef) return;

    const onDragOver = (e: CustomEvent<ObjectDragOver>) => {
      const { objectId: fdocId, height, width, y } = e.detail;

      // get child elements of the list between
      const children = Array.from(listRef.children).filter(
        (child) => child instanceof HTMLElement && !child.dataset.droppableCursor,
      ) as HTMLElement[];

      // if there are no children, just return
      if (children.length < 1) {
        setDragOverElement({
          position: 10,
          fdocId,
          height,
          width,
        });
        return;
      }

      // get closest index to place the fake element using the accurateY position
      // and ignoring the element that contains a data-draggable-id, and if found
      // detract an offset on the next elements to make space for the fake element
      const calculated = children.reduce(
        (acc, child, index) => {
          if (acc.index !== children.length) return acc;

          const childTop = child.getBoundingClientRect().y;
          const childBottom = childTop + child.getBoundingClientRect().height;
          const childCenter = childTop + child.getBoundingClientRect().height / 2;

          if (y < childCenter) {
            const listTop = listRef.getBoundingClientRect().y;

            const newCenter =
              index === 0
                ? listTop + (childTop - listTop) / 2
                : acc.bottom + (childTop - acc.bottom) / 2;

            return {
              ...acc,
              top: childTop,
              bottom: childBottom,
              center: newCenter,
              index: index - 1,
            };
          } else {
            return {
              ...acc,
              bottom: childBottom,
            };
          }
        },
        { index: children.length, top: 0, bottom: 0, center: 0 },
      );

      const center = calculated.center - listRef.getBoundingClientRect().y + listRef.scrollTop;
      const bottom = calculated.bottom - listRef.getBoundingClientRect().y + listRef.scrollTop;

      setDragOverElement({
        position: center < 0 ? bottom + 10 : center,
        fdocId,
        width,
        height,
      });
    };

    const onDragOut = () => {
      setDragOverElement(null);
    };

    listRef.addEventListener('object-drag-over', onDragOver);
    listRef.addEventListener('object-drag-out', onDragOut);

    return () => {
      listRef.removeEventListener('object-drag-over', onDragOver);
      listRef.removeEventListener('object-drag-out', onDragOut);
    };
  }, [listRef]);

  const { mutate: mutateChangeResourceLabel } = useMutationChangeResourceLabel();

  useEffect(() => {
    if (!listRef || viewMode !== 'Sort' || !list) return;

    const onDragDrop = async (e: CustomEvent<ObjectDragEvent>) => {
      const { objectId: fdocId } = e.detail;

      setDragOverElement(null);

      // If the colorlabel is null it means we want to remove the label
      if (colorLabel === undefined || !splitFdocs?.space?.id) return;

      mutateChangeResourceLabel(
        {
          resourceId: fdocId,
          spaceId: splitFdocs.space.id,
          labelId: colorLabel?.id ?? null,
        },
        {
          onSettled: () => {
            setDragOverElement(null);
          },
        },
      );
    };

    listRef.addEventListener('object-drag-drop', onDragDrop);

    return () => listRef.removeEventListener('object-drag-drop', onDragDrop);
  }, [
    client,
    colorLabel,
    list,
    listRef,
    viewMode,
    parentResourceId,
    mutateChangeResourceLabel,
    splitFdocs?.space?.id,
  ]);

  const isDesktopGlobalSearch = router.pathname === '/desktop-global-search';
  const { inboxRoot } = useQueryInbox();

  const sourceURL = useMemo(() => {
    if (!splitFdocs?.resourceId) return null;

    if (splitFdocs?.resourceId === inboxRoot?.id) return '/saved';
    else if (splitFdocs?.space?.id === splitFdocs.resourceId)
      return `/spaces/${splitFdocs?.space.id}`;
    else return `/folders/${splitFdocs?.resourceId}`;
  }, [splitFdocs?.resourceId, splitFdocs?.space, inboxRoot?.id]);

  const onClickOpenSource = useCallback(() => {
    if (router.pathname !== '/desktop-global-search') return;

    try {
      publish('open-path', { path: sourceURL });
    } catch (e) {
      window.open(`fabric://open-path?path=${sourceURL}`);
    }
    nativeWindow.close();
  }, [router.pathname, sourceURL]);

  const skeletonComponentsArray = useMemo(() => {
    if (fdocTotals) {
      const calculatedValue = Math.max(fdocTotals - (fdocsLoaded ?? fdocs.length), 0);
      const cappedValue = Math.min(calculatedValue, 20);
      return newArrayLength(cappedValue);
    }
    return [];
  }, [fdocTotals, fdocsLoaded, fdocs]);

  const [dynamicTitle, setDynamicTitle] = useState(title ?? '');
  const debounceRef = useRef<number | null>(null);

  const { mutate: mutateRenameColorLabel } = useMutationRenameColorLabel();

  const renameColorLabel = useCallback(() => {
    if (!colorLabel || !listId) return;

    mutateRenameColorLabel({
      folderId: listId,
      colorLabelId: colorLabel?.id ?? 0,
      newTitle: dynamicTitle,
    });
  }, [listId, colorLabel, dynamicTitle, mutateRenameColorLabel]);

  useEffect(() => {
    if (!listId || colorLabel?.name === dynamicTitle) return;

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = window.setTimeout(() => {
      renameColorLabel();
    }, 500);
  }, [listId, colorLabel, dynamicTitle, renameColorLabel]);

  const triggerColorUpdate = () => {
    if (!listId || colorLabel?.name === dynamicTitle) return;

    renameColorLabel();
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDynamicTitle(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      triggerColorUpdate();
    }
  };
  const showDivisionSkeletons = useMemo(() => {
    return (
      hasSeparateDivisions &&
      typeof amountOfDivisions === 'number' &&
      typeof fdocTotals === 'number' &&
      typeof fdocsLoaded === 'number' &&
      index === amountOfDivisions - 1 &&
      fdocsLoaded < fdocTotals
    );
  }, [hasSeparateDivisions, amountOfDivisions, fdocTotals, fdocsLoaded, index]);

  const estimatedContentHeight = useMemo(() => {
    // On mobile view the width/height of the items is dynamic, as such for now we will just return 0
    if (!rootList || !fdocs.length || isMobileView) return 0;

    if (viewMode === 'List') return fdocs.length * 72;
    if (viewMode === 'Grid') {
      if (!rootDom) return 0;
      const zoom = isMobileView ? 1 : zoomLevel ?? 1;

      const horizontalGap =
        (typeof gap === 'string' ? parseInt(gap.split(' ').pop() ?? '0') : gap ?? 0) * zoom;
      const verticalGap =
        (typeof gap === 'string' ? parseInt(gap.split(' ').shift() ?? '0') : gap ?? 0) * zoom;

      const columns = Math.floor(rootDom.width / (260 * zoom + horizontalGap / 2));
      const rows = Math.ceil(fdocs.length / columns);
      return rows * (280 * zoom) + (rows - 1) * verticalGap;
    }
  }, [viewMode, rootDom, fdocs.length, isMobileView, gap, rootList, zoomLevel]);

  if (fdocs.length === 0 && viewMode !== 'Sort') return null;

  const showLoadingSkeletons =
    (!hasSeparateDivisions || showDivisionSkeletons) && viewMode !== 'Sort';

  return (
    <div
      ref={setDivisionRootEl}
      className={clsx(
        styles.division,
        viewMode === 'Sort' && styles['division--sort'],
        !!splitFdocs?.space && viewMode !== 'Sort' && styles.collection,
        slimStyle && styles.slim,
      )}
      style={rootStyle}
      data-estimated-content-height={estimatedContentHeight}
    >
      {(colorLabel || title) && (
        <div className={styles.header}>
          {colorLabel && (
            <div className={styles.colorBubble} style={{ background: colorLabel.hexColor }} />
          )}
          {!!splitFdocs?.space && viewMode !== 'Sort' && !integration && (
            <div className={styles.header_icon}>
              <img src={FolderIcon.src} alt="Folder" />
            </div>
          )}

          {integration && (
            <div className={styles.header_icon}>
              <integration.viewConfig.Icon />
            </div>
          )}
          {title && (
            <h3 className={styles.title}>
              <span>
                {integration && `${integration.viewConfig.integrationName} - `}
                {!colorLabel && title}
              </span>
              {viewMode === 'Sort' && <span className={styles.total}>({fdocs.length})</span>}

              {colorLabel && (
                <input
                  type="text"
                  value={dynamicTitle}
                  disabled={!colorLabel}
                  style={!colorLabel ? { backgroundColor: '#efefef' } : {}}
                  placeholder="Title"
                  id={colorLabel.id.toString()}
                  onChange={handleChangeTitle}
                  onKeyDown={handleKeyPress}
                  onBlur={triggerColorUpdate}
                />
              )}

              {splitFdocs?.recapDate && splitFdocs.recapFrequency && splitFdocs.recapField && (
                <RecapSummaryButton
                  date={splitFdocs.recapDate}
                  frequency={splitFdocs.recapFrequency}
                  field={splitFdocs.recapField}
                />
              )}
            </h3>
          )}

          {title && (sourceURL || router.pathname === '/desktop-global-search') && (
            <DashboardButton
              color="transparent"
              className={styles.openSource}
              style={{
                position: 'relative',
              }}
              onClick={onClickOpenSource}
            >
              Open source location
              {sourceURL && !isDesktopGlobalSearch && (
                <Link
                  href={sourceURL}
                  target={sourceURL.startsWith('fabric://') ? '_blank' : '_self'}
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                  }}
                />
              )}
            </DashboardButton>
          )}
        </div>
      )}
      {inView || !estimatedContentHeight || !optimize ? (
        <div
          className={clsx(
            styles.fdocList,
            viewMode === 'Grid' && styles.gridMode,
            viewMode === 'List' && styles.listMode,
            viewMode === 'Sort' && `${styles.sortMode} dashboard_scrollbar`,
            columns === 1 && styles.singleColumn,
            {
              [styles.gridModeV2]: viewMode === 'Grid',
            },
          )}
          ref={setListRef}
          data-droppable
          style={{
            ...style,
            gap: viewMode === 'Grid' ? gap : undefined,
          }}
        >
          {viewMode === 'Sort' && deferredDragOverElement && (
            <div
              key={`dragOverElement-${deferredDragOverElement.fdocId}`}
              data-droppable-cursor
              className={styles.dragOverElement}
              style={{
                transform: `translateY(${deferredDragOverElement.position}px)`,
              }}
            />
          )}

          {fdocs.map(fdocMap)}

          {/** display the anchor conditionally */}
          {skeletonComponentsArray.length > 0 ? (
            <>
              {/**
               * we know that if there is non empty array of skeletons, we should render the anchor
               * if we actually render the skeletons, we render the anchor inside the first skeleton
               * othwerwise we render the anchor outside without them
               * */}
              {showLoadingSkeletons
                ? skeletonComponentsArray.map((item) => (
                    <ResourcePreviewSkeleton viewMode={viewMode} key={item}>
                      {item === 0 && anchor}
                    </ResourcePreviewSkeleton>
                  ))
                : anchor}
            </>
          ) : (
            fdocTotals === undefined && anchor
          )}
        </div>
      ) : (
        <div
          style={{
            height: estimatedContentHeight,
            flexShrink: 0,
            minHeight: estimatedContentHeight,
          }}
        ></div>
      )}
    </div>
  );
};

export default memo(FdocListDivision, shallow);
