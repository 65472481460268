import React from 'react';
import Modal from '../Modal/Modal';

const TooMuchModal: React.FC<{
  totalTabs: number;
  onCancel: () => void;
  onContinue: () => Promise<void>;
}> = ({ totalTabs, onCancel, onContinue }) => {
  return (
    <Modal
      onCancel={onCancel}
      onConfirm={onContinue}
      onClose={onCancel}
      title={`That’s a lot of tabs! (${totalTabs})`}
      confirmText="Yes, open all"
      showX={false}
      style={{ width: 400 }}
      cancelText="Cancel"
      showCancel={true}
      size="small"
    >
      Do you want to continue to open them all?
    </Modal>
  );
};

export default TooMuchModal;
