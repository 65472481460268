import { Space } from '../spaces.types';

export const mutateSpaceMeta = (space: Space, meta: Partial<Space['_meta']>): Space => {
  return {
    ...space,
    _meta: {
      ...space._meta,
      ...meta,
    },
  };
};
