import { PropsWithChildren } from 'react';
import styles from './ScalableContainer.module.scss';

import { TransformComponent } from 'react-zoom-pan-pinch';

const ScalableContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <TransformComponent wrapperClass={styles.transform_wrapper}>{children}</TransformComponent>
  );
};

export default ScalableContainer;
