import { IconBookmark } from '@/src/modules/icons';
import { PageFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';

interface ResourcePreviewPageProps {
  resource: OptimisticDraft<PageFdoc>;
}

export const ResourcePreviewListItemPage: React.FC<ResourcePreviewPageProps> = () => {
  return <IconBookmark style={{ width: '1rem', height: '1rem' }} />;
};
