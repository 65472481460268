import { useEffect, useState } from 'react';

type ValueType = number | string | boolean;

function useDelayedTransition<T extends ValueType>(
  initialValue: T,
  endValue: T,
  delayMs: number,
): T {
  const [value, setValue] = useState<T>(initialValue);

  useEffect(() => {
    const timer = setTimeout(() => {
      setValue(endValue);
    }, delayMs);

    return () => {
      clearTimeout(timer);
    };
  }, [initialValue, endValue, delayMs]);

  return value;
}

export default useDelayedTransition;
