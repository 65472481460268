import { ResourcePreviewContextMenu } from '@/src/components/FdocItem/ResourcePreviewContextMenu';
import { isInMobile } from '@/src/hooks/mobile';
import { useResponsive } from '@/src/hooks/responsive';
import { isInDesktop } from '@/src/hooks/todesktop';
import useDraggable from '@/src/hooks/useDraggable';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { ResourcePreview } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview';
import { ResourcePreviewContentProps } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { RESOURCE_GRID_PREVIEW_CONTENT } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/constants';
import { useDroppableAsTargetToResourceMove } from '@/src/modules/resources/hooks/useDroppableAsTargetToResourceMove';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { Space } from '@/src/modules/spaces/spaces.types';
import { useMutationResourcesTagAssign } from '@/src/modules/tags/mutations/useMutationResourcesTagAssign';
import { useQueryResourceTags } from '@/src/modules/tags/queries/useQueryResourceTags';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import useMobileSelectionStore from '@/src/store/mobileSelection';
import { Fdoc, getFdocURL } from '@/src/types/api';
import { PrivateTag } from '@fabric/woody-client';
import { platform } from '@todesktop/client-core';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import React, { CSSProperties, memo, useCallback, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import ShareModal from '../../modules/ui/components/ShareModal';
import { OptimisticDraft } from '../../types/draftable';
import EditTagsModal from '../Tags/EditTagsModal';
import { OfViewMode } from '../ViewModeSwitcher/ViewModeSwitcher';

type FdocItemProps = {
  fdoc: OptimisticDraft<Fdoc>;
  colorLabels?: ApiColorLabel[];
  list?: Space;
  index?: number;
  noBoxShadow?: boolean;
  handleOnRemove?: (fdoc: Fdoc) => void;
  handleOnClick?: (fdoc: Fdoc) => void;
  viewMode?: OfViewMode;
  selectionMode?: boolean;
  canMultiSelect?: boolean;
  selected?: boolean;
  disableHover?: boolean;
  canDelete?: boolean; // represents if the user can delete or remove the note
  canMove?: boolean; // represents if the user can move the note to another list
  hideComments?: boolean;
  linkToWebpage?: boolean; // if it's a webnote allow linking to the webpage
  disabled?: boolean;
  isFolderClickOverriden?: boolean;
  disableDrag?: boolean;
  isSelectable?: boolean;

  disableContextMenu?: boolean;

  isInfiniteTriggerEntity?: boolean;
  style?: React.CSSProperties;

  resourcePreviewProps?: Omit<ResourcePreviewContentProps, 'resource'>;
};

const FdocItem: React.FC<FdocItemProps> = (props) => {
  const {
    fdoc,
    handleOnClick = () => {},
    viewMode = 'List',
    selectionMode,
    canMultiSelect = false,
    // noBoxShadow = false,
    selected,
    // disableHover = false,
    canDelete = false,
    canMove = true,
    isInfiniteTriggerEntity = false,
    // linkToWebpage = true,
    // disabled = false,
    disableContextMenu = false,
    isFolderClickOverriden = false,
    disableDrag = false,

    colorLabels = [],
    list,
    isSelectable,
    style,
    resourcePreviewProps,
  } = props;

  const draggableRef = React.useRef<HTMLDivElement | null>(null);
  const droppableRef = React.useRef<HTMLDivElement | null>(null);

  const { isDraggingItemsOver } = useDroppableAsTargetToResourceMove(droppableRef, fdoc, {
    enabled: Boolean(fdoc?.listData?.type !== 'INTEGRATION'),
    action: 'drop-into-folder',
  });

  const isProcessing = isResourceStateProcessing(fdoc?.stateProcessing);
  const isDraft = Boolean(fdoc?.isDraft);
  const isLoadingState = isProcessing || isDraft;

  // const { isMobile } = useResponsive();
  const { mobileSelectModeIsActive } = useMobileSelectionStore(
    (state) => ({
      mobileSelectModeIsActive: state.mobileSelectModeIsActive,
    }),
    shallow,
  );

  const isIntegrationItem = !!fdoc?.listData?.integration;

  const { dragState, isDragging, onMouseDown, draggingStyles, shouldRender, isDragParent } =
    useDraggable({
      ref: draggableRef.current,
      disabled: viewMode === 'List' || disableDrag || isInMobile() || isIntegrationItem || isDraft,
      objectId: fdoc?.id,
      stopPropagation: viewMode !== 'Sort',
      scale: 0.3,
      objectParentId: fdoc?.parentResourceId,
    });

  const router = useRouter();

  const handleOnClickWrapper = useCallback(() => {
    if (fdoc.isDraft) return;
    if (fdoc.type === 'folder' && !isFolderClickOverriden && !mobileSelectModeIsActive) {
      router.push(`/folders/${fdoc.id}`);
      return;
    }
    handleOnClick(fdoc);
  }, [handleOnClick, isFolderClickOverriden, router, mobileSelectModeIsActive, fdoc]);

  const [tagEditorModalOpen, setTagEditorModalOpen] = useState(false);
  const queryResourceTags = useQueryResourceTags(fdoc?.id, {
    enabled: tagEditorModalOpen,
  });

  const { mutate: mutateResourcesTagAssign } = useMutationResourcesTagAssign();

  const onSelectTag = useCallback(
    (tag: PrivateTag, selected: boolean) => {
      if (!fdoc) return;

      mutateResourcesTagAssign({
        tag,
        resourceIds: [fdoc.id],
        operation: selected ? 'assign' : 'unassign',
      });
    },
    [fdoc, mutateResourcesTagAssign],
  );

  // In useCallback to avoid unecessary rerenders on children
  const openFdocInExternalPage = useCallback((fdoc: Fdoc) => {
    const url = getFdocURL(fdoc);
    if (!url) return;

    if (isInDesktop()) {
      platform.os.openURL(url);
      return;
    }

    window.open(url, '_blank');
  }, []);

  const [showingShareModal, setShowingShareModal] = useState(false);

  const { isMobileView } = useResponsive();

  const draggingStylesMerged: CSSProperties = React.useMemo(() => {
    if (!isDragging) {
      return {};
    }

    if (!isDragParent) {
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        width: RESOURCE_GRID_PREVIEW_CONTENT.width,
        height: RESOURCE_GRID_PREVIEW_CONTENT.height,
        ...draggingStyles,
      };
    }

    if (isDragParent && dragState) {
      return {
        width: RESOURCE_GRID_PREVIEW_CONTENT.width,
        height: RESOURCE_GRID_PREVIEW_CONTENT.height,
        pointerEvents: 'none',
      };
    }

    return {};
  }, [isDragging, draggingStyles, dragState, isDragParent]);

  const isSelected =
    ((selectionMode || canMultiSelect) && selected && !isDragging) || isDraggingItemsOver;
  const element = (
    <>
      {/*  @TODO ideally there should not be modal per item*/}
      {showingShareModal && (
        <ShareModal
          resourceId={fdoc.id}
          onClose={() => setShowingShareModal(false)}
          sharingObject="item"
        />
      )}

      {tagEditorModalOpen && (
        <EditTagsModal
          open={tagEditorModalOpen}
          onOpenChange={setTagEditorModalOpen}
          selectedTags={queryResourceTags.data}
          onSelect={onSelectTag}
        />
      )}
      <ListItemWrapper
        ref={droppableRef}
        isDragging={isDragging}
        viewMode={viewMode}
        as={motion.div}
        isSelected={isSelected}
        whileTap="whileTap"
        variants={
          (isMobileView || isInMobile()) && !mobileSelectModeIsActive
            ? {
                whileTap: {
                  transition: { duration: 0.01 },
                  scale: 1.02,
                },
              }
            : {}
        }
        data-drag-over={isDraggingItemsOver}
        data-droppable={Boolean(fdoc?.type === 'folder' && !isDragging)}
        data-testid={fdoc?.type + '-fdoc-item'}
        data-processing={isProcessing ? 'true' : undefined}
        data-draft={fdoc?.isDraft ? 'true' : undefined}
        data-loading={isLoadingState ? 'true' : undefined}
        data-resource-id={fdoc?.id}
        data-is-integration-item={isIntegrationItem ? 'true' : undefined}
        data-category={fdoc?.type}
        data-is-infinite-trigger-entity={isInfiniteTriggerEntity ? 'true' : undefined}
        data-view-mode={viewMode}
        style={{
          ...draggingStylesMerged,
          ...style,
          ...(viewMode === 'List' && { overflow: 'visible' }),
        }}
      >
        <ResourcePreviewContextMenu
          contextDisabled={isDragging || disableContextMenu}
          onItemClickAction={handleOnClickWrapper}
          onGoToSourceClick={() => openFdocInExternalPage(fdoc)}
          selectionMode={selectionMode}
          setShowingShareModal={setShowingShareModal}
          selected={selected}
          hideDelete={fdoc?.isDraft || !canDelete}
          hideMove={fdoc?.isDraft || !canMove}
          sourceUrl={fdoc?.isWebnote ? fdoc.data.pageUrl : undefined}
          colorLabels={colorLabels}
          openTagModal={() => setTagEditorModalOpen(true)}
          list={list}
          fdoc={fdoc}
        >
          <ResourcePreview
            variant={viewMode === 'List' ? 'list' : undefined}
            resource={fdoc}
            colorLabels={colorLabels}
            {...resourcePreviewProps}
            appearence={{
              ...resourcePreviewProps?.appearence,
              hideMeta: isDragging,
            }}
            isSelectable={isSelectable}
            onOpen={handleOnClickWrapper}
            onMouseDown={onMouseDown}
            onDragStart={(e) => e.preventDefault()}
            draggableRef={draggableRef}
            isSelected={isSelected}
          />
        </ResourcePreviewContextMenu>
      </ListItemWrapper>
    </>
  );

  const targetPortal =
    !isDragging || isDragParent
      ? document.getElementById('drag-and-drop-portal')
      : (document.querySelector('[data-drag-parent="true"]') as HTMLElement | null);

  const fdocElement = () => {
    if (!isDragging) return element;

    if (isDragging && !targetPortal) return null;

    return targetPortal
      ? createPortal(
          isDragParent ? (
            <DragParentContainer
              data-drag-parent={isDragParent}
              style={{
                ...(dragState && {
                  ...draggingStyles,
                  ...dragState,
                  width: dragState.width,
                  height: dragState.height,
                  top: dragState.y,
                  left: dragState.x,
                  pointerEvents: 'none',
                }),
              }}
            >
              {element}
            </DragParentContainer>
          ) : (
            element
          ),
          targetPortal,
        )
      : element;
  };

  /** needs to be wrapped with a div. Without it, during DND, component unmounts from the dom relatively, causing layout shifts  */
  return (
    <div>
      {isDragging && viewMode !== 'Sort' && (
        <ResourcePreviewPlaceholder
          variant={viewMode === 'List' ? 'list' : undefined}
          resource={fdoc}
          colorLabels={colorLabels}
          {...resourcePreviewProps}
        />
      )}
      {(!isDragging || shouldRender) && fdocElement()}
    </div>
  );
};

export default memo(FdocItem, shallow);

interface ListItemWrapperProps {
  isSelected: boolean;
  viewMode?: OfViewMode;
  isDragging: boolean;
}
const ListItemWrapper = styled.div.withConfig(
  preventForwardPropsConfig(['isSelected', 'viewMode', 'isDragging']),
)<ListItemWrapperProps>`
  position: relative;
  min-height: 100%;
`;

const ResourcePreviewPlaceholder = styled(ResourcePreview)`
  opacity: 0.5;
  pointer-events: none;
`;

const DragParentContainer = styled.div`
  position: fixed;
  transition:
    box-shadow 200ms cubic-bezier(0.165, 0.84, 0.44, 1),
    transform 200ms cubic-bezier(0.165, 0.84, 0.44, 1);
  will-change: transform, box-shadow, top, left, width, height;
`;
