import { useDomContentRect } from '@/src/hooks/useDomRect';
import React from 'react';
import styled from 'styled-components';

const HEIGHT_DIFF_THRESHOLD = 2;

/**
 * This component truncates text based on the height of the parent element.
 * Automatically tries to guess the line height and number of lines that fit in the parent element.
 */
export const TruncatorV2: React.FC<{
  children: React.ReactNode;
  parentRef: React.RefObject<HTMLElement>;
}> = ({ parentRef, children }) => {
  const [parentRect] = useDomContentRect(parentRef);
  const testRef = React.useRef<HTMLDivElement>(null);
  const [testRect] = useDomContentRect(testRef);

  /**
   * caching the container height
   * issue with subpixels
   * e.g. when hovering with outline, the container can shrink a bit, by a <1px
   * we simply omit the height update if the difference is less than or equal 1px
   */
  const [height, setHeight] = React.useState<number>(0);
  React.useEffect(() => {
    const parentRectHeight = Math.floor(parentRect?.height || 0);
    if (Math.abs(height - parentRectHeight) > HEIGHT_DIFF_THRESHOLD) {
      setHeight(parentRectHeight);
    }
  }, [parentRect?.height, height]);

  const linesCount = React.useDeferredValue(Math.floor(height / (testRect?.height || 16)));

  return (
    <>
      <TruncatedText lines={linesCount}>{children}</TruncatedText>
      <TestTextToCheckLineHeight ref={testRef}>placeholder</TestTextToCheckLineHeight>
    </>
  );
};

const TestTextToCheckLineHeight = styled.div`
  position: absolute;
  opacity: 0;
  pointer-events: none;
`;

const TruncatedText = styled.div<{ lines: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: ${(p) => p.lines};
  -webkit-box-orient: vertical;
`;
