import React from 'react';

import styles from './Toggle.module.scss';

const Toggle: React.FC<{
  label: string;

  disabled?: boolean;
  checked: boolean;
  onChange: (e: boolean) => void;
}> = ({ label, checked, onChange, disabled }) => {
  return (
    <label className={styles.toggle} data-disabled={disabled}>
      {label}
      <input
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        disabled={disabled}
      />
    </label>
  );
};

export default Toggle;
