import { useShowExpandedFdoc } from '@/src/hooks/useExpandedFdoc';
import { pick } from '@/src/lib/store';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import { AssistantContextSubType } from '@/src/modules/assistant/types';
import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

const useAskAssistantItem = () => {
  const { setAssistantSelectedIds, setLastContextSubtype } = useAssistantStore(
    (state) => pick(state, ['setLastContextSubtype', 'setAssistantSelectedIds']),
    shallow,
  );

  const showExpandedFdoc = useShowExpandedFdoc();

  return useCallback(
    (id: string) => {
      setAssistantSelectedIds([]);
      setLastContextSubtype(AssistantContextSubType.File);
      showExpandedFdoc(id, 'assistant');
    },
    [setAssistantSelectedIds, setLastContextSubtype, showExpandedFdoc],
  );
};

export { useAskAssistantItem };
