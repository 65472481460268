import {
  LocationSelectorNodeData,
  LocationSelectorNodePathItem,
} from '@/src/modules/resources/components/DestinationSelector/types';
import React from 'react';

export type LocationSelectorContextProps = {
  activeNodePath: LocationSelectorNodePathItem[] | null;
  onSelectNode: (data: LocationSelectorNodeData | null) => void;
  selectedNode: LocationSelectorNodeData | undefined | null;
  scrollAreaEl: HTMLDivElement | null;
  unselectableDestinationIds: string[];
};

export const LocationSelectorContext = React.createContext<LocationSelectorContextProps>(
  {} as LocationSelectorContextProps,
);

export const useLocationSelectorContext = () => React.useContext(LocationSelectorContext);
