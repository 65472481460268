import { useEffect, useState } from 'react';

const useDeferred = <T,>(value: T): T => {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  return state;
};

export default useDeferred;
