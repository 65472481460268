import { Fdoc } from '@/src/types/api';
import { PrivateTag } from '@fabric/woody-client';

export const mutateResourceTag = (
  resource: Fdoc,
  tag: PrivateTag,
  operation: 'assign' | 'unassign',
) => {
  const tags = resource.personal.tags.slice();
  const tagIndex = tags.findIndex((t) => t.id === tag.id);

  if (operation === 'assign' && tagIndex === -1) {
    tags.push(tag);
  } else if (operation === 'unassign' && tagIndex !== -1) {
    tags.splice(tagIndex, 1);
  }

  return {
    ...resource,
    personal: {
      ...resource.personal,
      tags,
    },
  };
};
