import { NodeViewProps, NodeViewWrapper } from '@tiptap/react';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import Spinner from '../../Spinner/Spinner';
import styles from './EmbedComponent.module.scss';

type EmbedAttributes = {
  src?: string | null;
  alt?: string | null;
  title?: string | null;
  width?: string | null;
  height?: string | null;
  mimeType?: string | null;
  loading?: boolean;
  version: string;
};

export type EmbedComponentProps = {
  node: NodeViewProps['node'] & { attrs: EmbedAttributes };
};

const EmbedComponent: React.FC<EmbedComponentProps> = ({ node }) => {
  // right now we only support images.
  const { src, alt, mimeType, title, width, height, loading } = node.attrs;
  const [ref, setRef] = useState<HTMLElement | null>(null);

  const element = useMemo(() => {
    if (!src) return null;

    if (mimeType?.startsWith('image/')) {
      return (
        <img
          ref={setRef}
          src={src}
          alt={alt ?? undefined}
          title={title ?? undefined}
          width={width ?? 'auto'}
          height={height ?? undefined}
          data-resizeable
        />
      );
    }
  }, [mimeType, src, alt, title, width, height]);

  const [spinnerPosition, setSpinnerPosition] = useState({ x: 0, y: 0 });
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
  useEffect(() => {
    if (!ref) return;

    const updatePosition = () => {
      const rect = ref.getBoundingClientRect();
      setSpinnerPosition({ x: rect.width / 2 - 16, y: rect.height / 2 - 16 });
      setImageSize({ width: rect.width, height: rect.height });
    };

    updatePosition();
    const observer = new ResizeObserver(updatePosition);
    observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [ref]);

  return (
    <NodeViewWrapper className={styles.wrapper}>
      <div className={clsx(styles.embed, loading && styles.loading)} draggable data-drag-handle>
        {element}
        {loading && element && ref && spinnerPosition.x > 0 && spinnerPosition.y > 0 && (
          <Spinner
            size={32}
            thickness={4}
            className={styles.spinner}
            style={{
              position: 'absolute',
              top: spinnerPosition.y,
              left: spinnerPosition.x,
            }}
          />
        )}
        <span
          className={styles.yjs_cursor}
          style={{
            width: `${imageSize.width}px`,
            height: `${imageSize.height}px`,
          }}
        />
      </div>
    </NodeViewWrapper>
  );
};

export default EmbedComponent;
