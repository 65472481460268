import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const PauseIcon: FC<IconProps> = ({ color = 'currentColor', size = 18, className, ...props }) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <rect x="3.5" y="2" width="4" height="14" rx="1" fill={color} />
      <rect x="10.5" y="2" width="4" height="14" rx="1" fill={color} />
    </svg>
  );
};

export default PauseIcon;
