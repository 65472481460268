import clsx from 'clsx';
import { FC } from 'react';
import { IconProps } from './types';

const SimilarIcon: FC<IconProps> = ({ color = 'currentColor', size = 18, className, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx('new_icon', className)}
      {...props}
    >
      <circle cx="4.33325" cy="4" r="4" fill={color} />
      <circle cx="8.33325" cy="8" r="4" fill={color} fillOpacity="0.5" />
    </svg>
  );
};

export default SimilarIcon;
