import { useBoolState } from '@/src/hooks/useBooleanState';
import { DrawerExpandedStyle } from '@/src/modules/ui/components/Drawer/drawerGlobalStyles';
import { defaultModalContentProps } from '@/src/modules/ui/components/Modal';
import { useWindowPanning } from '@/src/modules/ui/hooks/useWindowPanning';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { WithAsProp } from '@/src/types/react';
import { Content } from '@radix-ui/react-dialog';
import { motion } from 'framer-motion';
import React, { FC } from 'react';
import styled, { css, keyframes } from 'styled-components';

const inAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

const outAnimation = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;

const ModalDrawerContentContainerVariants = {
  default: css`
    background: ${cssVar['color-bg-primary']};
    &:after {
      background: ${cssVar['color-bg-primary']};
    }
  `,
  extraPanel: css`
    background: ${cssVar['bg-expanded-resource-extra-panel']};
    &:after {
      background: ${cssVar['bg-expanded-resource-extra-panel']};
    }
  `,
};

interface ModalDrawerContentContainerProps {
  variant?: keyof typeof ModalDrawerContentContainerVariants;
  disableAnimation?: boolean;
}

const ModalDrawerContentContainer = styled(Content).attrs((props) => {
  return {
    ...defaultModalContentProps,
    ...props,
  };
})<ModalDrawerContentContainerProps>`
  display: flex;
  flex-direction: column;
  border-radius: 2rem 2rem 0 0;
  /* padding-bottom: env(safe-area-inset-bottom); */
  will-change: height;
  /* overflow: hidden; */
  max-height: calc(
    100% - env(safe-area-inset-top) - max(var(--keyboard-height, 0px), env(safe-area-inset-bottom))
  );

  position: fixed;
  bottom: max(var(--keyboard-height, 0px), env(safe-area-inset-bottom));

  // to compensate bottom offset safe area
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    display: block;
    height: 100px;
    width: 100%;
    left: 0;
  }

  left: 0;
  width: 100vw;
  z-index: 125;

  animation-fill-mode: forwards;
  transform: translateY(100%);

  ${(p) =>
    !p.disableAnimation &&
    css`
      &[data-state='open'] {
        animation: ${inAnimation} 0.2s ease-out forwards;
      }

      &[data-state='closed'] {
        animation: ${outAnimation} 0.2s ease-out forwards;
      }
    `}

  ${(p) => ModalDrawerContentContainerVariants[p.variant || 'default']};
`;

const DragHandle = styled(motion.div)`
  height: calc(16px + 7px);
  position: relative;
  flex-shrink: 0;
  // visible area

  &:after {
    content: '';
    display: block;
    position: absolute;
    background: red;
    width: 100%;
    height: 70px;
    top: -10px;
    opacity: 0;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background: ${cssVar['color-bg-quinary']};
    height: 7px;
    width: 48px;
    border-radius: 3.5px;
  }
`;

const DEFAULT_HEIGHT = '60dvh';
const EXPANDED_HEIGHT = '100dvh';

interface DrawerProps extends ModalDrawerContentContainerProps {
  children: React.ReactNode;
  onClose: VoidFunction;
  baseHeight?: string;
  asChild?: boolean;
  panMode?: 'window' | 'handle';
}

export const ModalDrawerContent = React.forwardRef<HTMLDivElement, WithAsProp<DrawerProps, FC>>(
  (
    {
      children,
      onClose,
      as,
      baseHeight = DEFAULT_HEIGHT,
      panMode = 'handle',
      variant,
      disableAnimation,
    },
    ref,
  ) => {
    const [currentHeight, setCurrentHeight] = React.useState<string>(baseHeight);

    const [panValue, setPanValue] = React.useState(0);
    const isPanningState = useBoolState();

    const handlePanStart = () => {
      isPanningState.handleTrue();
    };

    const handlePan = (delta: number) => {
      setPanValue((prev) => prev + delta);
    };

    const handlePanEnd = (direction: 'up' | 'down' | null, velocity: number, offset: number) => {
      isPanningState.handleFalse();
      setPanValue(0);

      if (direction === 'up' && (velocity > 0.5 || offset < -80)) {
        setCurrentHeight(EXPANDED_HEIGHT);
      } else if (direction === 'down' && (velocity > 0.5 || offset > 80)) {
        if (currentHeight === EXPANDED_HEIGHT) {
          setCurrentHeight(baseHeight);
        } else {
          setCurrentHeight('0px');
          onClose();
        }
      }
    };

    // Window-wide panning
    useWindowPanning({
      onPanStart: handlePanStart,
      onPan: handlePan,
      onPanEnd: handlePanEnd,
      respectScrollAreas: currentHeight === EXPANDED_HEIGHT ? true : 'up',
      disabled: panMode !== 'window',
    });

    const height = `min(calc(${currentHeight} + ${-1 * panValue}px - env(safe-area-inset-top) - 1rem), 100dvh)`;

    return (
      <>
        <DrawerExpandedStyle />
        <ModalDrawerContentContainer
          style={{ height, transition: isPanningState.value ? undefined : 'height 0.1s ease-out' }}
          as={as}
          ref={ref}
          variant={variant}
          disableAnimation={disableAnimation}
        >
          <DragHandle
            onPanStart={() => {
              isPanningState.handleTrue();
            }}
            onPan={(e, info) => {
              setPanValue(info.offset.y);
            }}
            onPanEnd={(e, info) => {
              isPanningState.handleFalse();
              setPanValue(0);

              // expand
              if (info.offset.y < -80) {
                setCurrentHeight(EXPANDED_HEIGHT);
                return;
              }

              if (info.offset.y > 80) {
                if (currentHeight === EXPANDED_HEIGHT) {
                  setCurrentHeight(baseHeight);
                } else {
                  setCurrentHeight('0px');
                  onClose();
                }
              }
            }}
          />
          {children}
        </ModalDrawerContentContainer>
      </>
    );
  },
);

ModalDrawerContent.displayName = 'ModalDrawerContent';
