import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { useWoody } from '@/src/services/woody/woody';
import WoodyClient from '@fabric/woody-client';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { resourceDetailsQueryKeys } from './resourceDetailsQueryKeys';

const sizes = [32, 64, 128] as const;

export type FaviconSize = (typeof sizes)[number];

const isValidImage = async (url: string, requestedSize?: FaviconSize): Promise<boolean> => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => {
      /**
       * we're trying to omit the default provided placeholder images here
       * If the icon is not found, providers will send their placeholder image with response 404
       * However this is not detected via image.onerror so we need to filter this noise out by checking the naturalWidth of the image
       *
       * faviconkit has placeholder 256
       * google 16
       * duckduckgo 48
       */
      if (
        (requestedSize && img.naturalWidth !== requestedSize) ||
        !sizes.find((item) => item === img.naturalWidth)
      ) {
        resolve(false);
      } else {
        resolve(true);
      }
    };
    img.onerror = () => resolve(false);
    img.src = url;
  });
};

const faviconKitBase = `https://grumpy-plum-snail.faviconkit.com`;
const getFaviconKitImage = async (url: string, size: FaviconSize): Promise<string | null> => {
  try {
    const urlObject = new URL(url);
    const base = `${faviconKitBase}/${urlObject.hostname}/${size}`;
    const isValid = await isValidImage(base, size);
    return isValid ? base : null;
  } catch (error) {
    return null;
  }
};

const getGoogleFavicon = async (url: string, size: FaviconSize): Promise<string | null> => {
  try {
    const urlObject = new URL(url);
    const base = `https://www.google.com/s2/favicons?domain=${urlObject.hostname}&sz=${size}`;
    const isValid = await isValidImage(base, size);
    return isValid ? base : null;
  } catch (error) {
    return null;
  }
};

const getDuckDuckGoFavicon = async (url: string): Promise<string | null> => {
  try {
    const urlObject = new URL(url);
    const base = `https://icons.duckduckgo.com/ip2/${urlObject.hostname}.ico`;
    const isValid = await isValidImage(base);
    return isValid ? base : null;
  } catch (error) {
    return null;
  }
};

const getWoodyFavicon = async (url: string, client: WoodyClient): Promise<string | null> => {
  const { data: response, error } = await client.getLinkPreview(url);

  if (error || !response) return null;
  const faviconUrl = response.data?.icon?.url;

  if (!faviconUrl) return null;
  const isValid = await isValidImage(faviconUrl);

  return isValid ? faviconUrl : null;
};

/**
 * Fetches the preview for a given page resource.
 *
 * @param queryOptions
 * @returns
 */
export const useQueryPageFavicon = (
  url: string,
  size: FaviconSize = 32,
  queryOptions?: Partial<QueryObserverOptions<string | null>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: resourceDetailsQueryKeys.favicon(url, size),
    queryFn: async (params) => {
      const safeUrl = params.queryKey[1] as string;
      const safeSize = params.queryKey[2] as FaviconSize;

      return (
        (await getFaviconKitImage(safeUrl, safeSize)) ||
        (await getGoogleFavicon(safeUrl, safeSize)) ||
        (await getWoodyFavicon(safeUrl, client)) ||
        (await getDuckDuckGoFavicon(safeUrl))
      );
    },
    placeholderData: keepPreviousData,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    ...queryOptions,
    enabled: isQueryEnabled([!!url, queryOptions?.enabled]),
  });

  return query;
};
