import { resourceDetailsQueryKeys } from '@/src/modules/resource-detail/queries/resourceDetailsQueryKeys';
import { Query } from '@tanstack/react-query';

export const resourceDetailsQueryPredicates = {
  notepadStateById: (query: Query, notepadId: string) => {
    return (
      query.queryKey[0] === resourceDetailsQueryKeys.notepadState()[0] &&
      query.queryKey[1] === notepadId
    );
  },
};
