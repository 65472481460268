import { FC } from 'react';
import clsx from 'clsx';
import { IconProps } from '@/src/icons/types';

const GeneratedIcon: FC<IconProps> = ({ size = 14, className, ...props }) => {
  return (
    <svg
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <path
        id="Vector 436"
        d="M6.95379 3.87724L8.68566 4.40433C8.78018 4.4331 8.78018 4.5669 8.68566 4.59567L6.95379 5.12276C6.316 5.31687 5.81687 5.816 5.62276 6.45379L5.09567 8.18566C5.0669 8.28018 4.9331 8.28018 4.90433 8.18566L4.37724 6.45379C4.18313 5.816 3.684 5.31687 3.04621 5.12276L1.31434 4.59567C1.21982 4.5669 1.21982 4.4331 1.31434 4.40433L3.04621 3.87724C3.684 3.68313 4.18313 3.184 4.37724 2.54621L4.90433 0.814336C4.9331 0.719824 5.0669 0.719824 5.09567 0.814336L5.62276 2.54621C5.81687 3.184 6.316 3.68313 6.95379 3.87724Z"
        fill="currentColor"
        stroke="currentColor"
      />
      <path
        id="Vector 437"
        d="M8.27082 8.91758C8.82031 8.75034 9.25034 8.32031 9.41758 7.77082C9.44236 7.68939 9.55764 7.68939 9.58242 7.77082C9.74966 8.32031 10.1797 8.75034 10.7292 8.91758C10.8106 8.94236 10.8106 9.05764 10.7292 9.08242C10.1797 9.24966 9.74966 9.67969 9.58242 10.2292C9.55764 10.3106 9.44236 10.3106 9.41758 10.2292C9.25034 9.67969 8.82031 9.24966 8.27082 9.08242C8.18939 9.05764 8.18939 8.94236 8.27082 8.91758Z"
        fill="currentColor"
        stroke="currentColor"
      />
    </svg>
  );
};

export default GeneratedIcon;
