import clsx from 'clsx';
import React, { forwardRef } from 'react';

import styles from './DashboardButton.module.scss';

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  // some names where chosen using https://colornamer.robertcooper.me/ & https://color-name-generator.com/
  type?: 'button' | 'submit' | 'reset';
  color?:
    | 'primary'
    | 'light-primary'
    | 'red'
    | 'light-red'
    | 'violet'
    | 'transparent'
    | 'transparent-white'
    | 'eletric-ultramarine'
    | 'acid'
    | 'transparent-eletric-ultramarine'
    | 'black'
    | 'semi-transparent-black'
    | 'grey'
    | 'transparent-toggle'
    | 'white';
  size?: 'icon' | 'small' | 'medium' | 'medium-large' | 'large' | 'xl';
  border?: boolean;
  active?: boolean;
  disabled?: boolean;
}

/**
 * @deprecated please use {@link @/src/modules/ui/Button} instead
 */
const DashboardButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      type = 'button',
      color = 'primary',
      size = 'medium',
      border,
      active = false,
      children,
      className,
      ...props
    },
    ref,
  ) => {
    return (
      <button
        {...props}
        ref={ref}
        type={type}
        className={clsx(
          styles.button,
          styles[`button_${size}`],
          styles[`button_${color}`],
          active && styles.active,
          border && styles.border,
          className,
        )}
      >
        {children}
      </button>
    );
  },
);

DashboardButton.displayName = 'DashboardButton';

/**
 * @deprecated please use {@link @/src/modules/ui/Button} instead
 */
export default DashboardButton;
