import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import { ChatbotErrorCodes } from '@fabric/woody-client';
import Link from 'next/link';
import React from 'react';
import { useChabot } from '../../hooks/chatbot';

const messages = {
  [ChatbotErrorCodes.INTERNAL_SERVER_ERROR]: 'Something went wrong.',
  [ChatbotErrorCodes.USAGE_LIMIT_EXCEEDED]: 'You’ve hit your plan’s answer limit',
};

const isRetryable = (error: ChatbotErrorCodes) => {
  return error !== ChatbotErrorCodes.USAGE_LIMIT_EXCEEDED;
};

const needsUpgrade = (error: ChatbotErrorCodes) => {
  return error === ChatbotErrorCodes.USAGE_LIMIT_EXCEEDED;
};

const ChatbotError: React.FC = () => {
  const { error, retry } = useChabot();

  const { setChatAssistantOpen } = useAssistantStore();

  if (!error) {
    return null;
  }

  return (
    <div className="p-2 text-sm bg-red-100 text-red-600 rounded-md">
      {messages[error] || 'Something went wrong.'}

      {needsUpgrade(error) && (
        <>
          {' – '}
          <Link
            className="underline"
            href="/pricing"
            target="_self"
            // For a weird reason the link doesn't work while the chatbot is open
            // Forcing the chatbot to close fixes it.
            onClick={() => setChatAssistantOpen(false)}
          >
            upgrade for more.
          </Link>
        </>
      )}

      {retry && isRetryable(error) && (
        <button
          className="ml-2 underline"
          onClick={() => {
            retry();
          }}
        >
          Retry
        </button>
      )}
    </div>
  );
};

export default ChatbotError;
