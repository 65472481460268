export type StoredDataTransfer = {
  items: {
    [key: string]: string;
  };
};

/**
 * Given a DataTransfer object extracts all items into an object that can be used to recreate the dataTransfer object.
 * Seems like passing the dataTransfer around causes it to lose its reference to the original data.
 * Will not include files as they are not serializable, just strings and objects.
 * @param dataTransfer
 * @returns
 */
export const storeDataTransfer = (dataTransfer: DataTransfer): StoredDataTransfer => {
  const items: StoredDataTransfer['items'] = {};

  for (let i = 0; i < dataTransfer.types.length; i++) {
    const type = dataTransfer.types[i];
    const data = dataTransfer.getData(type);

    if (typeof data !== 'string') continue;

    items[type] = data;
  }

  return { items };
};

/**
 * Given a StoredDataTransfer object, recreates a DataTransfer object.
 * @param storedDataTransfer
 * @returns
 */
export const restoreDataTransfer = (storedDataTransfer: StoredDataTransfer): DataTransfer => {
  const dataTransfer = new DataTransfer();

  for (const type in storedDataTransfer.items) {
    dataTransfer.setData(type, storedDataTransfer.items[type]);
  }

  return dataTransfer;
};
