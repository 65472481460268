/* eslint-disable import/no-unused-modules */

/**
 * probably initial setup for the icons in the project as mobile icons will differ
 * so the idea will be something like export { IconConnectionPause: isMobileViewport ? IconConnectionPauseMobile : IconConnectionPause }
 */

/**
 * currently a simple reexport so it hints in the ide
 */

export { default as IconAlphabet } from './svg/Alphabet.svg';
export { default as IconBookmark } from './svg/Bookmark.svg';
export { default as IconClock } from './svg/Clock.svg';
export { default as IconConnectionPause } from './svg/ConnectionPause.svg';
export { default as IconConnectionResume } from './svg/ConnectionResume.svg';
export { default as IconCopy } from './svg/Copy.svg';
export { default as IconDownload } from './svg/Download.svg';
/**
 * Desktop variant of the export icon
 * has less rounded corners
 */
export { default as IconAssistantFace } from './svg/AssistantFace.svg';
export { default as IconAssistantSquare } from './svg/AssistantSquare.svg';
export { default as IconExportDesktop } from './svg/Export-desktop.svg';
export { default as IconExport } from './svg/Export.svg';
export { default as IconEye } from './svg/Eye.svg';
export { default as IconFolder } from './svg/Folder.svg';
export { default as IconFolderCreate } from './svg/FolderCreate.svg';
export { default as IconGlobe } from './svg/Globe.svg';
export { default as IconGrid4 } from './svg/Grid4.svg';
export { default as IconHamburgerFooter } from './svg/HamburgerFooter.svg';
export { default as IconLinkArrow } from './svg/LinkArrow.svg';
export { default as IconLinkWindow } from './svg/LinkWindow.svg';
export { default as IconMove } from './svg/Move.svg';
export { default as IconPadlock } from './svg/Padlock.svg';
export { default as IconPencil } from './svg/Pencil.svg';
export { default as IconPlus } from './svg/Plus.svg';
export { default as IconPlusSmall } from './svg/PlusSmall.svg';
export { default as IconTag } from './svg/Tag.svg';
export { default as IconThemeDark } from './svg/ThemeDark.svg';
export { default as IconThemeLight } from './svg/ThemeLight.svg';
export { default as IconTrash } from './svg/Trash.svg';
export { default as IconUserPlus } from './svg/UserPlus.svg';
