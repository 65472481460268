import { createGlobalStyle } from 'styled-components';

/**
 * render this stylesheet when drawer is open
 */
export const DrawerExpandedStyle = createGlobalStyle`
#__next {
  scale: 0.9;
}
`;
