import { withConfirmation } from '@/src/lib/react-query/utilities';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation } from '@tanstack/react-query';
import { useQueryCacheTagHelpers } from '../utils/useQueryCacheTagHelpers';

export const useMutationTagDelete = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { deleteCachedTag } = useQueryCacheTagHelpers();

  return withConfirmation(
    useMutation({
      mutationFn: async (tagId: string) => {
        return client.v2(
          {
            endpoint: '/v2/tags/{tagId}',
            params: {
              tagId,
            },
          },
          {
            method: 'delete',
          },
        );
      },
      onMutate: async (tagId) => {
        return deleteCachedTag(tagId);
      },
      onError: (_e, _v, context) => {
        toast({
          content: 'Something went wrong while deleting the tag. Please try again.',
        });

        context?.resetCacheToPreOptimisticState();
        context?.invalidateQueries();
      },
      onSuccess: (_, tagId) => {
        track(AnalyticsEvents.DeletedTag, { tagId });
      },
    }),
    () => ({
      title: 'Delete tag - Are you sure?',
      content: 'Are you sure you want to delete this tag? This action cannot be undone.',
      confirmLabel: 'Yes, Delete',
      cancelLabel: 'Cancel',
      confirmButtonProps: {
        variant: 'danger',
      },
    }),
  );
};
