import Skeleton from '@/src/components/Skeleton/Skeleton';
import { OfViewMode } from '@/src/components/ViewModeSwitcher/ViewModeSwitcher';
import { PreviewContainer } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/components/PreviewContainer';
import {
  COMMENT_SECTION_HEIGHT,
  DESKTOP_CARD_HEIGHT,
  DESKTOP_CARD_WIDTH,
  TAGS_SECTION_HEIGHT,
  TITLE_SECTION_HEIGHT,
} from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/constants';
import { containerPreviewCompact } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/cssPreviewContainer';
import { useResourcePreviewUiConfigContext } from '@/src/modules/resources/components/ResourcePreview/resourcePreviewUiConfigContext';
import { cssVar } from '@/src/modules/ui/theme/variables';
import React from 'react';
import styled, { css } from 'styled-components';

export const ResourcePreviewSkeleton: React.FC<{
  children?: React.ReactNode;
  viewMode: OfViewMode;
}> = ({ children, viewMode }) => {
  const uiConfigContext = useResourcePreviewUiConfigContext();
  const { appearence } = uiConfigContext || {};

  const minusBaseHeight = [
    appearence?.hideTitle ? TITLE_SECTION_HEIGHT : 0,
    appearence?.hideComment ? COMMENT_SECTION_HEIGHT : 0,
    appearence?.hideTags ? TAGS_SECTION_HEIGHT : 0,
  ].reduce((acc, curr) => acc + curr, 0);

  return (
    <PreviewContainer>
      <StyledSkeleton viewMode={viewMode} minusBaseHeight={minusBaseHeight}>
        {children}
      </StyledSkeleton>
    </PreviewContainer>
  );
};

const StyledSkeleton = styled(Skeleton)<{ viewMode: OfViewMode; minusBaseHeight: number }>`
  ${(p) => {
    if (p.viewMode === 'List') {
      return css`
        border-radius: 0.25rem;
        border: 1px solid ${cssVar['color-border-primary']};
        height: 100%;
      `;
    }
    return css`
      border-radius: 1.25rem;
      height: calc(${DESKTOP_CARD_HEIGHT}px - ${p.minusBaseHeight || 0}px);
      width: ${DESKTOP_CARD_WIDTH}px;
      ${containerPreviewCompact} {
        height: auto;
        width: auto;
        aspect-ratio: ${cssVar['preview-compact-card-aspect-ratio']};
      }
    `;
  }}
`;
