import FileDisplay from '@/src/components/FileDisplay/FileDisplay';
import { OfViewMode } from '@/src/components/ViewModeSwitcher/ViewModeSwitcher';
import { useQueryResourceFileLikeDetails } from '@/src/modules/resource-detail/queries/useQueryResourceFileLikeDetails';
import { Image } from '@/src/modules/ui/components/Image';
import { ImageFdoc } from '@/src/types/api';
import { Size } from '@/src/types/global';
import { useMemo } from 'react';
import { OptimisticDraft } from '../../../types/draftable';
import Skeleton from '../../Skeleton/Skeleton';
import styles from './FdocItemContent.module.scss';
type FdocItemContentImageProps = {
  fdoc: OptimisticDraft<ImageFdoc>;
  constrainedImages?: number | null;

  viewMode: OfViewMode;
  isMobileCard?: boolean;

  size?: Size;
};

function FdocItemContentImage({ fdoc, viewMode, isMobileCard }: FdocItemContentImageProps) {
  const { data, isLoading } = useQueryResourceFileLikeDetails(fdoc);

  const fileDisplayScale = useMemo(() => {
    if (viewMode === 'List') return 0.15;
    if (isMobileCard) return 1;
    return 0.4;
  }, [viewMode, isMobileCard]);

  const extension = (
    '.' + data?.url?.split('.').pop() ||
    fdoc.data?.domImageUrl?.split('.').pop() ||
    'img'
  ).slice(0, 5);

  return (
    <div className={styles.item__content__image} data-testid="item-content-section">
      {!isLoading ? (
        <Image src={data?.url} alt={fdoc.data.pageTitle}>
          <FileDisplay
            fileExtension={extension}
            fileName={fdoc.data.pageTitle}
            nameWithoutExtension
            scale={isMobileCard ? 1 : fileDisplayScale}
            hideFileName={viewMode === 'List'}
            compact={isMobileCard}
          />
        </Image>
      ) : (
        <Skeleton width={335} height={335} />
      )}
    </div>
  );
}

export default FdocItemContentImage;
