import CustomSignedBunnyLoader from '@/src/custom-image-loader';
import NextImage, { ImageProps } from 'next/image';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
  useControls,
} from 'react-zoom-pan-pinch';

interface PinchZoomImageProps extends ImageProps {
  src: string;
  disableGestures?: boolean;
}

import { useResponsive } from '@/src/hooks/responsive';
import { useBoolState } from '@/src/hooks/useBooleanState';
import { useDomContentRect } from '@/src/hooks/useDomRect';
import { useImageDimension } from '@/src/hooks/useImageDimension';
import { renderCssBasedOnColorScheme } from '@/src/modules/ui/theme/renderCssBasedOnColorScheme';
import { cssVar } from '@/src/modules/ui/theme/variables';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { mediaDesktop, mediaSidebarBottom, mediaTablet } from '../../styled-utils';

const PinchZoomImageContainer = styled.div`
  min-height: 200px;
  max-height: 100%;
  height: 100%;
  width: 100%;
  max-width: 100%;
  will-change: height;
  flex-grow: 1;
  ${renderCssBasedOnColorScheme({
    light: css`
      background: ${cssVar['color-bg-tertiary']};
    `,
    dark: css`
      background: ${cssVar['color-bg-primary']};
    `,
  })}

  ${mediaSidebarBottom} {
    height: 1px;
  }

  .pinch-zoom-image-wrapper {
    max-height: 100%;
    height: 100%;
    width: 100%;
  }

  .pinch-zoom-image-content {
    max-height: 100%;
    height: 100%;
    position: relative;
    ${mediaTablet} {
      height: auto;
      max-height: 100%;

      width: max-content;
      max-width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  ${mediaDesktop} {
    padding: 0.5rem;
    .pinch-zoom-image-wrapper {
      overflow: hidden;
      border-radius: 0.5rem 0.5rem 0.75rem 0.75rem;
    }
  }
`;

const StyledNextImage = styled(NextImage)<{
  $isVertical?: boolean;
  $cssHeight?: number;
  $isLoaded: boolean;
}>`
  max-width: 100%;
  object-fit: contain;
  position: relative !important;
  opacity: 0;
  transition: 0.2s opacity;
  will-change: height;
  ${(p) =>
    p.$isLoaded &&
    css`
      opacity: 1;
    `}
  ${mediaDesktop} {
    border-radius: 0.5rem;
  }
  ${mediaTablet} {
    /* width: 100%; */
    height: auto;

    ${(p) =>
      p.$isVertical &&
      css`
        // unfortunatelly there are some global styles we need to override this way, e.g. the height
        height: ${p.$cssHeight}px;
        max-height: 100%;
        width: auto;
        max-width: 100%;
      `}
  }
`;

const MIN_SCALE = 1;
const INIT_SCALE = Math.max(1, MIN_SCALE);
const MAX_ZOOM = 8;

const PinchZoomImageComponent = React.memo(
  React.forwardRef<
    HTMLImageElement,
    PinchZoomImageProps & {
      cssHeight: number;
      maxWidth: number;
    }
  >(function PinchZoomImg(props, ref) {
    const { cssHeight, maxWidth, ...imageProperties } = props;
    const imageSize = useImageDimension(props.src);
    const isVertical = imageSize.height > imageSize.width;
    const { handleTrue: setAsLoaded, value: isLoaded } = useBoolState();

    const { centerView } = useControls();

    React.useEffect(() => {
      if (props.disableGestures) {
        centerView(1, 0);
      }
    }, [props.disableGestures, centerView]);

    /**
     * on load, we center the image
     * the lib is broken
     */
    const onLoad = React.useCallback(() => {
      if (!isLoaded) {
        setTimeout(() => {
          centerView(1, 0);
          setAsLoaded();
        }, 100);
      }
    }, [centerView, setAsLoaded, isLoaded]);

    /**
     * on resize, center the image
     * the lib doesn't do it automatically...
     */
    React.useEffect(() => {
      centerView(undefined, 0);
    }, [cssHeight, maxWidth, centerView]);

    return (
      <StyledNextImage
        ref={ref}
        {...imageProperties}
        $isLoaded={isLoaded}
        draggable={false}
        onLoad={onLoad}
        loader={CustomSignedBunnyLoader}
        $isVertical={isVertical}
        $cssHeight={cssHeight}
      />
    );
  }),
);

export const PinchZoomImage = React.forwardRef<HTMLImageElement, PinchZoomImageProps>(
  function PinchZoomImage({ disableGestures, ...props }, ref) {
    const containerRef = useRef<HTMLDivElement>(null);
    const { isDesktopView } = useResponsive();

    const [domRect] = useDomContentRect(containerRef);

    /**
     * on any action, if scale is 1, move the image to the center
     */
    const handleCenterViewOnScale1 = (transform: ReactZoomPanPinchRef) => {
      if (transform.state.scale <= 1) {
        transform.centerView(1, 100);
      }
    };

    const imageProperties: PinchZoomImageProps = {
      ...props,
      ...(isDesktopView
        ? {
            fill: true,
          }
        : {
            width: domRect?.width ?? 0,
            height: domRect?.height ?? 0,
            fill: false,
          }),
    };

    const [scale, setScale] = React.useState<number>(1);

    return (
      <PinchZoomImageContainer
        data-testid="item-content-section"
        ref={containerRef}
        data-pan-id-ignore-expanded-resource={scale > 1 ? 'xy' : false}
      >
        <TransformWrapper
          key={props.src}
          initialScale={INIT_SCALE}
          minScale={MIN_SCALE}
          maxScale={MAX_ZOOM}
          centerOnInit
          centerZoomedOut
          onPanningStart={(ref, event) => {
            if (ref.state.scale <= 1) {
              event.preventDefault();
            }
          }}
          onZoom={(ref) => {
            setScale(Math.max(ref.state.scale, MIN_SCALE));
          }}
          panning={{
            lockAxisX: scale === 1,
            lockAxisY: scale === 1,
          }}
          onPanningStop={handleCenterViewOnScale1}
          onZoomStop={handleCenterViewOnScale1}
          pinch={{ step: 0.2 }}
          wheel={{ smoothStep: 0.02 }}
          disabled={disableGestures}
          limitToBounds
        >
          <TransformComponent
            contentClass="pinch-zoom-image-content"
            wrapperClass="pinch-zoom-image-wrapper"
          >
            <PinchZoomImageComponent
              cssHeight={domRect?.height ? domRect.height : 0}
              maxWidth={domRect?.width ?? 0}
              ref={ref}
              {...props}
              {...imageProperties}
            />
          </TransformComponent>
        </TransformWrapper>
      </PinchZoomImageContainer>
    );
  },
);
