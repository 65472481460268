import { withConditionalMutation } from '@/src/lib/react-query/utilities';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { useMutation } from '@tanstack/react-query';
import { Space } from '../spaces.types';
import { mutateSpaceShared } from '../utils/mutateSpaceShared';
import { useQueryCacheSpaceHelpers } from '../utils/useQueryCacheSpaceHelpers';

type MutationVariables = {
  space: Space;
  shared: boolean;
};

export const useMutationChangeSpaceShared = () => {
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { updateCachedSpace } = useQueryCacheSpaceHelpers();

  const mutation = useMutation({
    mutationFn: async ({ space, shared }: MutationVariables) => {
      return getWoodyResponseData(
        await client.putList(space.id, { title: space.title, isPublic: Boolean(shared) }),
      );
    },
    onMutate: ({ space, shared }) => {
      return updateCachedSpace(mutateSpaceShared(space, shared));
    },
    onError: (_, { shared }, context) => {
      toast({
        content: `Failed to ${shared ? 'share' : 'unshare'} space`,
      });

      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
    onSuccess: (_, { shared, space }) => {
      toast({
        content: `Space ${shared ? 'shared' : 'unshared'}`,
      });

      track(shared ? AnalyticsEvents.SharedSpace : AnalyticsEvents.UnsharedSpace, {
        spaceId: space.id,
      });
    },
  });

  return withConditionalMutation(mutation, {
    ignorePredicate: ({ space }) => {
      // we don't check for shared here because it needs to be used to get a fresh link
      return space.type !== 'SPACE';
    },
  });
};
