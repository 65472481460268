import { createLogger } from '@/src/lib/logger/createLogger';
import { ResourcePreviewFolder } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewFolder';
import { ResourcePreviewImage } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewImage';
import { ResourcePreviewNotepad } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewNotepad';
import { ResourcePreviewPage } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewPage';
import { ResourcePreviewStoredFile } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewStoredFile';
import { ResourcePreviewText } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/Content/ResourcePreviewText';
import {
  isFolderFdoc,
  isImageFdoc,
  isNotepadFdoc,
  isPageFdoc,
  isStoredFileFdoc,
  isTextFdoc,
} from '@/src/modules/resources/utils/resourceTypes';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';

interface ResourcePreviewContentProps {
  resource: OptimisticDraft<Fdoc>;
}

const logger = createLogger('ResourcePreviewContent');

export const ResourcePreviewContent: React.FC<ResourcePreviewContentProps> = ({ resource }) => {
  if (isStoredFileFdoc(resource)) {
    return <ResourcePreviewStoredFile resource={resource} />;
  }

  if (isPageFdoc(resource)) {
    return <ResourcePreviewPage resource={resource} />;
  }

  if (isFolderFdoc(resource)) {
    return <ResourcePreviewFolder resource={resource} />;
  }

  if (isTextFdoc(resource)) {
    return <ResourcePreviewText resource={resource} />;
  }

  if (isImageFdoc(resource)) {
    return <ResourcePreviewImage resource={resource} />;
  }

  if (isNotepadFdoc(resource)) {
    return <ResourcePreviewNotepad resource={resource} />;
  }

  logger.error('Unhandled render', resource);

  return null;
};
