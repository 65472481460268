import { createLogger, PrefixedLogger } from '@/src/lib/logger/createLogger';
import { ObservableV2 } from 'lib0/observable';
import * as promise from 'lib0/promise';
import * as Y from 'yjs';

/**
 * Interface for HybridSyncProvider events.
 */
interface HybridSyncProviderEvents {
  /**
   * Emitted when the provider is synced with initial data.
   * @param provider - The HybridSyncProvider instance.
   */
  synced: (provider: HybridSyncProvider) => void;
  /**
   * Emitted after a debounce period of coalesced updates.
   * @param update - The coalesced update data.
   */
  update: (update: Uint8Array) => void;
  /**
   * Emitted when the mode of the provider changes.
   * @param mode - The new mode of the provider.
   */
  modeChange: (mode: HybridSyncProviderMode) => void;
  /**
   * Emitted when the pending updates are changed.
   * This is used to display on the UI that 1 or more updates are pending.
   * @param pendingUpdates - The pending updates array.
   */
  pendingUpdatesChange: (pendingUpdates: Uint8Array[]) => void;
}

/**
 * Enum representing the syncing modes for the HybridSyncProvider.
 */
export enum HybridSyncProviderMode {
  /**
   * The provider will send update events 100% of the time.
   * e.g. User is not connected to websocket server for real-time events.
   */
  FullyOffline,
  /**
   * The provider will store updates in the pending updates array and return them
   * when destroyed.
   * e.g. User is currently connecting to the websocket server for real-time events,
   * but we want to avoid the user losing changes if they close before successfully
   * connecting.
   */
  SemiOffline,
  /**
   * The provider will cease sending update events because it is no longer the
   * main source of truth.
   * e.g. User is connected to websocket server for real-time events successfully.
   */
  Online,
}

/**
 * Interface for HybridSyncProvider options.
 */
interface HybridSyncProviderOptions {
  /**
   * The Y.Doc instance to be synchronized.
   */
  doc: Y.Doc;
  /**
   * The debounce wait time in milliseconds to send merged updates.
   * @default 1000
   */
  debounceWait?: number;
  /**
   * The initial mode for the HybridSyncProvider. (optional)
   */
  initialMode?: HybridSyncProviderMode;
}

/**
 * HybridSyncProvider class for managing document synchronization.
 * @extends ObservableV2<HybridSyncProviderEvents>
 */
export class HybridSyncProvider extends ObservableV2<HybridSyncProviderEvents> {
  private doc: Y.Doc;
  private debounceWait: number;
  public synced: boolean;
  private _destroyed: boolean;
  private _pendingUpdates: Uint8Array[];
  private _updateTimeout: number | null;
  public whenSynced: Promise<HybridSyncProvider>;
  private logger: PrefixedLogger;
  private mode: HybridSyncProviderMode;

  /**
   * Creates an instance of HybridSyncProvider.
   * @param {HybridSyncProviderOptions} options - The options for the provider.
   */
  constructor(options: HybridSyncProviderOptions) {
    super();
    this.logger = createLogger(`HybridSyncProvider#${options.doc.guid.slice(-5)}`);
    this.doc = options.doc;
    this.debounceWait = options.debounceWait || 1000;
    this.synced = false;
    this._destroyed = false;
    this._pendingUpdates = [];
    this._updateTimeout = null;
    this.mode = options.initialMode || HybridSyncProviderMode.FullyOffline;

    this.doc.on('update', this._storeUpdate.bind(this));
    this.doc.on('destroy', this.destroy.bind(this));

    this.whenSynced = promise.create((resolve) => this.on('synced', () => resolve(this)));

    this.logger.logDevOnly('HybridSyncProvider created');
  }

  private _clearPendingUpdates(): void {
    this._pendingUpdates = [];
    this.emit('pendingUpdatesChange', [this._pendingUpdates]);
  }

  private _consumePendingUpdates(): Uint8Array[] {
    const pendingUpdates = this._pendingUpdates;
    this._clearPendingUpdates();
    return pendingUpdates;
  }

  private _pushPendingUpdate(update: Uint8Array): void {
    this._pendingUpdates.push(update);
    this.emit('pendingUpdatesChange', [this._pendingUpdates]);
  }

  /**
   * Initializes the provider with the given initial state.
   * Will emit the 'synced' event when complete.
   * @param {Uint8Array | Promise<Uint8Array>} initialState - The initial state to apply.
   * @returns {Promise<void>}
   */
  public async initialize(initialState: Uint8Array | Promise<Uint8Array>): Promise<void> {
    if (this.synced) {
      this.logger.logDevOnly('already synced');
      return;
    }

    const state = await Promise.resolve(initialState);
    Y.applyUpdate(this.doc, state, this);
    this.synced = true;
    this.emit('synced', [this]);
    this.logger.logDevOnly('initialized and emitted synced');
  }

  /**
   * Stores an update based on the current mode.
   * @private
   * @param {Uint8Array} update - The update to store.
   * @param {unknown} origin - The origin of the update.
   */
  private _storeUpdate(update: Uint8Array, origin: unknown): void {
    if (origin === this) return;

    switch (this.mode) {
      case HybridSyncProviderMode.Online:
      case HybridSyncProviderMode.FullyOffline:
        this.logger.logDevOnly('storing update in pending updates and debouncing send', origin);
        this._pushPendingUpdate(update);
        this._debouncedSendUpdates();
        break;
      case HybridSyncProviderMode.SemiOffline:
        this.logger.logDevOnly('storing update in pending updates', origin);
        this._pushPendingUpdate(update);
        break;
    }
  }

  /**
   * Debounces the sending of updates.
   * @private
   */
  private _debouncedSendUpdates(): void {
    if (this._updateTimeout) {
      clearTimeout(this._updateTimeout);
    }

    this._updateTimeout = window.setTimeout(() => {
      this._sendUpdates();
    }, this.debounceWait);
  }

  /**
   * Sends the pending updates.
   * @private
   */
  private _sendUpdates(): void {
    const updates = this._consumePendingUpdates();
    if (updates.length > 0) {
      const mergedUpdate = Y.mergeUpdates(updates);
      this.emit('update', [mergedUpdate]);
      this.logger.logDevOnly('sent updates');
    }
  }

  /**
   * Gets the current state of the document.
   * @returns {Uint8Array} The encoded state of the document.
   */
  public getDocState(): Uint8Array {
    return Y.encodeStateAsUpdate(this.doc);
  }

  /**
   * Applies an update to the document.
   * @param {Uint8Array} update - The update to apply.
   */
  public applyUpdate(update: Uint8Array): void {
    this.logger.logDevOnly('applying external update');
    Y.applyUpdate(this.doc, update, this);
  }

  /**
   * Destroys the provider and returns any pending updates.
   * @returns {Uint8Array | null} The final update or null if no pending updates.
   */
  public destroy(): Uint8Array | null {
    this.logger.logDevOnly('destroying');

    this.doc.off('update', this._storeUpdate);
    this.doc.off('destroy', this.destroy);
    if (this._updateTimeout) {
      clearTimeout(this._updateTimeout);
    }
    this._destroyed = true;

    // Send any pending updates before destroying
    const finalUpdates = this._consumePendingUpdates();
    if (finalUpdates.length > 0) {
      this.logger.logDevOnly('sending final update');
      const finalUpdate = Y.mergeUpdates(finalUpdates);
      this.emit('update', [finalUpdate]);
      return finalUpdate;
    }
    return null;
  }

  /**
   * Allows consuming the pending updates without destroying the provider.
   * e.g. on window unload, if the user cancels the page close the provider would be
   * destroyed and the further updates would be lost.
   * @returns {Uint8Array | null} The final update or null if no pending updates.
   */
  public consumePendingUpdates(): Uint8Array | null {
    const finalUpdates = this._consumePendingUpdates();
    if (finalUpdates.length > 0) {
      const finalUpdate = Y.mergeUpdates(finalUpdates);
      return finalUpdate;
    }
    return null;
  }

  /**
   * Sets the mode of the provider.
   * @param {HybridSyncProviderMode} newMode - The mode to set.
   */
  public setMode(newMode: HybridSyncProviderMode): void {
    this.mode = newMode;
    if (newMode === HybridSyncProviderMode.FullyOffline && this._pendingUpdates.length > 0) {
      this._debouncedSendUpdates();
    }

    // Emit the mode change event
    this.emit('modeChange', [this.mode]);
    this.logger.logDevOnly('mode changed to', newMode);
  }
}
