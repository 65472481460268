import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { truncateText } from '@/src/utils/text';
import { useState } from 'react';

type TruncatorProps = {
  text: string;
  maxRows: number;
  ellipsis?: string;
  parentRef?: HTMLElement | null;
  offsetBefore?: number;
  keepLastChars?: number;
};

const Truncator: React.FC<TruncatorProps> = ({
  text,
  maxRows,
  ellipsis = '…',
  offsetBefore = 0,
  keepLastChars = 0,
  parentRef,
}) => {
  const [truncatedText, setTruncatedText] = useState(text);

  useClientLayoutEffect(() => {
    if (!parentRef) return;

    setTruncatedText(
      truncateText(text, {
        maxRows,
        ellipsis,
        element: parentRef,
        offsetBefore,
        keepLastChars,
      }),
    );

    let timeout: number;
    const onResize = () => {
      if (timeout) clearTimeout(timeout);

      timeout = window.setTimeout(() => {
        setTruncatedText(
          truncateText(text, {
            maxRows,
            ellipsis,
            element: parentRef,
            offsetBefore,
            keepLastChars,
          }),
        );
      }, 200);
    };

    const observer = new ResizeObserver(onResize);
    observer.observe(parentRef);

    return () => {
      observer.disconnect();
      if (timeout) clearTimeout(timeout);
    };
  }, [text, maxRows, ellipsis, parentRef, offsetBefore, keepLastChars]);

  return <>{truncatedText}</>;
};

export default Truncator;
