import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';
import { ApiUserMe } from '@/src/modules/user/user.types';
import { v4 as uuidv4 } from 'uuid';
import { OptimisticDraft } from '../../../types/draftable';
import { Space, SpaceRole } from '../spaces.types';

export const createOptimisticSpace = (
  user: ApiUserMe,
  title: string,
  role?: SpaceRole,
): OptimisticDraft<Space> => {
  const optimisticId = uuidv4();

  return {
    isDraft: true,
    id: optimisticId,
    title,
    role,
    owner: {
      ...user,
      name: user.name || 'Unknown',
      picture: user.pictureUrlCdn || null,
    },
    type: 'SPACE',
    isPublic: false,
    integration: null,
    labels: [],
    createdAt: new Date().toISOString(),
    modifiedAt: new Date().toISOString(),
  };
};

export const createOptimisticSpaceRoot = (
  name: string = 'New space',
): OptimisticDraft<ResourceRoot> => {
  const id = uuidv4();
  return {
    isDraft: true,
    id,
    type: 'SPACE',
    folder: {
      id,
      name,
      isReadonly: false,
      permissions: {
        role: 'owner',
      },
      childrenCount: 0,
      memberCount: 1,
      isPublic: false,
    },
    subtype: null,
    createdAt: new Date().toISOString(),
    modifiedAt: new Date().toISOString(),
  };
};
