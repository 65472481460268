import BuzzNoteTextIcon from '@/public/images/icons/BuzzNoteText.svg';
import { TextFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';

interface ResourcePreviewTextProps {
  resource: OptimisticDraft<TextFdoc>;
}

export const ResourcePreviewListItemText: React.FC<ResourcePreviewTextProps> = () => {
  return <BuzzNoteTextIcon style={{ width: '1rem', height: '1rem' }} />;
};
