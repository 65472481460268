import React from 'react';

export const useDomContentRect = <T extends HTMLElement | React.RefObject<HTMLElement>>(
  /**
   * preferably pass React.RefObject to properly update if the dom reference changes
   */
  ref?: T | null,
  options?: {
    observeParentResize?: boolean;
  },
) => {
  const [htmlElRect, setHtmlElRect] = React.useState<DOMRect | null>(null);

  /**
   * update
   */
  React.useEffect(() => {
    if (!ref) {
      return;
    }
    const refEl = 'current' in ref ? (ref.current as HTMLElement) : (ref as HTMLElement);
    if (refEl) {
      const updateRect = () => {
        const rect = refEl.getBoundingClientRect();
        setHtmlElRect(rect);
      };

      updateRect();

      const resizeObserver = new ResizeObserver((entries) => {
        const entry = entries[0];
        if (entry && refEl) {
          setHtmlElRect(refEl.getBoundingClientRect());
        }
      });

      resizeObserver.observe(refEl);
      resizeObserver.observe(document.body);
      /**
       * observe parent element as well, maybe it's resizing, changing layout
       * good to observe the positioning of the element withing the window context
       */
      if (options?.observeParentResize && refEl.parentElement) {
        resizeObserver.observe(refEl.parentElement);
      }

      window.addEventListener('scroll', updateRect);
      return () => {
        setHtmlElRect(null);
        resizeObserver.disconnect();
        window.removeEventListener('scroll', updateRect);
      };
    } else {
      setHtmlElRect(null);
    }
  }, [ref, options?.observeParentResize]);

  return [htmlElRect, setHtmlElRect] as const;
};
