import { P } from '@/src/modules/ui/components/Typography';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled, { css } from 'styled-components';

export const FieldHeader = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 4px;
`;

export const FieldLabel = styled.label<{ variant?: 'big-grey' }>`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${cssVar['color-text-primary']};

  ${(p) =>
    p.variant === 'big-grey' &&
    css`
      font-weight: 700;
      font-size: 15px;
      line-height: 18px;
      color: rgba(128, 128, 128, 0.4);
    `}
`;

export const FieldDescription = styled(P).attrs((props) => {
  return {
    size: 'sm',
    color: 'tertiary',
    weight: 400,
    ...props,
  };
})``;

export const FieldGroup = styled.div<{ gap?: 'sm' }>`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  ${(p) =>
    p.gap === 'sm' &&
    css`
      gap: 0.375rem;
    `}
`;
