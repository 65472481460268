import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { PrivateTag } from '@fabric/woody-client';
import { useMutation } from '@tanstack/react-query';
import { useQueryCacheTagHelpers } from '../utils/useQueryCacheTagHelpers';

export const useMutationResourcesTagAssign = () => {
  const { client } = useWoody();

  const { assignTagFromResources } = useQueryCacheTagHelpers();
  const { track } = useAnalytics();

  return useMutation({
    mutationFn: async ({
      tag,
      resourceIds,
      operation,
    }: {
      tag: PrivateTag;
      resourceIds: string[];
      operation: 'assign' | 'unassign';
    }) => {
      await client.v2(
        {
          endpoint: '/v2/tags/{tagId}/resources',
          params: {
            tagId: tag.id,
          },
        },
        {
          method: operation === 'assign' ? 'put' : 'delete',
          body: {
            resourceIds,
          },
        },
      );
    },
    onSuccess: (_, { tag, resourceIds, operation }) => {
      track(operation === 'assign' ? AnalyticsEvents.AssignTag : AnalyticsEvents.UnassignTag, {
        tagId: tag.id,
        resourceIds: resourceIds,
        count: resourceIds.length,
      });
    },
    onMutate: async ({ tag, resourceIds, operation }) => {
      return assignTagFromResources(tag, resourceIds, operation);
    },
    onError: (e, _, context) => {
      console.error(e);
      toast({
        content: 'Something went wrong while removing the tag. Please try again.',
      });

      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();
    },
  });
};
