'use client';

import styles from './HoverItem.module.scss';

import { useOnClickOutside } from '@/src/hooks/outside';
import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import LinkExternalIcon from '@/src/icons/LinkExternalIcon';
import { FaviconImage } from '@/src/modules/ui/components/Favicon/FaviconImage';
import inNextServer from '@/src/utils/next';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { create } from 'zustand';

type hoverState = {
  showId: string;
  setShowId: (showId: string) => void;
};

const HoverState = create<hoverState>()((set) => ({
  showId: '',
  setShowId: (showId: string) => set(() => ({ showId })),
}));

type hoverItemType = {
  fdocId: string;
  show: boolean;
  setShow: (show: boolean) => void;
  x: number;
  y: number;
  title: string;
  url: string;
};

const HoverItem: React.FC<hoverItemType> = ({ fdocId, show, setShow, x, y, title, url }) => {
  const { showId, setShowId } = HoverState();

  useEffect(() => {
    if (!show) return;

    setShowId(fdocId);
  }, [show, fdocId, setShowId]);

  useEffect(() => {
    if (showId !== fdocId) {
      setShow(false);
    }
  }, [showId, fdocId, setShow]);

  const [ref, setRef] = React.useState<HTMLDivElement | null>(null);

  const [flip, setFlip] = React.useState(false);

  useClientLayoutEffect(() => {
    if (!ref) return;

    const updateFlip = () => {
      const rect = ref.getBoundingClientRect();
      const rightEdge = x + rect.width;
      setFlip(rightEdge > window.innerWidth);
    };

    updateFlip();

    // observe changes to the element's size and position
    const resizeObserver = new ResizeObserver(updateFlip);
    resizeObserver.observe(ref);

    window.addEventListener('resize', updateFlip);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateFlip);
    };
  }, [ref, x]);

  useOnClickOutside(ref, () => {
    setShow(false);
  });

  if (inNextServer()) return null;

  const portalTarget = document.getElementById('tooltip-portal');

  const element = (
    <div
      style={{
        top: y + 10,
        left: x + 10,
        display: !show || (x === 0 && y === 0) ? 'none' : undefined,

        transform: flip ? 'translateX(-100%)' : undefined,
      }}
      className={styles.hover_item}
      ref={setRef}
    >
      <div className={styles.hover_item__header}>
        <div className={styles.favicon_wrapper}>
          <FaviconImage pageUrl={url} className={styles.hover_item__favicon} />
        </div>
        <span>{title}</span>
        <LinkExternalIcon
          size={16}
          style={{
            marginLeft: 'auto',
            alignSelf: 'flex-start',
          }}
        />
      </div>
      <div className={styles.hover_item__url}>
        <span>{url}</span>
      </div>
    </div>
  );

  if (!show) return null;
  return portalTarget ? createPortal(element, portalTarget) : element;
};

export default HoverItem;
