import { createClient, LsonObject, User } from '@liveblocks/client';
import { createRoomContext } from '@liveblocks/react';
import { liveblocks, WithLiveblocks } from '@liveblocks/zustand';
import { create } from 'zustand';
import {
  BaseCursorData,
  CursorMode,
  DashboardEvent,
  DashboardPresence,
  DashboardUserMeta,
} from '../types/presence';
import { ZustandDynamicCaller } from '../types/store';
import { woodyAuthEndpoint } from './fdocs.config';

export type SpaceClickEvent = {
  id: string;
  position: { x: number; y: number };
  user: User<DashboardPresence, DashboardUserMeta>;
};

type ZustandState = {
  userId: string; // we can get from the auth authenticated user ids, but not anonymous users so we have this here
  setUserId: (userId: string) => void;

  following: string | null;

  clickEvents: SpaceClickEvent[];
  setClickEvents: ZustandDynamicCaller<SpaceClickEvent[]>;

  broadcastClickEvent:
    | ((expandedFdocId: string, position: { x: number; y: number }) => void)
    | undefined;
  setBroadcastClickEvent: (broadcastClickEvent: ZustandState['broadcastClickEvent']) => void;

  normalizedScrollTop: number;
  normalizedScrollLeft: number;
  setNormalizedScrollTop: ZustandDynamicCaller<number>;
  setNormalizedScrollLeft: ZustandDynamicCaller<number>;

  cursor: BaseCursorData;
  setCursor: ZustandDynamicCaller<BaseCursorData>;
};

const client = createClient({
  authEndpoint: woodyAuthEndpoint,
});

export const useSpaceLiveblocksStore = create<
  WithLiveblocks<ZustandState, DashboardPresence, LsonObject, DashboardUserMeta>
>(
  liveblocks(
    (set) => ({
      userId: '',
      setUserId: (userId) => {
        set({ userId });
      },

      following: null,

      clickEvents: [],
      setClickEvents: (clickEvents) => {
        if (typeof clickEvents === 'function') {
          set((state) => ({ clickEvents: clickEvents(state.clickEvents) }));
          return;
        }

        set({ clickEvents });
      },

      broadcastClickEvent: undefined,
      setBroadcastClickEvent: (broadcastClickEvent) => {
        set({ broadcastClickEvent });
      },

      normalizedScrollTop: 0,
      normalizedScrollLeft: 0,
      setNormalizedScrollTop: (normalizedScrollTop) => {
        if (typeof normalizedScrollTop === 'function') {
          set((state) => ({ normalizedScrollTop: normalizedScrollTop(state.normalizedScrollTop) }));
          return;
        }

        set({ normalizedScrollTop });
      },
      setNormalizedScrollLeft: (normalizedScrollLeft) => {
        if (typeof normalizedScrollLeft === 'function') {
          set((state) => ({
            normalizedScrollLeft: normalizedScrollLeft(state.normalizedScrollLeft),
          }));
          return;
        }

        set({ normalizedScrollLeft });
      },

      cursor: {
        state: {
          mode: CursorMode.Normal,
          type: 'default',
        },
        lastWiggle: 0,
        position: { x: 0, y: 0, pressure: null },
      },
      setCursor: (cursor) => {
        if (typeof cursor === 'function') {
          set((state) => ({ cursor: cursor(state.cursor) }));
          return;
        }

        set({ cursor });
      },
    }),
    {
      client,
      presenceMapping: {
        cursor: true,
        normalizedScrollTop: true,
        normalizedScrollLeft: true,
        following: true,
      },
    },
  ),
);

export const {
  RoomProvider: SpaceRoomProvider,
  useOthers: useSpaceOthers,
  useSelf: useSpaceSelf,
  useUpdateMyPresence: useUpdateSpacePresence,
  useBroadcastEvent: useBroadcastSpaceEvent,
  useEventListener: useSpaceEventListener,
} = createRoomContext<DashboardPresence, LsonObject, DashboardUserMeta, DashboardEvent>(client);
