import { useAuthUser } from '@/src/hooks/auth';
import { useOptimisticallyUpdateQueriesWithPredicate } from '@/src/lib/react-query/utilities';
import { UseQueryResourceRootListData } from '@/src/modules/resource-roots/queries/roots.queries.types';
import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';
import { createOptimisticSpace } from '@/src/modules/spaces/utils/createOptimisticSpace';
import { OptimisticDraft } from '@/src/types/draftable';
import { useQueryClient } from '@tanstack/react-query';
import { spaceQueryPredicates } from '../queries/spaceQueryPredicates';
import { Space } from '../spaces.types';
import { mutateSpaceMeta } from './mutateSpaceMeta';

type UpdateChacheSpaceProperties = {
  id: string;
  title?: string;
  isPublic?: boolean;
};

const defaultDetailMutator = (space: Space, nextProperties: UpdateChacheSpaceProperties) => ({
  ...space,
  ...nextProperties,
});

export const useQueryCacheSpaceHelpers = () => {
  const queryClient = useQueryClient();
  const optimisticallyUpdateQueries = useOptimisticallyUpdateQueriesWithPredicate();
  const user = useAuthUser();

  return {
    updateCachedSpace: (
      nextProperties: {
        id: string;
        title?: string;
        isPublic?: boolean;
      },
      options?: {
        detailMutator?: typeof defaultDetailMutator;
      },
    ) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          spaceQueryPredicates.spacesAll(q) || spaceQueryPredicates.space(q, nextProperties.id),
        type: 'active',
      });

      /**
       * updating list of spaces where the space is present
       * !!! change - different structure from the detail until detail is transitioned to v2 as well
       * @TODO labels are not included, check if necessary
       */
      const optimisticallyUpdateSpaceAllQueries =
        optimisticallyUpdateQueries<UseQueryResourceRootListData>(
          (q) => spaceQueryPredicates.spacesAll(q),
          (data) => {
            return data
              ? {
                  ...data,
                  pages: data.pages.map((page) => ({
                    ...page,
                    roots: page.roots.map((root) => {
                      if (root.id === nextProperties.id) {
                        return {
                          ...root,
                          folder: {
                            ...root.folder,
                            name: nextProperties.title ?? root.folder.name,
                            isPublic: nextProperties.isPublic ?? root.folder.isPublic,
                          },
                        };
                      }
                      return root;
                    }),
                  })),
                }
              : data;
          },
        );

      const detailMutator = options?.detailMutator || defaultDetailMutator;

      /**
       * updating the space directly
       */
      const optimisticallyUpdateDirectQueries = optimisticallyUpdateQueries<Space>(
        (q) => spaceQueryPredicates.space(q, nextProperties.id),
        (space) => (space ? detailMutator(space, nextProperties) : undefined),
      );

      return {
        optimisticallyUpdateSpaceAllQueries,
        optimisticallyUpdateDirectQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateSpaceAllQueries.resetQueriesData();
          optimisticallyUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateSpaceAllQueries.invalidateQueries();
          optimisticallyUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    deleteCachedSpaces: (spaceIds: string[]) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          spaceQueryPredicates.spacesAll(q) || spaceQueryPredicates.space(q, spaceIds.join(',')),
        type: 'active',
      });

      /**
       * updating list of spaces where the space is present
       */
      const optimisticallyUpdateSpaceAllQueries =
        optimisticallyUpdateQueries<UseQueryResourceRootListData>(
          (q) => spaceQueryPredicates.spacesAll(q),
          (data) => {
            return data
              ? {
                  ...data,
                  pages: data.pages.map((page) => ({
                    ...page,
                    roots: page.roots.filter((root) => !spaceIds.includes(root.id)),
                  })),
                }
              : data;
          },
        );

      const optimisticallyUpdateDirectQueries = spaceIds.map((spaceId) =>
        optimisticallyUpdateQueries<Space>(
          (q) => spaceQueryPredicates.space(q, spaceId),
          (data) => data && mutateSpaceMeta(data, { isDeleting: true }),
        ),
      );

      return {
        optimisticallyUpdateSpaceAllQueries,
        optimisticallyUpdateDirectQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateSpaceAllQueries.resetQueriesData();
          optimisticallyUpdateDirectQueries.forEach((optimisticUpdateDirectQuery) =>
            optimisticUpdateDirectQuery.resetQueriesData(),
          );
        },
        invalidateQueries: () => {
          optimisticallyUpdateSpaceAllQueries.invalidateQueries();
          optimisticallyUpdateDirectQueries.forEach((optimisticUpdateDirectQuery) =>
            optimisticUpdateDirectQuery.invalidateQueries(),
          );
        },
      };
    },
    addNewSpaceToCache: (optimisticRootSpace: OptimisticDraft<ResourceRoot>) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          spaceQueryPredicates.spacesAll(q) ||
          spaceQueryPredicates.space(q, optimisticRootSpace.id),
        type: 'active',
      });

      const optimisticUpdateAllQueries = optimisticallyUpdateQueries<UseQueryResourceRootListData>(
        spaceQueryPredicates.spacesAll,
        (data) => {
          const nextData = data;
          if (nextData) {
            nextData.pages[0].roots.unshift(optimisticRootSpace as ResourceRoot);
          }
          return nextData;
        },
        {
          type: 'active',
        },
      );

      /**
       * before transitioning the new space
       */
      const optimisticSpace = createOptimisticSpace(
        user!,
        optimisticRootSpace.folder.name!,
        'viewer',
      );
      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<Space>(
        (q) => spaceQueryPredicates.space(q, optimisticSpace.id),
        (data) => data || optimisticSpace,
      );

      return {
        optimisticUpdateDirectQueries,
        optimisticUpdateAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateAllQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateAllQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
        },
      };
    },
  };
};
