import Tooltip from '@/src/ui/Tooltip';
import { Editor } from '@tiptap/react';
import styles from './Toolbar.module.scss';
import {
  ToolbarButton,
  ToolbarDropdown,
  ToolbarDropdownContent,
  ToolbarItemType,
  ToolbarSeparator,
  ToolbarToggle,
} from './types';

import { useResponsive } from '@/src/hooks/responsive';
import * as Dropdown from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';
import { ReactNode } from 'react';

const ToolbarToggleComponent: React.FC<{
  editor: Editor;
  item: ToolbarToggle;
  insideDropdown?: boolean;
}> = ({
  editor,
  item: { label, icon, onClick, getIsActive, shortcut },
  insideDropdown = false,
}) => {
  const isActive = getIsActive(editor);

  const renderContent = () => {
    const content = (
      <button
        className={styles.ToolbarToggleItem}
        data-state={isActive ? 'on' : 'off'}
        onClick={() => onClick(editor)}
        aria-label={label}
        aria-haspopup={insideDropdown}
        aria-expanded={insideDropdown && isActive}
        type="button"
      >
        {insideDropdown ? (
          <>
            <span className={styles.TriggerContent}>
              {icon}
              <span className={styles.ToolbarToggleItemLabel}>{label}</span>
            </span>

            {shortcut && <span className={styles.ToolbarButtonShortcut}>{shortcut}</span>}
          </>
        ) : (
          icon
        )}
      </button>
    );

    if (insideDropdown) {
      return <Dropdown.DropdownMenuItem asChild>{content}</Dropdown.DropdownMenuItem>;
    }

    return (
      <Tooltip
        label={
          <>
            {label}
            {shortcut}
          </>
        }
      >
        {content}
      </Tooltip>
    );
  };

  return renderContent();
};

const ToolbarButtonComponent: React.FC<{
  editor: Editor;
  item: ToolbarButton;
  insideDropdown?: boolean;
}> = ({ editor, item: { label, icon, onClick, shortcut }, insideDropdown = false }) => {
  const renderContent = () => {
    const content = (
      <button
        className={clsx(styles.ToolbarButton, insideDropdown && styles.DropdownButton)}
        onClick={() => onClick(editor)}
        aria-label={label}
        aria-haspopup={insideDropdown}
        type="button"
      >
        {insideDropdown ? (
          <>
            <span className={styles.TriggerContent}>
              {icon}
              <span className={styles.ToolbarButtonLabel}>{label}</span>
            </span>
            {shortcut && <span className={styles.ToolbarButtonShortcut}>{shortcut}</span>}
          </>
        ) : icon ? (
          icon
        ) : (
          label
        )}
      </button>
    );

    if (insideDropdown) {
      return <Dropdown.DropdownMenuItem asChild>{content}</Dropdown.DropdownMenuItem>;
    }

    return (
      <Tooltip
        label={
          <>
            {label}
            {shortcut}
          </>
        }
      >
        {content}
      </Tooltip>
    );
  };

  return renderContent();
};

const ToolbarDropdownComponent: React.FC<{
  editor: Editor;
  item: ToolbarDropdown;
  // no sub-dropdowns implemented yet
}> = ({ editor, item: { icon, label, items } }) => {
  const hasActiveItems = items.some(
    (item) => item.type === ToolbarItemType.Toggle && item.getIsActive(editor),
  );

  const { isMobile } = useResponsive();

  return (
    <Dropdown.Root modal={!isMobile()}>
      <Tooltip label={label}>
        <Dropdown.Trigger asChild>
          <button
            aria-label={label}
            className={styles.DropdownTrigger}
            data-state={hasActiveItems ? 'partially' : 'off'}
            type="button"
            onTouchStart={() => editor.chain().focus()}
          >
            {icon}
          </button>
        </Dropdown.Trigger>
      </Tooltip>
      <Dropdown.Portal>
        <Dropdown.Content
          className={styles.DropdownContent}
          align="start"
          sideOffset={10}
          onCloseAutoFocus={(e) => e.preventDefault()}
          onFocusOutside={isMobile() ? (e) => e.preventDefault() : undefined}
          side={isMobile() ? 'top' : 'bottom'}
        >
          {renderDropdownContent(editor, items)}
          <Dropdown.Arrow className={styles.DropdownMenuArrow} />
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const ToolbarSeparatorComponent: React.FC<{
  item: ToolbarSeparator;
  insideDropdown?: boolean;
}> = () => {
  return <Dropdown.DropdownMenuSeparator className={styles.DropdownSeparator} />;
};

const renderDropdownContent = (editor: Editor, items: ToolbarDropdownContent[]): ReactNode[] => {
  return items.map((item) => {
    switch (item.type) {
      case ToolbarItemType.Toggle:
        return (
          <ToolbarToggleComponent
            editor={editor}
            key={`dropdown-item-${item.key}`}
            item={item}
            insideDropdown
          />
        );
      case ToolbarItemType.Button:
        return (
          <ToolbarButtonComponent
            key={`dropdown-item-${item.key}`}
            editor={editor}
            item={item}
            insideDropdown
          />
        );
      case ToolbarItemType.DropdownSeparator:
        return (
          <ToolbarSeparatorComponent item={item} insideDropdown key={`dropdown-item-${item.key}`} />
        );
      case ToolbarItemType.Custom:
        return item.render(`dropdown-item-${item.key}`, editor, true);
    }
  });
};

export { ToolbarButtonComponent, ToolbarDropdownComponent, ToolbarToggleComponent };
