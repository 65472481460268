import { useResponsive } from '@/src/hooks/responsive';

import { useBoolState } from '@/src/hooks/useBooleanState';
import { useInputControls } from '@/src/hooks/useInputControls';
import useModalInactiveState from '@/src/hooks/useModalInactiveState';
import { useRefocusOnBlur } from '@/src/hooks/useRefocusOnBlur';
import CloseIcon from '@/src/icons/CloseIcon';
import SearchIcon from '@/src/icons/SearchIcon';
import { pick } from '@/src/lib/store';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import { useMutationTagCreate } from '@/src/modules/tags/mutations/useMutationTagCreate';
import { useQueryTags } from '@/src/modules/tags/queries/useQueryTags';
import Modal, { ModalControls } from '@/src/modules/ui/components/Modal';
import ScrollArea from '@/src/modules/ui/components/ScrollArea';
import { TextInput } from '@/src/modules/ui/components/TextInput/TextInput';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { CheckedState } from '@/src/ui/ui.types';
import { PrivateTag } from '@fabric/woody-client';
import React, { PropsWithChildren, useMemo, useRef } from 'react';
import styled from 'styled-components';
import TagsList from './TagsList';

const StyledHeader = styled(Modal.Header)`
  border-bottom: none;
`;

const SubHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  border-bottom: 1px solid ${cssVar['color-border-primary']};
`;

const StyledScrollArea = styled(ScrollArea)`
  display: grid;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: max-content;
  min-width: 0;
  min-height: 0;
`;

type EditTagsSelectHandler = (tag: PrivateTag, selected: boolean) => void;

export interface EditTagsProps {
  selectedTags?: PrivateTag[];
  indeterminateTags?: PrivateTag[];
  onSelect?: EditTagsSelectHandler;
}

interface EditTagsModalPropsWithControls extends EditTagsProps, ModalControls {}

interface EditTagsModalPropsWithoutControls extends EditTagsProps {}

/**
 * either include both modal controls or none
 */
type EditTagsModalProps = EditTagsModalPropsWithControls | EditTagsModalPropsWithoutControls;

const EditTagsModalContent: React.FC<EditTagsProps> = ({
  selectedTags = [],
  indeterminateTags = [],
  onSelect,
}) => {
  const { isMobileView } = useResponsive();
  const inputState = useInputControls();

  /******************************************************************
   * Creating new tag
   */
  const mutationTagCreate = useMutationTagCreate();

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (mutationTagCreate.isPending || inputState.value.length === 0) return;

    mutationTagCreate.mutateAsync(
      { tagName: inputState.value, action: 'from-resource' },
      {
        onSuccess: (tag) => {
          onSelect?.(tag, true);
        },
      },
    );
  };

  /******************************************************************
   * List of tags
   */
  const queryTags = useQueryTags(inputState.value);

  const tags: (PrivateTag & { checked: CheckedState })[] = useMemo(() => {
    if (queryTags.isLoading || !queryTags.data) return [];

    const tagsWithCheckedState = (queryTags.data || []).map((tag) => {
      if (indeterminateTags.find((t) => t.id === tag.id)) {
        return { ...tag, checked: 'indeterminate' as const };
      }
      if (selectedTags.find((t) => t.id === tag.id)) {
        return { ...tag, checked: true };
      }

      return { ...tag, checked: false };
    });

    return (
      tagsWithCheckedState
        // startsWith, because that's how API works so keeping this consistent
        .filter((tag) => tag.name.toLowerCase().startsWith(inputState.value.toLowerCase()))
        .sort((a, b) => {
          if (
            a.checked === b.checked ||
            (typeof a.checked === 'undefined' && typeof b.checked === 'undefined')
          ) {
            return a.name.localeCompare(b.name, undefined, {
              sensitivity: 'base',
            });
          }
          if (a.checked === true) {
            return -1;
          }
          if (b.checked === true) {
            return 1;
          }
          if (a.checked === 'indeterminate') {
            return -1;
          }

          return 1;
        })
    );
  }, [queryTags.data, queryTags.isLoading, selectedTags, indeterminateTags, inputState.value]);

  /******************************************************************
   * focus control
   */
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const isModalInactive = useModalInactiveState(formRef);
  const onBlurInput = useRefocusOnBlur(isModalInactive || isMobileView);

  return (
    <StyledForm onSubmit={onSubmit} ref={formRef}>
      <StyledHeader>Choose Tags</StyledHeader>
      <SubHeader>
        <Modal.Description>Select tags to add or remove from an item.</Modal.Description>
        <TextInput
          placeholder={isMobileView ? 'Filter tags' : 'Find tags or enter new tag name'}
          startAdornment={<SearchIcon />}
          onChange={inputState.onChange}
          onBlur={onBlurInput}
          value={inputState.value}
          ref={inputRef}
          autoFocus
          inputHeight="lg"
          endAdornment={
            inputState.value && (
              <button
                type="button"
                onClick={inputState.clear}
                className="cursor-pointer hover:text-black transition-colors"
              >
                <CloseIcon className="w-3.5 h-3.5" />
              </button>
            )
          }
        />
      </SubHeader>
      <StyledScrollArea type="always">
        <Modal.Body className="space-y-[20px] overflow-y-auto py-5">
          <TagsList
            tags={tags}
            onSelect={onSelect}
            isLoading={queryTags.isLoading}
            searchQuery={inputState.value}
          />
        </Modal.Body>
      </StyledScrollArea>
    </StyledForm>
  );
};

const EditTagsModal: React.FC<PropsWithChildren<EditTagsModalProps>> = ({ children, ...props }) => {
  const { open, onOpenChange } =
    'open' in props ? props : { open: undefined, onOpenChange: undefined };

  const { modalProps } = useBoolState();
  const { chatAssistantOpen, setChatAssistantOpen } = useAssistantStore((state) =>
    pick(state, ['chatAssistantOpen', 'setChatAssistantOpen']),
  );

  const isOpen = open || modalProps.open;

  React.useEffect(() => {
    if (isOpen && chatAssistantOpen) {
      setChatAssistantOpen(false);
    }
  }, [isOpen, chatAssistantOpen, setChatAssistantOpen]);

  return (
    <Modal open={isOpen} onOpenChange={onOpenChange || modalProps.onOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Portal>
        <Modal.Overlay />
        {/**
         * @TODO https://linear.app/futurebrowser/issue/FUT-3684/modal-add-general-behavior-to-the-core-definition
         */}
        <Modal.Content>
          <EditTagsModalContent {...props} />
        </Modal.Content>
      </Modal.Portal>
    </Modal>
  );
};

export default EditTagsModal;
