import { Button } from '@/src/modules/ui/components/Button';
import styled from 'styled-components';

const SummarizeInlineButton = styled(Button).attrs((p) => {
  return {
    size: 'xs',
    ...p,
    children: 'Summarize',
  };
})`
  background: linear-gradient(124deg, #000000 9.53%, #8118d3 86.22%);
  color: white;
  font-size: 14px;
  font-weight: 500;
  padding: 5.5px 8px;
  border-radius: 6px;
`;

export { SummarizeInlineButton };
