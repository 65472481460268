import { useReferencedFn } from '@/src/hooks/useReferencedFn';
import { useRef } from 'react';

/**
 * Creates a ref that is guaranteed to hold a single instance throughout the component's lifecycle.
 *
 * This hook addresses a common issue in React where values passed directly to `useRef` or `useState`
 * are re-evaluated on every render, potentially causing unnecessary object creation or side effects.
 *
 * @template T The type of the singleton instance.
 * @param {() => T} factory A function that creates the singleton instance.
 * @returns {React.RefObject<T>} A ref object that always returns the same instance.
 *
 * @example
 * const myServiceRef = useSingletonRef(() => new MyService(props.config));
 *
 * // Later in your component:
 * const result = myServiceRef.current.doSomething();
 */
export function useSingletonRef<T>(factory: () => T): React.MutableRefObject<T> {
  const ref = useRef<T | null>(null);
  const factoryRef = useReferencedFn(factory);

  if (ref.current === null) ref.current = factoryRef.current();

  return ref as React.MutableRefObject<T>;
}
