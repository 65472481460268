/**
 * https://github.com/framer/motion/discussions/1884#discussioncomment-5861808
 */

import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

interface AnimateChangeInHeightProps {
  children: React.ReactNode;
  className?: string;
  duration?: number;
}

const Container = styled(motion.div)`
  overflow: hidden;
  flex-shrink: 0;
`;

export const AnimateChangeInHeight: React.FC<AnimateChangeInHeightProps> = ({
  children,
  className,
  duration = 0.15,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [height, setHeight] = useState<number | 'auto'>('auto');

  useEffect(() => {
    if (containerRef.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        // We only have one entry, so we can use entries[0].
        const observedHeight = entries[0].contentRect.height;
        setHeight(observedHeight);
      });

      resizeObserver.observe(containerRef.current);

      return () => {
        // Cleanup the observer when the component is unmounted
        resizeObserver.disconnect();
      };
    }
  }, []);

  React.useEffect(() => {
    // if not rendering, set height to 0 immediatelly
    // otherwise the animation oddly stutter
    if (!children) {
      setHeight(0);
    }
  }, [children]);

  return (
    <Container
      className={className}
      style={{ height }}
      initial={{ height }}
      animate={{ height }}
      transition={{ duration, ease: 'linear' }}
    >
      <div ref={containerRef}>
        <AnimatePresence>{children}</AnimatePresence>
      </div>
    </Container>
  );
};
