import { isInMobile } from '@/src/hooks/mobile';
import { useResponsive } from '@/src/hooks/responsive';
import { ContextMenuContent } from '@/src/modules/ui/components/ContextMenu/ContextMenuContent';
import { ContextMenuContentMobile } from '@/src/modules/ui/components/ContextMenu/ContextMenuContentMobile';
import { ContextMenuItem } from '@/src/modules/ui/components/ContextMenu/ContextMenuItem';
import { ContextMenuSeparator } from '@/src/modules/ui/components/ContextMenu/ContextMenuSeparator';
import { mediaMobile, mediaNotMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { preventForwardPropsConfig } from '@/src/modules/ui/utils/preventForwardProps';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import * as RadixContextMenu from '@radix-ui/react-context-menu';
import React from 'react';
import styled, { css } from 'styled-components';

const Overlay = styled.div`
  display: block;
  position: fixed;
  inset: 0;
  z-index: 125;

  background-color: ${cssVar['color-bg-overlay']};
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
`;

const ContextMenuDemoBase: React.FC<{
  children: React.ReactNode;
  red?: boolean;
  content?: React.ReactNode;
  contextDisabled?: boolean;
  /**
   * rather showing the context menu only
   * we show an overlay with the context menu
   * as well as the anchor as a preview
   */
  previewAnchor?: boolean;
}> = ({ children, content, contextDisabled, previewAnchor }) => {
  const { isMobileView } = useResponsive();

  const overlayRef = React.useRef<HTMLDivElement>(null);

  return (
    <RadixContextMenu.Root
      modal
      onOpenChange={(value) => {
        value && isInMobile('ios') && Haptics.impact({ style: ImpactStyle.Medium });
      }}
    >
      <RadixContextMenu.Trigger disabled={contextDisabled} asChild>
        {children}
      </RadixContextMenu.Trigger>
      <RadixContextMenu.Portal>
        <>
          {isMobileView && <Overlay ref={overlayRef} />}
          {isMobileView ? (
            <ContextMenuContentMobile
              previewAnchor={previewAnchor ? children : null}
              onOpenChange={() => {
                // hack, radix context menu cannot be controlled but can be closed with a esc key for example
                const event = new KeyboardEvent('keydown', {
                  key: 'Escape',
                });
                document.dispatchEvent(event);
              }}
            >
              {content}
            </ContextMenuContentMobile>
          ) : (
            <ContextMenuContent>{content}</ContextMenuContent>
          )}
        </>
      </RadixContextMenu.Portal>
    </RadixContextMenu.Root>
  );
};

interface GroupProps {
  // If it should also have a border at the bottom if not the last child
  borderBottom?: boolean;
}

const Group = styled(RadixContextMenu.Group).withConfig(
  preventForwardPropsConfig(['borderBottom']),
)<GroupProps>`
  ${mediaNotMobile} {
    &:not(:first-child) {
      margin-top: 2px;
      padding-top: 2px;

      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }

    ${(p) =>
      p.borderBottom &&
      css`
        &:not(:last-child) {
          margin-bottom: 2px;
          padding-bottom: 2px;

          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }
      `}
  }
  ${mediaMobile} {
    &:not(:first-child) {
      margin-top: 1.25rem;
    }
  }
`;

export const ContextMenu = Object.assign(ContextMenuDemoBase, {
  Content: ContextMenuContent,
  Item: ContextMenuItem,
  ItemRaw: RadixContextMenu.Item,
  Separator: ContextMenuSeparator,
  Group,
});
