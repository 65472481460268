import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { QueryMeta } from '@/src/lib/react-query/types';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { isWoodyError } from '@/src/utils/error';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { Space } from '../spaces.types';
import { halListToSpace } from '../utils/halListToSpace';
import { spaceQueryKeys } from './spacesQueryKeys';

/**
 * fetches specific space by id
 *
 * @param includeInbox whether to include the inbox space, which is loaded in from the roots manually
 * @param queryOptions
 * @returns
 */
export const useQuerySpace = (
  spaceId?: string,
  queryOptions?: Partial<QueryObserverOptions<Space>>,
  meta?: QueryMeta,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: spaceQueryKeys.space(spaceId),
    queryFn: async (params) => {
      const safeSpaceId = params.queryKey[1] as string;

      return halListToSpace(getWoodyResponseData(await client.fetchListById(safeSpaceId)));
    },
    placeholderData: keepPreviousData,
    retry(failureCount, error) {
      if (isWoodyError(error) && error.status === 404) return false;
      return failureCount < 3;
    },
    refetchOnMount: true,
    meta,
    refetchInterval: 45000,
    ...queryOptions,
    enabled: isQueryEnabled([queryOptions?.enabled, !!spaceId]),
  });

  return query;
};
