import {
  FloatingFocusManager,
  FloatingPortal,
  Placement,
  Strategy,
  autoUpdate,
  flip,
  offset as floatingOffset,
  shift,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import clsx from 'clsx';
import gsap from 'gsap';
import React, { cloneElement, useCallback, useEffect, useMemo, useState } from 'react';
import styles from './DropdownMenu.module.scss';

function fadeInMenuOptions(onComplete?: () => void) {
  gsap.fromTo(
    `.${styles.menu__options}`,
    { opacity: 0, y: 10 },
    { opacity: 1, y: 0, duration: 0.1, stagger: 0.1, onComplete },
  );
}

function fadeOutMenuOptions(onComplete?: () => void) {
  gsap.fromTo(
    `.${styles.menu__options}`,
    { opacity: 1, y: 0 },
    {
      opacity: 0,
      y: 10,
      duration: 0.1,
      stagger: 0.1,
      onComplete,
    },
  );
}

const DropdownMenuOptions = React.forwardRef<
  HTMLElement,
  React.PropsWithChildren<{
    x: number;
    y: number;
    strategy: Strategy;
    floatingProps: Record<string, unknown>;
    itemProps: Record<string, unknown>;
    headless?: boolean;
  }>
>(({ children, x, y, strategy, floatingProps, headless = false }, ref) => {
  useEffect(() => {
    if (headless) return;

    fadeInMenuOptions();
  }, [headless]);

  return headless ? (
    cloneElement(children as React.ReactElement, {
      ref,
      style: {
        // @ts-expect-error children can be null
        ...children?.props?.style,
        position: strategy,
        left: x ?? 0,
        top: y ?? 0,
        zIndex: 80,
      },
      ...floatingProps,
    })
  ) : (
    <menu
      ref={ref}
      className={clsx(styles.menu__options, 'pointer-events-auto')}
      style={{
        position: strategy,
        left: x ?? 0,
        top: y ?? 0,
      }}
      {...floatingProps}
    >
      {children}
    </menu>
  );
});

DropdownMenuOptions.displayName = 'DropdownMenuOptions';

type DropdownMenuProps = React.PropsWithChildren<{
  triggerElement: React.ReactNode;
  placement?: Placement;
  buttonClassName?: string;
  ref?: (node: HTMLElement | null) => void;
  headless?: boolean;
  headlessTrigger?: boolean;
  tabIndex?: number;
  offset?: number;
  padding?: number;
  sameWidth?: boolean;
  rootClassName?: string;
  preventDefaultOnHandle?: boolean;

  shadedTrigger?: boolean;

  dropdownOpen?: boolean;
  setDropdownOpen?: (open: boolean) => void;
}>;

interface IDropdownMenuContext {
  open: boolean;

  floatingWidth: number;
  referenceWidth: number;
}

const DropdownMenuContext = React.createContext<IDropdownMenuContext>({
  open: false,

  floatingWidth: 0,
  referenceWidth: 0,
});

export const useDropdownMenuContext = () => {
  return React.useContext(DropdownMenuContext);
};

/**
 * @deprecated, use new src/modules/ui/DropdownMenu
 */
const DropdownMenu_Deprecated: React.FC<DropdownMenuProps> = ({
  children,
  triggerElement,
  placement = 'bottom-end',
  buttonClassName,
  ref = () => {},
  headless = false,
  headlessTrigger = headless,
  tabIndex = 0,
  offset = 10,
  padding = 18,
  sameWidth = false,
  shadedTrigger = false,
  rootClassName,
  preventDefaultOnHandle = false,

  dropdownOpen,
  setDropdownOpen,
}) => {
  const [localDropdown, setLocalDropdown] = useState(false);
  const dropdown = useMemo(() => {
    if (dropdownOpen !== undefined && setDropdownOpen !== undefined) {
      return dropdownOpen;
    }
    return localDropdown;
  }, [dropdownOpen, setDropdownOpen, localDropdown]);

  const setDropdown = useCallback(
    (open: boolean) => {
      if (dropdownOpen !== undefined && setDropdownOpen !== undefined) {
        setDropdownOpen(open);
      } else {
        setLocalDropdown(open);
      }
    },
    [dropdownOpen, setDropdownOpen],
  );

  const [floatingWidth, setFloatingWidth] = useState(0);
  const [referenceWidth, setReferenceWidth] = useState(0);

  const { x, y, strategy, refs, context } = useFloating<HTMLElement>({
    placement,
    middleware: [
      floatingOffset(offset),
      flip(),
      shift({
        padding,
      }),
      size({
        apply({ rects, elements }) {
          setFloatingWidth(rects.floating.width);
          setReferenceWidth(rects.reference.width);

          if (sameWidth)
            Object.assign(elements.floating.style, {
              width: `${rects.reference.width}px`,
            });
        },
      }),
    ],
    open: dropdown,
    onOpenChange: setDropdown,
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useRole(context as never, { role: 'menu' }),
    useDismiss(context as never),
  ]);

  const handleToggleMenuOptions: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    if (preventDefaultOnHandle) e.preventDefault();
    if (dropdown) return fadeOutMenuOptions(() => setDropdown(false));
    return setDropdown(true);
  };

  const handleCloseMenuOptions: React.MouseEventHandler = (e) => {
    e.stopPropagation();
    setDropdown(false);
  };

  const providerValue = useMemo(
    () => ({ open: dropdown, floatingWidth, referenceWidth }),
    [dropdown, floatingWidth, referenceWidth],
  );

  return (
    <DropdownMenuContext.Provider value={providerValue}>
      <div className={clsx(styles.menu, rootClassName)}>
        {headlessTrigger ? (
          cloneElement(triggerElement as React.ReactElement, {
            onClick: handleToggleMenuOptions,
            ref: refs.setReference,
            ...getReferenceProps(),
          })
        ) : (
          <button
            ref={refs.setReference}
            type="button"
            tabIndex={tabIndex}
            className={clsx(styles.menu__button, {
              [styles.menu__button__open]: dropdown,
              [String(buttonClassName)]: !!buttonClassName,
              [styles.shaded]: shadedTrigger,
            })}
            onClick={handleToggleMenuOptions}
            data-testid="dropdown-menu-button"
            {...getReferenceProps()}
          >
            {triggerElement}
          </button>
        )}

        <FloatingPortal>
          {dropdown && (
            <FloatingFocusManager
              context={context as never}
              // Prevent outside content interference.
              modal={true}
              // Only initially focus the root floating menu.
              initialFocus={0}
              // Only return focus to the root menu's reference when menus close.
              returnFocus={true}
              // Allow touch screen readers to escape the modal root menu
              // without selecting anything.
              visuallyHiddenDismiss
            >
              <DropdownMenuOptions
                ref={(htmlElement) => {
                  refs.setFloating(htmlElement);
                  ref(htmlElement);
                }}
                x={x ?? 0}
                y={y ?? 0}
                strategy={strategy}
                floatingProps={getFloatingProps({ onClick: handleCloseMenuOptions })}
                itemProps={getItemProps()}
                headless={headless}
              >
                {children}
              </DropdownMenuOptions>
            </FloatingFocusManager>
          )}
        </FloatingPortal>
      </div>
    </DropdownMenuContext.Provider>
  );
};

export default DropdownMenu_Deprecated;
