import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

export const ButtonExpandCollapse = styled.button`
  position: relative;
  color: ${cssVar['color-text-tertiary']};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition:
    color 0.2s,
    background-color 0.2s,
    opacity 0.2s;

  outline: none !important;
  ${mediaHover} {
    &:hover:enabled {
      background-color: #e8e2fe;
      color: ${cssVar['color-app-primary']};
    }
  }

  // increase hit click area
  &:after {
    position: absolute;
    content: '';
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
  }

  &:disabled {
    color: ${cssVar['color-text-placeholder']};
    opacity: 0.5;
  }
`;
