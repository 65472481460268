import { useQueryResourceRootIntegrationList } from '@/src/modules/connections/queries/useQueryResourceRootIntegrationList';
import { ResourceRootIntegration } from '@/src/modules/resource-roots/resource-roots.types';
import { useMemo } from 'react';

/**
 * @TODO migrate to useQueryResourceRoot when shape is updated
 * https://linear.app/futurebrowser/issue/FUT-4781/update-shape-of-v2resource-rootsresourcerootid-to-newer-v2resource
 */
export const useQueryResourceRootIntegration = (
  findBy:
    | { integrationId?: string | null }
    | { rootId?: string }
    | { fn: (resourceRoot: ResourceRootIntegration) => boolean },
) => {
  const { integrationRoots, isLoading } = useQueryResourceRootIntegrationList();

  const integrationRoot = useMemo(() => {
    if ('integrationId' in findBy) {
      return integrationRoots.find((root) => root.integration.id === findBy.integrationId);
    }

    if ('rootId' in findBy) {
      return integrationRoots.find((connectionRoot) =>
        connectionRoot.integration.mirrorRegistries.some(
          (mirrorRegistry) => mirrorRegistry.rootId === findBy.rootId,
        ),
      );
    }

    if ('fn' in findBy) {
      return integrationRoots.find(findBy.fn);
    }

    return undefined;
  }, [integrationRoots]);

  return {
    integrationRoot,
    isLoading,
  };
};
