import { useResponsive } from '@/src/hooks/responsive';
import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import useModalInactiveState from '@/src/hooks/useModalInactiveState';
import { useRefocusOnBlur } from '@/src/hooks/useRefocusOnBlur';
import { useRunOnce } from '@/src/hooks/useRunOnce';
import * as Conversation from '@/src/modules/ui/components/Conversation';
import { framerAnimationSlideUp } from '@/src/modules/ui/constants/framerAnimations';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import { ChatbotErrorCodes } from '@fabric/woody-client';
import { ArrowUpIcon } from '@radix-ui/react-icons';
import React, { useMemo, useState } from 'react';
import { mergeRefs } from 'react-merge-refs';
import { useChabot } from '../../hooks/chatbot';

type AnyEvent = React.KeyboardEvent | React.MouseEvent;

const MessageForm = React.forwardRef<
  HTMLTextAreaElement,
  { placeholder?: string; permanentFocus?: boolean; autoFocus?: boolean }
>(({ placeholder = 'Ask a question...', permanentFocus = false, autoFocus = false }, propRef) => {
  const [content, setContent] = useState('');
  const { addMessage, isLoading: isLoadingAnswer, error, usage } = useChabot();
  const [textAreaRef, setTextAreaRef] = useState<HTMLTextAreaElement | null>(null);
  const { isMobileView } = useResponsive();

  const formRef = React.useRef<HTMLFormElement>(null);
  const ref = useMemo(() => mergeRefs([propRef, setTextAreaRef]), [propRef]);

  const handleSubmit = async (e?: AnyEvent) => {
    if (usage && usage.used >= (usage.limit ?? 3)) {
      return;
    }

    if (isLoadingAnswer) return;

    e?.preventDefault();
    addMessage(content);
    setContent('');
    textAreaRef?.focus();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      // Allow Shift + Enter to insert a new line
      e.preventDefault();
      handleSubmit();
    }
  };

  const isModalInactive = useModalInactiveState(formRef);
  const onBlurInput = useRefocusOnBlur<HTMLTextAreaElement>(isModalInactive || !permanentFocus);
  useClientLayoutEffect(() => {
    if (permanentFocus || isModalInactive) return;

    textAreaRef?.focus();
  }, [permanentFocus, isModalInactive]);

  /**
   * This might seem unnecessary but on the expanded resource for some reason when selecting a tab
   * the tab steals focus a few MS after the tab is selected. This causes the input to blur right after
   * being auto focused.
   */
  useRunOnce(() => {
    if (!autoFocus && !permanentFocus) return;

    window.requestAnimationFrame(() => {
      textAreaRef?.focus();
    });
  }, Boolean(textAreaRef));

  const hiddenInput = isModalInactive && isMobileView && (permanentFocus || autoFocus);

  return (
    <ForcedTheme colorScheme="light" asChild>
      <Conversation.Form {...framerAnimationSlideUp} ref={formRef}>
        <Conversation.FormField>
          {!hiddenInput && (
            <Conversation.FormTextArea
              autoFocus={permanentFocus || autoFocus}
              onBlur={onBlurInput}
              placeholder={placeholder}
              ref={ref}
              onChange={(e) => setContent(e.target.value)}
              value={content}
              onKeyDown={handleKeyDown}
            />
          )}
          <Conversation.FormButton
            onClick={handleSubmit}
            disabled={
              content === '' || isLoadingAnswer || error === ChatbotErrorCodes.USAGE_LIMIT_EXCEEDED
            }
          >
            <ArrowUpIcon />
          </Conversation.FormButton>
        </Conversation.FormField>
      </Conversation.Form>
    </ForcedTheme>
  );
});

MessageForm.displayName = 'MessageForm';

export default MessageForm;
