import { isDefined } from '@/src/lib/utils';
import {
  AttributeMap,
  FalsyAttributeMap,
} from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/types';
import dayjs from 'dayjs';

export function formatter<T>(value: T, format: (value: NonNullable<T>) => string | undefined) {
  return isDefined(value) ? format(value) : undefined;
}

export function isValidAttribute(attribute: FalsyAttributeMap): attribute is AttributeMap {
  return isDefined(attribute.value) && attribute.value !== '';
}

export function attributeDateFormatter(dateString: string | Date | number): string {
  return dayjs(dateString).format('dddd, D MMMM YYYY [at] HH:mm');
}
