import clsx from 'clsx';
import { FC } from 'react';

interface Props {
  className?: string;
}

const MenuIcon: FC<Props> = ({ className }) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      viewBox="0 0 16 12"
      className={clsx('new_icon', className)}
    >
      <rect width="16" height="2" rx="1" fill="currentColor" />
      <rect y="5" width="16" height="2" rx="1" fill="currentColor" />
      <rect y="10" width="16" height="2" rx="1" fill="currentColor" />
    </svg>
  );
};

export default MenuIcon;
