import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { isFolderFdoc } from '@/src/modules/resources/utils/resourceTypes';
import { WoodyError } from '@fabric/woody-client';
import { InfiniteData, InfiniteQueryObserverOptions, QueryKey } from '@tanstack/react-query';
import { FilteredFdocs } from '../resources.types';
import { useQueryResources } from './useQueryResources';

/**
 * fetches all folders within a parent folder
 *
 * @param queryOptions
 * @returns
 */
export const useQueryFolders = (
  parentId?: string,
  queryOptions?: Partial<
    InfiniteQueryObserverOptions<
      FilteredFdocs,
      WoodyError,
      InfiniteData<FilteredFdocs, string | undefined>,
      FilteredFdocs,
      QueryKey,
      string | undefined
    >
  >,
) => {
  const { resources: _resources, ...query } = useQueryResources(
    {
      filters: {
        parent: parentId,
        type: ['folder'],
      },
    },
    {
      ...queryOptions,
      enabled: isQueryEnabled([queryOptions?.enabled, !!parentId]),
    },
  );

  return {
    ...query,
    folders: _resources.filter(isFolderFdoc),
  };
};
