import { Node } from '@tiptap/core';
import { mergeAttributes } from '@tiptap/react';
import type { MarkdownNodeSpec } from 'tiptap-markdown';

import markdownitContainer from 'markdown-it-container';

type MarkdownItFn = {
  use: (md: typeof markdownitContainer, name: string) => void;
};

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    callout: {
      /**
       * Set a callout node
       */
      setCallout: () => ReturnType;

      /**
       * Toggle a callout node
       */
      toggleCallout: () => ReturnType;
      /**
       * Unset a callout node
       */
      unsetCallout: () => ReturnType;
    };
  }
}

const Callout = Node.create({
  name: 'callout',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: 'block+',
  group: 'block',
  defining: true,

  parseHTML() {
    return [
      {
        tag: 'div',
        getAttrs: (node) => {
          const domNode = node as HTMLElement;
          const role = domNode.getAttribute('data-role');
          return role === 'callout' ? {} : false;
        },
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        ['data-role']: 'callout',
      }),
      0,
    ];
  },

  addKeyboardShortcuts() {
    // shift+mod+c
    return {
      'Shift-Mod-c': () => this.editor.commands.toggleCallout(),
    };
  },

  addCommands() {
    return {
      setCallout:
        () =>
        ({ commands }) => {
          return commands.wrapIn('callout');
        },
      toggleCallout:
        () =>
        ({ commands }) => {
          return commands.toggleWrap('callout');
        },
      unsetCallout:
        () =>
        ({ commands }) => {
          return commands.lift('callout');
        },
    };
  },

  addStorage() {
    return {
      markdown: {
        serialize(
          state: Parameters<MarkdownNodeSpec<unknown>['serialize']>[0],
          node: Parameters<MarkdownNodeSpec<unknown>['serialize']>[1],
        ) {
          state.write('::: callout\n');
          state.renderContent(node);
          state.write('\n:::\n');
          state.closeBlock(node);
        },
        parse: {
          setup(markdownit: MarkdownItFn) {
            markdownit.use(markdownitContainer, 'callout');
          },
        },
      },
    };
  },
});

export default Callout;
