import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { resourceMutationKeys } from '@/src/modules/resources/mutations/resourceMutationKeys';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { convertWoodyResourceToFdoc, Fdoc } from '@/src/types/api';
import { isWoodyError } from '@/src/utils/error';
import { WoodyError } from '@fabric/woody-client';
import {
  InfiniteData,
  InfiniteQueryObserverOptions,
  keepPreviousData,
  QueryKey,
  useInfiniteQuery,
  useIsMutating,
} from '@tanstack/react-query';
import { useRef } from 'react';
import { defaultResourceFilterOptions } from '../resources.config';
import { FilteredFdocs, ResourceFilterOptions } from '../resources.types';
import { resourceFilterClientSort } from '../utils/resourceFilterClientSort';
import { resourceQueryKeys } from './resourceQueryKeys';

// These make the empty values unique so they don't cause re-renders and we don't need to memoize them
const emptyResources: Fdoc[] = [];
const emptyTotal = 0;

const filterSelector = (data: InfiniteData<FilteredFdocs>) => {
  const sort = data.pages[0]._meta.options.sort;

  return {
    total: data.pages[0].total,
    pages: data.pages.map((page) => ({
      cursorNext: page.cursorNext,
      pageTotal: page.results.length,
    })),
    resources: resourceFilterClientSort(
      data.pages.flatMap((page) => page.results),
      sort,
    ),
  };
};

/**
 * fetches resources based on the provided options
 *
 * @param queryOptions
 * @returns
 */
export const useQueryResources = (
  options?: ResourceFilterOptions,
  queryOptions?: Partial<
    InfiniteQueryObserverOptions<
      FilteredFdocs,
      WoodyError,
      InfiniteData<FilteredFdocs, string | undefined>,
      FilteredFdocs,
      QueryKey,
      string | undefined
    >
  >,
) => {
  const { client } = useWoody();

  const isMutating = useIsMutating({
    mutationKey: resourceMutationKeys.createResource,
  });

  const mergedOptions = {
    ...defaultResourceFilterOptions,
    ...options,
  };

  const queryDataRef = useRef<ReturnType<typeof filterSelector> | undefined>(undefined);

  const mutatingOrEmpty = Boolean(isMutating > 0 && queryDataRef.current);

  const query = useInfiniteQuery({
    queryKey: resourceQueryKeys.filter(mergedOptions),
    queryFn: async ({ pageParam }) => {
      const data = getWoodyResponseData(
        await client.filterResources({
          ...mergedOptions,
          pagination: {
            cursor: pageParam,
            pageSize: mergedOptions?.perPage,
          },
        }),
      );

      return {
        ...data,
        results: data.results.map(convertWoodyResourceToFdoc),

        // This allows the select function to have access to the options used in the query
        // And we avoid having to memoize the select function
        _meta: {
          options: mergedOptions,
        },
      };
    },
    placeholderData: keepPreviousData,
    refetchInterval: 45000,
    retry: (failureCount, error) => {
      if (isWoodyError(error) && error.status === 404) {
        return failureCount < 1;
      }

      return failureCount < 4;
    },
    ...queryOptions,
    select: filterSelector,
    enabled: isQueryEnabled([queryOptions?.enabled, !mutatingOrEmpty]),
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => {
      return (lastPage.hasMore && lastPage?.cursorNext) || undefined;
    },
  });
  queryDataRef.current = query.data;

  return {
    ...query,
    resources: query.data?.resources || emptyResources,
    total: query.data?.total ?? emptyTotal,
  };
};
