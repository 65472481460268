import {
  ResourceDetail,
  ResourceDetailAudio,
  ResourceDetailBookmark,
  ResourceDetailDefault,
  ResourceDetailDocument,
  ResourceDetailFolder,
  ResourceDetailHighlight,
  ResourceDetailImage,
  ResourceDetailNotepad,
  ResourceDetailVideo,
} from '@/src/modules/resources/resources.types';

const isResourceDetailImage = (detail: ResourceDetail): detail is ResourceDetailImage =>
  detail.kind === 'image';
const isResourceDetailDocument = (detail: ResourceDetail): detail is ResourceDetailDocument =>
  detail.kind === 'document';
const isResourceDetailBookmark = (detail: ResourceDetail): detail is ResourceDetailBookmark =>
  detail.kind === 'bookmark';
const isResourceDetailFolder = (detail: ResourceDetail): detail is ResourceDetailFolder =>
  detail.kind === 'folder';
const isResourceDetailHighlight = (detail: ResourceDetail): detail is ResourceDetailHighlight =>
  detail.kind === 'highlight';
const isResourceDetailNotepad = (detail: ResourceDetail): detail is ResourceDetailNotepad =>
  detail.kind === 'notepad';
const isResourceDetailDefault = (detail: ResourceDetail): detail is ResourceDetailDefault =>
  detail.kind === 'default';
const isResourceDetailAudio = (detail: ResourceDetail): detail is ResourceDetailAudio =>
  detail.kind === 'audio';
const isResourceDetailVideo = (detail: ResourceDetail): detail is ResourceDetailVideo =>
  detail.kind === 'video';

export {
  isResourceDetailAudio,
  isResourceDetailBookmark,
  isResourceDetailDefault,
  isResourceDetailDocument,
  isResourceDetailFolder,
  isResourceDetailHighlight,
  isResourceDetailImage,
  isResourceDetailNotepad,
  isResourceDetailVideo,
};
