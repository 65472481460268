import { Query } from '@tanstack/react-query';
import { commentQueryKeys } from './commentQueryKeys';

export const commentQueryPredicates = {
  commentByResourceId: (query: Query, resourceId: string) => {
    return (
      query.queryKey[0] === commentQueryKeys.resourceComments()[0] &&
      query.queryKey[1] === resourceId
    );
  },
};
