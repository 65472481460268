import React from 'react';

interface Event {
  preventDefault?: VoidFunction;
}

const isEvent = (e?: unknown): e is Required<Event> =>
  Boolean(e && typeof e === 'object' && 'preventDefault' in (e as Event));

export const useBoolState = (defaultValue = false, options?: { onFalse?: VoidFunction }) => {
  const onFalse = options?.onFalse;

  const [value, setValue] = React.useState(defaultValue);

  const handleToggle = React.useCallback(
    (e?: Event) => {
      e?.preventDefault?.();

      const nextValue = !value;
      if (!nextValue) {
        onFalse?.();
      }
      setValue(nextValue);
    },
    [value, onFalse],
  );

  const handleTrue = React.useCallback((e?: unknown) => {
    if (isEvent(e)) {
      e.preventDefault();
    }

    setValue(true);
  }, []);

  const handleFalse = React.useCallback(() => {
    onFalse?.();
    setValue(false);
  }, [onFalse]);

  return React.useMemo(
    () => ({
      value: value,
      handleToggle,
      handleTrue,
      handleFalse,
      set: setValue,
      modalProps: {
        open: value,
        onOpenChange: setValue,
      },
    }),
    [handleFalse, handleToggle, handleTrue, value],
  );
};

// eslint-disable-next-line import/no-unused-modules
export type UseBoolStateReturn = ReturnType<typeof useBoolState>;
