// make sure to ignore escaped characters
const fixSpacedStrongRegex = /([^\\]|^)\*\*(\s*)(.*?)(\s*)\*\*/g;

/**
 * Fixes spaced strong markdown elements.
 * @param markdown - The raw markdown string.
 * @returns The fixed markdown string.
 */
export function fixSpacedStrong(markdown: string): string {
  return markdown.replace(fixSpacedStrongRegex, '$1$2**$3**$4');
}
