import { RefObject, useEffect, useState } from 'react';

function useModalInactiveState(elementRef: RefObject<HTMLElement>): boolean {
  const [isInactive, setIsInactive] = useState(false);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const modal = element.closest('[role=dialog]');
    if (!modal) return;

    const observer = new MutationObserver(() => {
      if (modal instanceof HTMLElement) {
        const isPointerEventsNone = getComputedStyle(modal).pointerEvents === 'none';
        setIsInactive(isPointerEventsNone);
      }
    });

    observer.observe(modal, { attributes: true, attributeFilter: ['style'] });

    return () => {
      observer.disconnect();
    };
  }, [elementRef]);

  return isInactive;
}

export default useModalInactiveState;
