import { isInDesktop } from '@/src/hooks/todesktop';
import { platform } from '@todesktop/client-core';
import React from 'react';
import styled from 'styled-components';

export const LinkExternal = styled.a.attrs((props) => {
  const { href, onClick } = props;
  const clickHandler = React.useMemo(() => {
    return isInDesktop() && href
      ? (e: React.MouseEvent<HTMLAnchorElement>) => {
          platform.os.openURL(href!);
          e.stopPropagation();
          onClick?.(e);
        }
      : (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.stopPropagation();
          onClick?.(e);
        };
  }, [href, onClick]);
  return {
    ...props,
    onClick: clickHandler,
    target: '_blank',
    rel: 'noopener noreferrer',
  };
})``;
