import { ResourcePreviewComponent } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import { ResourcePreviewGridItem } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewGrid/ResourcePreviewGridItem';
import { ResourcePreviewListItem } from '@/src/modules/resources/components/ResourcePreview/ResourcePreviewList/ResourcePreviewListItem';

export const ResourcePreview: ResourcePreviewComponent = (props) => {
  if (props.variant === 'list') {
    // we don't pass the draggable ref and mousedown as it's used for dragging which is not enabled for list
    const { draggableRef, onMouseDown, ...rest } = props;
    return <ResourcePreviewListItem {...rest} />;
  }

  return <ResourcePreviewGridItem {...props} />;
};
