import { useWindowEvent } from '@/src/hooks/useWindowEvent';
import { useCallback, useEffect, useRef, useState } from 'react';

type PanDirection = 'up' | 'down' | null;

interface UseWindowPanningOptions {
  onPanStart?: () => void;
  onPan?: (delta: number) => void;
  onPanEnd?: (direction: PanDirection, velocity: number, offset: number) => void;
  respectScrollAreas?: PanDirection | boolean;
  threshold?: number;
  disabled?: boolean;
}

/**
 * CURRENTLY UNUSED, but
 * This is a panning alternative that is window-based, it is inspired by how Android handles side/bottom panels,
 * mostly for drawer based UI, the idea is that instead of expanding/closing by panning the drawer this
 * reacts to any panning within the entire window, but it respects scroll areas and other similar niche cases.
 * It would need some polishing before using but it is quite flow-y and smooth.
 */
export const useWindowPanning = ({
  onPanStart,
  onPan,
  onPanEnd,
  respectScrollAreas = true,
  threshold = 10,
  disabled = false,
}: UseWindowPanningOptions) => {
  const [isPanning, setIsPanning] = useState(false);
  const startY = useRef<number | null>(null);
  const lastY = useRef<number | null>(null);
  const startTime = useRef<number | null>(null);
  const isScrolling = useRef(false);
  const hasPanStarted = useRef(false);
  const thresholdPassed = useRef(false);

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      if (disabled || e.touches.length !== 1) return;
      startY.current = e.touches[0].clientY;
      lastY.current = e.touches[0].clientY;
      startTime.current = Date.now();
      setIsPanning(true);
      isScrolling.current = false;
      hasPanStarted.current = false;
      thresholdPassed.current = false;
    },
    [disabled],
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (disabled || !isPanning || !startY.current || !lastY.current) return;

      const currentY = e.touches[0].clientY;
      let deltaY = currentY - lastY.current;
      let totalDelta = currentY - startY.current;

      const target = e.target as HTMLElement;
      const scrollableParent = findScrollableParent(target);

      if (respectScrollAreas) {
        if (scrollableParent) {
          const { scrollTop, scrollHeight, clientHeight } = scrollableParent;
          const isAtTop = scrollTop <= 0;
          const isAtBottom = scrollTop + clientHeight >= scrollHeight;

          if (
            ((deltaY < 0 &&
              !isAtBottom &&
              (respectScrollAreas === 'down' || respectScrollAreas === true)) || // Scrolling up and not at bottom
              (deltaY > 0 &&
                !isAtTop &&
                (respectScrollAreas === 'up' || respectScrollAreas === true))) &&
            !thresholdPassed.current // Scrolling down and not at top
          ) {
            isScrolling.current = true;
            return;
          } else if (isScrolling.current) {
            resetElementScrolling(scrollableParent);
            isScrolling.current = false;
            // reset start to now
            startY.current = currentY;
            lastY.current = currentY;
            lastY.current = currentY;
            startTime.current = Date.now();
            deltaY = 0;
            totalDelta = 0;
          } else if (thresholdPassed.current) {
            // allow it to return back and when deltaY is below the threshhold, reset it
            if (Math.abs(deltaY) < threshold) {
              thresholdPassed.current = false;
            }
          }
        }
      }

      if (e.cancelable) e.preventDefault();

      if (!thresholdPassed.current) {
        if (Math.abs(totalDelta) > threshold) {
          thresholdPassed.current = true;

          if (!hasPanStarted.current) {
            onPanStart?.();
            hasPanStarted.current = true;
          }
        } else {
          lastY.current = currentY;
          return;
        }
      }

      if (!isScrolling.current) {
        onPan?.(deltaY);
      }

      lastY.current = currentY;
    },
    [disabled, isPanning, onPan, onPanStart, respectScrollAreas, threshold],
  );

  const handleTouchEnd = useCallback(() => {
    if (disabled || !isPanning || !startY.current || !lastY.current || !startTime.current) return;

    const endY = lastY.current;
    const endTime = Date.now();
    const duration = endTime - startTime.current;
    const distance = endY - startY.current;
    const velocity = Math.abs(distance / duration);
    const totalDelta = endY - startY.current;

    let direction: PanDirection = null;
    if (Math.abs(distance) > threshold) {
      direction = distance > 0 ? 'down' : 'up';
    }

    onPanEnd?.(direction, velocity, totalDelta);
    setIsPanning(false);
    startY.current = null;
    lastY.current = null;
    startTime.current = null;
    isScrolling.current = false;
    hasPanStarted.current = false;
    thresholdPassed.current = false;
  }, [disabled, isPanning, onPanEnd, threshold]);

  useEffect(() => {
    if (disabled) {
      setIsPanning(false);
      startY.current = null;
      lastY.current = null;
      startTime.current = null;
      isScrolling.current = false;
      hasPanStarted.current = false;
      thresholdPassed.current = false;
    }
  }, [disabled]);

  useWindowEvent('touchstart', handleTouchStart);
  useWindowEvent('touchend', handleTouchEnd);

  useEffect(() => {
    window.addEventListener('touchmove', handleTouchMove, { passive: false, capture: true });

    return () => {
      window.removeEventListener('touchmove', handleTouchMove, { capture: true });
    };
  }, [handleTouchMove]);

  return { isPanning };
};

function findScrollableParent(element: HTMLElement | null): HTMLElement | null {
  if (!element || element === document.body) return null;
  if (isScrollable(element)) return element;
  return findScrollableParent(element.parentElement);
}

function isScrollable(element: HTMLElement): boolean {
  const { overflow, overflowY } = window.getComputedStyle(element);
  return /(auto|scroll)/.test(overflow + overflowY);
}

function resetElementScrolling(element: HTMLElement) {
  // we clone and set the element back again without causing a repaint and set the proper scroll,
  // this is to prevent the scroll from jumping back to the top
  const scrollPos = element.scrollTop;
  const clonedElement = element.cloneNode(false) as HTMLElement;
  const children = Array.from(element.children);

  children.forEach((child) => {
    clonedElement.appendChild(child);
  });

  element.parentElement?.replaceChild(clonedElement, element);
  clonedElement.scrollTop = scrollPos;
}
