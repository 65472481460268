import { useQueryPageResourcePreview } from '@/src/modules/resource-detail/queries/useQueryPageResourcePreview';
import { Image } from '@/src/modules/ui/components/Image';
import { FdocOfType } from '@/src/types/api';
import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import { OptimisticDraft } from '../../../types/draftable';
import { TWITTER_URL_REGEX } from '../../../utils/url-regex';
import { OfViewMode } from '../../ViewModeSwitcher/ViewModeSwitcher';
import styles from './FdocItemContent.module.scss';
import localStyles from './FdocItemContentPage.module.scss';

type FdocItemContentPageProps = {
  fdoc: OptimisticDraft<FdocOfType<'page'>>;
  constrainedImages?: number | null;
  viewMode: OfViewMode;
  isMobileCard?: boolean;
  isNoteStack?: boolean;

  size?: {
    width: number;
    height: number;
  };
};

function FdocItemContentPage({
  fdoc,
  constrainedImages,
  viewMode,
  size,
  isMobileCard,
  isNoteStack,
}: FdocItemContentPageProps) {
  const { data: preview } = useQueryPageResourcePreview(fdoc);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    // update the image width based on the image's itself width
    if (imageRef.current) {
      imageRef.current.style.width = `${imageRef.current.naturalWidth}px`;
    }
  }, [imageRef]);

  if (fdoc.type !== 'page') return null;

  if (preview?.description && fdoc.data.pageUrl.match(TWITTER_URL_REGEX)) {
    return (
      <div
        className={clsx(
          styles.item__content__page,
          isMobileCard && styles.item__content__page__mobile,
        )}
      >
        <p className={clsx(localStyles.description, localStyles.twitter, 'dashboard_scrollbar')}>
          {preview.description}
        </p>
      </div>
    );
  }

  if (viewMode === 'List') return null;

  if (!preview?.image?.url) return <div className={styles.item__content__page} />;

  return (
    <div
      className={styles.item__content__page}
      style={{
        width: size?.width ?? undefined,
        flex: size ? 'none' : undefined,
        justifyContent: isMobileCard || isNoteStack ? 'flex-start' : undefined,
        position: 'relative',
      }}
    >
      <Image
        src={preview.image.url}
        alt={fdoc.data.pageTitle}
        width={size?.width ?? undefined}
        height={size?.height ?? undefined}
        style={{
          maxHeight: constrainedImages ? constrainedImages : undefined,
          objectFit: size ? 'cover' : undefined,
          marginTop: isMobileCard || isNoteStack ? 'auto' : '',
        }}
        draggable={false}
      />
    </div>
  );
}

export default FdocItemContentPage;
