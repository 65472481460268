import { cssVar } from '@/src/modules/ui/theme/variables';
import { css } from 'styled-components';

type AppThemeBackgroundFromBorderColor = 'primary' | 'secondary' | 'tertiary';

const borders: Record<AppThemeBackgroundFromBorderColor, string> = {
  primary: cssVar['color-border-primary'],
  secondary: cssVar['color-border-secondary'],
  tertiary: cssVar['color-border-tertiary'],
};

export interface CssBackgroundFromBorderColorProp {
  borderColor?: AppThemeBackgroundFromBorderColor;
}

export const getCssBackgroundFromBorderColor = (
  defaultBackground?: AppThemeBackgroundFromBorderColor,
) => css<CssBackgroundFromBorderColorProp>`
  ${(p) => {
    const bgKey = p.borderColor || defaultBackground;
    if (bgKey) {
      return css`
        background: ${borders[bgKey]};
      `;
    }
    return ``;
  }}
`;
