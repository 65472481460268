import useDeferred from '@/src/hooks/useDeferred';
import clsx from 'clsx';
import { useEffect, useMemo, useRef } from 'react';
import { createPortal } from 'react-dom';
import { usePDFContent } from './PDF';
import TinyPage from './TinyPage';
import styles from './TinyPagesList.module.scss';

const TinyPagesList: React.FC<{
  pageWidth: number;
  portalTo?: HTMLElement;
  className?: string;
  forcedVisible?: boolean;
}> = ({ pageWidth, portalTo, className, forcedVisible = false }) => {
  const {
    pdf,
    isReady,
    tinyPagesVisible,
    currentPage,
    userScrolled,
    setUserScrolled,
    scrollToPage,
    setTinyPagesRef,
    tinyPagesRef,
    scrollToTinyPage,
  } = usePDFContent();
  const pdfPages = useMemo(() => Array.from(Array(pdf?.numPages ?? 0).keys()), [pdf]);

  // when portalTo changes we need to unmount the pages and remount them in the new portal
  const deferredPortalTo = useDeferred(portalTo);

  const debounced = useRef<number | null>(null);

  useEffect(() => {
    if (!userScrolled) return;

    if (debounced.current) clearTimeout(debounced.current);

    debounced.current = window.setTimeout(() => {
      setUserScrolled?.(false);
      scrollToTinyPage?.(currentPage);
    }, 300);
  }, [currentPage, userScrolled, setUserScrolled, tinyPagesVisible, scrollToTinyPage]);

  // when the portal changes or visibility changes we also need to scroll to the current page
  const prevTinyPagesVisible = useRef(tinyPagesVisible);
  const prevPortalTo = useRef(deferredPortalTo);
  const stateDebounce = useRef<number | null>(null);

  useEffect(() => {
    if (
      prevTinyPagesVisible.current === tinyPagesVisible &&
      prevPortalTo.current === deferredPortalTo
    )
      return;

    if (stateDebounce.current) clearTimeout(stateDebounce.current);

    stateDebounce.current = window.setTimeout(() => {
      scrollToTinyPage?.(currentPage);
      prevTinyPagesVisible.current = tinyPagesVisible;
      prevPortalTo.current = deferredPortalTo;
    }, 300);
  }, [currentPage, tinyPagesVisible, scrollToTinyPage, deferredPortalTo]);

  if (!isReady || (!tinyPagesVisible && !forcedVisible)) return null;

  const element = (
    <div className={clsx(styles.pages, className)} ref={setTinyPagesRef}>
      {tinyPagesRef &&
        pdfPages.map((index) => (
          <TinyPage
            key={index + 1}
            pageNumber={index + 1}
            width={pageWidth}
            root={tinyPagesRef}
            onClick={() => {
              scrollToPage?.(index + 1);
            }}
          />
        ))}
    </div>
  );

  return portalTo ? createPortal(element, portalTo) : element;
};

export default TinyPagesList;
