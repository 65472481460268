import HoverItem from '@/src/components/HoverItem/HoverItem';
import { useBoolState } from '@/src/hooks/useBooleanState';
import LinkExternalIcon from '@/src/icons/LinkExternalIcon';
import detectMobile from '@/src/modules/mobile/utils/detectMobile';
import { FaviconImage } from '@/src/modules/ui/components/Favicon/FaviconImage';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { Fdoc } from '@/src/types/api';
import { cleanUrl } from '@/src/utils/text';
import React from 'react';
import styled from 'styled-components';

const StyledFaviconImage = styled(FaviconImage)`
  width: 22px;
  height: 22px;
`;

const Title = styled.h2`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1rem;
`;

const Container = styled.div`
  padding-left: 1rem;
  width: fit-content;
  max-width: 100%;
  min-width: 30px;
  height: 62px;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  cursor: pointer;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: ${cssVar['color-text-primary']};
`;

export const ResourceTitleUrl: React.FC<{
  resource: Fdoc;
  resourceUrl: string;
  title: string;
  className?: string;
}> = ({ resource, resourceUrl, title, className }) => {
  const showHoverLinkState = useBoolState(false);
  const [hoverPos, setHoverPos] = React.useState({ x: 0, y: 0 });
  const isMobile = Boolean(detectMobile(true));

  const onMouseMoveOverTitle = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isMobile) return;

    const { x, y } = e.nativeEvent;
    setHoverPos({ x, y });
    showHoverLinkState.handleTrue();
  };

  const gotoExternalLink = () => {
    if (!resourceUrl) return;
    window.open(resourceUrl, '_blank');

    showHoverLinkState.handleFalse();
  };

  return (
    <>
      {resource.isWebnote && !isMobile && (
        <HoverItem
          setShow={showHoverLinkState.handleTrue}
          show={showHoverLinkState.value}
          fdocId={resource.id}
          title={resource.data.pageTitle}
          url={resource.data.pageUrl}
          x={hoverPos.x}
          y={hoverPos.y}
        />
      )}
      <Container
        onClick={gotoExternalLink}
        onMouseEnter={showHoverLinkState.handleTrue}
        onMouseLeave={showHoverLinkState.handleFalse}
        onMouseMove={onMouseMoveOverTitle}
        className={className}
      >
        <TitleContainer>
          <StyledFaviconImage pageUrl={resourceUrl} sizeToLoad={32} />
          <Title data-or-obscured>{title}</Title>
          <LinkExternalIcon
            data-resource-title-link-icon
            style={{
              width: 12,
            }}
          />
        </TitleContainer>
        {/** @TODO FUT-3454 the badge_url should be a component.. */}
        <span className="badge_url">{cleanUrl(resourceUrl, { includeSearch: true })}</span>
      </Container>
    </>
  );
};
