import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface ResourcesUiState {
  hideTitle: boolean;
  hideTags: boolean;
  hideComment: boolean;
}

interface ResourcesUiStore extends ResourcesUiState {
  // @TODO currently unsued, transfer from the ui store
  zoomLevel: number;
  toggleTitle: VoidFunction;
  togglePinnedComment: VoidFunction;
  toggleTags: VoidFunction;
}

export type ResourceUiStoreContext = 'global' | 'search';

type SetFn = (state: ResourcesUiStore) => Partial<ResourcesUiStore>;

const baseInit = (set: (state: SetFn) => void): ResourcesUiStore => ({
  zoomLevel: 0.9,
  hideTitle: false,
  hideTags: false,
  hideComment: false,
  toggleTitle: () => set((state) => ({ hideTitle: !state.hideTitle })),
  togglePinnedComment: () => set((state) => ({ hideComment: !state.hideComment })),
  toggleTags: () => set((state) => ({ hideTags: !state.hideTags })),
});

/**
 * we don't want to flood the store with a lot of properties so choosing approach of having store per context
 *
 * context - configuration per UI context, e.g. search can have different configuration than rest (global)
 */
const useGlobalStore = create<ResourcesUiStore>()(
  persist(baseInit, {
    name: `resource-ui-store-global`,
    version: 0.1,
  }),
);

const useSearchStore = create<ResourcesUiStore>()(
  persist(baseInit, {
    name: `resource-ui-store-search`,
    version: 0.1,
  }),
);

export const useResourcesUiStore = (uiContext: ResourceUiStoreContext = 'global') => {
  const use = uiContext === 'global' ? useGlobalStore : useSearchStore;
  return use();
};
