import React, { useEffect } from 'react';
import styles from './SpaceClick.module.scss';
import { BodyContext } from '../ExpandedFdoc/ExpandedFdocContent';
import { getUserColorFromId } from '@/src/utils/color';
import Color from 'color';
import clsx from 'clsx';
import { SpaceClickEvent } from '@/src/multiplayer/spaces.config';

const SpaceClick: React.FC<{
  event: SpaceClickEvent;
  bodyContext: BodyContext;
  dismiss: () => void;
}> = ({ event, bodyContext, dismiss }) => {
  const x = event.position.x * (bodyContext.size?.width ?? 0) - 5;
  const y = event.position.y * (bodyContext.size?.height ?? 0) - 5;

  const color = getUserColorFromId(event.user.id);
  const alphaColor = new Color(color).alpha(0.2).toString();

  useEffect(() => {
    setTimeout(() => {
      dismiss();
    }, 1000);
  }, [dismiss]);

  return (
    <span
      className={clsx(styles.click, 'animate-[ping_1s_cubic-bezier(0,0,0.2,1)_forwards]')}
      style={{
        left: x,
        top: y,
        backgroundColor: alphaColor,
      }}
    />
  );
};

export default SpaceClick;
