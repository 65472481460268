import { useExpandedFdocContext } from '@/src/components/ExpandedFdoc/ExpandedFdocProvider';
import Skeleton from '@/src/components/Skeleton/Skeleton';
import Modal from '@/src/modules/ui/components/Modal';
import { cssVar } from '@/src/modules/ui/theme/variables';
import React from 'react';
import styled from 'styled-components';

// This is a temporary skeleton loader for expanded resource because the expanded fdoc modal is
// in imminent danger of being deprecated/refactored.

const AbsoluteClose = styled(Modal.Close)`
  position: absolute;
  top: 18px;
  right: 16px;
  z-index: 10;
`;

interface ContentSkeletonProps {
  onClose: () => void;
}

const ContentSkeleton = styled(Skeleton).attrs<ContentSkeletonProps>((p) => {
  return {
    ...p,
    children: <AbsoluteClose onPointerUp={p.onClose} />,
  };
})`
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 300px;
  margin-left: auto;
  margin-right: auto;
  background: ${cssVar['color-bg-tertiary']};
  border-radius: 20px;
  box-shadow:
    0px 4px 4px rgba(128, 128, 128, 0.04),
    0px 4px 25px rgba(128, 128, 128, 0.1);
`;

const LoadingSidebarUI = styled(Skeleton)`
  --shimmer-colour-rgb: 50, 50, 50;

  min-width: 360px;
  max-width: 360px;
  width: 100%;
  height: 100%;

  flex-shrink: 0;
  background: rgba(0, 0, 0, 0.8);

  border-radius: 20px;
`;

const SidebarSkeleton: React.FC = () => {
  const { sidebarOpen } = useExpandedFdocContext();

  if (!sidebarOpen) {
    return null;
  }

  return <LoadingSidebarUI />;
};

export { ContentSkeleton, SidebarSkeleton };
