import React, { useMemo } from 'react';
import styles from './SpaceCursor.module.scss';
import { CursorMode, DashboardPresence, DashboardUserMeta } from '@/src/types/presence';
import { User } from '@liveblocks/client';
import CursorNormal from '../Cursors/CursorNormal';
import { getTextColor, getUserColorFromId } from '@/src/utils/color';
import { BodyContext } from '../ExpandedFdoc/ExpandedFdocContent';

const SpaceCursor: React.FC<{
  user: User<DashboardPresence, DashboardUserMeta>;
  bodyContext: BodyContext;
}> = ({ user, bodyContext }) => {
  const x = useMemo(
    () => (user.presence.cursor?.position.x ?? 0) * (bodyContext.scrollWidth ?? 0),
    [bodyContext.scrollWidth, user.presence.cursor?.position.x]
  );

  const y = useMemo(
    () => (user.presence.cursor?.position.y ?? 0) * (bodyContext.scrollHeight ?? 0),
    [user.presence.cursor?.position.y, bodyContext.scrollHeight]
  );

  const color = getUserColorFromId(user.id);
  const textColor = getTextColor(color);

  if (!user.id) return null;
  return (
    <div
      className={styles.cursor_wrapper}
      style={{
        transform: `translate(${x}px, ${y}px)`,
      }}
    >
      <CursorNormal className={styles.cursor} color={color} />
      {user.presence.cursor?.state?.mode !== CursorMode.Chat ? (
        <span
          className={styles.cursor_name}
          style={{
            background: color,
            color: textColor,
          }}
        >
          {user.info.name}
        </span>
      ) : (
        <span className={styles.cursor_chat} style={{ background: color, color: textColor }}>
          {user.presence.cursor.state.message.length > 0
            ? user.presence.cursor.state.message
            : user.presence.cursor.state.previousMessage ?? '...'}
        </span>
      )}
    </div>
  );
};

export default SpaceCursor;
