import { Fdoc } from '@/src/types/api';
import {
  FabricQueryMode,
  FabricSearchQuerySemanticText,
  FabricSearchQuerySimilarImage,
  FabricSearchQuerySimilarText,
} from '@fabric/woody-client';

export const getSimilarTo = (
  target: Fdoc | string,
): FabricSearchQuerySemanticText | FabricSearchQuerySimilarImage | FabricSearchQuerySimilarText => {
  if (typeof target === 'string')
    return {
      mode: FabricQueryMode.Semantictext,
      text: target,
    };

  const isImage = target.type === 'stored_file' && target.data.contentType?.startsWith('image/');
  return {
    mode: isImage ? FabricQueryMode.SimilarImage : FabricQueryMode.SimilarText,
    item: target.id,
  };
};
