import { useSpaceLiveblocksStore } from '@/src/multiplayer/spaces.config';
import { CursorMode } from '@/src/types/presence';
import { getTextColor, getUserColorFromId } from '@/src/utils/color';
import isHotkey from 'is-hotkey';
import React, { useEffect } from 'react';
import { shallow } from 'zustand/shallow';
import { BodyContext, useExpandedFdocContentContext } from '../ExpandedFdoc/ExpandedFdocContent';
import SpaceClick from './SpaceClick';
import SpaceCursor from './SpaceCursor';
import styles from './SpaceCursors.module.scss';

const SpaceCursors: React.FC<{
  expandedFdocId: string | null;
  bodyContext: BodyContext;
}> = ({ expandedFdocId, bodyContext }) => {
  const { itemPreviewContentBodyRef } = useExpandedFdocContentContext();
  const [chatInputFocused, setChatInputFocused] = React.useState(false);
  const { others, clickEvents, setClickEvents, broadcastClickEvent, cursor, setCursor, userId } =
    useSpaceLiveblocksStore(
      (state) => ({
        userId: state.userId,
        cursor: state.cursor,
        setCursor: state.setCursor,
        others: state.liveblocks.others,
        clickEvents: state.clickEvents,
        setClickEvents: state.setClickEvents,
        broadcastClickEvent: state.broadcastClickEvent,
      }),
      shallow,
    );

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (
        !isHotkey('mod+/', {
          byKey: true,
        })(e) &&
        !isHotkey('mod+shift+/', {
          byKey: true,
        })(e)
      )
        return;
      if (cursor.state.mode === CursorMode.Chat && chatInputFocused) return;

      e.preventDefault();
      setCursor((cursor) => ({
        ...cursor,
        state: {
          mode: CursorMode.Chat,
          previousMessage: null,
          message: '',
        },
      }));
    };

    window.addEventListener('keydown', onKeyDown);

    return () => {
      window.removeEventListener('keydown', onKeyDown);
    };
  }, [chatInputFocused, cursor, setCursor]);

  useEffect(() => {
    const onMouseClick = (e: MouseEvent) => {
      if (!itemPreviewContentBodyRef) return;

      const rect = itemPreviewContentBodyRef.getBoundingClientRect();

      const normalizedX =
        (e.clientX - rect.left + itemPreviewContentBodyRef.scrollLeft) /
        itemPreviewContentBodyRef.scrollWidth;
      const normalizedY =
        (e.clientY - rect.top + itemPreviewContentBodyRef.scrollTop) /
        itemPreviewContentBodyRef.scrollHeight;

      if (!broadcastClickEvent || !expandedFdocId) return;
      broadcastClickEvent(expandedFdocId, {
        x: normalizedX,
        y: normalizedY,
      });
    };

    itemPreviewContentBodyRef?.addEventListener('click', onMouseClick);

    return () => {
      itemPreviewContentBodyRef?.removeEventListener('click', onMouseClick);
    };
  }, [broadcastClickEvent, expandedFdocId, itemPreviewContentBodyRef]);

  const color = getUserColorFromId(userId);
  const textColor = getTextColor(color);
  const cursorX = cursor.position.x * (bodyContext.scrollWidth ?? 0) + 30;
  const cursorY = cursor.position.y * (bodyContext.scrollHeight ?? 0) + 30;
  const chatCursorTimeout = React.useRef<number | undefined>(undefined);

  useEffect(() => {
    setCursor((cursor) => ({
      ...cursor,
      position: {
        x: 0,
        y: 0,
        pressure: null,
      },
      state: {
        mode: CursorMode.Normal,
        type: 'default',
      },
    }));
  }, [expandedFdocId, setCursor]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChatInputFocused(true);

    // limit to 50 characters
    if (e.target.value.length > 50) return;

    setCursor((cursor) => ({
      ...cursor,
      state: {
        ...cursor.state,
        message: e.target.value,
      },
    }));
  };

  const onInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (cursor.state.mode !== CursorMode.Chat) return;

    switch (e.key) {
      case 'Enter': {
        e.preventDefault();

        setCursor({
          ...cursor,
          state: {
            ...cursor.state,
            mode: CursorMode.Chat,
            previousMessage: cursor.state.message,
            message: '',
          },
        });
        break;
      }
      case 'Escape': {
        e.preventDefault();
        e.stopPropagation();

        setCursor({
          ...cursor,
          state: {
            mode: CursorMode.Normal,
            type: 'default',
          },
        });
        break;
      }
      default: {
        return;
      }
    }
  };

  const onInputBlur = (_e: React.FocusEvent<HTMLInputElement>) => {
    setChatInputFocused(false);
  };

  const onInputFocus = (_e: React.FocusEvent<HTMLInputElement>) => {
    setChatInputFocused(true);
  };

  useEffect(() => {
    if (chatInputFocused) return;

    clearTimeout(chatCursorTimeout.current);

    chatCursorTimeout.current = window.setTimeout(() => {
      setCursor({
        ...cursor,
        state: {
          mode: CursorMode.Normal,
          type: 'default',
        },
      });
    }, 5000);

    return () => {
      clearTimeout(chatCursorTimeout.current);
    };
  }, [chatInputFocused, cursor, setCursor]);

  // unfocus chat input after 10s of inactivity
  const inactivityTimeout = React.useRef<number | undefined>(undefined);
  const message = cursor.state.mode === CursorMode.Chat ? cursor.state.message : '';
  useEffect(() => {
    if (cursor.state.mode !== CursorMode.Chat) return;

    clearTimeout(inactivityTimeout.current);

    inactivityTimeout.current = window.setTimeout(() => {
      setCursor((cursor) => ({
        ...cursor,
        state: {
          mode: CursorMode.Normal,
          type: 'default',
        },
      }));
    }, 10000);

    return () => {
      clearTimeout(inactivityTimeout.current);
    };
  }, [cursor.state.mode, message, setCursor]);

  // unfocus and set the focused state to false when the window is blurred
  useEffect(() => {
    const onBlur = () => {
      setChatInputFocused(false);
    };

    window.addEventListener('blur', onBlur);

    return () => {
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  if (!expandedFdocId) return null;
  return (
    <div className={styles.main}>
      {cursor.state.mode === CursorMode.Chat && (
        <div
          className={styles.chat_wrapper}
          style={{
            transform: `translate(${cursorX}px, ${cursorY}px)`,
            background: color,
            color: textColor,
          }}
        >
          {cursor.state.previousMessage && (
            <div className={styles.previous_message}>{cursor.state.previousMessage}</div>
          )}
          <input
            autoFocus
            onKeyDown={onInputKeyDown}
            onChange={onInputChange}
            onBlur={onInputBlur}
            onFocus={onInputFocus}
            value={cursor.state.message}
          />
        </div>
      )}
      {others
        .filter((other) => other.presence.expandedFdocId === expandedFdocId)
        .map((user) => (
          <SpaceCursor user={user} key={user.id} bodyContext={bodyContext} />
        ))}

      {clickEvents.map((event, i) => (
        <SpaceClick
          event={event}
          key={i}
          bodyContext={bodyContext}
          dismiss={() => {
            setClickEvents((events) => events.filter((e) => e.id !== event.id));
          }}
        />
      ))}
    </div>
  );
};

export default SpaceCursors;
