import {
  upsertItemInArrayById,
  useOptimisticallyUpdateInfiniteQueriesWithPredicate,
  useOptimisticallyUpdateQueriesWithPredicate,
} from '@/src/lib/react-query/utilities';
import { Fdoc } from '@/src/types/api';
import { isTruthy } from '@/src/utils/guards';
import { useQueryClient } from '@tanstack/react-query';
import { resourceQueryPredicates } from '../../resources/queries/resourceQueryPredicates';
import { FilteredFdocs } from '../../resources/resources.types';
import { Comment } from '../comments.types';
import { commentQueryPredicates } from '../queries/commentQueryPredicates';
import { mutateResourcePinnedComment } from './mutateResourcePinnedComment';

type CommentWithOptionalProperties = Partial<Omit<Comment, 'id' | 'resourceId'>> &
  Pick<Comment, 'id' | 'resourceId'>;
type CommentMutator = (comment?: Comment) => Comment | undefined;

export const useQueryCacheCommentHelpers = () => {
  const queryClient = useQueryClient();

  const optimisticallyUpdateQueries = useOptimisticallyUpdateQueriesWithPredicate();
  const optimisticallyUpdateInfiniteQueries = useOptimisticallyUpdateInfiniteQueriesWithPredicate();

  return {
    updateCachedComment: (
      commentWithUpdatedProperties: CommentWithOptionalProperties,
      mutator?: CommentMutator,
    ) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          commentQueryPredicates.commentByResourceId(q, commentWithUpdatedProperties.resourceId) ||
          resourceQueryPredicates.resource(q, commentWithUpdatedProperties.resourceId) ||
          resourceQueryPredicates.filterAndSearchAll(q),
        type: 'active',
      });

      /**
       * updating the list of comments in the resource where the comment is present
       */
      const optimisticallyUpdateCommentQueries = optimisticallyUpdateQueries<Comment[]>(
        (q) =>
          commentQueryPredicates.commentByResourceId(q, commentWithUpdatedProperties.resourceId),
        (data) =>
          mutator
            ? data
                ?.map((comment) =>
                  comment.id === commentWithUpdatedProperties.id ? mutator(comment) : comment,
                )
                .filter(isTruthy)
            : upsertItemInArrayById(data ?? [], commentWithUpdatedProperties, {
                createIfNotFound: false,
              }),
      );

      /**
       * updating the resource directly (because of the pinned comment)
       */
      const optimisticallyUpdateResourceQueries = optimisticallyUpdateQueries<Fdoc>(
        (q) => resourceQueryPredicates.resource(q, commentWithUpdatedProperties.resourceId),
        (resource) =>
          (resource?.commentPinned &&
            mutateResourcePinnedComment(resource, {
              ...resource.commentPinned,
              ...commentWithUpdatedProperties,
              ...mutator?.(resource.commentPinned),
            })) ??
          resource,
      );

      /**
       * Update the list of resources where the resource is present to update the pinned comment
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
          predicate: resourceQueryPredicates.filterAndSearchAll,
          pageUpdater: (page) => ({
            ...page,
            results: page.results.map((resource) =>
              resource.id === commentWithUpdatedProperties.resourceId && resource.commentPinned
                ? mutateResourcePinnedComment(resource, {
                    ...resource.commentPinned,
                    ...commentWithUpdatedProperties,
                    ...mutator?.(resource.commentPinned),
                  })
                : resource,
            ),
          }),
        });

      return {
        optimisticallyUpdateCommentQueries,
        optimisticallyUpdateResourceQueries,
        optimisticallyUpdateFilterAndSearchAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateCommentQueries.resetQueriesData();
          optimisticallyUpdateResourceQueries.resetQueriesData();
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateCommentQueries.invalidateQueries();
          optimisticallyUpdateResourceQueries.invalidateQueries();
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
        },
      };
    },
    deleteCachedComment: (commentId: string, resourceId: string) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          commentQueryPredicates.commentByResourceId(q, resourceId) ||
          resourceQueryPredicates.resource(q, resourceId) ||
          resourceQueryPredicates.filterAndSearchAll(q),
        type: 'active',
      });

      /**
       * updating the list of comments in the resource where the comment is present
       */
      const optimisticallyUpdateCommentQueries = optimisticallyUpdateQueries<Comment[]>(
        (q) => commentQueryPredicates.commentByResourceId(q, resourceId),
        (data) => data?.filter((comment) => comment.id !== commentId),
      );

      /**
       * updating the resource directly (because of the pinned comment)
       */
      const optimisticallyUpdateResourceQueries = optimisticallyUpdateQueries<Fdoc>(
        (q) => resourceQueryPredicates.resource(q, resourceId),
        (resource) =>
          resource
            ? {
                ...resource,
                commentPinned:
                  resource.commentPinned?.id === commentId ? null : resource.commentPinned,
                commentCount: Math.max(0, (resource.commentCount ?? 0) - 1),
              }
            : resource,
      );

      /**
       * Update the list of resources where the resource is present to update the pinned comment
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
          predicate: resourceQueryPredicates.filterAndSearchAll,
          pageUpdater: (page) => ({
            ...page,
            results: page.results.map((resource) =>
              resource.id === resourceId
                ? {
                    ...resource,
                    commentPinned:
                      resource.commentPinned?.id === commentId ? null : resource.commentPinned,
                    commentCount: Math.max(0, (resource.commentCount ?? 0) - 1),
                  }
                : resource,
            ),
          }),
        });

      return {
        optimisticallyUpdateCommentQueries,
        optimisticallyUpdateResourceQueries,
        optimisticallyUpdateFilterAndSearchAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateCommentQueries.resetQueriesData();
          optimisticallyUpdateResourceQueries.resetQueriesData();
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateCommentQueries.invalidateQueries();
          optimisticallyUpdateResourceQueries.invalidateQueries();
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
        },
      };
    },
    addCachedComment: (comment: Comment) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          commentQueryPredicates.commentByResourceId(q, comment.resourceId) ||
          resourceQueryPredicates.resource(q, comment.resourceId) ||
          resourceQueryPredicates.filterAndSearchAll(q),
        type: 'active',
      });

      /**
       * updating the list of comments in the resource where the comment is present
       */
      const optimisticallyUpdateCommentQueries = optimisticallyUpdateQueries<Comment[]>(
        (q) => commentQueryPredicates.commentByResourceId(q, comment.resourceId),
        (data) => upsertItemInArrayById(data ?? [], comment, { createIfNotFound: true }),
      );

      /**
       * updating the resource directly (because of the pinned comment)
       */
      const optimisticallyUpdateResourceQueries = optimisticallyUpdateQueries<Fdoc>(
        (q) => resourceQueryPredicates.resource(q, comment.resourceId),
        (resource) =>
          resource
            ? {
                ...resource,
                ...mutateResourcePinnedComment(resource, comment),
                commentCount: (resource.commentCount ?? 0) + 1,
              }
            : resource,
      );

      /**
       * Update the list of resources where the resource is present to update the pinned comment
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
          predicate: resourceQueryPredicates.filterAndSearchAll,
          pageUpdater: (page) => ({
            ...page,
            results: page.results.map((resource) =>
              resource.id === comment.resourceId
                ? {
                    ...resource,
                    ...mutateResourcePinnedComment(resource, comment),
                    commentCount: (resource.commentCount ?? 0) + 1,
                  }
                : resource,
            ),
          }),
        });

      return {
        optimisticallyUpdateCommentQueries,
        optimisticallyUpdateResourceQueries,
        optimisticallyUpdateFilterAndSearchAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateCommentQueries.resetQueriesData();
          optimisticallyUpdateResourceQueries.resetQueriesData();
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateCommentQueries.invalidateQueries();
          optimisticallyUpdateResourceQueries.invalidateQueries();
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
        },
      };
    },
    replaceCommentInCache: (oldComment: Comment, newComment: Comment) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          commentQueryPredicates.commentByResourceId(q, oldComment.resourceId) ||
          resourceQueryPredicates.resource(q, oldComment.resourceId) ||
          resourceQueryPredicates.filterAndSearchAll(q),
        type: 'active',
      });

      /**
       * updating the list of comments in the resource where the comment is present
       */
      const optimisticallyUpdateCommentQueries = optimisticallyUpdateQueries<Comment[]>(
        (q) => commentQueryPredicates.commentByResourceId(q, oldComment.resourceId),
        (data) =>
          (data ?? []).map((comment) => (comment.id === oldComment.id ? newComment : comment)),
      );

      /**
       * updating the resource directly (because of the pinned comment)
       */
      const optimisticallyUpdateResourceQueries = optimisticallyUpdateQueries<Fdoc>(
        (q) => resourceQueryPredicates.resource(q, oldComment.resourceId),
        (resource) =>
          (resource?.commentPinned && mutateResourcePinnedComment(resource, newComment)) ??
          resource,
      );

      /**
       * Update the list of resources where the resource is present to update the pinned comment
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
          predicate: resourceQueryPredicates.filterAndSearchAll,
          pageUpdater: (page) => ({
            ...page,
            results: page.results.map((resource) =>
              resource.id === oldComment.resourceId && resource.commentPinned
                ? mutateResourcePinnedComment(resource, newComment)
                : resource,
            ),
          }),
        });

      return {
        optimisticallyUpdateCommentQueries,
        optimisticallyUpdateResourceQueries,
        optimisticallyUpdateFilterAndSearchAllQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateCommentQueries.resetQueriesData();
          optimisticallyUpdateResourceQueries.resetQueriesData();
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateCommentQueries.invalidateQueries();
          optimisticallyUpdateResourceQueries.invalidateQueries();
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
        },
      };
    },
  };
};
