import { Extension } from '@tiptap/core';
import { v4 } from 'uuid';

interface NodeInfoOptions {
  types: string[];
}

const NodeInfo = Extension.create<NodeInfoOptions>({
  name: 'nodeInfo',

  addOptions() {
    return {
      types: [],
    };
  },

  // this adds:
  // - data-uuid v4 to all nodes
  // - data-created-at iso string
  addGlobalAttributes() {
    return [
      {
        types: this.options.types,
        attributes: {
          'data-uuid': {
            default: null,
            isRequired: true,
            keepOnSplit: false,
          },
          'data-created-at': {
            default: null,
            isRequired: true,
            keepOnSplit: false,
          },
        },
      },
    ];
  },

  onTransaction({ transaction }) {
    if (!transaction.docChanged) return;

    const types = this.options.types;

    const changes: number[] = [];

    transaction.doc.descendants((node, pos) => {
      if (types.includes(node.type.name) && this.options && !node.attrs['data-uuid']) {
        changes.push(pos);
      }
    });

    if (changes.length) {
      const view = this.editor.view;
      const { state } = view;
      const newTransaction = state.tr;

      changes.forEach((pos) => {
        newTransaction.setMeta('$nodeInfo', true);
        newTransaction.setNodeAttribute(pos, 'data-uuid', v4());
        newTransaction.setNodeAttribute(pos, 'data-created-at', new Date().toISOString());
      });

      view.dispatch(newTransaction);
    }
  },

  onCreate() {
    // this will fix a previous bug where all nodes had the same uuid
    // so we will loop through all nodes and update any duplicates
    const types = this.options.types;
    const view = this.editor.view;
    const uuids: string[] = [];

    if (!view || !this.editor.isEditable) return;

    const { state } = view;

    let transaction = state.tr;
    let hasChanges = false;

    state.doc.descendants((node, pos) => {
      if (types.includes(node.type.name) && node.attrs['data-uuid']) {
        const uuid = node.attrs['data-uuid'];

        if (uuids.includes(uuid)) {
          hasChanges = true;
          transaction = transaction.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            'data-uuid': v4(),
          });
        } else {
          uuids.push(uuid);
        }
      }
    });

    if (hasChanges) {
      transaction.setMeta('$nodeInfo', true);
      view.dispatch(transaction);
    }
  },
});

export default NodeInfo;
