import { ErrorMessages } from '@/src/core/config/errors';
import { useAuthUser } from '@/src/hooks/auth';
import { useResponsive } from '@/src/hooks/responsive';
import { useShowExpandedFdoc } from '@/src/hooks/useExpandedFdoc';
import { RecapForDateSortField } from '@/src/modules/recap/recap.types';
import { upgradePrompt } from '@/src/store/alerts';
import useUIStore from '@/src/store/ui';
import { isSubscribedPlan } from '@/src/types/pricing';
import { getErrorType } from '@/src/utils/error';
import dayjs from 'dayjs';
import Link from 'next/link';
import { useState } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import { Button } from '../../ui/components/Button';
import { Flex } from '../../ui/components/Flex';
import Modal from '../../ui/components/Modal';
import ScrollArea from '../../ui/components/ScrollArea';
import { Spinner } from '../../ui/components/Spinner';
import { A, LI, P, SPAN, TypographyContainer, UL } from '../../ui/components/Typography';
import { mediaMobile } from '../../ui/styled-utils';
import { cssVar } from '../../ui/theme/variables';
import { useQueryRecapForDate } from '../queries/useQueryRecapForDate';

type RecapSummaryModalProps = {
  date: string;
  frequency?: 'daily';
  field: RecapForDateSortField;
  children: React.ReactNode;
};

const StyledBody = styled(Modal.Body)`
  padding: 50px;
  min-height: 100%;

  ${mediaMobile} {
    padding: 16px;
    color: ${cssVar['color-text-tertiary']};
  }
`;

const SetupButton = styled(Button).attrs({
  variant: 'bg-primary-reverse',
  size: 'xs',
})`
  white-space: nowrap;
  font-weight: 500;
`;

const RecapSummaryModal: React.FC<RecapSummaryModalProps> = ({
  date: dateProp,
  children,
  field,
}) => {
  const { isMobileView } = useResponsive();
  const [open, setOpen] = useState(false);
  const user = useAuthUser();

  const isPayingUser = isSubscribedPlan(user?.subscription.tier);

  const date = dayjs(dateProp).format('dddd, MMMM Do');
  const { data, isLoading, error } = useQueryRecapForDate(dateProp, 'daily', field, {
    enabled: open,
  });

  const errorType = getErrorType(error);

  const expandedFdocId = useUIStore((state) => state.expandedFdocId, shallow);
  const showExpandedFdoc = useShowExpandedFdoc();

  const onOpenChange = (isOpen: boolean) => {
    if (expandedFdocId && !isOpen) {
      return;
    }

    setOpen(isOpen);
  };

  const onSetupButtonClick = (e: React.MouseEvent) => {
    if (!isPayingUser) {
      e.preventDefault();
      upgradePrompt({
        id: 'recap-summary-upgrade',
        title: 'Upgrade to access AI Recaps',
      });
    }
  };

  return (
    <Modal open={open} onOpenChange={onOpenChange}>
      <Modal.Trigger asChild>{children}</Modal.Trigger>
      <Modal.Portal>
        <Modal.Overlay />
        <Modal.Content
          onInteractOutside={(e) => expandedFdocId && e.preventDefault()}
          gap="none"
          width="lg"
          dynamicHeight={false}
        >
          <Modal.BaseHeader padding="sm" mobileShadowOverContent>
            <Modal.Title size="sm">
              {isMobileView ? `${date} summary` : `Your summary for ${date}`}
            </Modal.Title>
            <Modal.Close />
          </Modal.BaseHeader>
          <ScrollArea grow expandVertically>
            <StyledBody>
              {isLoading && (
                <Flex direction="column" alignItems="center" justifyContent="center" flexGrow>
                  <Spinner size={32} thickness={4} />
                </Flex>
              )}

              {data?.resources && !isLoading && (
                <TypographyContainer gap="elementsContainer">
                  <P color="primary" size="md">
                    {data?.overall_summary}
                  </P>
                  <P color="primary" size="md" weight={700}>
                    Top highlights:
                  </P>
                  <UL>
                    {data?.resources?.map((item) => (
                      <LI key={item.resourceId}>
                        {item.title && (
                          <>
                            <SPAN weight={700} color="primary">
                              <A
                                onClick={() => {
                                  showExpandedFdoc(item.resourceId);
                                }}
                              >
                                {item.title}
                              </A>
                            </SPAN>
                            {' - '}
                          </>
                        )}
                        <SPAN color="primary">{item.summary}</SPAN>
                      </LI>
                    ))}
                  </UL>
                </TypographyContainer>
              )}

              {(error || !data?.resources) && !isLoading && (
                <P color="tertiary" size="md">
                  {errorType
                    ? ErrorMessages[errorType]
                    : 'Unable to load the summary. Please try again later.'}
                </P>
              )}
            </StyledBody>
          </ScrollArea>
          <Modal.Footer variant="call-to-action">
            <P size="sm" color="primary" weight={500}>
              {isMobileView
                ? 'Get email summaries of the notes and content you’ve saved.'
                : 'Get daily, weekly, or monthly email summaries of the notes and content you’ve saved.'}
            </P>
            <SetupButton onClick={onSetupButtonClick} as={Link} href="/settings/recap">
              Set up Recaps
            </SetupButton>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal>
  );
};

export default RecapSummaryModal;
