import LabelSelector from '@/src/components/FdocItem/FdocItemContextMenu/LabelSelector';
import useAuthStore from '@/src/hooks/auth';
import useOpenInTabs from '@/src/hooks/useOpenInTabs';
import { ApiColorLabel } from '@/src/modules/labels/labels.types';
import { useMutationChangeResourceLabel } from '@/src/modules/resource-detail/mutations/useMutationChangeResourceLabel';
import { Space } from '@/src/modules/spaces/spaces.types';
import { ContextMenu } from '@/src/modules/ui/components/ContextMenu/ContextMenu';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { shallow } from 'zustand/shallow';

export const ResourceListContextMenuSelectedItemsContent: React.FC<{
  onAddSelectionToSpaceClick: (e?: React.MouseEvent) => void;
  onOpenSelectionAsTabsClick: (e?: React.MouseEvent) => void;
  onAskAssistantSelectionClick: (e?: React.MouseEvent) => void;
  onDeleteSelectionClick: (e?: React.MouseEvent) => void;
  onMultiTagEditClick: (e?: React.MouseEvent) => void;
  onCreateSubfolderFromSelection?: (fdocs: OptimisticDraft<Fdoc>[]) => void;
  canAddToSpace?: boolean;
  colorLabels?: ApiColorLabel[];
  list?: Space;
  fdocs?: Fdoc[];
}> = ({
  colorLabels,
  onDeleteSelectionClick,
  list,
  onCreateSubfolderFromSelection,
  onMultiTagEditClick,
  fdocs,
  canAddToSpace,
  onAddSelectionToSpaceClick,
  onOpenSelectionAsTabsClick,
  onAskAssistantSelectionClick,
}) => {
  const mutationChangeResourceLabel = useMutationChangeResourceLabel();
  const clearLabels = async () => {
    if (!fdocs || !list || fdocs?.length < 1) return;

    fdocs.forEach((fdoc) => {
      mutationChangeResourceLabel.mutate({
        resourceId: fdoc.id,
        spaceId: list.id,
        labelId: null,
      });
    });
  };

  const user = useAuthStore((state) => state.user, shallow);
  const { fabricExtensionInstalled } = useOpenInTabs();
  return (
    <>
      {onCreateSubfolderFromSelection && fdocs && (
        <ContextMenu.Item
          onClick={() => onCreateSubfolderFromSelection(fdocs)}
          data-testid="create-sub-folder-and-move-context-menu"
        >
          New folder from selection
        </ContextMenu.Item>
      )}
      <ContextMenu.Item onClick={onMultiTagEditClick} data-testid="edit-tags-context-menu">
        Add/edit tags
      </ContextMenu.Item>

      <ContextMenu.Group borderBottom>
        <ContextMenu.Item
          data-testid="context-menu-ask-about-selection"
          onClick={onAskAssistantSelectionClick}
        >
          Ask about selection...
        </ContextMenu.Item>
      </ContextMenu.Group>

      {canAddToSpace && (
        <ContextMenu.Item
          onClick={onAddSelectionToSpaceClick}
          data-testid="add-selection-to-space-context-menu"
        >
          Move...
        </ContextMenu.Item>
      )}
      {fabricExtensionInstalled && (
        <ContextMenu.Item
          onClick={onOpenSelectionAsTabsClick}
          data-testid="open-selection-as-tabs-context-menu"
        >
          Open selection as tabs
        </ContextMenu.Item>
      )}
      {user?.id && colorLabels && list && fdocs && (fdocs?.length ?? 0) > 0 && (
        <ContextMenu.ItemRaw>
          <LabelSelector space={list} fdocs={fdocs} colorLabels={colorLabels} />
          {fdocs.find((fdoc) => !!fdoc.label) && (
            <ContextMenu.Item onClick={clearLabels} data-testid="clear-labels-context-menu">
              Clear labels
            </ContextMenu.Item>
          )}
        </ContextMenu.ItemRaw>
      )}

      {onDeleteSelectionClick && (
        <ContextMenu.Item
          onClick={onDeleteSelectionClick}
          data-testid="delete-selection-context-menu"
          variant="danger"
        >
          Delete selection
        </ContextMenu.Item>
      )}
    </>
  );
};
