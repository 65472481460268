import { IconBookmark } from '@/src/modules/icons';
import { Image } from '@/src/modules/ui/components/Image';
import { cssVar } from '@/src/modules/ui/theme/variables';
import { ImageFdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import styled from 'styled-components';

interface ResourcePreviewImageProps {
  resource: OptimisticDraft<ImageFdoc>;
}

export const ResourcePreviewListItemImage: React.FC<ResourcePreviewImageProps> = ({ resource }) => {
  return resource.data.domImageUrl ? (
    <ThumbnailImage
      src={resource.data.domImageUrl}
      alt={resource.data.pageTitle}
      draggable={false}
    />
  ) : (
    <IconBookmark style={{ width: '1rem', height: '1rem' }} />
  );
};

/** thumbnail or empty state */

const ThumbnailImage = styled(Image)`
  width: 100%;
  flex-grow: 1;
  object-fit: cover;
  display: block;
  border-radius: 0.25rem;
  background: ${cssVar['color-bg-section']};
`;
