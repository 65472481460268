import clsx from 'clsx';
import React from 'react';

const ShortcutKey: React.FC<{
  keybind: string;
  shadow?: boolean;
  compact?: boolean;
}> = ({ keybind, shadow = false, compact = false }) => {
  return (
    <span
      className={clsx(
        !compact
          ? 'font-semibold text-sm inline-flex justify-center items-center p-[2px] w-[19px] h-[18px] text-black bg-white rounded'
          : 'text-gray-400',
        shadow && 'shadow-md'
      )}
    >
      {keybind}
    </span>
  );
};

export default ShortcutKey;
