import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { isResourceStateProcessing } from '@/src/modules/resources/utils/isResourceStateProcessing';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { Fdoc, convertWoodyResourceToFdoc } from '@/src/types/api';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import { resourceQueryKeys } from './resourceQueryKeys';

const getRefetchInterval = (data: Fdoc | undefined) => {
  if (!data) {
    return 45000;
  }

  if (
    // If it's a notepad it has more potential to change
    // note: note sure if it's relevant with yjs?
    data.type === 'notepad'
  )
    return 15000;

  if (
    isResourceStateProcessing(data.stateProcessing) ||
    (data.type === 'page' && isResourceStateProcessing(data.data.statePreview))
  ) {
    return 5000;
  }

  return 45000;
};

/**
 * Fetches a resource directly by its ID.
 *
 * @param resourceId
 * @param queryOptions
 * @returns
 */
export const useQueryResource = (
  resourceId?: string,
  queryOptions?: Partial<QueryObserverOptions<Fdoc>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: resourceQueryKeys.resource(resourceId),
    queryFn: async (params) => {
      const safeResourceId = params.queryKey[1] as string;
      return convertWoodyResourceToFdoc(
        getWoodyResponseData(await client.getResource(safeResourceId)),
      );
    },
    placeholderData: keepPreviousData,
    refetchInterval: (query) => {
      return getRefetchInterval(query.state.data);
    },
    ...queryOptions,
    enabled: isQueryEnabled([!!resourceId, queryOptions?.enabled]),
  });

  return {
    ...query,
    resource: query.data,
  };
};
