import { Fdoc } from '@/src/types/api';
import clsx from 'clsx';
import Highlighter from 'react-highlight-words';
import { OptimisticDraft } from '../../../types/draftable';
import stringToRegExp from '../../../utils/stringToRegExp';
import styles from './FdocItemContent.module.scss';

type FdocItemContentTextProps = {
  fdoc: OptimisticDraft<Fdoc>;
  query: string;
  isMobileCard?: boolean;
};

function FdocItemContentText({ fdoc, query, isMobileCard }: FdocItemContentTextProps) {
  if (fdoc.type !== 'text') return null;

  return (
    <div
      className={clsx(
        styles.item__content__text,
        isMobileCard && styles.item__content__text__mobile,
        'dashboard_scrollbar',
      )}
    >
      <Highlighter
        highlightTag="span"
        highlightClassName="highlight"
        textToHighlight={fdoc.data.text}
        searchWords={[stringToRegExp(query)]}
      />
    </div>
  );
}

export default FdocItemContentText;
