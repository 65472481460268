import useDeferred from '@/src/hooks/useDeferred';
import clsx from 'clsx';
import { useMemo } from 'react';
import RenderIfVisible from 'react-render-if-visible';
import Skeleton from '../../Skeleton/Skeleton';
import PagePlaceholder from './PagePlaceholder';
import { usePDFContent } from './PDF';
import styles from './TinyPage.module.scss';

const TinyPage: React.FC<{
  pageNumber: number;
  width: number;
  style?: React.CSSProperties;
  root: HTMLElement;
  onClick?: () => void;
  isFullscreen?: boolean;
}> = ({ pageNumber, width, style, root, onClick = () => {} }) => {
  const { getEstimatedHeight, currentPage } = usePDFContent();

  const active = useMemo(() => pageNumber === currentPage, [pageNumber, currentPage]);

  const height = useMemo(
    () => getEstimatedHeight(pageNumber, width, true),
    [getEstimatedHeight, pageNumber, width]
  );

  const deferredRoot = useDeferred(root);

  return (
    <div
      aria-current={active}
      aria-label={`Page ${pageNumber}`}
      role="button"
      data-page={pageNumber}
      className={clsx(active && styles.active)}
    >
      <div
        className={styles.wrapper}
        style={{
          ...style,
        }}
      >
        {root === deferredRoot ? (
          <RenderIfVisible
            key={pageNumber}
            defaultHeight={height}
            visibleOffset={height * 10}
            root={deferredRoot}
            initialVisible={pageNumber < 8}
            placeholderElement="div"
            // Make it so 3 pages after and before the currentPage are always rendered
            stayRendered={pageNumber > currentPage - 3 && pageNumber < currentPage + 3}
          >
            <div
              className={clsx(styles.page, active && styles.active)}
              onClick={onClick}
              style={{
                width,
                height,
              }}
            >
              <PagePlaceholder pageNumber={pageNumber} smallSpinner size="small" />
            </div>
          </RenderIfVisible>
        ) : (
          <div style={{ border: '4px solid transparent', width, height }}>
            <Skeleton
              borderRadius={10}
              style={{ width: '100%', height: '100%', display: 'flex' }}
            />
          </div>
        )}
        <span>{pageNumber}</span>
      </div>
    </div>
  );
};

export default TinyPage;
