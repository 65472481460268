import React from 'react';

/**
 * attach the return param to the input onBlur event handler to keep the focus on the input
 * @param disabled
 * @returns
 */
export const useRefocusOnBlur = <T extends HTMLElement>(disabled: boolean = false) => {
  return React.useMemo(() => {
    return disabled
      ? undefined
      : (e: React.FocusEvent<T>) => {
          e.preventDefault();
          setTimeout(() => {
            e.target.focus();
          }, 0);
        };
  }, [disabled]);
};
