import { EmbedElementProps } from '../../expandedResource.types';
import { convertUrlToSpotifyEmbedUrl } from '../../expandedResource.utils';

export const SpotifyEmbed: React.FC<EmbedElementProps> = ({ url, children }) => {
  const embedUrl = convertUrlToSpotifyEmbedUrl(url);

  if (!embedUrl) {
    return children;
  }

  return (
    <iframe
      style={{
        borderRadius: 12,
      }}
      src={embedUrl}
      width="100%"
      height="352"
      frameBorder="0"
      allowFullScreen
      allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
      loading="lazy"
    />
  );
};
