import ExpandedFdocSidebarAssistant from '@/src/components/ExpandedFdoc/ExpandedFdoc__Assistant';
import { ExpandedFdocSidebar_SimilarContent } from '@/src/components/ExpandedFdoc/ExpandedFdocSidebar_SimilarContent';
import { useResponsive } from '@/src/hooks/responsive';
import useDelayedTransition from '@/src/hooks/useDelayedTransition';
import SimilarIcon from '@/src/icons/NewSimilarIcon';
import { pick } from '@/src/lib/store';
import { Comment } from '@/src/modules/comments/comments.types';
import { useQueryComments } from '@/src/modules/comments/queries/useQueryComments';
import AssistantIcon from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Assistant/assets/assistant-tab-item-icon.svg';
import InfoIcon from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Info/assets/info-tab-item-icon.svg';
import { ExtraPanelInfo } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Info/ExtraPanelInfo';
import * as Sidebar from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/Sidebar';
import { ExtraPanelTab } from '@/src/modules/resource-detail/components/ExpandedResource/ExtraPanel/types';
import * as Tabs from '@/src/modules/ui/components/Tabs';
import { ForcedTheme } from '@/src/modules/ui/theme/ForcedTheme';
import { cssVar } from '@/src/modules/ui/theme/variables';
import useUIStore from '@/src/store/ui';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { forwardRef, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import styles from './ExpandedFdoc.module.scss';
import ExpandedFdocCommentsTab from './ExpandedFdocCommentsTab';
import { useExpandedFdocContext } from './ExpandedFdocProvider';

const Badge = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.188rem 0.313rem;
  min-width: 25px;
  height: 21px;
  border-radius: 8px;

  font-family: Inter, sans-serif;
  font-size: 11.5px;
  font-weight: 500;
  letter-spacing: -0.26px;

  background: rgba(${cssVar['color-bg-contrast-rgb']}, 0.3);
  color: ${cssVar['color-text-primary']};
`;

interface Props {
  fdoc: OptimisticDraft<Fdoc>;
  onClose: () => void;

  showCommentsMobile: boolean;
  toggleComments: () => void;
  hideSimilar?: boolean;

  isFullscreen: boolean;

  splitContentElement: HTMLDivElement | null;

  disableGestures?: boolean;
}

const ExpandedFdocSidebar = forwardRef<HTMLDivElement, Props>(
  ({ fdoc, showCommentsMobile, isFullscreen, splitContentElement, hideSimilar = false }, ref) => {
    const [editingComment, setEditingComment] = useState<OptimisticDraft<Comment> | null>(null);
    /**
     * We use this to prevent the comment from getting immediately auto focused when opening a resource.
     * That way it can be auto focused whenever the tab is switched instead.
     */
    const canAutoFocus = useDelayedTransition(false, true, 100);

    const { isDesktopView } = useResponsive();
    const {
      selectedExtraPanel: selectedSidePanel,
      setSelectedExtraPanel: setSelectedSidePanel,
      sidebarOpen,
      setSidebarOpen,
    } = useExpandedFdocContext();

    // Desktop/Web only state, mobile resolutions use the showCommentsMobile prop, because it doesn't get saved in the store
    const {
      expandedFdocSidebarOpen: storedExpandedFdocSidebarOpen,
      setExpandedFdocSidebarOpen: setStoredExpandedFdocSidebarOpen,
      expandedFdocSidebarInitTab,
    } = useUIStore(
      (s) =>
        pick(s, [
          'expandedFdocSidebarInitTab',
          'expandedFdocSidebarOpen',
          'setExpandedFdocSidebarOpen',
        ]),
      shallow,
    );

    const expandedFdocSidebarOpen = useMemo(
      () => sidebarOpen ?? storedExpandedFdocSidebarOpen,
      [sidebarOpen, storedExpandedFdocSidebarOpen],
    );
    const setExpandedFdocSidebarOpen = useMemo(
      () => setSidebarOpen ?? setStoredExpandedFdocSidebarOpen,
      [setSidebarOpen, setStoredExpandedFdocSidebarOpen],
    );

    /* Set the selected side panel to "comments" when the user opens the drawer 
        without selecting one */
    useEffect(() => {
      if (selectedSidePanel) return;
      if (showCommentsMobile || expandedFdocSidebarOpen) setSelectedSidePanel('comments');
    }, [showCommentsMobile, setSelectedSidePanel, selectedSidePanel, expandedFdocSidebarOpen]);

    const { comments } = useQueryComments(fdoc.id, {
      enabled: !fdoc.isDraft,
    });

    const onClickToggleSidebarDesktop = (newSelectedSidePanel: ExtraPanelTab) => {
      if (!isDesktopView || fdoc.isDraft) return;
      setSelectedSidePanel(newSelectedSidePanel);
      setExpandedFdocSidebarOpen(!expandedFdocSidebarOpen);
    };

    const onCancelEditingComment = () => {
      setEditingComment(null);
    };

    const animateIn = expandedFdocSidebarInitTab !== selectedSidePanel;

    const element = (
      <motion.div
        className={clsx(
          styles.item_preview__sidebar,
          showCommentsMobile && styles.active,
          (!expandedFdocSidebarOpen || fdoc.isDraft) && styles.desktop_sidebar_closed,
          fdoc.isDraft && styles.disabled,
          isFullscreen && isDesktopView && styles.fullscreen,
          selectedSidePanel === 'similar' && styles.similar_selected,
        )}
        data-testid="expanded-fdoc-sidebar"
        ref={ref}
      >
        <Tabs.Root
          value={selectedSidePanel ?? 'comments'}
          onValueChange={(value) => setSelectedSidePanel(value as ExtraPanelTab)}
          asChild
        >
          <Sidebar.Content>
            <ForcedTheme asChild colorScheme="dark" disabled={isFullscreen}>
              <div className={styles.item_preview__sidebar__header__title__container}>
                <h3
                  className={clsx(
                    styles.item_preview__sidebar__header__title,
                    isFullscreen && styles.fullscreen,
                  )}
                  onClick={() => onClickToggleSidebarDesktop('comments')}
                  style={{ width: '129px', padding: '13px 11px 13px 15px' }}
                >
                  Comments <Badge>{comments.length}</Badge>
                </h3>

                {!hideSimilar && (
                  <>
                    <h3
                      className={clsx(
                        styles.item_preview__sidebar__header__title,
                        styles.similar_button,
                        isFullscreen && styles.fullscreen,
                      )}
                      onClick={() => onClickToggleSidebarDesktop('similar')}
                      style={{ width: '87px' }}
                      tabIndex={7}
                    >
                      <span>
                        <SimilarIcon size={12} />
                      </span>
                      Similar
                    </h3>
                    <h3
                      className={clsx(
                        styles.item_preview__sidebar__header__title,
                        styles.similar_button,
                        isFullscreen && styles.fullscreen,
                      )}
                      onClick={() => onClickToggleSidebarDesktop('assistant')}
                      style={{ width: '87px' }}
                      tabIndex={7}
                    >
                      <span>
                        <AssistantIcon style={{ width: 20, height: 20 }} />
                      </span>
                      Ask
                    </h3>

                    <h3
                      className={clsx(
                        styles.item_preview__sidebar__header__title,
                        styles.similar_button,
                        isFullscreen && styles.fullscreen,
                      )}
                      onClick={() => onClickToggleSidebarDesktop('info')}
                      style={{ width: '87px' }}
                      tabIndex={7}
                    >
                      <span>
                        <InfoIcon style={{ width: 16, height: 16 }} />
                      </span>
                    </h3>
                  </>
                )}
              </div>
            </ForcedTheme>

            <ForcedTheme asChild colorScheme="dark" disabled={isFullscreen}>
              <Sidebar.Header>
                <Sidebar.CloseButton onClick={() => setExpandedFdocSidebarOpen(false)} />

                <Tabs.List variant="flush">
                  <Tabs.Trigger variant="apparent" value="comments" disableArrowNavigation>
                    Comments{' '}
                    <Badge
                      style={{
                        marginRight: -4,
                      }}
                    >
                      {comments.length}
                    </Badge>
                  </Tabs.Trigger>
                  <Tabs.Trigger variant="apparent" value="similar" disableArrowNavigation>
                    <SimilarIcon size={12} />
                    Similar
                  </Tabs.Trigger>
                  <Tabs.Trigger variant="apparent" value="assistant" disableArrowNavigation>
                    <AssistantIcon style={{ width: 20, height: 20 }} />
                    Ask
                  </Tabs.Trigger>
                  <Tabs.Trigger variant="apparent" value="info" square disableArrowNavigation>
                    <InfoIcon style={{ width: 16, height: 16 }} />
                  </Tabs.Trigger>
                </Tabs.List>
              </Sidebar.Header>
            </ForcedTheme>

            <Tabs.Content value="comments" asChild>
              <ExpandedFdocCommentsTab
                comments={comments}
                fdoc={fdoc}
                isFullscreen={isFullscreen}
                onStartEditingComment={setEditingComment}
                editingComment={editingComment}
                onCancelEditingComment={onCancelEditingComment}
                resourceId={fdoc.id}
                animateIn={animateIn}
                canAutoFocus={canAutoFocus}
              />
            </Tabs.Content>

            <Tabs.Content value="similar" asChild>
              <ExpandedFdocSidebar_SimilarContent
                resource={fdoc}
                hideSimilar={hideSimilar}
                isFullscreen={isFullscreen}
              />
            </Tabs.Content>

            <Tabs.Content value="assistant" asChild>
              <ExpandedFdocSidebarAssistant
                resource={fdoc}
                isFullscreen={isFullscreen}
                canAutoFocus={canAutoFocus}
              />
            </Tabs.Content>

            {selectedSidePanel === 'info' && <ExtraPanelInfo resource={fdoc} />}
          </Sidebar.Content>
        </Tabs.Root>
      </motion.div>
    );

    return isFullscreen && isDesktopView && splitContentElement
      ? createPortal(element, splitContentElement)
      : element;
  },
);

ExpandedFdocSidebar.displayName = 'ExpandedFdocSidebar';

export default ExpandedFdocSidebar;
