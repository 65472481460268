import { renderCssBasedOnColorScheme } from '@/src/modules/ui/theme/renderCssBasedOnColorScheme';
import Tooltip from '@/src/ui/Tooltip';
import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { getLightenedColor } from '../../../../utils/color';
import { Image } from '../../../ui/components/Image';
import { Spinner } from '../../../ui/components/Spinner';
import { cssVar } from '../../../ui/theme/variables';

interface AvatarContainerProps {
  size?: number;
  color?: string;
}

const AvatarContainer = styled.span<AvatarContainerProps>`
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  position: relative;
  overflow: hidden;

  flex-shrink: 0;

  ${({ size = 17 }) =>
    size &&
    css`
      width: ${size}px;
      height: ${size}px;
      font-size: ${size}px; // set to the same, and the text will use 0.54em

      ${size < 32 &&
      css`
        ${AvatarSpinner} {
          display: none;
        }
      `}
    `}

  ${({ color = cssVar['color-bg-tertiary'] }) =>
    color &&
    renderCssBasedOnColorScheme({
      light: css`
        color: ${color};
        background-color: ${getLightenedColor(color, 0.8)};
      `,
      dark: css`
        color: rgba(0, 0, 0, 0.5);
        background-color: ${getLightenedColor(color, 0.6)};
      `,
    })}
`;

const AvatarImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const AvatarText = styled.div`
  text-transform: uppercase;
  font-size: 0.6em;
  font-weight: 500;
  color: inherit;
`;

const AvatarSpinner = styled(Spinner).attrs({ size: 16, thickness: 3 })``;

interface AvatarProps extends React.ComponentProps<typeof AvatarContainer> {
  tooltip?: React.ReactNode;
  allowTooltipHover?: boolean;
}

const Avatar = forwardRef<HTMLSpanElement, AvatarProps>(
  ({ tooltip, children, allowTooltipHover = false, ...props }, ref) => {
    if (tooltip) {
      return (
        <Tooltip label={tooltip} delay={0} allowTooltipHover={allowTooltipHover}>
          <AvatarContainer data-color={props.color} ref={ref} {...props}>
            {children}
          </AvatarContainer>
        </Tooltip>
      );
    }

    return (
      <AvatarContainer ref={ref} {...props}>
        {children}
      </AvatarContainer>
    );
  },
);

Avatar.displayName = 'Avatar';

export default Object.assign(Avatar, {
  Container: AvatarContainer,
  Image: AvatarImage,
  Text: AvatarText,
  Spinner: AvatarSpinner,
});
