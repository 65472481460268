import { pick } from '@/src/lib/store';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import { AssistantContextSubType } from '@/src/modules/assistant/types';
import { useCallback } from 'react';
import { shallow } from 'zustand/shallow';

const useAskAssistantSelection = () => {
  const { setAssistantSelectedIds, setLastContextSubtype, setChatAssistantOpen } =
    useAssistantStore(
      (state) =>
        pick(state, ['setLastContextSubtype', 'setAssistantSelectedIds', 'setChatAssistantOpen']),
      shallow,
    );

  return useCallback(
    (ids: string[]) => {
      setLastContextSubtype(AssistantContextSubType.Selection);
      setAssistantSelectedIds(ids);
      setChatAssistantOpen(true);
    },
    [setAssistantSelectedIds, setChatAssistantOpen, setLastContextSubtype],
  );
};

export { useAskAssistantSelection };
