import Image, { ImageProps } from 'next/image';
import { forwardRef, useState } from 'react';
import CustomSignedBunnyLoader from '../custom-image-loader';
import useOnScreen from '../hooks/useOnScreen';

type CustomImageProps = ImageProps;
const CustomImage = forwardRef<HTMLImageElement, CustomImageProps>(({ onError, ...props }, ref) => {
  const [localRef, setLocalRef] = useState<HTMLImageElement | null>(null);

  const setRef = (el: HTMLImageElement | null) => {
    setLocalRef(el);
    if (ref) {
      if (typeof ref === 'function') {
        ref(el);
      } else {
        ref.current = el;
      }
    }
  };

  const onScreen = useOnScreen(localRef, '800px');

  return (
    // eslint-disable-next-line jsx-a11y/alt-text
    <Image
      {...props}
      onError={onError}
      priority={onScreen}
      ref={setRef}
      loader={CustomSignedBunnyLoader}
    />
  );
});

CustomImage.displayName = 'CustomImage';

export default CustomImage;
