import { useResponsive } from '@/src/hooks/responsive';
import { Editor } from '@tiptap/react';
import React from 'react';
import Toolbar from '../ui/Toolbar/Toolbar';
import {
  basicGroup,
  mediaGroup,
  structuralFormattingGroup,
  textFormattingGroup,
} from '../ui/Toolbar/toolbarConfig';
import { ToolbarGroup, ToolbarItemType } from '../ui/Toolbar/types';

const desktopFixedToolbarGroups: ToolbarGroup[] = [
  structuralFormattingGroup,
  mediaGroup,
  basicGroup,
];
const mobileFixedToolbarGroups: ToolbarGroup[] = [
  basicGroup,
  structuralFormattingGroup,
  textFormattingGroup,
  mediaGroup,

  {
    name: 'done',
    label: 'Done',
    items: [
      {
        label: 'Done',
        type: ToolbarItemType.Button,
        key: 'done',
        onClick: (editor) => {
          setTimeout(() => {
            editor.chain().focus().blur().run();
          }, 10);
        },
      },
    ],
  },
];

const FixedToolbar: React.FC<{
  editor: Editor;
  onToolbarHeightChange?: (height: number) => void;
}> = ({ editor, onToolbarHeightChange }) => {
  const { isMobile } = useResponsive();

  return (
    <Toolbar
      editor={editor}
      onToolbarHeightChange={onToolbarHeightChange}
      groups={isMobile() ? mobileFixedToolbarGroups : desktopFixedToolbarGroups}
    />
  );
};

export default FixedToolbar;
