import { v4 as uuidv4 } from 'uuid';
import { OptimisticDraft } from '../../../types/draftable';
import { PrivateTag } from '@fabric/woody-client';

export const createOptimisticPrivateTag = (name: string): OptimisticDraft<PrivateTag> => {
  const optimisticId = uuidv4();

  return {
    isDraft: true,
    id: optimisticId,
    name,
  };
};
