import React from 'react';
import styles from './Chatbot.module.scss';

const ChatbotWarning: React.FC = () => {
  return (
    <div className={styles.warning}>
      Pssttt... Fabric Assistant currently only supports PDFs, notes, links/bookmarks and images.
    </div>
  );
};

export default ChatbotWarning;
