import { ResourcePreviewComponent } from '@/src/modules/resources/components/ResourcePreview/ResourcePreview.types';
import styled from 'styled-components';

export const ColorLabel: ResourcePreviewComponent = ({ colorLabels, resource }) => {
  if (!colorLabels || typeof resource.label !== 'number') return null;

  const color = colorLabels[resource.label]?.hexColor;

  if (!color) {
    return null;
  }

  return <Label style={{ background: color }} />;
};

const Label = styled.div`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  position: absolute;
  left: -1.5rem;
  top: 50%;
  transform: translateY(-50%);
`;
