import {
  isFolderFdoc,
  isImageFdoc,
  isNotepadFdoc,
  isPageFdoc,
  isStoredFileFdoc,
  isTextFdoc,
} from '@/src/modules/resources/utils/resourceTypes';
import { Fdoc } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';

/**
 * @deprecated Please use getResourceTitle and getResourcePlaceholderTitle instead
 * due to use of ?? this fn doesn't really return the default value when the value is empty string
 */
export const getResourceTitleWithDefault = (
  resource: OptimisticDraft<Fdoc>,
  includeExt = false,
) => {
  if (resource.isWebnote) return resource.data.pageTitle ?? getResourcePlaceholderTitle(resource);
  if (resource.type === 'notepad')
    return resource.data.title ?? getResourcePlaceholderTitle(resource);
  if (resource.type === 'stored_file')
    return (
      `${resource.data.title ?? ''}${includeExt && resource.data.extension ? `${resource.data.extension}` : ''}` ||
      getResourcePlaceholderTitle(resource)
    );
  return resource.data.name ?? getResourcePlaceholderTitle(resource);
};

export const getResourceTitle = (resource: OptimisticDraft<Fdoc>) => {
  if (resource.isWebnote) return resource.data.pageTitle;
  if (resource.type === 'notepad') return resource.data.title;
  if (resource.type === 'stored_file') return resource.data.title;
  return resource.data.name;
};

export const getResourcePlaceholderTitle = (resource: OptimisticDraft<Fdoc>) => {
  if (isStoredFileFdoc(resource)) {
    return 'Untitled File';
  }

  if (isPageFdoc(resource)) {
    return 'Untitled Webnote';
  }

  if (isFolderFdoc(resource)) {
    return 'Untitled Folder';
  }

  if (isTextFdoc(resource)) {
    return 'Untitled Webnote';
  }

  if (isImageFdoc(resource)) {
    return 'Untitled Webnote';
  }

  if (isNotepadFdoc(resource)) {
    return 'Unitled Notepad';
  }

  return 'Untitled';
};
