import useAuthStore, { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { isWoodyError } from '@/src/utils/error';
import { QueryObserverOptions, keepPreviousData, useQuery } from '@tanstack/react-query';
import React from 'react';
import { shallow } from 'zustand/shallow';
import { Member } from '../members.types';
import { halMembershipToMember } from '../utils/halMembershipToMember';
import { membersQueryKeys } from './membersQueryKeys';

const defaultData: Member[] = [];

export const useQueryMembersByListId = (
  listId?: string,
  queryOptions?: Partial<QueryObserverOptions<Member[]>>,
) => {
  const user = useAuthStore((state) => state.user, shallow);
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();

  const enabled = isQueryEnabled([!!listId, isLoggedIn, queryOptions?.enabled]);

  const query = useQuery({
    queryKey: membersQueryKeys.byListId(listId),
    queryFn: async (params) => {
      /**
       * query is not enabled if the listId is not provided
       * using it here should be considered safe
       */
      const listIdSafe = params.queryKey[1] as string;
      return getWoodyResponseData(
        await client.fetchMembershipsByListId(listIdSafe),
      )._embedded.item.map(halMembershipToMember);
    },
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: true,
    refetchOnMount: true,
    // small stale time so it doesn't refetch everytime simple space is rendered when filtering
    staleTime: 1000 * 15,
    retry(failureCount, error) {
      if (isWoodyError(error) && error.status === 404) return false;
      return failureCount < 3;
    },
    ...queryOptions,
    enabled,
  });

  const members = query.data || defaultData;

  const isCurrentUserMember = React.useMemo(
    () => !!members?.some((member) => member.id === user?.id),
    [members, user],
  );

  return {
    ...query,
    members,
    isCurrentUserMember,
  };
};
