import React from 'react';

import useAuthStore from '@/src/hooks/auth';
import { Comment } from '@/src/modules/comments/comments.types';
import { useMutationDeleteComment } from '@/src/modules/comments/mutations/useMutationDeleteComment';
import { IconPencil, IconTrash } from '@/src/modules/icons';
import * as Conversation from '@/src/modules/ui/components/Conversation';
import { DropdownMenu } from '@/src/modules/ui/components/DropdownMenu/DropdownMenu';
import { mediaHover, mediaMobile } from '@/src/modules/ui/styled-utils';
import AvatarUser from '@/src/modules/user/components/AvatarUser/AvatarUser';
import { Fdoc } from '@/src/types/api';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import { OptimisticDraft } from '../../types/draftable';
import { getUserColorFromId } from '../../utils/color';

type Props = {
  comment: OptimisticDraft<Comment>;
  fdoc: Fdoc;
  forceEditMode?: boolean;
  onSave?: () => void;
  onSaved?: () => void;
  disabled?: boolean;
  onEdit: () => void;
  light?: boolean;
};

const CommentComponent: React.FC<Props> = ({ comment, fdoc, onEdit }) => {
  const user = useAuthStore((state) => state.user, shallow);

  const mutationDeleteComment = useMutationDeleteComment();

  const deleteHandler = async () => {
    if (typeof comment === 'string') return;

    mutationDeleteComment.mutate({ resourceId: fdoc.id, commentId: comment.id });
  };

  const owner: { name: string; pictureUrlCdn?: string | null; color: string } =
    typeof comment === 'string'
      ? {
          name: user?.name ?? '',
          pictureUrlCdn: user?.pictureUrlCdn || undefined,
          color: getUserColorFromId(user?.id ?? ''),
        }
      : {
          name: comment.user.name || '',
          pictureUrlCdn: comment.user.pictureUrlCdn || undefined,
          color: getUserColorFromId(comment.user.id),
        };

  return (
    <CommentWrapper data-testid="comment">
      <Conversation.ItemHeader>
        <AvatarUser user={owner} size={26} color={owner.color} />
        <Conversation.ItemHeaderName>{owner.name}</Conversation.ItemHeaderName>

        {typeof comment === 'object' && !comment.isDraft && comment.user.id === user?.id && (
          <DropdownMenu
            renderTriggerElement={({ onOpenChange, open }) => (
              <DropdownButton onOpenChange={onOpenChange} open={open} />
            )}
          >
            <DropdownMenu.Group>
              <DropdownMenu.Item
                iconElement={<IconPencil />}
                onClick={onEdit}
                data-testid="comment-edit-button"
              >
                Edit
              </DropdownMenu.Item>
            </DropdownMenu.Group>
            <DropdownMenu.Group>
              <DropdownMenu.Item
                iconElement={<IconTrash />}
                variant="danger"
                onClick={deleteHandler}
                data-testid="comment-delete-button"
              >
                Delete
              </DropdownMenu.Item>
            </DropdownMenu.Group>
          </DropdownMenu>
        )}
      </Conversation.ItemHeader>
      <Conversation.ItemBody markdown={comment.content} data-or-obscured />
    </CommentWrapper>
  );
};

export default CommentComponent;

const DropdownButton = styled(DropdownMenu.TriggerButtonThreeDotsSlim)<{ open?: boolean }>`
  width: 1.625rem;
  height: 1.25rem;
  background: transparent;
  overflow: visible;
  svg {
    rotate: 90deg;
    scale: 0.65;
    color: white;
  }
  // to increase tap zone sizes
  &:before {
    content: '';
    display: block;
    width: 2rem;
    height: 2rem;
    position: absolute;
  }

  ${mediaHover} {
    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }
  }

  opacity: 0;
  ${mediaMobile} {
    opacity: 1;
    height: 1rem;
  }
  ${(p) => p.open && 'opacity: 1;'}
`;

const CommentWrapper = styled(Conversation.Item)`
  ${mediaHover} {
    &:hover {
      ${DropdownButton} {
        opacity: 1;
      }
    }
  }
`;
