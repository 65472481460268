import ImageIcon from '@/public/images/icons/categories/Image.svg';
import { CssBackgroundProp, getCssBackground } from '@/src/modules/ui/theme/cssBackground';
import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { cssVar } from '../theme/variables';

interface ImageElementProps extends CssBackgroundProp {
  fullWidth?: boolean;
  fullHeight?: boolean;
}

const ImageElement = styled.img<ImageElementProps>`
  max-width: 100%;
  object-fit: contain;
  border-radius: 6px;

  ${(p: ImageElementProps) => (p.fullWidth ? 'width: 100%;' : '')}
  ${(p: ImageElementProps) => (p.fullHeight ? 'height: 100%;' : '')}

  ${getCssBackground('secondary')}
`;

const ImageFailure = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 0;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  background: ${cssVar['color-bg-secondary']};
  color: ${cssVar['color-text-tertiary']};
  border-radius: 6px;
  pointer-events: none;

  font-size: 80%;
`;

type ImageProps = {
  src?: string;
  alt: string;
  className?: string;
  errorText?: string;
  onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  children?: React.ReactNode;
  /**
   * if enabled, this component will cachce the src without query params
   * BE always returns different token in the query params for the same image, and because FE is polling,
   * the client will always has to fetch for the same image, it just has a different token.
   *
   * If we preserve the same URL, the dom won't change, so the browser won't attempt to fetch new image
   */
} & ImageElementProps &
  React.ImgHTMLAttributes<HTMLImageElement>;

/**
 * @TODO - Standardize this more, right now it's mainly for images in the screenshot viewer of page resources.
 * But the idea is that we can use this component for all images in the future, so will need props for different
 * variations of images.
 */
export const Image = forwardRef<HTMLImageElement, ImageProps>(
  (
    { src, alt, className, errorText = 'Failed to load image', onError, children, ...props },
    ref,
  ) => {
    const [failed, setFailed] = React.useState(false);

    const showFallback = Boolean(failed || !src);

    return showFallback ? (
      children ?? (
        <ImageFailure>
          <ImageIcon width={32} height={32} />
          {errorText}
        </ImageFailure>
      )
    ) : (
      <ImageElement
        {...props}
        ref={ref}
        src={src}
        alt={alt}
        className={className}
        onError={(event) => {
          setFailed(true);
          onError?.(event);
        }}
      />
    );
  },
);

Image.displayName = 'Image';
