import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const PlayIcon: FC<IconProps> = ({ color = 'currentColor', size = 18, className, ...props }) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <path
        d="M6 9V4.22684C6 3.36399 7.01946 2.90619 7.66436 3.47943L12.1933 7.50518C13.0884 8.30077 13.0884 9.69923 12.1933 10.4948L7.66436 14.5206C7.01946 15.0938 6 14.636 6 13.7732V9Z"
        fill={color}
      />
      <path
        d="M4.875 9V3.0646C4.875 2.22891 5.83924 1.76187 6.49496 2.27997L13.0139 7.43073C14.0273 8.23148 14.0273 9.76852 13.0139 10.5693L6.49496 15.72C5.83924 16.2381 4.875 15.7711 4.875 14.9354V9Z"
        fill={color}
      />
    </svg>
  );
};

export default PlayIcon;
