import PauseIcon from '@/src/icons/PauseIcon';
import PlayIcon from '@/src/icons/PlayIcon';
import SpotifyIcon from '@/src/icons/SpotifyIcon';
import { Space } from '@/src/modules/spaces/spaces.types';
import { useWoody } from '@/src/services/woody/woody';
import { Fdoc } from '@/src/types/api';
import { SpotifyPreview } from '@fabric/woody-client';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import useSWR from 'swr';
import { OptimisticDraft } from '../../../types/draftable';
import Skeleton from '../../Skeleton/Skeleton';
import { OfViewMode } from '../../ViewModeSwitcher/ViewModeSwitcher';
import { useFdocItemContext } from '../FdocItem';
import FdocItemComments from '../FdocItemComments/FdocItemComments';
import styles from './FdocItemContentSpotify.module.scss';

import { useDomContentRect } from '@/src/hooks/useDomRect';
import { motion } from 'framer-motion';

type FdocItemContentSpotifyProps = {
  fdoc: OptimisticDraft<Fdoc>;
  viewMode: OfViewMode;
  isMobileCard?: boolean;
  list?: Space;

  size?: {
    width: number;
    height: number;
  };
};

const MOVE_SPEED_PER_PX = 0.3;

const ScrollingText = ({
  children,
  animating,
}: {
  children: React.ReactNode;
  animating: boolean;
}) => {
  const outerRef = React.useRef<HTMLDivElement | null>(null);
  const innerRef = React.useRef<HTMLDivElement | null>(null);
  const [innerRect] = useDomContentRect(innerRef.current);
  const [outerRect] = useDomContentRect(outerRef.current);

  const innerWidth = innerRect?.width || 0;
  const outerWidth = outerRect?.width || 0;

  const moveWidth = outerWidth - innerWidth;

  const shouldAnimate = animating && moveWidth < 0;

  return (
    <motion.div className={styles.scrollingText} ref={outerRef}>
      <motion.div
        ref={innerRef}
        animate="animate"
        variants={
          shouldAnimate
            ? {
                animate: {
                  x: [0, moveWidth],
                  transition: {
                    x: {
                      repeat: Infinity,
                      repeatType: 'mirror',
                      duration: -1 * moveWidth * MOVE_SPEED_PER_PX,
                      ease: 'linear',
                    },
                  },
                },
              }
            : {}
        }
        style={
          animating
            ? {
                width: 'max-content',
              }
            : {
                width: '100%',
                overflow: 'hidden',
              }
        }
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

const FdocItemContentSpotify: React.FC<FdocItemContentSpotifyProps> = ({
  fdoc,
  viewMode,
  list,
}) => {
  const { client } = useWoody();

  const { isHovered, calculatedSize, setCustomCardFailed } = useFdocItemContext();
  const [audioRef, setAudioRef] = useState<HTMLAudioElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const spotifyUrl = useMemo(() => {
    if (fdoc.type !== 'page' || !fdoc.data.pageUrl) return null;
    return fdoc.data.pageUrl;
  }, [fdoc]);

  const { data, isLoading, error } = useSWR<SpotifyPreview | undefined>(
    spotifyUrl,
    // Change this to run woody client call, throw if response.error, otherwise return the preview
    async (url) => {
      const response = await client.getSpotifyPreview(url);
      if (response.error) throw response.error;
      return response.data.data ?? undefined;
    },
    {
      // minimize the number of requests as much as possible
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      shouldRetryOnError: true,
      errorRetryCount: 3,
      errorRetryInterval: 100,
    },
  );

  const errorCount = useRef<number>(0);
  useEffect(() => {
    if (!error) {
      errorCount.current = 0;
      return;
    }

    errorCount.current += 1;

    if (errorCount.current > 3) {
      setCustomCardFailed(true);
    }
  }, [error, setCustomCardFailed]);

  useEffect(() => {
    if (isLoading || error || data) return;

    // if it has loaded, no errors and the data is undefined we also count this as an error
    setCustomCardFailed(true);
  }, [isLoading, error, data, setCustomCardFailed]);

  const onTogglePlay = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!audioRef) return;
    if (isPlaying) {
      audioRef.pause();
      setIsPlaying(false);
    } else {
      audioRef.play();
      setIsPlaying(true);
    }
  };

  // handlers for audio element
  const onAudioPlay = () => setIsPlaying(true);
  const onAudioPause = () => setIsPlaying(false);
  const onAudioEnded = () => setIsPlaying(false);
  const onAudioError = () => setIsPlaying(false);

  const isMobile = (calculatedSize?.height ?? 0) < 230;

  if (fdoc.type !== 'page') return null;
  return error || isLoading || !data ? (
    <Skeleton
      style={{
        width: '100%',
        height: '100%',
      }}
    />
  ) : (
    <>
      <div className={clsx(styles.main, isMobile && styles.mobile)}>
        <SpotifyIcon size={isMobile ? 30 : 54} />
        <div className={styles.content}>
          <ScrollingText animating={isHovered}>
            <h6>{data?.title}</h6>
          </ScrollingText>
          <ScrollingText animating={isHovered}>
            <span className={styles.subtitle}>{data?.artist}</span>
          </ScrollingText>
        </div>
        {data?.audio && !isMobile && (
          <>
            <button className={styles.play} onClick={onTogglePlay}>
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
              {`${isPlaying ? 'Pause' : 'Play'} ${data.type}`}
            </button>

            <audio
              className={styles.audio}
              ref={setAudioRef}
              onPlay={onAudioPlay}
              onPause={onAudioPause}
              onEnded={onAudioEnded}
              onError={onAudioError}
            >
              <source src={data.audio} type="audio/mpeg" />
            </audio>
          </>
        )}
      </div>

      {(fdoc.commentCount ?? 0) > 0 && (
        <FdocItemComments fdoc={fdoc} viewMode={viewMode} list={list} />
      )}
    </>
  );
};

export default FdocItemContentSpotify;
