// this attempts at fixing malformed lists in markdown strings
// e.g.
// item 1
// -
// item 2
// -
// item 3
// making sure we are not grabbing any headers or other markdown elements
// so the above would be converted to
// - item 1
// - item 2
// - item 3
//
const malformedListRegex = /^(?![#>])(.*)\n-\n/gm;

/**
 * Fixes malformed lists in the markdown string.
 * @param markdown - The raw markdown string.
 * @returns The fixed markdown string.
 */
export function fixMalformedLists(markdown: string): string {
  return markdown.replace(malformedListRegex, '- $1\n');
}
